<template>
  <el-dialog append-to-body class="custom-dialog ss-dialog no-header-bottom" title="关联内容" top="40px" width="1400px"
    :visible.sync="dialogVisible">
    <div class="content_wrapper">
      <div class="product-category">
        <div class="product-category-top">
          <div class="product-category-primary__wrap" :class="{ 'is-overflow': sliceIndex > -1 && !showAllCategory }">
            <div v-for="i in primaryCategories" class="product-category-primary"
              :class="{ 'is-active': primaryCategory && primaryCategory.id === i.id }" :key="i.id"
              @click="selectPrimaryCategory(i)">{{ i.categoryName }}</div>
          </div>
          <el-popover v-if="sliceIndex > -1" v-model="showMoreCategories" popper-class="category-popover"
            placement="bottom" width="96" trigger="click" :visible-arrow="false">
            <div class="product-category-arrow-right product-arrow-right" slot="reference"></div>
            <div v-for="(i, index) in categories.slice(1)" class="category-popover-item" :key="i.id"
              @click="selectRemainCategory(i, index)">{{ i.categoryName }}</div>
          </el-popover>
        </div>
        <div v-if="primaryCategory" class="product-category-secondary__wrap">
          <div class="product-category-secondary latest" :class="{ 'is-active': queryData.sortByUpdateAtFlag }"
            @click="setUpdateFlag">最新上架</div>
          <div v-for="(i, index) in primaryCategory.children" class="product-category-secondary secondary"
            :class="{ 'is-active': secondaryCategory && secondaryCategory.id === i.id }" :key="i.id"
            @click="selectSecondaryCategory(i, index)">
            <div class="product-category-secondary__label">{{ i.categoryName }}</div>
            <div class="product-category-secondary__number">{{ i.collectionCount + i.subjectCount }}</div>
          </div>
        </div>
      </div>
      <div class="product-content">
        <div class="product-filter">
          <div class="product-filter-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="item.id"
                @click.native="changeCollectId($event, item, index)">{{ item.name }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="product-filter-r">
            <el-input v-model="queryData.keyWord" clearable placeholder="请输入关键字搜索" prefix-icon="el-icon-search"
              size="small" style="width: 280px;" @keyup.enter.native="getList"></el-input>
          </div>
        </div>
        <div class="product-content__wrapper" ref="productWrapper">
          <div class="product-content-grid">
            <div v-for="item in tableData.records" class="subject-wrapper" :class="{ 'collection': item.type === 2 }"
              :key="item.id" @click="handleClick(item)">
              <div class="subject">
                <div class="subject-check" :class="{ 'is-checked': associatedVosMap.has(item.mirrorId) }"
                  @click.stop="handleCheck(item)">
                </div>
                <div class="subject-lt">
                  <div v-if="item.newFlag == 0" class="subject-new"></div>
                  <div v-if="item.type === 1 && item.shelfFlag !== 1" class="subject-lt-i">下架</div>
                  <div v-if="item.hideFlag" class="subject-lt-i">隐藏</div>
                </div>
                <div class="subject-cover">
                  <div v-if="item.type === 2" class="subject-multi">
                    <div class="subject-multi-empty"></div>
                  </div>
                  <template v-if="item.type === 1">
                    <el-carousel v-if="item.subjectCoverVos.length > 1" height="173px" indicator-position="none"
                      :autoplay="false">
                      <el-carousel-item v-for="i in item.subjectCoverVos" :key="i.id">
                        <img class="w-h-100" :src="i.coverUrl">
                      </el-carousel-item>
                    </el-carousel>
                    <img v-if="item.subjectCoverVos.length === 1" class="w-h-100" :src="item.subjectCoverVos[0].coverUrl">
                  </template>
                  <div class="subject-tags">
                    <div v-for="i in item.tagVos" class="subject-tag" :key="i.id">{{ i.tagName }}</div>
                  </div>
                </div>
                <div class="subject-content">
                  <div class="subject-name">{{ item.name }}</div>
                  <div class="subject-category">
                    <span>
                      <span>分类：</span>
                      <template v-if="item.subjectCategoryVos.length">
                        <span v-for="i in item.subjectCategoryVos.slice(0, 1)" class="subject-category-text"
                          :key="i.id">{{
                            i.categoryName }}</span>
                      </template>
                    </span>
                    <span v-if="item.type === 2">
                      <span>合集：{{ item.sonCollectionNumber }}&nbsp;&nbsp;&nbsp;内容：{{ item.sonSubjectNumber }}</span>
                    </span>
                  </div>
                  <div class="subject-date">最新更新：{{ Object.dayjs(item.updateAt || item.createAt) }}</div>
                  <div class="subject-toolbar">
                    <el-tooltip effect="dark" content="产品简介" placement="bottom" :enterable="false">
                      <div class="subject-toolbar-item">
                        <svg-icon icon="subject_bar_introduction" color="#8ab1ff"></svg-icon>
                      </div>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="功能介绍" placement="bottom" :enterable="false">
                      <div class="subject-toolbar-item">
                        <svg-icon icon="subject_bar_features" color="#8ab1ff"></svg-icon>
                      </div>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="相关文件" placement="bottom" :enterable="false">
                      <div class="subject-toolbar-item">
                        <svg-icon icon="subject_bar_files" color="#8ab1ff"></svg-icon>
                      </div>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="关联内容" placement="bottom" :enterable="false">
                      <div class="subject-toolbar-item disabled">
                        <svg-icon icon="subject_bar_associations" color="#8ab1ff"></svg-icon>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="product-content-pagination">
            <span class="product-content-pagination-tip">共 {{ tableData.total }} 个内容，当前展示数量 {{ tableData.records.length
            }}</span>
            <el-pagination background class="app-pagination" layout="prev, pager, next" :current-page="tableData.current"
              :page-size="tableData.size" :total="tableData.total" @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ProductLibrary } from "@/api/ProductLibrary";
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
export default {
  name: "AssociatedContentDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: Object,
    originAssociatedVos: Array,
    originAssociatedVosMap: Map,
  },
  data() {
    return {
      categories: [], // 分类列表
      sliceIndex: -2, // -2: 初始化 -1: 不需要显示展开收起按钮 大于-1: 收起时显示的数组长度
      showAllCategory: false, // 是否显示所有分类
      primaryCategory: null, // 选中的一级分类
      secondaryCategory: null, // 选中的二级分类
      showMoreCategories: false,

      queryData: {
        moduleId: "",
        categoryIds: [],
        sortByUpdateAtFlag: false,
        hideFlag: false, // 隐藏标识，true:隐藏的不展示， false：隐藏的也展示
        shelfFlag: true, // 下架标识
        keyWord: "",
        sortDesc: "update_at", // 降序
        sortAsc: "", // 升序
        collectionId: "",
        pageNo: 1,
        pageSize: 10,
      },
      tableData: new this.common.Pagination(),

      breadcrumbList: [{ id: "-1", name: "" }],
      clickItem: null, // 点击项
      associatedVos: [],
      associatedVosMap: new Map(),
      disabledCheck: false,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
    ...mapGetters(['userInfo', 'activeMenu']),
    primaryCategories() {
      if (this.sliceIndex === -2) {
        return []
      } else {
        if (this.sliceIndex === -1) {
          return this.categories
        } else {
          if (this.showAllCategory) {
            return this.categories
          } else {
            return this.categories.slice(0, this.sliceIndex);
          }
        }
      }
    }
  },
  mounted() {
    this.associatedVos = cloneDeep(this.originAssociatedVos);
    this.associatedVosMap = cloneDeep(this.originAssociatedVosMap);
    this.queryData.moduleId = this.activeMenu.id;
    this.breadcrumbList[0].name = this.activeMenu.name;
    this.getCategoryList();
  },
  methods: {
    /**
     * @description: 获取分类
     * @return {void}
     */
    async getCategoryList() {
      this.primaryCategory = null;
      this.secondaryCategory = null;
      this.queryData.categoryIds = [];
      this.queryData.sortByUpdateAtFlag = false;
      const res = await ProductLibrary.getSubjectCategoryList({
        moduleId: this.activeMenu.id,
        tenantId: this.userInfo.tenantIdNow,
      });
      let list = [];
      const props = ["moduleId", "parentId", "id", "categoryName", "privateFlag", "sortNo", "collectionCount", "subjectCount"];
      const listSortBy = sortBy(res, "sortNo"); // 按 sortNo 排序
      listSortBy.forEach(element => {
        let primary = pick(element, props);
        primary.children = [];
        if (element.children && element.children.length) {
          const inListSortBy = sortBy(element.children, "sortNo"); // 按 sortNo 排序
          inListSortBy.forEach(item => {
            let secondary = pick(item, props);
            primary.children.push(secondary);
          })
        }
        list.push(primary);
      })
      const origin = cloneDeep(list);
      list.unshift({ id: "all-categories", categoryName: "全部", children: origin });
      this.categories = list;
      this.primaryCategory = this.categories[0];
      this.sliceIndex = this.getSliceIndex(this.categories);
      this.queryData.collectionId = "";
      this.breadcrumbList.splice(1);
      this.getTableData();
    },
    /**
     * @description: 获取分类收起时显示的数组长度
     * @param {Array} array 一级分类数组
     * @return {Number} 数组长度
     */
    getSliceIndex(array) {
      let sum = 0;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const width = (this.getElementWidth(element.categoryName) * 100 + 1600) / 100; // 16 = 左右 padding 3 + margin-right 10
        // 242: 一级分类的容器宽度 226: 显示展开 icon 时一级分类的容器宽度
        if (sum + width > 242) {
          return sum > 226 ? index - 1 : index;
        }
        sum += width;
      }
      return -1
    },
    /**
     * @description: 获取文本元素宽度
     * @param {String} text 文本
     * @param {String} id 文本id
     * @return {Number} 文本元素宽度
     */
    getElementWidth(text, id = "virtualSpan") {
      let el = document.createElement("span");
      el.setAttribute('id', id);
      el.innerHTML = text;
      el.style.setProperty('font-size', "14px");
      el.style.setProperty('font-weight', 400);
      el.style.setProperty('height', 0);
      el.style.setProperty('padding', 0);
      el.style.setProperty('border', "none");
      el.style.setProperty('margin', 0);
      document.body.appendChild(el);
      let el2 = document.getElementById(id);
      const width = Math.ceil(parseInt(el2.getBoundingClientRect().width * 1000) / 10) / 100;
      document.body.removeChild(el);
      return width
    },
    /**
     * @description: 选择展开的一级分类
     * @param {Object} value
     * @return {void}
     */
    selectRemainCategory(value, index) {
      this.showMoreCategories = false;
      this.primaryCategory = value;
      this.queryData.sortByUpdateAtFlag = false;
      if (this.sliceIndex - 2 >= 0 && this.sliceIndex - 2 < index) {
        const list = this.categories.filter(item => item.id !== "all-categories" && item.id !== value.id);
        list.unshift(value);
        const newList = cloneDeep(list);
        list.unshift({ id: "all-categories", categoryName: "全部", children: newList });
        this.categories = list;
        this.sliceIndex = this.getSliceIndex(this.categories);
      }
      this.queryData.categoryIds = [value.id];
      value.children.forEach(element => this.queryData.categoryIds.push(element.id));
      this.queryData.collectionId = "";
      this.breadcrumbList.splice(1);
      this.getTableData();
    },
    /**
     * @description: 选择一级分类
     * @param {Object} value 点击项
     * @return {void}
     */
    selectPrimaryCategory(value) {
      if (this.primaryCategory && this.primaryCategory.id === value.id) {
        return
      }
      this.primaryCategory = value;
      this.secondaryCategory = null;
      this.queryData.sortByUpdateAtFlag = false;
      this.queryData.categoryIds = [];
      if (value.id !== "all-categories") {
        this.queryData.categoryIds.push(value.id);
        value.children.forEach(element => this.queryData.categoryIds.push(element.id));
      }
      this.queryData.collectionId = "";
      this.breadcrumbList.splice(1);
      this.getTableData();
    },
    /**
     * @description: 选择二级分类
     * @param {Object} value 点击项
     * @return {void}
     */
    selectSecondaryCategory(value, index) {
      if (value.parentId === "-1") {
        // 选择一级分类
        this.primaryCategory = value;
        this.secondaryCategory = null;
        this.queryData.sortByUpdateAtFlag = false;
        if (this.sliceIndex - 2 >= 0 && this.sliceIndex - 2 < index) {
          const list = this.categories.filter(item => item.id !== "all-categories" && item.id !== value.id);
          list.unshift(value);
          const newList = cloneDeep(list);
          list.unshift({ id: "all-categories", categoryName: "全部", children: newList });
          this.categories = list;
          this.sliceIndex = this.getSliceIndex(this.categories);
        }
        this.queryData.categoryIds = [value.id];
        value.children.forEach(element => this.queryData.categoryIds.push(element.id));
        this.queryData.collectionId = "";
        this.breadcrumbList.splice(1);
        this.getTableData();
      } else {
        // 选择二级分类
        if (this.secondaryCategory && this.secondaryCategory.id === value.id) {
          this.secondaryCategory = null;
        } else {
          this.secondaryCategory = value;
          this.queryData.sortByUpdateAtFlag = false;
        }
        if (this.secondaryCategory) {
          this.queryData.categoryIds = [this.secondaryCategory.id];
        } else {
          this.queryData.categoryIds = [this.primaryCategory.id];
          this.primaryCategory.children.forEach(element => this.queryData.categoryIds.push(element.id));
        }
        this.queryData.collectionId = "";
        this.breadcrumbList.splice(1);
        this.getTableData();
      }
    },
    /**
     * @description: 选择最新上架
     * @return {void}
     */
    setUpdateFlag() {
      this.queryData.sortByUpdateAtFlag = !this.queryData.sortByUpdateAtFlag;
      if (this.queryData.sortByUpdateAtFlag && this.secondaryCategory) {
        this.secondaryCategory = null;
      }
      this.queryData.categoryIds = [];
      if (this.primaryCategory && this.primaryCategory.id !== "all-categories") {
        this.queryData.categoryIds.push(this.primaryCategory.id);
        this.primaryCategory.children.forEach(element => this.queryData.categoryIds.push(element.id));
      }
      this.queryData.collectionId = "";
      this.breadcrumbList.splice(1);
      this.getTableData();
    },
    /**
     * @description: 获取产品列表
     * @return {void}
     */
    async getTableData() {
      const loading = this.$loading({ target: this.$refs.productWrapper, background: "#ebeef2" });
      this.tableData = await ProductLibrary.getSubjectList(this.queryData);
      this.tableData.records.forEach(element => element.type === 2 && (element.mirrorId = element.id));
      loading.close();
    },
    /**
     * @description: currentPage 改变时会触发
     * @param {Number} value 当前页
     * @return {void}
     */
    handleCurrentChange(value) {
      this.queryData.pageNo = value;
      this.getTableData();
    },
    /**
     * @description: 点击面包屑
     * @param {PointerEvent} e
     * @param {Object} value 点击项
     * @param {Number} index 点击项索引
     * @return {void}
     */
    changeCollectId(e, value, index) {
      if (index === this.breadcrumbList.length - 1) {
        return
      }
      if (e.target.className === "el-breadcrumb__inner") {
        this.queryData.collectionId = value.id;
        this.breadcrumbList.splice(index + 1);
        this.getTableData();
      }
    },
    /**
     * @description: 点击主题/合集
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(value) {
      this.clickItem = value;
      if (value.type === 2) {
        this.queryData.collectionId = value.id;
        const index = this.breadcrumbList.findIndex(item => item.id === value.id);
        index === -1 && this.breadcrumbList.push({ id: value.id, name: value.name });
        this.getTableData();
      }
    },
    /**
     * @description: 选中、取消选中
     * @param {Object} value 选择项
     * @return {void}
     */
    async handleCheck(value) {
      if (this.disabledCheck) {
        return
      }
      this.disabledCheck = true;
      if (value.type === 1) {
        if (this.associatedVosMap.has(value.mirrorId)) {
          this.associatedVosMap.delete(value.mirrorId);
          const index = this.associatedVos.findIndex(item => item.targetMirrorId === value.mirrorId);
          index !== -1 && this.associatedVos.splice(index, 1);
        } else {
          let taget = {
            subjectId: this.current.id,
            targetSubjectId: value.id,
            targetSubjectName: value.name,
            targetMirrorId: value.mirrorId,
            targetType: 1,
            targetVersionId: value.versionId,
          }
          this.associatedVosMap.set(value.mirrorId, taget);
          value.coverList = value.subjectCoverVos;
          value.targetSubjectName = value.name;
          value = { ...value, ...taget };
          const index = this.associatedVos.findIndex(item => item.targetMirrorId === value.mirrorId);
          index === -1 && this.associatedVos.push(value);
        }
      } else {
        const response = await ProductLibrary.querySubjectListByCollectionId(value.id);
        if (this.associatedVosMap.has(value.mirrorId)) {
          this.associatedVosMap.delete(value.mirrorId);
          response.forEach(element => {
            this.associatedVosMap.delete(element.mirrorId);
            const index = this.associatedVos.findIndex(item => item.targetMirrorId === element.mirrorId);
            index !== -1 && this.associatedVos.splice(index, 1);
          })
        } else {
          this.associatedVosMap.set(value.mirrorId, {
            subjectId: this.current.id,
            targetSubjectId: value.id,
            targetSubjectName: value.name,
            targetType: 6
          });
          response.forEach(element => {
            const target = {
              subjectId: this.current.id,
              targetSubjectId: element.id,
              targetSubjectName: element.name,
              targetType: 1,
              targetMirrorId: element.mirrorId,
              targetVersionId: element.versionId,
            }
            !this.associatedVosMap.has(element.mirrorId) && this.associatedVosMap.set(element.mirrorId, target);
            element.coverList = element.subjectCoverVos;
            element.targetSubjectName = element.name;
            element = { ...element, ...target };
            const index = this.associatedVos.findIndex(item => item.targetMirrorId === element.mirrorId);
            index === -1 && this.associatedVos.push(element);
          })
        }
      }
      this.$forceUpdate();
      this.disabledCheck = false;
    },
    /**
     * @description: 确定
     * @return {void}
     */
    handleConfirm() {
      this.dialogVisible = false;
      this.$emit("finish", { associatedVos: this.associatedVos, associatedVosMap: this.associatedVosMap });
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-dialog {
  ::v-deep .el-dialog {
    margin-bottom: 20px;
  }
}

.content_wrapper {
  height: 680px;
  padding: 20px 10px 20px 30px;
  background-color: #ebeef2;
  display: flex;
}

.product-category {
  box-sizing: border-box;
  width: 270px;
  height: 100%;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  margin-right: 8px;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  .product-category-top {
    border-bottom: 1px solid #e6ebf2;
    margin: 0 14px;
    position: relative;

    .product-category-primary__wrap {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      display: flex;

      &.is-overflow {
        padding-right: 16px;
      }

      .product-category-primary {
        width: fit-content;
        height: 45px;
        line-height: 45px;
        padding: 0 3px;
        border-bottom: 2px solid #fff;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #344563;
        cursor: pointer;

        &.is-active {
          border-bottom-color: #1664ff;
        }
      }
    }

    .product-category-arrow-right {
      position: absolute;
      right: 0;
      top: 15px;
    }
  }

  .product-category-secondary__wrap {
    flex: 1;
    box-sizing: border-box;
    padding: 14px;
    overflow-y: auto;
    font-size: 14px;
    font-weight: 400;
    color: #344563;

    .product-category-secondary {
      box-sizing: border-box;
      height: 45px;
      line-height: 45px;
      padding: 0 20px 0 50px;
      border-radius: 5px;
      border: 1px solid #f6f6f8;
      margin-bottom: 10px;
      display: flex;
      position: relative;
      cursor: pointer;

      &:hover {
        background-color: #f5f7fa;
      }

      &.is-active {
        color: #fff;
        background-color: #1664ff;
        border-color: #1664ff;

        &.secondary::before {
          background-image: url("@/assets/images/productLibrary/secondary-category2.png");
        }

        .product-category-secondary__number {
          color: #d1e2ff;
        }
      }

      &.latest::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 13px;
        width: 24px;
        height: 24px;
        background-image: url("@/assets/images/productLibrary/new.png");
        background-size: 24px 24px;
        background-repeat: no-repeat;
      }

      &.secondary::before {
        content: "";
        position: absolute;
        top: 14px;
        left: 17px;
        width: 16px;
        height: 16px;
        background-image: url("@/assets/images/productLibrary/secondary-category.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }

      .product-category-secondary__label {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .product-category-secondary__number {
        margin-left: 12px;
        color: #9facc2;
      }
    }
  }
}

.product-arrow-right {
  width: 16px;
  height: 16px;
  background-image: url("@/assets/images/productLibrary/arrow-right.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-image: url("@/assets/images/productLibrary/arrow-right__hover.png");
  }
}

.product-content {
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .product-filter {
    display: flex;
    align-items: center;
    padding: 0 20px 10px 8px;

    .product-filter-breadcrumb {
      flex: 1;
      min-width: 200px;

      ::v-deep .el-breadcrumb {
        overflow-x: auto;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
          display: none;
        }

        .el-breadcrumb__item {
          float: none;
          flex-shrink: 0;

          .el-breadcrumb__inner {
            font-weight: 700;
            color: #4f5d74;
            cursor: pointer;
          }

          .el-breadcrumb__separator[class*=icon] {
            margin: 0;
          }

          &:last-child {
            .el-breadcrumb__inner {
              font-weight: 400;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .product-filter-r {
      padding-left: 18px;
      display: flex;

      .product-filter-panel {
        width: 82px;
        height: 30px;
        margin-left: 10px;
        border-radius: 5px;
        border: 1px solid #d3dae6;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .product-filter-panel__label {
          font-size: 14px;
          font-weight: 500;
          color: #344563;
          padding: 0 6px;
        }
      }
    }
  }

  .product-content__wrapper {
    width: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .product-content-grid {
      width: 100%;
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      &::-webkit-scrollbar-thumb {
        background: #abb4c4;
      }
    }
  }
}

.subject-wrapper {
  width: 250px;
  height: 280px;
  padding: 8px;
  border-radius: 5px;
  position: relative;

  &.collection {
    background-image: url('@/assets/images/productLibrary/collection.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .subject {
      .subject-content {
        background-color: #fcfcfc;
      }
    }

    &:hover {
      background-image: url('@/assets/images/productLibrary/collection_hover.png');
    }
  }

  &:not(.collection):hover {
    .subject {
      box-shadow: 0px 0px 4px 4px rgba(52, 69, 99, 0.1);
    }
  }

  .subject {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    &:hover {
      .subject-date {
        display: none;
      }

      .subject-toolbar {
        display: flex;
      }
    }

    .subject-new {
      // position: absolute;
      // z-index: 10;
      // top: 0;
      // left: 0;
      width: 31px;
      height: 15px;
      margin-right: 8px;
      background: url('@/assets/images/productLibrary/new_content.png');
      background-size: 31px 15px;
      background-repeat: no-repeat;
    }

    .subject-lt {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      display: flex;

      .subject-lt-i {
        width: 42px;
        height: 22px;
        border-radius: 5px 0 5px 0;
        background-color: rgba(52, 69, 99, .8);
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        line-height: 22px;
        text-align: center;

        &+.subject-lt-i {
          margin-left: 4px;
        }
      }
    }

    .subject-check {
      position: absolute;
      z-index: 10;
      top: 12px;
      right: 12px;
      width: 18px;
      height: 18px;
      background-image: url("@/assets/images/productLibrary/check0.png");
      background-size: 18px 18px;
      background-repeat: no-repeat;
      cursor: pointer;

      &:hover {
        background-image: url("@/assets/images/productLibrary/check1.png");
      }

      &.is-checked {
        background-image: url("@/assets/images/productLibrary/check1.png");
      }
    }

    .subject-cover {
      width: 100%;
      height: 173px;
      position: relative;

      .subject-multi {
        width: 217px;
        height: 143px;
        margin: 0 14px 0 19px;
        padding-top: 30px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .subject-multi-empty {
          width: 118px;
          height: 101px;
          font-size: 16px;
          font-weight: 500;
          color: #ccc;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: url("@/assets/images/productLibrary/folder.png");
          background-size: 118px 101px;
          background-repeat: no-repeat;
        }
      }

      .subject-tags {
        position: absolute;
        z-index: 10;
        max-width: 238px;
        bottom: 4px;
        right: 6px;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;

        .subject-tag {
          width: fit-content;
          padding: 4px 8px;
          margin: 3px;
          border-radius: 2px;
          background: rgba(0, 186, 173, 0.8);
          font-size: 12px;
          font-weight: 500;
          color: #fff;
        }
      }
    }

    .subject-content {
      width: 100%;
      height: calc(100% - 173px);
      border-radius: 0 0 5px 5px;
      background-color: #fff;
    }

    .subject-name {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 700;
      color: #344563;
      padding: 18px 20px 0;
    }

    .subject-category {
      line-height: 18px;
      font-size: 12px;
      font-weight: 500;
      color: #8997b0;
      padding: 8px 20px;
      display: flex;
      justify-content: space-between;

      .subject-category-text {
        color: #2a82e4;
        cursor: pointer;

        &+.subject-category-text {
          margin-left: 6px;
        }
      }
    }


    .subject-date {
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      font-weight: 500;
      color: #8997b0;
      padding: 0 20px;
    }

    .subject-toolbar {
      padding: 0 20px;
      display: none;

      .subject-toolbar-item {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(.disabled):hover {
          background-color: #dbeeff;

          .svg-icon {
            color: #1664ff !important;
          }
        }

        &.disabled {
          .svg-icon {
            color: #cbd1db !important;
          }
        }

        &+.subject-toolbar-item {
          margin-left: 12px;
        }
      }
    }
  }
}

.product-content-pagination {
  padding: 8px 20px 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .product-content-pagination-tip {
    font-size: 14px;
    font-weight: 500;
    color: #5e6c84;
  }

  .app-pagination {
    ::v-deep .btn-prev {
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
      border: 1px solid #d3dce6;
    }

    ::v-deep .btn-next {
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
      border: 1px solid #d3dce6;
    }

    ::v-deep .el-pager {
      li {
        height: 30px;
        line-height: 30px;
        border-radius: 2px;
        border: 1px solid #d3dce6;
      }

      .number {
        font-size: 12px;
        font-weight: 500;
        color: #586677;
        background-color: #fff;

        &:hover {
          background: #dbeeff;
          border-color: #91b6ff;
        }
      }

      li:not(.disabled).active {
        background: #5991ff;
        border-color: #1664ff;
      }
    }
  }
}
</style>