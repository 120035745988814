<template>
  <el-dialog append-to-body class="ss-dialog" :width="current ? '400px' : '740px'" :title="current ? '编辑栏目' : '新增展示栏目'"
    :close-on-click-modal="false" :visible.sync="dialogVisible">
    <div class="column-title" v-if="!current">
      <el-radio-group v-model="column.columnCategoryId">
        <el-radio-button v-for="i in categoryList" :key="i.id" :name="i.id" :label="i.id">{{ i.categoryName
          }}</el-radio-button>
      </el-radio-group>
    </div>
    <div class="column-title flex align-items-center">
      <span>名称：</span>
      <el-input v-model="column.columnName" placeholder="请输入栏目名称" maxlength="10"></el-input>
      <component :is="column.columnIcon.startsWith('http')?'svg-icon-2':'svg-icon'" width="36" height="36"
        :icon="column.columnIcon"></component>
      <el-popover popper-class="popover" placement="bottom" width="280" trigger="click">
        <div class="column-icon__placeholder">
          <div v-loading="iconLoading" class="column-icon__wrapper" ref="iconWrapper" :class="{ 'is-open': true }">
            <!-- <div class="xs-icon-caret" @click="changeCollapse"></div> -->
            <div v-for="(i, index) in iconList" class="column-icon"
              :class="{ 'is-active': column.columnIcon === i.url, 'deleting': isDetete }" :key="i.id"
              @click.prevent="handleSelectIcon(i)">
              <svg-icon v-if="i.iconType === 'local'" width="36" height="36" :icon="i.url"></svg-icon>
              <svg-icon-2 v-if="i.iconType === 'online'" width="36" height="36" color="#5e6c84" activeColor="#ffffff"
                :icon="i.url" :is-active="column.columnIcon === i.url" />
              <div v-if="i.iconType === 'online' && isDetete" class="column-icon__delete" @click="deleteIcon(i, index)">
                <img src="@/assets/images/common/module-icon-close.png">
              </div>
            </div>
            <el-upload accept=".svg," name="photo" :action="action" :disabled="isDetete" :headers="headers"
              :show-file-list="false" :on-success="handleIconSuccess">
              <div class="icon-button" title="上传图标,仅支持SVG格式的图标,推荐大小为36x36" :class="{ 'disabled': isDetete }">
                <svg-icon width="14" height="14" icon="module-icon-plus"></svg-icon>
              </div>
            </el-upload>
            <div v-if="showDeleteIcon && !isDetete" class="icon-button" title="删除图标" @click="isDetete = !isDetete">
              <svg-icon width="14" height="4" icon="module-icon-subtract"></svg-icon>
            </div>
            <div v-if="isDetete" class="icon-button" @click="isDetete = !isDetete">
              <svg-icon width="14" height="12" icon="module-icon-check"></svg-icon>
            </div>
          </div>
        </div>
        <!-- <svg-icon slot="reference" width="36" height="36" icon="column-icon-1"></svg-icon> -->
        <div slot="reference" style="width: 20px;height: 20px;">
          <img class="pointer" style="width: 6px;height: 5px;margin-left: 6px"
            src="@/assets/images/common/triangle_down.png" alt="">
        </div>
      </el-popover>
    </div>
    <div class="content flex" v-if="!current && column.columnCategoryId == '1668175815360421890'">
      <div class="template-content">
        <el-input prefix-icon="el-icon-search" v-model="modelname" :disabled="true" placeholder="搜索模板"></el-input>
        <el-tabs>
          <el-tab-pane label="经典模板（32）"></el-tab-pane>
          <el-tab-pane label="现代风模板（14）"></el-tab-pane>
          <el-tab-pane label="古典风模板（7）"></el-tab-pane>
        </el-tabs>
        <div class="template">
          <div class="template-item is-active" style="background-color: #f5f7fa;">
            <i class="el-icon-plus plus"></i>
            <div class="template-item-cover"></div>
            <div class="template-item-name" style="color: #b6c0d1;text-align: center;">不使用模板</div>
          </div>
          <div v-for="i in 2" class="template-item disabled" title="模板功能正在开发中..." :key="i">
            <img class="template-item-cover" :src="require(`@/assets/images/sample-${i}.png`)" alt="">
            <div class="template-item-name">测试模板{{ i }}</div>
            <div class="template-item-date">更新于 {{ Object.dayjs(Date.now(), "YYYY-MM-DD") }}</div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button type="info" @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确定</el-button>
    </span>
    <el-dialog v-if="actionPanelVisible" append-to-body class="custom-contextmenu" id="contextmenu" ref="contextmenu"
      :modal="false" :visible.sync="actionPanelVisible">
      <div class="popover-item" id="deleteButton" @click="deleteModuleIcon">删除</div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ProductLibrary } from "@/api/ProductLibrary";
import { Template } from "@/api/Template";
import { Module } from "@/api/Module";
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
export default {
  name: "ColumnDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: Object,
    subject: Object, // 主题
    columnList: Array, // 栏目列表
  },
  data() {
    return {
      loading: true,
      isOpen: false, // 是否展开图标面板
      isDetete: false, // 删除状态
      iconLoading: false, // 图标面板loading
      showDeleteIcon: false, // 是否显示图标面板的删除图标
      column: {
        columnCategoryId: "",
        columnCategoryName: "",
        id: "",
        columnIcon: "column-icon-1",
        columnName: "",
        sortNo: null,
        columnContents: [{ content: "", id: "", subjectColumnId: "" }]
      },
      rules: {
        columnIcon: { required: true, message: '请选择栏目图标', trigger: ['blur', 'change'] },
        columnName: { required: true, message: '请输入栏目名称', trigger: ['blur', 'change'] },
        columnCategoryId: { required: true, message: '请选择栏目类型', trigger: ['blur', 'change'] },
      },
      iconList: [
        { id: "column-icon-1", url: "column-icon-1", iconType: "local" },//链接
        { id: "column-icon-2", url: "column-icon-2", iconType: "local" },//介绍
        { id: "column-icon-3", url: "column-icon-3", iconType: "local" },//文件
        { id: "column-icon-4", url: "column-icon-4", iconType: "local" },//文章
        { id: "column-icon-5", url: "column-icon-5", iconType: "local" },//关联
      ],
      categoryList: [],
      confirmLoading: false,

      action: "",
      headers: {
        "X-Access-Token": "",
        "Tenant-Id": "",
      },
      uploadedIconList: [], // 已上传图标列表
      iconPanelVisible: false, // 是否显示图标面板
      selectedIcon: null, // 选择的在线图标
      actionPanelVisible: false, // 显示右键删除按钮
      clickedIcon: null, // 右键点击的图标
      modelname: "",
      filecolumnid: "",//文件栏目的id
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
    ...mapGetters(["token", "userInfo"])
  },
  watch: {
    'column.columnCategoryId'(val) {
      this.column.columnName = ""
      if (!this.current) {
        // 每个类型给一个默认图标
        const column = this.categoryList.filter(item => item.id == val)
        if (column.length) {
          const type = this.handleIcon(column[0].categoryCode)
          this.column.columnIcon = 'column-icon-' + type
        }
      }
    }
  },
  created() {
    this.action = process.env.VUE_APP_URL + "/sales-show-4.0/subject/uploadIcon";
    this.headers['X-Access-Token'] = this.token;
    this.headers['Tenant-Id'] = this.userInfo.tenantIdNow;
  },
  mounted() {
    this.column.sortNo = this.columnList.length + 1;
    if (this.current) {
      this.column = pick(this.current, ["columnCategoryId", "columnCategoryName", "id", "columnIcon", "columnName", "sortNo", "columnContents"]);
    }
    this.getColumnCategoryList();
    this.getIconList();
    document.addEventListener("click", this.clickEvent, false);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickEvent, false);
  },
  methods: {
    /**
     * @description: 获取栏目类型图标，因为旧数据的关系，这里只能先这样分类型了
     * @return {void}
     */
    handleIcon(typecode) {
      switch (typecode) {
        case 1:
          return "2"
        case 2:
          return "3"
        case 3:
          return "5"
        case 4:
          return "1"
        case 5:
          return "4"
        default:
          return "2";
      }
    },
    /**
     * @description: 获取栏目类型列表
     * @return {void}
     */
    async getColumnCategoryList() {
      let res = await Template.getTemplateColumnList();
      // 这里是之前产品说的文件清单栏目和内容关联栏目只能创建一个
      // res.forEach(element => {
      //   element.disabled = false;
      //   if (element.categoryName === "文件清单栏目" && this.columnList.find(i => i.columnCategoryName === "文件清单栏目")) {
      //     element.disabled = true;
      //   }
      //   if (element.categoryName === "内容关联栏目" && this.columnList.find(i => i.columnCategoryName === "内容关联栏目")) {
      //     element.disabled = true;
      //   }
      // });
      // 过滤文件栏目
      this.categoryList = res.filter(item => item.categoryCode != 2);
      res.forEach(element => {
        if (element.categoryName === "文件清单栏目") {
          this.filecolumnid = element.id
        }
      });
      if (this.current) {
        this.column.columnCategoryId = this.current.columnCategoryId
        this.column.columnName = this.current.columnName
      } else {
        this.column.columnCategoryId = this.categoryList[0].id
      }
    },
    /**
     * @description: 选择图标
     * @param {String} value 图标
     * @return {void}
     */
    selectIcon(value) {
      this.column.columnIcon = value;
      this.$refs.form.validateField("columnIcon");
    },
    /**
     * @description: 选择已上传的图标
     * @param {Object} value 当前选择项
     * @return {void}
     */
    selectUploadedIcon(value) {
      this.selectedIcon = value;
      this.column.columnIcon = value.url;
      this.$refs.column.validateField("columnIcon");
      this.$refs.iconPopover && this.$refs.iconPopover.doClose();
    },
    /**
     * @description: 显示模块图标列表
     * @return {void}
     */
    async showIconPanel() {
      this.iconPanelVisible = !this.iconPanelVisible;
      this.iconPanelVisible && this.getIconList();
    },
    /**
     * @description: 获取上传的图标列表
     * @return {void}
     */
    async getIconList(url) {
      this.iconLoading = true;
      const res = await Module.queryColumnIconList();
      this.showDeleteIcon = Boolean(res.length);
      if (url) {
        const item = res.find(i => i.url === url);
        item && this.iconList.push({ id: item.id, url: item.url, iconType: "online" })
      } else {
        const list = res.map(item => {
          return { id: item.id, url: item.url, iconType: "online" }
        })
        this.iconList = [...this.iconList, ...list]
        const index = this.iconList.findIndex(item => item.url === this.column.columnIcon);
        if (index > 2) {
          const select = this.iconList[index];
          this.iconList.splice(index, 1);
          this.iconList.unshift(select);
        }
      }
      this.loading = false;
      this.iconLoading = false;
    },
    /**
     * @description: 删除模块图标
     * @return {void}
     */
    async deleteModuleIcon() {
      this.actionPanelVisible = false;
      const res = await Module.deleteSubjectColumnIcon(this.clickedIcon.id);
      if (res) {
        if (this.selectedIcon && this.selectedIcon.id === this.clickedIcon.id) {
          this.selectedIcon = null;
          this.column.columnIcon = "";
        }
        this.getIconList();
      }
    },
    /**
     * @description: 右键点击已上传的图标
     * @param {PointerEvent} event
     * @return {void}
     */
    contextmenu(event, value) {
      this.clickedIcon = value;
      this.top = event.y;
      this.left = event.x;
      this.actionPanelVisible = true;
      this.$nextTick(() => {
        const contextmenu = this.$refs.contextmenu;
        const dialog = contextmenu.$el.getElementsByClassName("el-dialog")[0];
        dialog.setAttribute("style", `top: ${event.y + 4}px; left: ${event.x + 4}px;`);
      })
    },
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      if (!this.column.columnName.trim()) {
        this.$message.error('名称不能为空')
        return
      }
      this.confirmLoading = true;
      const columnCategory = this.categoryList.find(item => item.id === this.column.columnCategoryId);
      this.column.columnCategoryName = columnCategory.categoryName;
      let payload = pick(this.column, ["columnCategoryId", "columnIcon", "columnName", "sortNo"]);
      payload.id = this.subject.id;
      payload.moduleId = this.subject.moduleId;
      payload.versionId = this.subject.versionId;
      payload.columnId = this.current ? this.current.id : "";
      payload.parentId = "-1";
      const res = await ProductLibrary.updateColumn(this.current ? "edit" : "add", payload);
      this.confirmLoading = false;
      if (res) {
        if (!this.current) {
          this.column.id = res.id;
          this.column.columnContents[0].subjectColumnId = res.id;
        }
        this.$emit("finish", { type: this.current ? "edit" : "add", value: this.column });
        this.dialogVisible = false;
      }
    },
    /**
     * @description: 删除模块图标
     * @return {void}
     */
    async deleteIcon(value, index) {
      this.iconLoading = true;
      const res = await Module.deleteSubjectColumnIcon(value.id);
      if (res) {
        this.iconList.splice(index, 1);
        const onlineIconList = this.iconList.filter(item => item.url.startsWith("http"));
        this.showDeleteIcon = Boolean(onlineIconList.length);
        // 删除已选中的图标则重新选中第一个图标
        if (value.url === this.column.columnIcon) {
          this.column.columnIcon = this.iconList[0].url;
        }
      }
      this.iconLoading = false;
    },
    /**
     * @description: 文件上传成功时的钩子
     * @param {Object} response 接口返回的参数
     * @return {void}
     */
    handleIconSuccess(response) {
      if (response.code === 200) {
        this.$message.success("图标上传成功");
        this.getIconList(response.result.src);
      }
    },
    /**
     * @description: 点击事件
     * @param {PointerEvent} event
     * @return {void}
     */
    clickEvent(event) {
      if (this.isOpen && !this.$refs.iconWrapper.contains(event.target)) {
        this.changeCollapse();
      }
    },
    /**
     * @description: 打开、关闭图标面板
     * @return {void}
     */
    changeCollapse() {
      if (this.isOpen) {
        this.$refs.iconWrapper.scrollTo(0, 0); // 滚动到顶部
        // 选中图标索引大于 2 则将它至于数组开头
        const index = this.iconList.findIndex(item => item.url === this.column.columnIcon);
        if (index > 2) {
          const select = this.iconList[index];
          this.iconList.splice(index, 1);
          this.iconList.unshift(select);
        }
        this.isDetete = false;
      }
      this.isOpen = !this.isOpen;
    },
    /**
     * @description: 选择图标
     * @param {Object} value 图标
     * @return {void}
     */
    handleSelectIcon(value) {
      this.column.columnIcon = value.url;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/scss/demo.scss";

.template-content {
  padding: 0 20px;
  box-sizing: border-box;
}

.column-title {
  padding: 10px 40px;

  ::v-deep .el-input {
    width: 320px;
    flex: 1;
  }
}

.column-icon__placeholder {
  width: 266px;
  // height: 72px;
  position: relative;

  .column-icon__wrapper {
    width: 100%;
    box-sizing: border-box;
    max-height: 72px;
    padding: 0 16px;
    // border: 1px solid #c5d0e3;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: hidden;

    &.is-open {
      max-height: 212px;
      overflow-y: auto;

      .xs-icon-caret {
        transform: rotate(180deg);
      }
    }

    .xs-icon-caret {
      box-sizing: content-box;
      width: 6px;
      height: 5px;
      padding: 4px;
      background-image: url("@/assets/images/common/triangle_down.png");
      background-position: 4px 4px;
      background-size: 6px 5px;
      background-repeat: no-repeat;
      transition: all .3s;
      cursor: pointer;
    }

    .column-icon {
      box-sizing: border-box;
      width: 54px;
      height: 54px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;

      .column-icon__delete {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 10px;
        background-color: rgba(52, 69, 99, .8);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 25px;
          height: 25px;
        }
      }

      .svg-icon {
        color: #5e6c84 !important;
      }

      &:not(.is-active, .deleting):hover {
        border-color: var(--color-primary);
      }

      &.is-active {
        border-color: var(--color-primary);
        background-color: var(--color-primary);

        .svg-icon {
          color: #fff !important;
        }
      }
    }

    .icon-button {
      box-sizing: border-box;
      width: 36px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid var(--color-text-regular);
      margin: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .svg-icon {
        color: var(--color-text-regular) !important;
      }

      &:not(.disabled):hover {
        background-color: var(--color-primary);
        border-color: var(--color-primary);

        .svg-icon {
          color: #fff !important;
        }
      }

      &.disabled {
        border-color: #dae0eb;
        cursor: not-allowed;

        .svg-icon {
          color: #dae0eb !important;
        }
      }
    }
  }
}

.content {
  padding: 24px;

  .wrapper {
    // width: fit-content;
    border-radius: 5px;
    background: #fff;
    // padding: 8px 16px;
    border: 1px solid #c5d0e3;
    display: flex;
    align-items: center;

    .icon-wrapper {
      box-sizing: border-box;
      width: 54px;
      height: 54px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .svg-icon {
        color: #5e6c84 !important;
      }

      &:not(.is-active, .deleting):hover {
        border-color: var(--color-primary);
      }

      &.is-active {
        border-color: var(--color-primary);
        background-color: var(--color-primary);

        .svg-icon {
          color: #fff !important;
        }
      }
    }

    .arrow {
      width: 6px;
      height: 5px;
      background-image: url("@/assets/images/common/triangle_down.png");
      background-size: 6px 5px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.custom-contextmenu {
  .el-dialog {
    width: 100px;
    margin: 0 !important;
    position: absolute !important;
    border-radius: 5px;
    border: 1px solid #d3dae6;
    box-shadow: 0px 0px 5px 2px rgba(94, 108, 132, 0.2);

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 8px;
    }
  }
}

.column-icon-panel-popover {
  box-sizing: border-box;
  height: 192px;
  padding: 10px;
  border-radius: 5px;
  overflow-y: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.column-icon-panel-popover__wrapper {
  display: grid;
  grid-gap: 10px 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.column-icon-panel-popover-item {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-active {
    background-color: #fff;
    // border-color: #8997b0;
    border-color: #8997b0;
  }

  &:hover {
    background-color: #1644ff;

    .online-svg {
      color: #fff !important;
    }

    .online-img {
      img {
        filter: drop-shadow(#fff 36px 0);
        transform: translateX(-36px);
      }
    }
  }

  &:not(.is-active):hover {
    border-color: #f2f7ff;
  }

  .icon {
    width: 36px;
    height: 36px;
  }
}

.column-icon-panel-popover-uploader {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border: 1px solid #8997b0;
  border-radius: 5px;

  &:hover {
    background-color: #f2f7ff;
  }

  .el-upload {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>