<template>
  <el-dialog append-to-body class="ss-dialog" width="560px" :visible.sync="dialogVisible">
    <div slot="title">
      <div class="title">
        <span>编辑分类</span>
        <div class="title-action" @click="handleAdd(null)">
          <i class="el-icon-plus"></i>
          <span style="margin-left: 4px;">添加大分类</span>
        </div>
      </div>
    </div>
    <div class="content">
      <el-table v-loading="loading" default-expand-all header-cell-class-name="app-header-cell" height="360px" ref="table"
        row-key="id" :data="tableData" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column label="分类" prop="dictName" width="300px">
          <template slot-scope="{ row }">
            <div class="category-name"
              :class="{ 'pl': row.level === 1 && row.children.length === 0, 'left-icon': row.level === 2 }">
              <div class="category-name__text">{{ row.dictName }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <i v-if="row.level === 1" class="el-icon-plus action-btn" @click="handleAdd(row)"></i>
            <i v-if="row.dictType !== 1" class="el-icon-edit-outline action-btn"
              :class="row.level === 1 ? 'ml-20' : 'ml-40'" @click="handleEdit(row)"></i>
            <i v-if="row.dictType !== 1" class="el-icon-delete action-btn ml-20" @click="handleDelete(row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </div>
    <el-dialog append-to-body destroy-on-close class="ss-dialog" width="400px" :title="targetId ? '编辑分类' : '新增分类'"
      :visible.sync="innerVisible">
      <div style="padding: 22px 30px 0;">
        <el-form label-width="100px" ref="form" :model="categoryVo" :rules="rules">
          <el-form-item v-if="firstCategory" label="上级分类：">
            <span>{{ firstCategory ? firstCategory.dictName : "" }}</span>
          </el-form-item>
          <el-form-item label="分类名称：" prop="dictName">
            <el-input v-model="categoryVo.dictName" placeholder="请输入分类名称" :maxlength="10"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="innerVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUpdateSingle">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { File } from "@/api/File";
import { cloneDeep } from 'lodash';
export default {
  name: "FileCategoryDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    queryId: Object,
  },
  data() {
    return {
      loading: true,
      tableData: [],
      confirmLoading: false,
      firstCategory: null,
      innerVisible: false,
      categoryVo: {
        parentId: "",
        delFlag: 0,
        dictName: "",
        dictType: 2,
      },
      rules: {
        dictName: { required: true, message: "请输入分类名称", trigger: ["blur", "change"] },
      },
      targetId: "",
      deleteList: [], // 删除的分类
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
    ...mapGetters(['userInfo', 'activeMenu']),
  },
  mounted() {
    this.getCategoryTree();
  },
  methods: {
    /**
     * @description: 获取分类树
     * @return {void}
     */
    async getCategoryTree() {
      const res = await File.queryFileByCategoryTree({ dataType: 1, ...this.queryId });
      let list = [];
      const defaults = ["演示视频", "演示模型", "演示文档"];
      res.forEach(element => {
        let first = {
          level: 1,
          parentId: "",
          id: element.dictId,
          delFlag: 0,
          dictName: element.dictName,
          dictType: element.dictType,
          children: [],
        }
        if (element.detailDTOList && element.detailDTOList.length) {
          element.detailDTOList.forEach(item => {
            first.children.push({
              level: 2,
              parentId: element.dictId,
              id: item.id,
              delFlag: 0,
              dictName: item.itemText,
              dictType: element.dictType === 1 && defaults.includes(item.itemText) ? 1 : 2,
              children: null,
            })
          });
        }
        list.push(first);
      })
      this.tableData = list;
      this.loading = false;
    },
    /**
     * @description: 获取分类:先获取一级分类列表,再获取每个一级分类下的二级分类列表(接口设计问题,不能一次性获取整个分类树)
     * @return {void}
     */
    async getFirstCategory() {
      let res = await File.queryFileByFirstCategory(this.queryId);
      res.forEach(async element => {
        element.children = await File.queryFileBySecondCategory({ dictId: element.id, ...this.queryId });
      });
    },
    /**
     * @description: 删除
     * @param {Object} value 点击项
     * @return {void}
     */
    handleDelete(value) {
      if (value.parentId) {
        const index = this.tableData.findIndex(item => item.id === value.parentId);
        const inIndex = this.tableData[index].children.findIndex(item => item.id === value.id);
        this.tableData[index].children.splice(inIndex, 1);
      } else {
        const index = this.tableData.findIndex(item => item.id === value.id);
        this.tableData.splice(index, 1);
      }
      if (!value.id.startsWith("new-")) {
        // 删除的一级分类放在前面、二级分类放后面
        value.parentId ? this.deleteList.push(value) : this.deleteList.unshift(value);
      }
    },
    /**
     * @description: 新增分类
     * @param {Object} value
     * @return {void}
     */
    handleAdd(value) {
      this.firstCategory = value;
      this.categoryVo.parentId = value ? value.id : "";
      this.categoryVo.dictName = "";
      this.targetId = "";
      this.innerVisible = true;
      this.$nextTick(() => this.$refs.form.clearValidate("dictName"));
    },
    /**
     * @description: 新增一个分类
     * @return {void}
     */
    handleUpdateSingle() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        if (this.firstCategory) {
          const index = this.tableData.findIndex(item => item.id === this.firstCategory.id);
          if (this.targetId) {
            const inIndex = this.tableData[index].children.findIndex(item => item.id === this.targetId);
            this.$set(this.tableData[index].children[inIndex], "dictName", this.categoryVo.dictName);
          } else {
            this.tableData[index].children.push({ level: 2, id: "new-" + Date.now(), children: null, ...this.categoryVo })
          }
        } else {
          if (this.targetId) {
            const index = this.tableData.findIndex(item => item.id === this.targetId);
            this.$set(this.tableData[index], "dictName", this.categoryVo.dictName);
          } else {
            this.tableData.push({ level: 1, id: "new-" + Date.now(), children: [], ...this.categoryVo });
          }
        }
        this.innerVisible = false;
      })
    },
    /**
     * @description: 编辑分类
     * @param {Object} value
     * @return {void}
     */
    handleEdit(value) {
      if (value.level === 2) {
        this.firstCategory = this.tableData.find(item => item.id === value.parentId);
      } else {
        this.firstCategory = null;
      }
      this.categoryVo.parentId = value.parentId;
      this.categoryVo.dictName = value.dictName;
      this.targetId = value.id;
      this.innerVisible = true;
    },
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      this.confirmLoading = true;
      let addBatchDTOS = [];
      const tableData = cloneDeep(this.tableData);
      // 插入删除的分类
      if (this.deleteList.length) {
        this.deleteList.forEach(element => {
          if (element.level === 1) {
            element.delFlag = 1;
            tableData.push(element);
          } else {
            const index = tableData.findIndex(item => item.id === element.parentId);
            if (index !== -1) {
              const inIndex = tableData[index].children.findIndex(item => item.id === element.id);
              if (inIndex !== -1) {
                tableData[index].children[inIndex].delFlag = 1;
              } else {
                element.delFlag = 1;
                tableData[index].children.push(element);
              }
            }
          }
        })
      }
      tableData.forEach(element => {
        let first = {
          id: element.id.startsWith("new-") ? "" : element.id,
          delFlag: element.delFlag,
          dictName: element.dictName,
          dictType: element.dictType,
          detailDTOS: [],
        }
        if (element.children && element.children.length) {
          element.children.forEach(item => {
            first.detailDTOS.push({
              id: item.id.startsWith("new-") ? "" : item.id,
              delFlag: element.delFlag === 1 ? 1 : item.delFlag,
              itemText: item.dictName,
            })
          });
        }
        addBatchDTOS.push(first);
      })
      const payload = {
        addBatchDTOS,
        dataType: 1,
        ...this.queryId
      }
      const res = await File.saveBatchFileCategory(payload);
      this.confirmLoading = false;
      if (res) {
        this.dialogVisible = false;
        this.$emit("refresh");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding-right: 48px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-action {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #8997b0;
    cursor: pointer;
    position: relative;
    padding-left: 20px;

    &:hover {
      color: var(--color-primary);
    }
  }
}

.content {
  padding: 0 30px 20px;
  font-weight: 500;

  ::v-deep .el-table {
    .el-table__body tr:hover {
      td {
        background-color: #f5f7fa;
      }
    }

    .el-table__body tr td .cell {
      display: flex;
      align-items: center;
    }
  }

  .category-name {
    width: fit-content;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    position: relative;

    &.pl {
      padding-left: 24px;
    }

    &.left-icon {
      padding-left: 16px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 10px;
        height: 12px;
        background-image: url("@/assets/images/productLibrary/icon-L.png");
        background-size: 10px 12px;
        background-repeat: no-repeat;
      }
    }
  }

  .category-name__text {
    width: fit-content;
    height: 32px;
    line-height: 32px;
    padding: 0 6px;
  }

  .svg {
    vertical-align: 0;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &+.svg {
      margin-left: 20px;
    }
  }

}

.action-btn {
  cursor: pointer;
  font-size: 20px;

  &.ml-20 {
    margin-left: 20px;
  }

  &.ml-40 {
    margin-left: 40px;
  }
}
</style>