var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scheme-container"},[_c('div',{staticClass:"w-h-100 flex app-scheme-wrapper"},[(!_vm.showscheme && _vm.schemeList && _vm.schemeList.length > 1)?_c('div',{staticClass:"app-scheme-sidebar"},[_c('svg-icon',{staticClass:"scheme-sidebar-icon pointer",class:{ 'scheme-sidebar-icon-active': _vm.showscheme },attrs:{"icon":"icon-scheme","height":20,"width":20},nativeOn:{"click":function($event){_vm.showscheme = !_vm.showscheme}}})],1):_vm._e(),(_vm.showscheme)?_c('div',{staticClass:"app-scheme-left"},[_c('div',{staticClass:"scheme-title flex-between align-items-center"},[_c('div',{staticClass:"scheme-title-name"},[_vm._v("展示方案")]),_c('div',{staticClass:"flex align-items-center"},[(!_vm.ispre)?_c('svg-icon',{staticClass:"mr-10 pointer",attrs:{"icon":"mind-add","color":"#5E6C84","height":12,"width":12},nativeOn:{"click":function($event){return _vm.handleCreat(null)}}}):_vm._e(),_c('svg-icon',{staticClass:"mr-10 pointer",attrs:{"icon":"close-side","color":"#5E6C84","height":16,"width":16},nativeOn:{"click":function($event){_vm.showscheme = false}}})],1)]),_c('div',{staticClass:"scheme-title-list"},_vm._l((_vm.schemeList),function(i){return _c('div',{key:i.id,staticClass:"flex scheme-title-list-items pointer align-items-center",class:{ 'items-active': _vm.activeScheme && _vm.activeScheme.id == i.id },on:{"click":function($event){return _vm.handleClick(i)}}},[(!i.isDefault)?_c('svg-icon',{staticClass:"scheme-items-icon",attrs:{"icon":"icon-scheme","height":16,"width":16,"color":"#344563"}}):_vm._e(),(i.isDefault)?_c('div',{staticClass:"scheme-items-icon-default"},[_vm._v("默认")]):_vm._e(),_c('div',{staticClass:"scheme-items-name one-line"},[_vm._v(_vm._s(i.name))])],1)}),0)]):_vm._e(),_c('div',{staticClass:"app-scheme-main main-edit"},[[(
            _vm.activeColumn &&
            _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '5'
          )?_c('ArticlePreview',{attrs:{"active-column":_vm.activeColumn,"scheme":_vm.activeScheme,"subject":_vm.subject,"loginrequired":_vm.loginrequired,"shareId":_vm.currentpayload.shareId}}):_vm._e(),(
            _vm.activeColumn &&
            _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '1'
          )?_c('ContentPreview',{attrs:{"active-column":_vm.activeColumn,"scheme":_vm.activeScheme,"subject":_vm.subject,"loginrequired":_vm.loginrequired,"shareId":_vm.currentpayload.shareId}}):_vm._e(),(
            _vm.activeColumn &&
            _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '4'
          )?_c('LinkPreview',{attrs:{"active-column":_vm.activeColumn,"scheme":_vm.activeScheme,"subject":_vm.subject,"loginrequired":_vm.loginrequired,"shareId":_vm.currentpayload.shareId}}):_vm._e(),(
            _vm.activeColumn &&
            _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '3'
          )?_c('ProductPreview',{attrs:{"active-column":_vm.activeColumn,"scheme":_vm.activeScheme,"subject":_vm.subject,"loginrequired":_vm.loginrequired,"shareId":_vm.currentpayload.shareId}}):_vm._e(),(!_vm.activeColumn && _vm.isfile)?_c('FilePreview',{attrs:{"active-column":_vm.activeColumn,"scheme":_vm.activeScheme,"subject":_vm.subject,"loginrequired":_vm.loginrequired,"shareId":_vm.currentpayload.shareId}}):_vm._e()]],2)]),(this.schemeList.length && _vm.ispre)?_c('div',{staticClass:"tool-center project-tool-center"},[_vm._l((_vm.columnList),function(i){return _c('div',{key:i.id,staticClass:"tool-center-item",class:{
        'is-active': _vm.activeColumn && i.id === _vm.activeColumn.id,
        'show-more': !_vm.isPreview,
      },on:{"click":function($event){return _vm.changeColumn(i)}}},[_c('div',{staticClass:"tool-center-item-w"},[_c(i.columnIcon.startsWith('http') ? 'svg-icon-2' : 'svg-icon',{tag:"component",attrs:{"width":"25","height":"25","color":"#5e6c84","activeColor":"#fff","icon":i.columnIcon ? i.columnIcon : 'column-icon-1',"is-active":_vm.activeColumn?.id === i.id}}),_c('div',{staticClass:"tool-center-item__label one-line",staticStyle:{"width":"100%","text-align":"center"},attrs:{"title":i.columnName}},[_vm._v(" "+_vm._s(i.columnName)+" ")])],1)])}),_c('div',{staticClass:"tool-center-item",class:{
        'is-active': !_vm.activeColumn && _vm.isfile,
        'show-more': !_vm.isPreview,
      },on:{"click":_vm.handleShowFile}},[_c('div',{staticClass:"tool-center-item-w"},[_c('svg-icon',{attrs:{"icon":"column-icon-3","width":"25","height":"25"}}),_c('div',{staticClass:"tool-center-item__label"},[_vm._v("文件")])],1)])],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }