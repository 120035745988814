import request from "@/utils/appServer";
import common from "@/utils/common";
class Template {
  // 模板分类列表
  static async getTemplateCategoryList(moduleCategoryId) {
    try {
      const res = await request.get('/sales-show-4.0/template/category/queryList', { params: { moduleCategoryId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 新增、编辑模板分类
  static async updateTemplateCategory(url, data) {
    try {
      const res = await request.post('/sales-show-4.0/template/category/' + url, data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除模板分类
  static async deleteTemplateCategory(id) {
    try {
      const res = await request.get('/sales-show-4.0/template/category/delete', { params: { id } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 平台管理-模板列表
  static async getTemplateList(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/queryPageBySystem', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 设置模板上架、下架
  static async updateTemplateStatus(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/updateStatus', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 设置模板是否公开
  static async updatePublicFlag(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/updatePublicFlag', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 租户端-模板列表/根据模板分类id获取模板列表
  static async getTemplate(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/queryPageByTenant', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 租户端-常用模板
  static async getCommonTemplate(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/queryCommonTemplate', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination(1, 4);
    } catch (error) {
      return new common.Pagination(1, 4);
    }
  }
  // 租户端-推荐模板列表
  static async getRecommendTemplate(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/queryRecommendTemplate', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination(1, 4);
    } catch (error) {
      return new common.Pagination(1, 4);
    }
  }
  // 模板市场-模板列表
  static async getTemplateFromMarket(data) {
    try {
      const res = await request.get('/sales-show-4.0/template/queryPageByTemplateMarket', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination(1, 4);
    } catch (error) {
      return new common.Pagination(1, 4);
    }
  }

  // 主题模板 - 新增、编辑
  static async updateTemplate(url, data) {
    try {
      const res = await request.post('/sales-show-4.0/template/' + url, data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 主题模板 - 删除
  static async deleteTemplate(id) {
    try {
      const res = await request.get('/sales-show-4.0/template/delete', { params: { id } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 主题模板 - 查看模板详情
  static async getTemplateInfo(id) {
    try {
      const res = await request.get('/sales-show-4.0/template/getInfo', { params: { id } });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 模板-获取主题封面列表
  static async getTemplateCovers(templateId) {
    try {
      const res = await request.get('/sales-show-4.0/template/queryCoverPhotoList', { params: { templateId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 模板-上传封面
  static async uploadCoverPhoto(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/uploadCoverPhoto', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 模板栏目分类列表
  static async getTemplateColumnList() {
    try {
      const res = await request.get('/sales-show-4.0/templateColumn/category/queryList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 新增、编辑栏目
  static async updateColumn(url, data) {
    try {
      const res = await request.post(`/sales-show-4.0/template/${url}Column`, data, true);
      return res.data.code === 200 ? res.data.result || true : false;
    } catch (error) {
      return false;
    }
  }
  // 删除栏目
  static async deleteColumn(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/deleteColumn', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
}

export { Template }