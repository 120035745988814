<template>
  <div class="product-card-contain" :class="{ 'product-card-auto': showtype == 2 }">
    <div
      class="product-card"
      :class="{ 'product-card2': showtype == 2 }"
      v-for="item in tableData"
      :key="item.id"
      @click="handlePreview(item)"
    >
      <div class="product-card-cover">
        <img class="w-h-100" :src="item.subjectCoverVos[0].coverUrl" alt="" />
      </div>
      <div class="product2-card__info">
        <div class="product-card__info-title one-line">
          {{ item.subjectName ? item.subjectName : item.sourceName }}
        </div>
        <div class="product-card__info-introduce" v-if="item.coverRemark">
          {{ item.coverRemark }}
        </div>
        <div
          class="product-card__info-introduce product-card__info-nointroduce"
          v-if="!item.coverRemark"
        >
          <!-- 暂无简介 -->
        </div>
        <div
          class="product-card__info-bottom"
          :class="{ 'product-card__info-bottom-demo': demonstrate }"
        >
          <div class="product-card__info-update">
            最新更新：{{ Object.dayjs(item.updateAt) }}
          </div>
          <!-- 底部工具栏 -->
          <div class="product-card__info-toolbar">
            <div class="product-card__info-toolbar-left">
              <el-tooltip
                v-for="column in item.settingColumn"
                :key="column.id"
                effect="dark"
                placement="bottom"
                :enterable="false"
                :content="column.columnName"
              >
                <div
                  class="product-card__info-toolbar-item"
                  @click.stop="getDetail(item, column)"
                >
                  <svg-icon
                    :icon="column.columnIcon ? column.columnIcon : 'column-icon-1'"
                    color="#344563"
                  ></svg-icon>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="check-icon" @click.stop="handleSelect(item)">
        <svg-icon
          v-if="showtype == 2"
          icon="check-purple"
          :color="checkSelect(item) ? '#365EFF' : '#ccc'"
          width="22"
          height="18"
        ></svg-icon>
      </div>
    </div>
    <FileList
      v-if="fileListVisible"
      :visible.sync="fileListVisible"
      :subject="clickItem"
      :dialog-title="fileListType"
      :activecolum="activeColumn"
    />
    <QuickPreview
      v-if="showPreview"
      :visible.sync="showPreview"
      :subject="clickItem"
      :activeid="activecolumnid"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import { Homecontents } from "@/api/Homecontents";
import { subjectdata } from "../data";
import FileList from "@/views/ProductLibrary/dialog/FileList.vue";
import QuickPreview from "@/views/ProductLibrary/dialog/QuickPreview/index.vue";
import ProdudtEdito from "@/views/ProdudtEdito/index.vue";
import PlayEditor from "@/views/PlayEditor/index.vue";
export default {
  name: "quote",
  props: {
    selectlist: Array,
    showtype: {
      type: Number,
      default: 1, //1不需要选框，2有选择框
    },
    demonstrate: {
      type: Boolean,
      default: false, //是否为演示类
    },
    datalist: Object,
  },
  components: { FileList, QuickPreview },
  data() {
    return {
      tableData: [],
      viewType: "grid",
      clickItem: null,
      fileListVisible: false, // 演示视频、模型、文档弹窗
      showPreview: false,
      // demonstrate: false,
      selectData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    selectlist(val) {
      this.selectData = val;
    },
  },
  created() {},
  mounted() {
    if (this.datalist) {
      this.datalist.data.forEach((element) => {
        element.settingColumn.settingSubjectColumnVos = _.sortBy(
          element.settingColumn.settingSubjectColumnVos,
          "sortNo"
        );
        if (element.subjectCoverVos.length === 0) {
          element.subjectCoverVos.push({
            coverUrl: require(`@/assets/images/sample-${this.common.getLastChar(
              this.showtype == 2 ? element.sourceId : element.id,
            )}.png`),
          });
        }
      });
      this.tableData = this.datalist.data;
    }
    this.selectlist && (this.selectData = _.cloneDeep(this.selectlist));
  },
  methods: {
    /**
     * @description: 点击主题/合集
     * @param {Object} value 点击项
     * @return {void}
     */
    async handlePreview(value) {
      const payload = {
        subjectId: this.showtype==2?value.sourceId:value.id,
        mirrorId: value.mirrorId,
        versionId: value.versionId,
        moduleId: value.moduleId,
      };
      const type = await Homecontents.getTypeByModuleId(value.moduleId);
      let props;
      if (type == 1) {
        props = {
          currentpayload: payload,
          // visivle: true,
          previewtype: this.Viewstatus == 1,
        };
      } else {
        props = {
          unitqueryids: payload,
          // visivle: true,
          previewtype: this.Viewstatus == 1,
        };
      }
      this.$JhDialog({
        // visivle: true,
        zIndexValue:6666,//层级提高一点，盖过el-dialog
        componentName: type == 1 ? ProdudtEdito : PlayEditor,
        componentProps: props,
      });
    },
    /**
     * @description: 切换格子/列表视图
     * @param {Object} value 点击项
     * @return {void}
     */
    changeViewType(type) {
      this.viewType = type;
    },
    /**
     * @description: 复制
     * @param {String} text 复制项
     * @return {void}
     */
    handleCopy(text) {
      const res = this.common.handleCopy(text);
      res && this.$message.success("复制成功");
    },
    /**
     * @description:预览演示模式
     * @param {Object} value  点击项
     * @return {void}
     */
    handleDemoClick(value, id) {
      this.$router.push({
        path: "/product-preview",
        query: {
          subjectId: this.showtype == 2 ? value.sourceId : value.id,
          mirrorId: value.mirrorId,
          versionId: value.versionId,
          demoId: id,
        },
      });
    },
    /**
     * @description:获取主题详情
     * @param {Object} value  点击项
     * @param {Object} value  栏目
     * @return {void}
     */
    async getDetail(value, column) {
      this.clickItem = value;
      const res = await ProductLibrary.getSubjectDetail({
        mirrorId: value.mirrorId,
        subjectId: this.showtype == 2 ? value.sourceId : value.id,
        versionId: value.versionId,
      });
      if (res.subjectColumnVos) {
        res.subjectColumnVos.forEach((element) => {
          if (element.id == column.id) {
            this.activeColumn = element;
          }
        });
      }
      if (this.activeColumn.columnCategoryName == "文件清单栏目") {
        this.fileListVisible = true;
      } else {
        this.showPreview = true;
        this.activecolumnid = column.id;
      }
    },
    handleSelect(value) {
      const index = this.selectData.findIndex((item) => item.id === value.id);
      if (index !== -1) {
        this.selectData.splice(index, 1);
      } else {
        this.selectData.push(value);
      }
      this.$emit("select", this.selectData);
    },
    checkSelect(value) {
      const index = this.selectData.findIndex((item) => item.id === value.id);
      return index != "-1";
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card-contain {
  // height: 100%;
  width: 100%;
  padding: 16px 7px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 18px 18px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  .product-content-grid__wrapper {
    display: grid;
    grid-gap: 18px 18px;
  }
  .product-card {
    height: 230px;
    background-color: #ccc;
    box-shadow: 0px 0px 2px 2px rgba(52, 69, 99, 0.1);
    position: relative;
    .product-card-cover {
      width: 100%;
      height: 120px;
    }
    &:hover {
      box-shadow: 0px 0px 4px 4px rgba(52, 69, 99, 0.1);
    }
    .check-icon {
      position: absolute;
      right: 8px;
      top: 5px;
    }
  }
  .product-card2 {
    height: 260px;
    .product-card-cover {
      width: 100%;
      height: 150px;
    }
  }
}
::v-deep .el-divider--horizonta {
  margin: 15px 0;
}
.product-card__info-toolbar {
  .product-card__info-toolbar-item {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 7px;
    box-sizing: border-box;

    &.disabled {
      cursor: default;

      .svg-icon {
        color: #c4cbd7 !important;
      }
    }

    &:not(.disabled):hover {
      background-color: #e4ecf7;
    }
  }
}
.morelink-dropdown {
  box-shadow: 0px 0px 5px 2px rgba(94, 108, 132, 0.2);

  .el-dropdown-menu__item {
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #344563;
    border-radius: 4px;
    padding: 0;
    margin: 0 8px;

    &:hover {
      color: var(--color-text-primary);
      background-color: #fbfaff;
    }

    .filter-dropdown-item {
      position: relative;
      line-height: 32px;
      padding: 0 10px 0 10px;
      .title {
        width: 96px;
        color: #6a87fc;
      }
      .item {
        display: flex;
        align-items: center;
        &:hover {
          color: var(--color-primary);
          .svg-icon {
            color: var(--color-primary) !important;
          }
        }
      }
    }
  }
}
.product2-card__info {
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  padding-top: 13px;
  border-radius: 0 0 5px 5px;
  background-color: #fff;

  .product-card__info-introduce {
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    padding: 0 16px;
    color: var(--color-text-primary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .product-card__info-nointroduce {
    color: var(--color-text-placeholder);
  }

  .product-card__info-bottom {
    // width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0 16px;
    background-color: #f5f8fc;

    .product-card__info-update {
      color: #8997b0;
    }

    .product-card__info-toolbar-left {
      display: flex;
      width: 80%;

      .el-tooltip {
        margin-right: 10px;
      }
    }
    &:hover {
      .product-card__info-toolbar {
        display: flex;
      }
      .product-card__info-update {
        display: none;
      }
    }
  }
  .product-card__info-bottom-demo {
    &:hover {
      .product-card__info-toolbar {
        display: none;
      }
      .product-card__info-update {
        display: block;
      }
    }
  }

  .product-card__info-title {
    position: relative;
    height: 20px;
    line-height: 20px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text-regular);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 16px;

    &.collection-private {
      padding-left: 20px;

      &::before {
        position: absolute;
        content: "";
        top: 2px;
        left: 0;
        width: 16px;
        height: 16px;
        background: url("@/assets/images/productLibrary/collection-private.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
    }
  }

  .product-card__info-update {
    // height: 25px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-secondary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .product-card__info-count {
    height: 25px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-secondary);
    display: none;

    div {
      width: 50%;
      min-width: 60px;
      flex-shrink: 0;
    }
  }

  .product-card__info-toolbar {
    // height: 25px;
    display: none;

    .product-card__info-toolbar-item {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 7px;
      box-sizing: border-box;

      &.disabled {
        cursor: default;

        .svg-icon {
          color: #c4cbd7 !important;
        }
      }

      &:not(.disabled):hover {
        background-color: #e4ecf7;
      }
    }
  }
}

@media screen and (min-width: 2560px) {
  .product-card-auto {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media screen and (max-width: 2560px) and (min-width: 2401px) {
  .product-card-auto {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media screen and (max-width: 2400px) and (min-width: 2160px) {
  .product-card-auto {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media screen and (max-width: 2159px) and (min-width: 1921px) {
  .product-card-auto {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1920px) and (min-width: 1680px) {
  .product-card-auto {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1679px) and (min-width: 1440px) {
  .product-card-auto {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .product-card-auto {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1023px) and (min-width: 801px) {
  .product-card-auto {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media screen and (max-width: 800px) and (min-width: 0) {
  .product-card-auto {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
