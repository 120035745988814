<template>
  <div class="jscontainer" ref="boxRef" @click="handleClick">
    <div class="nodetool" ref="nodetool" :style="menuStyle">
      <el-tooltip
        class="item"
        effect="dark"
        content="编辑节点"
        placement="top-start"
        v-if="node"
      >
        <div class="item-icon ml-0" @click="editornode">
          <svg-icon
            icon="mind-smallpen"
            width="16"
            height="16"
            color="#ffffff"
          ></svg-icon>
        </div>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="添加同级节点"
        placement="top-start"
        v-if="node && !node.isroot && node.data.dataType != 1"
      >
        <el-popover
          placement="right"
          popper-class="mindedit-popover"
          trigger="manual"
          v-model="addvisible"
        >
          <div class="flex item-addtype">
            <div
              v-for="item in tools"
              :key="'tool' + item.id"
              @click="addnode(item.id)"
              :style="`background:url(${imageicons[item.id]})`"
            >
              {{ item.name }}
            </div>
          </div>
          <div
            class="item-icon"
            slot="reference"
            :class="{ 'ml-0': this.node && this.node.isroot }"
            @click="addvisible = true"
          >
            <svg-icon icon="mind-add" width="16" height="16" color="#ffffff"></svg-icon>
          </div>
        </el-popover>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="添加子节点"
        placement="top-start"
        v-if="node && node.data.dataType != 1"
      >
        <el-popover
          placement="right"
          popper-class="mindedit-popover"
          v-model="branchvisible"
          trigger="manual"
        >
          <div class="flex item-addtype">
            <div
              v-for="item in tools"
              :key="'tool' + item.id"
              @click="addchlidnode(item.id)"
              :style="`background:url(${imageicons[item.id]})`"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="item-icon" slot="reference" @click="branchvisible = true">
            <svg-icon
              icon="mind-branch"
              width="16"
              height="16"
              color="#ffffff"
            ></svg-icon>
          </div>
        </el-popover>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="删除节点"
        placement="top-start"
        v-if="node && !node.isroot"
      >
        <div class="item-icon" @click="deletenode()">
          <svg-icon icon="mind-delete" width="16" height="16" color="#ffffff"></svg-icon>
        </div>
      </el-tooltip>
    </div>
    <div id="jsmind_container" ref="jsmind"></div>
  </div>
</template>
<script>
import { Playeditor } from "@/api/Playeditor";
// import "jsmind/style/jsmind.css";
// import jsMind from "jsmind/js/jsmind.js";
import "@/utils/jsmind/style/jsmind.css";
import jsMind from "@/utils/jsmind/js/jsmind.js";
import imagelist from "../../DemoMode/component/Base64Img";
window.jsMind = jsMind;
export default {
  name: "MyJsmind",
  props: {
    data: Object,
    activeDemo: Object,
    // clickoutlineid: String,
    ispreview: {
      default: false,
      type: Boolean,
    },
    queryIds: Object,
    loginrequired: {
      type: Boolean,
      default: true,//是否需要登录
    },
  },
  computed: {},
  data: function () {
    return {
      menuStyle: {
        display: "none",
        position: "absolute",
        top: 0,
        left: 0,
      },
      jm: null,
      clickoutlineid: "",
      whichoutline: null,
      nodeTree: [],
      mindinfo: {
        id: "1234",
        topic: "",
        children: [],
        "font-size": "14",
        "background-color": "#78A5FF",
        "background-image": imagelist[0],
        outlineType: 1,
      },
      detaildata: "",
      node: "",
      imageicons: imagelist,
      tools: [
        {
          id: 1,
          name: "网页",
        },
        {
          id: 2,
          name: "关联内容",
        },
        {
          id: 3,
          name: "栏目",
        },
        {
          id: 4,
          name: "文章",
        },
        {
          id: 5,
          name: "链接",
        },
        {
          id: 6,
          name: "文档",
        },
      ],
      branchvisible: false, //添加分支的popover
      addvisible: false, //添加同级节点的popover
      subject: null,
      pubsubToken: [],
    };
  },
  watch: {
    ispreview(val) {
      if (val) {
        this.jm && this.jm.disable_edit();
      } 
    },
    // queryIds: {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     if (this.jm) {
    //       var container = document.getElementById("jsmind_container");
    //       container.innerHTML = "";
    //       this.menuStyle.display = "none";
    //       this.jm = null;
    //     }
    //     val && val.subjectId && this.handleCurrent();
    //   },
    // },
  },
  mounted() {
    this.pubsubToken.push(
      // 发布一个更新节点名称的方法
      this.$Pubsub.subscribe("update-mind-name-data", (val) => {
        this.UpdateAssNode(val.id, val.name);
      }),
      this.$Pubsub.subscribe("update-mind-data", (id) => {
        if (this.jm) {
          let container = document.getElementById("jsmind_container");
          container.innerHTML = "";
          this.menuStyle.display = "none";
          this.jm = null;
        }
        this.queryIds && this.queryIds.subjectId && this.handleCurrent(id);
      }),
      this.$Pubsub.subscribe("update-mind-select-data", (id) => {
        if (this.jm) {
          this.jm.select_node(id);
        }
      })
    );
    this.handleCurrent();
  },
  beforeDestroy() {
    this.pubsubToken.forEach((token) => {
      this.$Pubsub.unsubscribe(token);
    });
    this.pubsubToken = [];
    if (this.jm) {
      let container = document.getElementById("jsmind_container");
      if (container) container.innerHTML = "";
      this.menuStyle.display = "none";
      this.jm = null;
    }
  },
  methods: {
    init_data() {
      const mind = {
        /* 元数据，定义思维导图的名称、作者、版本等信息 */
        meta: {
          name: "演示",
          // author: "hizzgdev@163.com",
          // version: "0.2",
        },
        /* 数据格式声明 */
        format: "node_tree",
        data: this.mindinfo,
      };

      const options = {
        container: "jsmind_container",
        editable: true,
        // 主题样式
        theme: "belizehole",
        // 指定新增子节点在右侧，fullz则是左右都有
        mode: "side",
        layout: {
          hspace: 40, // 节点之间的水平间距
          vspace: 46, // 节点之间的垂直间距
          pspace: 13, // 节点与连接线之间的水平间距（用于容纳节点收缩/展开控制器）
          cousin_space: 0, // 相邻节点的子节点之间的额外的垂直间距
        },
        default_event_handle: {
          // enable_mousedown_handle: true,
          enable_click_handle: true,
          enable_dblclick_handle: true,
          enable_mousewheel_handle: false,
        },
        view: {
          engine: "svg",
          draggable: true, // 当容器不能完全容纳思维导图时，是否允许拖动画布代替鼠标滚动
          hide_scrollbars_when_draggable: true, // 当设置 draggable = true 时，是否隐藏滚动条
          node_overflow: "hidden", // 节点文本过长时的样式wrap换行
          line_color: "#D3DBEB", // 思维导图线条的颜色
          line_width: 1,
        },
        shortcut: {
          enable: false, // 是否启用快捷键
        },
      };
      this.jm = new jsMind(options);
      this.jm.show(mind);
      // 点击事件，监听点击的键
      this.jm.add_event_listener((e, key) => {
        if (!this.jm) return;
        let s1 = this.jm.get_selected_node();
        if (s1 && this.$refs.boxRef) {
          if (s1.isroot) {
            // 根节点是否可编辑
            // this.jm.disable_edit();
          } else {
            this.jm.enable_edit();
          }
          this.node = s1;
          const boxRect = this.$refs.boxRef.getBoundingClientRect();
          // 菜单的位置
          const menuLeft =
            s1._data.view.element.getBoundingClientRect().left - boxRect.left + "px";
          const menuTop =
            s1._data.view.element.getBoundingClientRect().top - boxRect.top - 30 + "px";

          // 更新菜单的位置
          this.menuStyle.top = menuTop;
          this.menuStyle.left = menuLeft;
          // 显示菜单
          // this.menuStyle.display = "flex";
          if (this.ispreview) {
            this.menuStyle.display = "none";
          } else {
            this.menuStyle.display = "flex";
          }
        }
        if (key.evt === "select_node") {
          // this.$refs.nodetool.style.display = "flex";
          if (this.ispreview) {
            this.$refs.nodetool.style.display = "none";
          } else {
            this.$refs.nodetool.style.display = "flex";
          }
          this.$emit("editornode", key, this.node);
        }
        if (key.evt === "update_node") {
          this.UpdateNode(this.node);
        }
        if (
          key.evt === "remove_node" ||
          key.evt === "update_node" ||
          key.evt === "insert_node_after" ||
          key.evt === "add_node" ||
          key.evt === "move_node"
        ) {
          this.$emit("editornode", key, this.node);
        }
        this.ispreview && this.jm.disable_edit();
      });
    },
    // 节点的child数组
    transformData(data) {
      return data.map((item) => {
        return {
          id: item.id,
          topic: item.content,
          children: item.children ? this.transformData(item.children) : [],
          "font-size": "12",
          "background-color": "#78A5FF",
          "background-image": this.imageicons[item.outlineType],
          outlineType: item.outlineType,
          dataType: item.dataType,
        };
      });
    },
    /**
     * @description: 更新节点
     * @return {void}
     */
    editornode() {
      this.jm.begin_edit(this.node);
    },
    /**
     * @description: 删除节点
     * @return {void}
     */
    async deletenode() {
      let deletenode = _.cloneDeep(this.node);
      const res = await Playeditor.deleteSubjectDemoModeNode(deletenode.id);
      if (res) {
        this.jm.remove_node(this.node);
        this.menuStyle.display = "none";
        this.$nextTick(() => {
          this.jm.select_node(deletenode.parent.id);
        });
      }
    },
    /**
     * @description: 添加同级节点
     * @return {void}
     */
    async addnode(type) {
      this.addvisible = false; //关闭popover
      let data = {
        "font-size": "12",
        "background-color": "#78A5FF",
        "background-image": this.imageicons[type],
        outlineType: type,
      };
      const nodeid = await Playeditor.saveSubjectDemoModeNode({
        content: this.getDefalutName(type),
        schemeId: this.subject.id,
        outlineType: type,
        parentId: this.node.parent.id,
        versionId: this.subject.versionId,
      });
      if (nodeid) {
        this.jm.insert_node_after(this.node, nodeid, this.getDefalutName(type), data);
        this.jm.select_node(nodeid);
        this.jm.begin_edit(nodeid);
      }
    },
    /**
     * @description: 添加子节点
     * @return {void}
     */
    async addchlidnode(type) {
      this.branchvisible = false; //关闭popover
      this.jm.enable_edit();
      let data = {
        "font-size": "12",
        "background-color": "#78A5FF",
        "background-image": this.imageicons[type],
        outlineType: type,
      };
      const nodeid = await Playeditor.saveSubjectDemoModeNode({
        content: this.getDefalutName(type),
        schemeId: this.subject.id,
        outlineType: type,
        parentId: this.node.id,
        versionId: this.subject.versionId,
      });
      if (nodeid) {
        this.jm.add_node(this.node, nodeid, this.getDefalutName(type), data);
        this.jm.select_node(nodeid);
        this.jm.begin_edit(nodeid);
      }
    },
    /**
     * @description: 获取新建节点时的默认名字
     * @param {Number} type
     * @return {void}
     */
    getDefalutName(type) {
      switch (type) {
        case 1:
          return "文本";
        case 2:
          return "关联内容";
        case 3:
          return "栏目";
        case 4:
          return "关联文章";
        case 5:
          return "链接";
        case 6:
          return "文档";
        default:
          return "未知";
      }
    },
    /**
     * @description: 点击事件
     * @param {Object} event
     * @return {void}
     */
    handleClick(event) {
      const target = event.target;
      if (
        target.nodeName != "JMNODE" &&
        target.nodeName != "use" &&
        target.nodeName != "svg"
      ) {
        this.menuStyle.display = "none";
        this.addvisible = false;
        this.branchvisible = false;
      }
    },
    /**
     * @description: 切换演示
     * @return {void}
     */
    async handleCurrent(id) {
      // const res = await Playeditor.getShowProjectDetail(this.queryIds);
      let res = await Playeditor[this.loginrequired?"getShowProjectDetail":"getShowProjectDetailNoLogin"](this.queryIds);
      this.subject = res;
      if (res && res.showProjectOutlineVos) {
        this.nodeTree = res.showProjectOutlineVos[0];
        this.mindinfo.topic = this.nodeTree.content;
        this.mindinfo.id = this.nodeTree.id;
        this.mindinfo.expanded = false; // 根节点展开
        this.mindinfo.outlineType = this.nodeTree.outlineType;
        this.mindinfo.children = [];
        this.mindinfo.children = this.transformData(this.nodeTree.children);
        this.clickoutlineid = id ? id : this.nodeTree.id;
        this.init_data();
        this.$nextTick(() => {
          this.jm.select_node(this.clickoutlineid);
        });
      }
    },
    /**
     * @description: 更新节点
     * @param {Object} value 更新的节点
     * @return {void}
     */
    async UpdateNode(value) {
      await Playeditor.saveSubjectDemoModeNode({
        id: value.id,
        content: value.topic,
        schemeId: this.subject.id,
        outlineType: value.data.outlineType,
        parentId: value.parent ? value.parent.id : "-1",
        versionId: this.subject.versionId,
      });
    },
    /**
     * @description: 更新节点名称为关联内容
     * @param {String} nodeId 更新的节点id
     * @param {String} newName 更新的节点名称
     * @return {void}
     */
    UpdateAssNode(nodeId, newName) {
      let node = this.jm.get_node(nodeId);
      if (node) {
        this.jm.update_node(nodeId, newName);
      }
    },
  },
};
</script>
<style lang="scss">
.mindedit-popover {
  @include popover(94px);
}
</style>
<style lang="scss" scoped>
.jscontainer {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #fff;
  &:hover {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
}

#jsmind_container {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #fff;
}

.nodetool {
  height: 29px;
  font-size: 12px;
  display: flex;
  vertical-align: middle;
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 10;
  padding: 0 10px;
  background-color: #fff;
  background-color: rgba(15, 23, 36, 0.8);
  .item-icon {
    height: 16px;
    width: 16px;
    margin-left: 14px;
    margin-top: 5px;
    cursor: pointer;
    // margin-left: 5px;
    &:hover .svg-icon {
      color: #a3c2ff !important;
    }
  }
  .ml-0 {
    margin-left: 0;
  }
}
::v-deep jmnode:hover {
  box-shadow: none;
}
::v-deep jmnode {
  box-shadow: none;
  padding-left: 30px;
  background-size: 20px !important;
  background-position: 5px 8px;
  background-repeat: no-repeat;
}
::v-deep jmnodes.theme-belizehole jmnode.selected {
  background-color: #1664ff;
}
::v-deep jmnode input {
  border: none !important;
}

::v-deep jmexpander {
  height: 15px;
  width: 15px;
  color: #a3c2ff;
  text-align: center;
  border-color: #a3c2ff !important;
}

.item-addtype {
  flex-direction: column;
  line-height: 28px;
  div {
    cursor: pointer;
    &:hover {
      color: #1764fe;
    }
  }
}
</style>
