<template>
  <el-dialog append-to-body class="ss-dialog tag-dialog" width="680px" :visible.sync="dialogVisible"
    :close-on-click-modal="false">
    <span slot="title">
      <div class="flex align-items-center">
        <span>标签管理</span>
        <i v-if="!showInput" class="el-icon-circle-plus-outline pointer" style="margin-left: 10px;"
          @click="handleShowInput"></i>
        <template v-if="showInput">
          <el-input v-model="tag.tagName" show-word-limit class="tag-input" placeholder="请输入或搜索标签名称" ref="input"
            size="small" style="width: 200px;margin-left: 10px;" :maxlength="10" @keyup.enter.native="addTag"></el-input>
          <el-popover popper-class="header-product-popover" placement="bottom" width="70" trigger="click"
            :visible-arrow="false">
            <div class="app-select m-l-12" slot="reference">
              <span>{{ tag.type === 2 ? "公共" : "私有" }}</span>
              <div class="app-select__arrow"></div>
            </div>
            <div class="header-product-popover-item" @click="tag.type = 2">公共</div>
            <div class="header-product-popover-item" @click="tag.type = 1">私有</div>
          </el-popover>
          <div class="check-14 m-l-12" @click="addTag"></div>
          <div class="close-14 m-l-12" @click="showInput = false"></div>
        </template>
      </div>
    </span>
    <div class="content" :class="{ 'center': selectedTagList.length === 0 }">
      <div v-for="(item, index) in selectedTagList" class="tag" :key="item.id">
        <div class="tag-text">{{ item.tagName }}</div>
        <div class="tag-close" @click="removeTag(index)"></div>
      </div>
      <span class="tip">{{ tip }}</span>
    </div>
    <div v-show="tagList.length" class="tag-list">
      <div v-for="item in tagList" class="tag-item"
        :class="{ 'selected': selectedTagList.findIndex(i => i.id === item.id) !== -1 }" :key="item.id"
        @click="selectTag(item)">{{ item.tagName }}</div>
    </div>
    <div v-show="tagList.length" class="footer">
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ProductLibrary } from "@/api/ProductLibrary";
import { cloneDeep, pick } from 'lodash';
export default {
  name: "TagDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    moduleId: String, // 模块 id
    subjectId: String, // 主题 id
    list: Array, // 已选标签的列表
    type: Number, // 类型 1:主题编辑标签 2:主题详情-更多设置-选择标签
  },
  data() {
    return {
      loading: false,
      tip: "",
      showInput: false,
      tag: {
        moduleId: "",
        type: 2,
        tagName: "",
      },
      tagList: [],
      selectedTagList: [],
      confirmLoading: false,
    }
  },
  watch: {
    selectedTagList() {
      if (this.selectedTagList.length) {
        this.tip = "请选择标签";
      } else {
        if (this.tagList.length) {
          this.tip = "暂未选择标签";
        } else {
          this.tip = "请点击上方“+”号，新建你的第一个标签";
        }
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.selectedTagList = cloneDeep(this.list);
    this.tag.moduleId = this.moduleId;
    this.getTagList();
  },
  methods: {
    /**
     * @description: 获取标签列表
     * @return {void}
     */
    async getTagList() {
      const res = await ProductLibrary.getTagList(this.moduleId);
      this.tagList = res.map(item => pick(item, ["bizType", "id", "moduleId", "tagName", "tenantId", "type"]));
      if (this.tagList.length === 0) {
        this.tip = "请点击上方“+”号，新建你的第一个标签";
      } else if (this.selectedTagList.length === 0) {
        this.tip = "暂未选择标签";
      } else {
        this.tip = "请选择标签";
      }
    },
    /**
     * @description: 显示新增标签输入框
     * @return {void}
     */
    handleShowInput() {
      this.showInput = true;
      this.$nextTick(() => this.$refs.input.focus());
    },
    /**
     * @description: 新增标签
     * @return {void}
     */
    async addTag() {
      if (this.tag.tagName) {
        const index = this.tagList.findIndex(i => i.tagName === this.tag.tagName);
        if (index !== -1) {
          const selectedIndex = this.selectedTagList.findIndex(i => i.tagName === this.tag.tagName);
          if (selectedIndex === -1) {
            this.selectedTagList.push(this.tagList[index]);
          }
          this.showInput = false;
          this.tag.tagName = "";
        } else {
          const res = await ProductLibrary.updateTag("add", this.tag);
          if (res) {
            this.getTagList();
            this.showInput = false;
            this.tag.tagName = "";
          }
        }
      }
    },
    /**
     * @description: 选择标签
     * @param {Object} value 当前项
     * @return {void}
     */
    selectTag(value) {
      if (this.selectedTagList.findIndex(i => i.id === value.id) !== -1) {
        return
      }
      this.selectedTagList.push(value);
    },
    /**
     * @description: 移除标签
     * @param {*} index
     * @return {*}
     */
    removeTag(index) {
      this.selectedTagList.splice(index, 1);
    },
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      if (this.type === 1) {
        this.confirmLoading = true;
        let payload = {
          id: this.subjectId,
          tagType: 1,
          tagIds: [],
        }
        this.selectedTagList.forEach(element => payload.tagIds.push(element.id));
        const res = await ProductLibrary.editSubjectTag(payload);
        this.confirmLoading = false;
        if (res) {
          this.$emit("finish");
          this.dialogVisible = false;
        }
      } else {
        this.$emit("finish", this.selectedTagList);
        this.dialogVisible = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.tag-dialog {
  ::v-deep .el-dialog__header {
    padding: 24px 0 17px;
    height: 32px;
    line-height: 32px;
  }

  .content {
    padding: 26px 30px 8px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    &.center {
      justify-content: center;
    }

    .tag {
      height: 32px;
      padding: 0 8px 0 16px;
      margin: 0 10px 14px 0;
      border-radius: 5px;
      background-color: #f5f7fa;
      display: flex;
      align-items: center;

      .tag-text {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-text-primary);
        line-height: 32px;
        max-width: 72px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .tag-close {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-left: 16px;
        background-image: url("@/assets/images/common/close_16.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;

        &:hover {
          background-image: url("@/assets/images/common/close_red.png");
        }
      }
    }

    .tip {
      font-size: 16px;
      font-weight: 500;
      color: #abb4c4;
      height: 32px;
      line-height: 32px;
      margin-bottom: 14px;
    }
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 16px 16px 2px;
    margin: 0 30px;
    background-color: #f5f7fa;
    border-radius: 10px;

    .tag-item {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-text-primary);
      padding: 7px 16px;
      margin: 0 10px 14px 0;
      background-color: #fff;
      border-radius: 5px;
      cursor: pointer;

      &.selected {
        color: #949eaf;
        background-color: #fafbfd;
        cursor: not-allowed;
      }
    }
  }

  .footer {
    text-align: right;
    padding: 24px 30px;
  }
}
</style>