<template>
  <div class="scheme-container">
    <div class="w-h-100 flex app-scheme-wrapper">
      <div
        class="app-scheme-sidebar"
        v-if="!showscheme && schemeList && schemeList.length > 1"
      >
        <svg-icon
          class="scheme-sidebar-icon pointer"
          :class="{ 'scheme-sidebar-icon-active': showscheme }"
          icon="icon-scheme"
          :height="20"
          :width="20"
          @click.native="showscheme = !showscheme"
        ></svg-icon>
      </div>
      <div class="app-scheme-left" v-if="showscheme">
        <div class="scheme-title flex-between align-items-center">
          <div class="scheme-title-name">展示方案</div>
          <div class="flex align-items-center">
            <svg-icon
              v-if="!ispre"
              class="mr-10 pointer"
              icon="mind-add"
              color="#5E6C84"
              :height="12"
              :width="12"
              @click.native="handleCreat(null)"
            ></svg-icon>
            <svg-icon
              class="mr-10 pointer"
              icon="close-side"
              color="#5E6C84"
              :height="16"
              :width="16"
              @click.native="showscheme = false"
            ></svg-icon>
          </div>
        </div>
        <div class="scheme-title-list">
          <div
            v-for="i in schemeList"
            :key="i.id"
            @click="handleClick(i)"
            class="flex scheme-title-list-items pointer align-items-center"
            :class="{ 'items-active': activeScheme && activeScheme.id == i.id }"
          >
            <svg-icon
              class="scheme-items-icon"
              icon="icon-scheme"
              :height="16"
              :width="16"
              color="#344563"
              v-if="!i.isDefault"
            ></svg-icon>
            <div class="scheme-items-icon-default" v-if="i.isDefault">默认</div>
            <div class="scheme-items-name one-line">{{ i.name }}</div>
          </div>
        </div>
      </div>
      <div class="app-scheme-main main-edit">
        <template>
          <ArticlePreview
            v-if="
              activeColumn &&
              columnCategoryTypeDict[activeColumn.columnCategoryId] === '5'
            "
            :active-column="activeColumn"
            :scheme="activeScheme"
            :subject="subject"
            :loginrequired="loginrequired"
            :shareId="currentpayload.shareId"
          />
          <ContentPreview
            v-if="
              activeColumn &&
              columnCategoryTypeDict[activeColumn.columnCategoryId] === '1'
            "
            :active-column="activeColumn"
            :scheme="activeScheme"
            :subject="subject"
            :loginrequired="loginrequired"
            :shareId="currentpayload.shareId"
          />
          <LinkPreview
            v-if="
              activeColumn &&
              columnCategoryTypeDict[activeColumn.columnCategoryId] === '4'
            "
            :active-column="activeColumn"
            :scheme="activeScheme"
            :subject="subject"
            :loginrequired="loginrequired"
            :shareId="currentpayload.shareId"
          />
          <ProductPreview
            v-if="
              activeColumn &&
              columnCategoryTypeDict[activeColumn.columnCategoryId] === '3'
            "
            :active-column="activeColumn"
            :scheme="activeScheme"
            :subject="subject"
            :loginrequired="loginrequired"
            :shareId="currentpayload.shareId"
          />
          <FilePreview
            v-if="!activeColumn && isfile"
            :active-column="activeColumn"
            :scheme="activeScheme"
            :subject="subject"
            :loginrequired="loginrequired"
            :shareId="currentpayload.shareId"
          />
        </template>
      </div>
    </div>
    <div class="tool-center project-tool-center" v-if="this.schemeList.length && ispre">
      <div
        v-for="i in columnList"
        class="tool-center-item"
        :class="{
          'is-active': activeColumn && i.id === activeColumn.id,
          'show-more': !isPreview,
        }"
        :key="i.id"
        @click="changeColumn(i)"
      >
        <div class="tool-center-item-w">
          <component
            :is="i.columnIcon.startsWith('http') ? 'svg-icon-2' : 'svg-icon'"
            width="25"
            height="25"
            color="#5e6c84"
            activeColor="#fff"
            :icon="i.columnIcon ? i.columnIcon : 'column-icon-1'"
            :is-active="activeColumn?.id === i.id"
          >
          </component>
          <div
            :title="i.columnName"
            class="tool-center-item__label one-line"
            style="width: 100%; text-align: center"
          >
            {{ i.columnName }}
          </div>
        </div>
      </div>
      <div
        class="tool-center-item"
        @click="handleShowFile"
        :class="{
          'is-active': !activeColumn && isfile,
          'show-more': !isPreview,
        }"
      >
        <div class="tool-center-item-w">
          <svg-icon icon="column-icon-3" width="25" height="25"></svg-icon>
          <div class="tool-center-item__label">文件</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import ArticlePreview from "@/views/ProdudtEdito/components/scheme/ArticlePreview.vue";
import ContentPreview from "@/views/ProdudtEdito/components/scheme/ContentPreview.vue";
import LinkPreview from "@/views/ProdudtEdito/components/scheme/LinkPreview.vue";
import ProductPreview from "@/views/ProdudtEdito/components/scheme/ProductPreview.vue";
import FilePreview from "@/views/ProdudtEdito/components/scheme/FilePreview.vue";
export default {
  components: {
    ArticlePreview,
    ContentPreview,
    LinkPreview,
    ProductPreview,
    FilePreview,
  },
  props: {
    ispre: {
      type: Boolean,
      default: false,
    },
    // subject: Object,
    shareinfo: Object,
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录
    },
    currentpayload:Object,
  },
  data() {
    return {
      richText: "",
      columnLoading: false,
      schemeList: [], //方案列表
      columnList: [],
      activeColumn: null,
      activeScheme: null, //当前展示方案
      clickScheme: null, //编辑的方案
      addvisible: false, //添加/编辑方案名称弹窗
      showColumn: false, //编辑方案详情弹窗
      isfile: false,
      showscheme: true, //侧边条
      queryselectData: [],
      temporaryfile: [],
      subject: null,
    };
  },
  watch: {
    activeScheme(val) {
      this.getSchemeColumn();
    },
    shareinfo() {
      this.subject = {
        id: this.shareinfo.subjectId,
        mirrorId: this.shareinfo.mirrorId,
        versionId: this.shareinfo.versionId,
        moduleId: this.shareinfo.moduleId,
      };
      this.getScheme();
    },
  },
  computed: {
    isPreview() {
      return this.ispre;
    },
    ...mapGetters(["userInfo", "columnCategoryTypeDict"]),
  },
  mounted() {
    if (this.shareinfo) {
      this.subject = {
        id: this.shareinfo.subjectId,
        mirrorId: this.shareinfo.mirrorId,
        versionId: this.shareinfo.versionId,
        moduleId: this.shareinfo.moduleId,
      };
      this.getScheme();
    }
    if (this.isPreview) {
      this.showscheme = false;
    }
  },
  methods: {
    /**
     * @description: 获取展示方案列表
     * @return {void}
     */
    async getScheme() {
      if (!this.loginrequired) {
        this.schemeList = await ProductLibrary.SchemequeryListNoLogin({
          shareId: this.currentpayload.shareId,
          subjectId: this.subject.id,
        });
      } else {
        this.schemeList = await ProductLibrary.SchemequeryList(this.subject.id);
      }
      // this.schemeList =this.shareinfo.subjectDisplaySchemes;
      if (this.schemeList.length) {
        let defaultdata = this.schemeList.filter((item) => item.isDefault);
        if (defaultdata.length) {
          this.activeScheme = defaultdata[0];
        } else {
          this.activeScheme = this.schemeList[0];
        }
        // this.getSchemeColumn();
      }
    },
    /**
     * @description: 获取展示方案关联栏目列表
     * @return {void}
     */
    async getSchemeColumn() {
      this.columnLoading = true;
      let res;
      if(this.loginrequired){
        res = await ProductLibrary.getSchemeSubjectColumnList({
          schemeId: this.activeScheme.id,
          subjectVersionId: this.subject.versionId,
        });
      }else{
        res = await ProductLibrary.getSchemeSubjectColumnListNoLogin({
          schemeId: this.activeScheme.id,
          subjectVersionId: this.subject.versionId,
          shareId: this.currentpayload.shareId,
        });
      }
      this.columnList = _.sortBy(res, "sortNo");
      // 过滤关联内容栏目
      this.columnList = this.columnList.filter(
        (item) => this.columnCategoryTypeDict[item.columnCategoryId] != "3"
      );
      if (this.columnList.length) {
        this.activeColumn = this.columnList[0];
      } else {
        this.handleShowFile();
      }
      this.columnLoading = false;
    },
    /**
     * @description: 创建展示方案
     * @return {void}
     */
    handleCreat(value) {
      this.clickScheme = value;
      this.addvisible = true;
    },
    /**
     * @description: 切换栏目
     * @return {void}
     */
    changeColumn(value) {
      this.activeColumn = value;
    },
    showDialog(visible) {
      this[visible] = true;
    },
    /**
     * @description: 切换方案
     * @return {void}
     */
    handleClick(value) {
      this.activeScheme = value;
    },
    /**
     * @description: 显示文件栏目
     * @return {void}
     */
    handleShowFile() {
      this.activeColumn = null;
      this.isfile = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";

.scheme-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-scheme-wrapper {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
}

.project-tool-center {
  height: 60px;
  width: 100%;
  border-top: 1px solid #ebeef2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .tool-center-item {
    margin: 0 5px;
    width: 69px;
    height: 51px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #344563;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &.is-active {
      background: #f1ebff;
      color: var(--color-primary);

      .tool-center-item-w {
        .svg-icon,
        .online-svg {
          color: var(--color-primary) !important;
        }
      }
    }

    .tool-center-item-w {
      width: 69px;
      height: 51px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .svg-icon,
      .online-svg {
        color: #5e6c84 !important;
      }

      .tool-center-item__icon {
        width: 25px;
        height: 25px;
      }

      .tool-center-item__label {
        height: 18px;
        line-height: 18px;
        margin-top: 2px;
        padding: 0 2px;
        box-sizing: border-box;
      }
    }

    &:not(.is-active) {
      .tool-center-item-w {
        &:hover {
          background-color: #f7f9fc;
        }
      }
    }
  }
}

.main-edit {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
