<template>
  <div class="flex navigation-tool">
    <div class="navigation-tool-item pointer" @click="PageUp">上一页</div>
    <div class="navigation-tool-item pointer" @click="PageDown">下一页</div>
  </div>
</template>
<script>
export default {
  props: {
    onlineTree: Array, //提纲树
    nodeid: String, //提纲树
  },
  data() {
    return {
      activeonline: null,
      previewList: [],
      activeIndex: 0,
    };
  },
  watch: {
    nodeid(val) {
      if (val) {
        this.activeIndex = this.previewList.findIndex((item) => (item.id == val));
      }
    },
    onlineTree(val){
      this.previewList = this.flattenTree([this.onlineTree[0]]);
    }
  },
  mounted() {
    this.previewList = this.flattenTree([this.onlineTree[0]]);
    if (this.previewList.length) {
      this.handleNavigate(this.previewList[this.activeIndex].id);
    }
  },
  methods: {
    /**
     * @description: 铺平数组
     * @return {void}
     */
    flattenTree(tree) {
      let flattened = [];

      function flatten(node) {
        flattened.push({
          id: node.id,
          content: node.content,
          topic: node.topic,
          // "font-size": node["font-size"],
          // "background-color": node["background-color"],
          outlineType: node.outlineType,
          dataType: node.dataType,
        });

        if (node.children && node.children.length > 0) {
          node.children.forEach((child) => {
            flatten(child);
          });
        }
      }
      tree.forEach((node) => {
        flatten(node);
      });

      return flattened;
    },
    /**
     * @description: 节点选择
     * @return {void}
     */
    handleNavigate(id) {
      this.$Pubsub.publish("update-mind-select-data", id);
    },
    /**
     * @description: 下一页
     * @return {void}
     */
    PageDown() {
      if (this.activeIndex < this.previewList.length-1) {
        this.activeIndex++;
        if(this.previewList[this.activeIndex].dataType==2&&this.activeIndex < this.previewList.length-1){
          this.activeIndex++;
        }
        this.handleNavigate(this.previewList[this.activeIndex].id);
      } else {
        this.$message("最后一页了");
      }
    },
    /**
     * @description: 上一页
     * @return {void}
     */
    PageUp() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
        if(this.previewList[this.activeIndex].dataType==2&&this.activeIndex > 0){
          this.activeIndex--;
        }
        this.handleNavigate(this.previewList[this.activeIndex].id);
      } else {
        this.$message("当前第一页");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.navigation-tool{
  width: 100%;
  height: 32px;
  padding: 0 10px;
  line-height: 32px;
  border-top: 1px solid #d3dbeb;
  box-sizing: border-box;
  justify-content: center;
  .navigation-tool-item{
    padding:0 10px;
    color: green;
    font-weight: 700;
    &:hover{
      color: var(--color-primary);
    }
  }
}
</style>