var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-column",class:{ 'is-preview': _vm.isPreview },on:{"drop":_vm.handleDrop,"dragover":_vm.allowDrop}},[_c('div',{staticClass:"second-column-main"},[(
        _vm.activeColumn && _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '1'
      )?_c('TextEditor',{attrs:{"ispre":_vm.isPreview,"rich-text":_vm.richText,"module-id":_vm.subject.moduleId,"subject-id":_vm.subject.id,"version-id":_vm.subject.versionId,"active-column":_vm.activeColumn,"loginrequired":_vm.loginrequired,"shareId":_vm.queryIds.shareId},on:{"update:richText":function($event){_vm.richText=$event},"update:rich-text":function($event){_vm.richText=$event}}}):_vm._e(),(
        _vm.activeColumn && _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '5'
      )?_c('AssociateArticle',{attrs:{"active-column":_vm.activeColumn,"subject":_vm.subject,"ispre":_vm.isPreview,"loginrequired":_vm.loginrequired}}):_vm._e(),(
        _vm.loginrequired &&
        _vm.activeColumn &&
        _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '3'
      )?_c('AssociateProduct',{attrs:{"active-column":_vm.activeColumn,"subject":_vm.subject,"ispre":_vm.isPreview,"loginrequired":_vm.loginrequired,"shareId":_vm.queryIds.shareId}}):_vm._e(),(
        _vm.activeColumn && _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '4'
      )?_c('LinkColumn',{attrs:{"subject":_vm.subject,"active-column":_vm.activeColumn,"ispre":_vm.isPreview,"loginrequired":_vm.loginrequired,"shareId":_vm.queryIds.shareId}}):_vm._e(),(
        !_vm.loginrequired &&
        _vm.activeColumn &&
        _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '3'
      )?_c('div',[_vm._v("关联的产品需要登录后查看")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }