<template>
  <div class="app-upload" :class="{ 'no-border': !showtitle }">
    <div class="app-upload-title flex-between" v-if="showtitle">
      <div>插件上传文件列表</div>
      <i @click="dialogVisible = false" class="el-icon-close pointer"></i>
    </div>
    <div class="content">
      <div v-for="file in uploadFilelist" :key="file.msgId">
        <div class="flex-between align-items-center">
          <div class="filename mr-10 one-line">{{ file.filename }}</div>
          <div style="font-size: 12px; min-width: 56px">
            ({{ file.size ? file.size : "--" }})
          </div>
        </div>
        <div class="flex align-items-center">
          <el-progress
            color="#4ed4cb"
            :percentage="Number(file.progress.replace('%', ''))"
            style="width: 300px"
          ></el-progress>
          <i
            v-if="Number(file.progress.replace('%', '')) != 100"
            class="mr-10 pointer"
            :class="file.uploadType == '1' ? 'el-icon-video-pause' : 'el-icon-video-play'"
            @click="handlePause(file)"
          ></i>
          <i
            v-if="Number(file.progress.replace('%', '')) != 100"
            class="el-icon-error pointer"
            @click="handleDelete(file)"
          ></i>
          <i
            style="color: #67c23a"
            class="el-icon-success"
            v-if="Number(file.progress.replace('%', '')) == 100"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    filename: {
      type: String,
      default: "",
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    query: Object,
    filereceiveid: {
      type: String,
      default: "",
    },
    showtitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      progress: 0, // 进度条
      status: "ready",
      queryData: {},
      uploadFilelist: [],
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo", "isconnected", "sale4localuploadList"]),
  },
  watch: {},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    /**
     * @description: 取消上传
     * @return {void}
     */
    handleDelete(file) {
      this.$showMessage(
        {
          message: `是否确认取消上传 <span style="font-weight: 1000;">${file.filename}</span> ?`,
          ...this.$global.deleteMessage,
          title: "确定取消上传？",
          confirmButtonText: "确认取消",
        },
        async (done) => {
          const item = this.sale4localuploadList.find(
            (item) => item.msgId === file.msgId
          );
          if (!item) {
            done(true);
            return;
          }
          item.token = this.token ? this.token : "";
          item.uploadType = "3"; //上传消息类型，1:开始，2:暂停,3:取消
          this.$websocket.send(JSON.stringify(item));
          done(true);
          this.$store.commit("deleteUploadList", file);
          const fileindex = this.uploadFilelist.findIndex(
            (item) => item.msgId == file.msgId
          );
          this.uploadFilelist.splice(fileindex, 1);
        }
      );
    },
    /**
     * @description: 关闭弹窗
     * @return {void}
     */
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * @description: 暂停
     * @return {void}
     */
    handlePause(file) {
      const item = this.sale4localuploadList.find((item) => item.msgId === file.msgId);
      if (!item) {
        return;
      }
      item.token = this.token ? this.token : "";
      item.uploadType = file.uploadType == "1" ? "2" : "1"; //上传消息类型，1:开始，2:暂停,3:取消; //上传消息类型，1:开始，2:暂停,3:取消
      item.itemId = item.id;
      const fileindx = this.uploadFilelist.findIndex((item) => item.msgId == file.msgId);
      this.uploadFilelist[fileindx].uploadType = item.uploadType;
      this.$websocket.send(JSON.stringify(item));
    },
    init() {
      this.$messageService.$on("websocketMessage", (data) => {
        // 处理接收到的消息
        const parsedMessage = JSON.parse(data);
        if (parsedMessage.funcname == "uploadingFile") {
          const fileindx = this.uploadFilelist.findIndex(
            (item) => item.msgId == parsedMessage.msgId
          );
          if (fileindx == "-1") {
            let files = parsedMessage.data[0];
            files.msgId = parsedMessage.msgId;
            files.show = true;
            const item = this.sale4localuploadList.find(
              (item) => item.msgId === parsedMessage.msgId
            );
            if (item) {
              if (!item.file) item.file = parsedMessage.data[0].localFilePath;
              files.uploadType = item.uploadType;
              files.folderId = item.folderId;
              files.msgId.startsWith("sale4-") && this.uploadFilelist.push(files);
            }
            // files.msgId.startsWith("sale4-") && this.uploadFilelist.push(files);
          } else {
            this.uploadFilelist[fileindx].progress = parsedMessage.data[0].progress;
            if (
              parsedMessage.data[0].progress == "DONE" &&
              parsedMessage.data[0].progress == "100%"
            ) {
              const fileindex = this.uploadFilelist.findIndex(
                (item) => item.msgId == parsedMessage.msgId
              );
              setTimeout(() => {
                this.uploadFilelist.splice(fileindex, 1);
                this.uploadFilelist[fileindx].show = false;
                this.$store.commit("deleteUploadList", parsedMessage);
              }, 1000);
            }
          }
        }

        // 新版文件上传
        if (parsedMessage.funcname == "uploadProgress"){
          if (parsedMessage.data.children.length){
            let filelist = [...parsedMessage.data.children]
            filelist.forEach(item => {
              item.uploadType = item.status == "UPLOADING" ?1:2;
              item.folderId = item.parentId;
              item.progress = ((item.uploadedFileSize / item.totalFileSize)*100).toFixed(0).toString();
              item.msgId = item.id;
              item.filename=item.name;
              item.size = this.common.formatFileSize(item.totalFileSize);
              if (item.status == "DONE" && item.progress=="100"){
                const payload = {
                  "msgId": item.id,
                  "funcname": "cleanUploadDone",
                  "systemType": "1",
                }
                this.$websocket.send(JSON.stringify(payload));
              }
            });
            this.uploadFilelist = filelist
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-upload {
  width: 440px;
  height: 300px;
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #fff;
  color: var(--color-text-primary);
  border-radius: 5px;
  .app-upload-title {
    font-size: 14px;
    line-height: 32px;
    border-bottom: 1px solid #ccc;
    margin: 0 10px;
    align-items: center;
  }
}
.no-border {
  border: none;
  height: 100%;
}
.content {
  height: calc(100% - 36px);
  padding: 10px 32px;
  box-sizing: border-box;
  overflow-y: auto;

  .filename {
    // padding: 12px 14px 0 0;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    word-break: break-all;
  }
}

.mr-10 {
  margin-right: 10px;
}
</style>
