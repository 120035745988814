<template>
  <!-- 隐藏展示状态时的页头 -->
  <svg-icon
    class="mr-10 pointer"
    :icon="titlefixed ? 'fixed' : 'not-fixed'"
    color="#ffffff"
    :height="18"
    :width="18"
    @click.native="headerfixed = !headerfixed"
  ></svg-icon>
</template>
<script>
import { Users } from "@/api/Users";
import Mousetrap from "mousetrap";
export default {
  props: {
    titlefixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyresult: "",
    };
  },
  computed: {
    headerfixed: {
      get() {
        return this.titlefixed;
      },
      set(val) {
        this.$emit("update:titlefixed", val);
      },
    },
  },
  watch: {},
  mounted() {
    this.getKey();
  },
  beforeDestroy() {
    Mousetrap.unbind(this.keyresult);
  },
  methods: {
    /**
     * @description: 获取设置的快捷键
     * @return {void}
     */
    async getKey() {
      const res = await Users.getShortcutKey();
      let hotkey;
      if (res && res.length) {
        hotkey = res.filter((item) => item.keyType == 2);
      }
      if (hotkey && hotkey[0]) {
        let result = hotkey[0].key1;
        if (hotkey[0].key2) {
          result += "+" + hotkey[0].key2;
        }
        if (hotkey[0].key3) {
          result += "+" + hotkey[0].key3;
        }
        this.keyresult = result;
      } else {
        this.keyresult = "ctrl+d"; //未设置时给个默认值
      }
      Mousetrap.bind(this.keyresult, this.onCtrlSShortcut);
    },
    /**
     * @description: 触发快捷键操作
     * @return {void}
     */
    onCtrlSShortcut(event) {
      event.preventDefault(); // Prevent the default browser action
      this.handleHide();
    },
    /**
     * @description: 页头隐藏/显示
     * @return {void}
     */
    handleHide() {
      this.headerfixed = !this.headerfixed;
    },
  },
};
</script>
<style lang="scss"></style>
