<template>
  <el-dialog append-to-body class="ss-dialog no-header-bottom" title="添加自定义属性" width="480px" :show-close="false"
    :visible.sync="dialogVisible">
    <div class="custom-body">
      <div class="flex">
        <el-input v-model="attributeDetail.dictName" class="attribute-input" placeholder="属性名称" :maxlength="10">
          <div slot="suffix" class="input-suffix">*</div>
        </el-input>
        <div class="attribute-button disabled" style="flex-shrink: 0;" @click="handleAdd">
          <svg-icon icon="xs-icon-plus2" width="16" height="16" color="#c5d0e3"></svg-icon>
        </div>
      </div>
      <div v-for="(item, index) in attributeDetail.detailDTOS" :key="index" class="detail-wrapper">
        <el-input v-model="item.itemText" placeholder="值" style="width: 176px;margin-right: 16px;"
          :maxlength="10"></el-input>
        <el-input v-model="item.description" placeholder="描述" class="flex-1" :maxlength="10"></el-input>
        <div v-if="attributeDetail.detailDTOS.length > 1" class="attribute-button" @click="handleClick(item, index)">
          <svg-icon icon="xs-icon-close2" width="12" height="12" color="#c5d0e3"></svg-icon>
        </div>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">保&nbsp;存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { File } from "@/api/File";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: Object,
    file: Object,
  },
  data() {
    return {
      attributeDetail: {
        dataId: "", // 文件或文件夹id
        dictName: "", // 属性名称
        dictItemType: 1, // 属性值类型（1：自由输入，2：固定值）
        dictType: 2, // 属性类型（1：基础数据，2：定义）
        detailDTOS: [
          {
            delFlag: 0,
            description: "",
            isSelected: 0,
            itemText: "",
          }
        ], // 值集合
      },
      deleteList: [],
      confirmLoading: false,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  mounted() {
    if (this.current) {
      let obj = _.pick(this.current, ['dataId', 'dictId', 'id', 'dictName', 'dictItemType', 'dictType']);
      obj.detailDTOS = _.cloneDeep(this.current.detailDTOList);
      this.attributeDetail = obj;
    } else {
      this.attributeDetail.dataId = this.file.id;
    }
  },
  methods: {
    handleAdd() {
      this.attributeDetail.detailDTOS.push({
        description: "",
        isSelected: 0,
        itemText: "",
      });
    },
    /**
     * @description: 新增/删除属性
     * @param {Object} item 属性对象
     * @param {Number} index 索引
     * @return {void}
     */
    handleClick(item, index) {
      if (item.id) {
        let obj = { ...item };
        obj.delFlag = 1;
        this.deleteList.push(obj);
      }
      this.attributeDetail.detailDTOS.splice(index, 1);
    },
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      this.confirmLoading = true;
      if (!this.attributeDetail.dictName) {
        this.confirmLoading = false;
        return this.$message.warning('请输入属性名称');
      }
      for (let index = 0; index < this.attributeDetail.detailDTOS.length; index++) {
        const element = this.attributeDetail.detailDTOS[index];
        if (!element.itemText) {
          this.confirmLoading = false;
          return this.$message.warning('请输入属性值');
        }
      }
      let payload = _.cloneDeep(this.attributeDetail);
      if (payload.detailDTOS.length === 1) {
        payload.detailDTOS[0].isSelected = 1;
        payload.dictItemType = 1;
      } else {
        payload.dictItemType = 2;
      }
      if (this.deleteList.length) {
        payload.detailDTOS = payload.detailDTOS.concat(this.deleteList);
      }
      const res = await File.saveFileAttribute(payload);
      this.confirmLoading = false;
      if (res) {
        this.$emit("finish");
        this.dialogVisible = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-body {
  padding: 0 24px 20px;

  ::v-deep(.el-input) {
    .el-input__inner {
      height: 46px;
      line-height: 46px;
    }
  }

  .attribute-input {
    ::v-deep(.el-input__suffix) {
      right: 18px;
    }
  }

  .input-suffix {
    padding-top: 16px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-danger-hover);
  }

  .detail-wrapper {
    margin-top: 16px;
    display: flex;

    // .el-input {
    //   width: 176px;

    //   &+.el-input {
    //     margin-left: 16px;
    //   }
    // }
  }

  .attribute-button {
    box-sizing: border-box;
    width: 46px;
    height: 46px;
    border: 1px solid #c5d0e3;
    border-radius: 5px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(.disabled):hover {
      border-color: var(--color-danger-hover);
      background-color: var(--color-danger-hover);

      .svg-icon {
        color: #fff !important;
      }
    }
  }
}

.dialog-footer {
  text-align: center;

  .el-button {
    min-width: 112px;
  }
}
</style>