<template>
  <div
    class="scheme-title-list2"
    :class="{ 'list2-asunit': existingdata && existingdata.length }"
  >
    <div class="scheme-title-grouplist-title flex-between align-items-center">
      <div>名称</div>
      <div>引用</div>
    </div>
    <div
      class="scheme-title-grouplist"
      v-infinite-scroll="loadResource"
      style="overflow: auto"
    >
      <div class="scheme-title-group" v-for="(i, index) in tableData" :key="i.id">
        <div
          class="scheme-title-group-parent flex align-items-center"
          @click="loadChild(i, index)"
        >
          <!-- <svg-icon
            class="mr-8 pointer"
            icon="module-icon-1"
            color="#344563"
            :height="14"
            :width="14"
          ></svg-icon> -->
          <i v-if="i.showchildren" class="el-icon-caret-bottom"></i>
          <i v-else class="el-icon-caret-right"></i>
          <div class="one-line">{{ i.name }}</div>
        </div>
        <div
          v-show="i.showchildren"
          class="scheme-title-group-children"
          :class="{ 'border-1': i.children.length && i.showchildren }"
        >
          <div
            v-for="(child, index) in i.children"
            :key="child.id"
            class="scheme-title-group-child flex-between align-items-center"
            :class="{
              'border-none': index == i.children.length - 1,
              'active-child': selectids.includes(child.id),
            }"
            :draggable="selectids.includes(child.id) ? false : true"
            @dragstart="(e) => dragstart(e, i, child)"
          >
            <div class="scheme-title-group-child-name one-line">
              {{ child.columnName }}
            </div>
            <svg-icon
              class="mr-24"
              icon="check-fill"
              color="#5E6C84"
              :height="15"
              :width="15"
            ></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ProductLibrary } from "@/api/ProductLibrary";
import { mapGetters } from "vuex";
export default {
  props: {
    menuid: String,
    menuname: String,
    // categoryId:String,
    productvisible: {
      type: Boolean,
      default: false,
    },
    categorytype: {
      type: Number,
      default: 1,
    }, //模块分类，1内容5文章
    selectids: Array,
    existingdata: Array,
  },
  components: {},
  data() {
    return {
      // 二级分类列表
      CategoryList: [],
      allcategoryList: [],
      tableData: [],
      collectionId: "-1",
      queryData: {
        moduleId: this.menuid,
        categoryIds: [],
        sortByUpdateAtFlag: false,
        keyWord: "",
        sortDesc: "update_at", // 降序
        sortAsc: "", // 升序
        pageNo: 1,
        pageSize: 30,
        shelfFlag: true,
        hideFlag: true,
        status: 2,
      },
      // 栏目列表
      dataList: [],
      loading: false, // 加载状态
      Treevisible: false,
      itemdata: "",
      visible: false,
      pagingData: new this.common.Pagination(),
      clickItem: null,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "activeMenu", "columnCategoryTypeDict"]),
  },
  watch: {
    menuid(val) {
      this.queryData.moduleId = val;
      this.queryData.pageNo = 1;
      this.tableData = [];
      this.clickItem = null;
      this.getList(1);
    },
    existingdata(val) {
      if (this.existingdata && this.existingdata.length) {
        this.existingdata.forEach((element) => {
          element.children = [];
          element.showchildren = false;
        });
        this.tableData = _.cloneDeep(this.existingdata);
      }
    },
  },
  mounted() {
    // 如果有传入的数据，直接使用该数据
    if (this.existingdata && this.existingdata.length) {
      this.existingdata.forEach((element) => {
        element.children = [];
        element.showchildren = false;
      });
      this.tableData = _.cloneDeep(this.existingdata);
      return;
    }
    this.categorytype && this.getList(1);
  },
  methods: {
    /**
     * @description: 获取产品列表
     * @return {void}
     */
    async getList(pageNo) {
      pageNo && (this.queryData.pageNo = pageNo);
      if (this.categorytype == 5) {
        return;
      }
      const res = await ProductLibrary.getSubjectList(this.queryData);
      res.records.forEach((element) => {
        element.children = [];
        element.showchildren = false;
      });
      this.tableData = [...this.tableData, ...res.records];
      this.pagingData = res;
    },
    /**
     * @description: 点击主题
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(value) {
      this.clickItem = value;
    },
    async loadChild(value, index) {
      if (value.children.length) {
        this.tableData[index].showchildren = !this.tableData[index].showchildren;
        return;
      }
      const payload = {
        mirrorId: value.mirrorId,
        subjectId: value.id,
        versionId: value.versionId,
      };
      let res = await ProductLibrary.getSubjectDetail(payload);
      if (res) {
        let children = _.sortBy(res.subjectColumnVos, "sortNo"); // 按 sortNo 排序;
        // 过滤文件栏目
        children = children.filter(
          (item) => this.columnCategoryTypeDict[item.columnCategoryId] != "2"
        );
        this.tableData[index].children = children;
        this.tableData[index].showchildren = true;
      }
    },
    /**
     * @description: 拖拽赋值
     * @param {Object} value 点击项
     * @return {void}
     */
    dragstart(e, value, column) {
      value.selectColumn = column;
      value.moduleName = this.menuname;
      const data = JSON.stringify(value);
      e.dataTransfer.setData("dragsubject", data);
    },
    /**
     * @description: 下拉加载
     * @return {void}
     */
    loadResource() {
      if (this.existingdata && this.existingdata.length) {
        return;
      }
      if (this.queryData.pageNo * this.queryData.pageSize > this.pagingData.total) {
        return;
      }
      this.queryData.pageNo++;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.list2-asunit {
  height: 100% !important;
}
.scheme-title-list2 {
  height: calc(100% - 78px);
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  overflow-y: hidden;
}
.scheme-title-grouplist-title {
  height: 26px;
  font-size: 12px;
  padding: 0 28px;
  background-color: #f7f9fc;
}
.scheme-title-grouplist {
  height: calc(100% - 26px);
  width: 100%;
  // padding: 0 10px 10px 10px;
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px;
  border: 1px solid #d3dbeb;
  .scheme-title-group {
    .scheme-title-group-parent {
      height: 32px;
      line-height: 32px;
      width: 100%;
      border-bottom: 1px solid #d3dbeb;
      padding: 0 24px;
      box-sizing: border-box;
      i {
        margin-right: 5px;
        font-size: 14px;
      }
    }
    .scheme-title-group-children {
      width: 100%;
      // border-bottom: 1px solid #d3dbeb;
    }
    .border-1 {
      border-bottom: 1px solid #d3dbeb;
    }
    .scheme-title-group-child {
      height: 32px;
      line-height: 32px;
      margin-left: 40px;
      position: relative;
      border-bottom: 1px solid #d3dbeb;
      padding: 0;
      cursor: move;
      // overflow: hidden;
      &::before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        background: url("@/assets/images/productLibrary/icon-solidL.png") no-repeat;
        background-size: 8px;
      }
      .scheme-title-group-child-name {
        padding-left: 5px;
      }
    }
    .active-child {
      cursor: not-allowed;
      .svg-icon {
        color: #43cf7c !important;
      }
    }
    .border-none {
      border: none;
    }
    .mr-24 {
      margin-right: 24px;
    }
    .mr-8 {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}
</style>
