<template>
  <div class="file">
    <div v-loading="categoryLoading" class="productfile-wrapper">
      <div class="productfile-title">产品文件</div>
      <el-input v-model="subjecuFilePayload.fileName" clearable placeholder="请输入关键字搜索" prefix-icon="el-icon-search"
        size="small" style="width: 260px;" @keyup.enter.native="getSubjectFile(1)" @clear="getSubjectFile(1)"></el-input>
      <div class="productfile-list">
        <el-tree ref="filetree" :data="ProductFileTable" :props="defaultProps" @node-click="handleNodeClick"
          @node-expand="handleNodeClick" :load="loadNode" lazy>
          <template v-slot="{ node, data }">
            <div class="filename" :class="{ 'active-node': data.id == activeNodeId }"
              :draggable="isPreview ? false : true" @dragstart="dragStart(data)" @dragend="dragEnd">
              <svg-icon @click.native="handleView(data)" :icon="`tableicon-${data.aliasType}`" :color="data.color" style="margin-right: 5px;margin-top: 10px;"></svg-icon>
              <div style="max-width:150px" class="one-line" :title="node.label">{{ node.label }}</div>
            </div>
          </template>
        </el-tree>
      </div>
    </div>
    <div v-loading="tableLoading" class="file-content" :class="{ 'demominview': demoview }">
      <div class="file-content-top">
        <div class="file-content-top-left">
          <template v-if="!isPreview">
            <el-button class="folder-button" icon="el-icon-plus" size="small" type="success"
              @click="folderDialogVisible = true">新建</el-button>
            <UploadFile :folderid="queryFile.folderId ? queryFile.folderId : ''" :subject="subject" :text="'上传文件'" :columnId="current.id"  @finish="getTableData(null, true)"></UploadFile>
          </template>
        </div>
        <div class="file-content-top-right">
          <el-input v-model="queryFile.name" clearable placeholder="请输入关键字搜索" prefix-icon="el-icon-search" size="small"
            style="width: 212px;" @keyup.enter.native="getTableData(1)" @clear="getTableData(1)"></el-input>
        </div>
      </div>
      <div class="file-breadcrumb">
        <span class="pointer fw-700" @click="jumpTo(null, -1)">根目录</span>
        <template v-for="(item, index) in breadcrumbList">
          <span class="fw-400" :key="item.id">&nbsp;/&nbsp;</span>
          <span class="pointer fw-500" :key="'breadcrumb' + item.id" @click="jumpTo(item, index)">{{ item.folderName
          }}</span>
        </template>
      </div>
      <div class="file-content-table" ref="fileTableWrapper" @drop="drop" @dragend="end">
        <el-table highlight-current-row class="file-table" header-cell-class-name="app-header-cell" ref="table"
          row-class-name="file-table-row" row-key="id" :data="tableData" :height="fileTableHeight" @row-click="rowClick" @row-dblclick="rowdbClick">
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column label="名称" prop="folderName" min-width="500px">
            <template slot-scope="{ row }">
              <div class="file-name__wrapper">
                <img class="file-icon" :src="require(`@/assets/images/file/file-${row.aliasType}.png`)">
                <div>
                  <div v-if="!row.showprogress" class="file-name">{{ row.folderName }}</div>
                  <el-progress v-else color="#4ed4cb" :percentage="row.progress" style="width: 300px"></el-progress>
                  <div class="file-information">
                    <span v-if="row.fileType == 1">{{ row.fileSize }}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <span>{{ row.createBy ? row.createBy : '' }}&nbsp;于&nbsp;{{ Object.dayjs(row.createTime) }}&nbsp;{{
                      row.fileType == 1 ? "上传" : "创建" }}</span>
                  </div>
                  <div class="file-operation">
                    <div v-if="row.fileType == 2" class="file-operation-item" @click.stop="handleOpenfolder(row)">打开</div>
                    <div v-if="row.fileType == 1" class="file-operation-item"
                      :class="{ 'disabled': row.aliasType === 'other' && row.contentType != 'video' }"
                      @click.stop="handleView(row)">预览</div>
                    <div v-if="row.fileType == 1" class="file-operation-item" @click.stop="handleDownload(row)">下载</div>
                    <!-- <div v-if="!isPreview && row.fileType == 1" class="file-operation-item"
                      @click.stop="handleUpdateVersion(row)">版本更新</div> -->
                      <UploadFile class="file-operation-item" v-if="!isPreview && row.fileType == 1" :folderid="queryFile.folderId" :subject="subject" :text="'更新'" @finish="getTableData(null, true)" :folder="row"></UploadFile>
                    <div v-if="!isPreview" class="file-operation-item"
                      @click.stop="handleUpdate(row, row.fileType == 1 ? 1 : 2)">重命名</div>
                    <!-- <div v-if="!isPreview" class="file-operation-item"
                      @click.stop="handleUpdate(row, row.fileType == 1 ? 3 : 4)">编辑说明</div> -->
                    <div v-if="!demoview" class="file-operation-item" @click.stop="handleCheck(row)">属性</div>
                    <div v-if="row.fileType == 1" class="file-operation-item"
                      @click.stop="showDialog(row, 'fileVersionVisible')">历史版本
                    </div>
                    <!-- <div v-if="!isPreview" class="file-operation-item" @click.stop="handleShare">分享</div> -->
                    <!-- <div class="file-operation-item" @click.stop="showDialog(row, 'filePermissionVisible')">权限</div> -->
                    <div v-if="!isPreview" class="file-operation-item" @click.stop="handleDelete(row)">删除</div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="版本" prop="classify" min-width="140">
            <template slot-scope="{ row }">
              <span>{{ row.fileType == 1 ? row.fileVersion : "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="说明" prop="describes" min-width="140">
            <template slot-scope="{ row }">
              <span style="line-height: 20px;">
                <span style="margin-right: 4px;">{{ row.describes }}</span>
                <span class="describes-icon" @click.stop="handleUpdate(row, row.fileType == 1 ? 3 : 4)">
                  <svg-icon class="pointer" icon="xs-icon-edit2"></svg-icon>
                </span>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="my-pagination">
        <span class="my-pagination-tip">共 {{ pagingData.total }} 个内容，当前展示数量 {{ pagingData.records.length }} </span>
        <el-pagination background class="app-pagination" layout="prev, pager, next" :current-page="pagingData.current"
          :page-size="pagingData.size" :total="pagingData.total" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <div v-show="!showAttribute && !isPreview" class="file-disk" :class="{ 'demoview': demoview }">
      <ColudDisk/>
    </div>
    <div v-show="showAttribute" class="attribute-container">
      <div class="right-close">
        <svg-icon icon="xs-icon-close" @click.native="closeAttribute"></svg-icon>
      </div>
      <el-tabs v-model="activeTab" class="attribute-tabs" @tab-click="attributeTabClick">
        <el-tab-pane v-loading="attributeLoading" label="属性" name="attribute">
          <div class="attribute-wrapper">
            <div class="attribute-header">
              <div class="resize-box" ref="resizeBox">
                <div class="resize-box-cell one-line" ref="resizeLeft">名称</div>
                <div class="resize-box-div" ref="resizeDiv"></div>
                <div class="resize-box-cell-right">值</div>
              </div>
              <!-- <svg-icon class="pointer" style="margin: 0 20px;" icon="xs-icon-plus"
                @click.native="showAttributeDialog(null)"></svg-icon>
              <svg-icon icon="xs-icon-setting"></svg-icon> -->
            </div>
            <div class="attribute-row">
              <span class="one-line" :style="`width: ${attributeLabelWidth}px;padding-left: 10px;`">文件来源：</span>
              <span class="attribute-row__content">{{ attributeDetail.filePosition ||
                attributeDetail.folderPosition }}</span>
            </div>
            <template v-for="item in attributeDetail.fsDictQueryVOList">
              <!-- 分类 -->
              <div v-if="item.dictUseType === 1" class="file-attribute" :key="item.dictId">
                <div class="file-attribute__label one-line" :style="`width: ${attributeLabelWidth}px;`">{{ item.dictName
                }}：</div>
                <div class="file-attribute__content">
                  <div v-if="item.dictItemType === 1" class="attribute-row__content flex">
                    <div style="width: 180px;line-height: 32px;">{{ item.itemText }}</div>
                    <!-- <span class="descr">{{ item.description }}</span> -->
                  </div>
                  <el-checkbox-group v-if="item.dictItemType === 2 || item.dictItemType == 0" v-model="item.itemTextId"
                    class="xs-checkbox-group" size="small" :disabled="isPreview"
                    @change="(val) => { changeCheckbox(val, item) }">
                    <el-checkbox v-for="site in item.detailDTOList" border :key="site.id" :label="site.id"
                      :disabled="defaultSelect.includes(site.id) || disabledList.includes(site.id)">{{ site.itemText
                      }}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
              <!-- 属性 -->
              <div v-if="item.dictUseType === 2" class="attribute-row" :key="item.dictId"
                :class="{ 'attribute-row-2': item.dictItemType === 2, 'custom-attribute': !isPreview && item.dictType === 2, }">
                <span class="one-line" :style="`width: ${attributeLabelWidth}px;padding-left: 10px;`">{{ item.dictName
                }}：</span>
                <div v-if="item.dictItemType === 1" class="attribute-row__content flex">
                  <div style="width: 180px;">{{ item.itemText }}</div>
                  <!-- <span class="descr">{{ item.description }}</span> -->
                </div>
                <span v-if="item.dictItemType === 2" class="attribute-row__content">
                  <el-select v-model="item.itemTextId" multiple placeholder="空值" popper-class="xs-select" size="small"
                    style="width: 180px;" :disabled="isPreview" @change="(val) => { changeAttrValue(val, item) }">
                    <el-option v-for="item in item.detailDTOList" :key="item.id" :label="item.itemText" :value="item.id">
                    </el-option>
                  </el-select>
                </span>
              </div>
            </template>
            <div class="file-info">
              <div class="file-info-row">文件大小：{{ common.formatFileSize(attributeDetail.fileSize ||
                attributeDetail.folderSize) }}</div>
              <div class="file-info-item">更新时间：{{ Object.dayjs(attributeDetail.updateTime) }}</div>
              <div class="file-info-item">更新人：{{ attributeDetail?.updateBy }}</div>
              <div class="file-info-item">创建时间：{{ Object.dayjs(attributeDetail.createTime) }}</div>
              <div class="file-info-item">创建人：{{ attributeDetail?.createBy }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="log">
          <div v-for="item in operationLog" class="log-item" :key="item.id">
            <div class="log-item-top">
              <div class="log-item-top-left">
                <div class="log-item-top__avatar no-avatar">
                  <span>{{ common.getFirstChat(item.createBy) }}</span>
                </div>
                <span class="log-item-top__name">{{ item.createBy }}</span>
              </div>
              <span class="log-item-top__date">{{ Object.dayjs(item.createTime) }}</span>
            </div>
            <span class="log-item-content">{{ item.optContent }}</span>
          </div>
        </el-tab-pane>
        <el-tab-pane label="关联" name="associate">
          <el-table header-cell-class-name="app-header-cell" :data="associateList">
            <el-table-column show-overflow-tooltip label="名称" prop="eventName" min-width="200"></el-table-column>
            <el-table-column label="类型" prop="eventType" min-width="120"></el-table-column>
            <el-table-column label="负责人" prop="responsibleBy" min-width="120"></el-table-column>
            <el-table-column label="状态" prop="status" min-width="120"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <CategoryDialog v-if="categoryDialogVisible" :visible.sync="categoryDialogVisible" :query-id="queryId"
      @refresh="updateCategoryList" />
    <FolderDialog v-if="folderDialogVisible" :visible.sync="folderDialogVisible" :query-id="queryId"
      :category-id="{ 'dictId': firstCategory.id, 'dictItemIds': secondCategory.id }" :item="subject"
      :parent-id="queryFile.folderId ? queryFile.folderId : ''" @finish="updateTableData" :api="'createFileFolder'" />
    <UpdateFolder v-if="updateFolderVisible" :visible.sync="updateFolderVisible" :current="clickItem"
      :dialog-type="updateFolderType" @finish="getTableData(null, true)" />
    <FileVersionDialog v-if="fileVersionVisible" :visible.sync="fileVersionVisible" :current="clickItem"
      @finish="getTableData(null, true)" />
    <CustomAttribute v-if="customAttributeVisible" :visible.sync="customAttributeVisible" :file="currentItem"
      :current="clickedAttribute" @finish="handleCheck(currentItem)" />
    <SelectAttribute v-if="selectAttributeVisible" :visible.sync="selectAttributeVisible" :file="currentItem"
      :current="clickedAttribute" @finish="handleCheck(currentItem)" />
    <FilePermission v-if="filePermissionVisible" :visible.sync="filePermissionVisible" />
    <FilePreview v-if="preview" :visible.sync="preview" :haslist="haslist" :currentitem.sync="currentItem" />
    <PlugInDialog v-if="plugvisible" :visible.sync="plugvisible"/>
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import { mapGetters } from 'vuex';
import { File } from "@/api/File";
import CategoryDialog from '../dialog/Category.vue';
import FolderDialog from '@/components/filecompanies/Folder.vue';
import UpdateFolder from '@/components/filecompanies/UpdateFolder.vue';
import FileVersionDialog from '../dialog/FileVersion.vue';
import UploadProgress from '../dialog/UploadProgress.vue';
import CustomAttribute from '../dialog/CustomAttribute.vue';
import SelectAttribute from '../dialog/SelectAttribute.vue';
import FilePermission from '../dialog/FilePermission.vue';
import FilePreview from '@/components/dialog/FilePreview.vue';
import ColudDisk from '@/components/filecompanies/ColudDiskTable.vue';
import UploadFile from '@/components/filecompanies/uploadFile.vue';
import PlugInDialog from '@/components//dialog/PlugIn.vue';
import { DownloadFile, officeView } from "@/utils/file";
export default {
  name: "FilesComponents",
  props: {
    current: Object,
    visible: {
      type: Boolean,
      default: false,
    },
    subject: Object, // 主题详情
    isPreview: {
      type: Boolean,
      default: false,
    }, // true: 预览状态 false: 编辑状态
    which: {
      default: false,
    },
    demoview: {
      type: Boolean,
      default: false,
    },
    moduleId: {
      type: String,
      default: '',
    }
  },
  components: { CategoryDialog, FolderDialog, UpdateFolder, FileVersionDialog, UploadProgress, CustomAttribute, SelectAttribute, FilePermission, FilePreview, ColudDisk, UploadFile, PlugInDialog },
  data() {
    return {
      fileTableHeight: 400,
      uploadPercent: 0, // 上传进度信息
      uploadFile: null, // 上传的文件
      progressVisible: false, // 上传进度弹窗
      breadcrumbList: [],
      tableData: [],
      tableLoading: true,
      pagingData: new this.common.Pagination(),

      categoryDialogVisible: false, // 分类弹窗
      folderDialogVisible: false,
      uploadDialogVisible: false,
      updateFolderVisible: false,
      fileVersionVisible: false,
      updateFolderType: 1,
      clickItem: null,

      activeName: "first",
      cloudTable: [],
      cloudTableLoading: false,
      flattenCloudTable: [],

      currentItem: null, // 选中的文件/文件夹
      activeTab: "attribute",
      showAttribute: false,
      attributeDetail: {}, // 属性详情
      operationLog: [], // 操作日志
      associateList: [],

      queryId: {
        moduleId: "",
        subjectId: "",
        subjectColumnId: "",
      },
      categoryLoading: true, // 分类区域 loading
      allItem: {
        children: [],
        countNum: null,
        dictName: "全部",
        dictType: 1,
        id: "all",
      },
      firstCategoryList: [], // 一级分类列表
      firstCategory: {}, // 选中的一级分类
      secondCategoryList: [], // 二级分类列表
      secondCategory: {}, // 选中的二级分类
      queryFile: {
        dictItemIds: "", // 二级分类id
        folderId: "",
        name: "",
        pageNo: 1,
        pageSize: 10,
      },
      fileList: [], // 文件列表
      // 属性的分类里默认不能删除的选中项,因为文件和文件夹一定要属于某个二级分类,但是不能明确上传时的二级分类是哪个
      // 目前规则是(后续可能会修改)：1、选中二级分类时,不能删除当前二级分类 2、选中全部时,文件属性里第一个选中的二级分类不能删除
      defaultSelect: [],
      disabledList: [], // 不能选择的二级分类id
      attributeLoading: false,
      attributeLabelWidth: 76, // 属性名称的宽度
      cloudTableHeight: 550,
      customAttributeVisible: false, // 自定义属性弹窗
      clickedAttribute: null, // 点击编辑的属性
      selectAttributeVisible: false, // 属性选择弹窗
      filePermissionVisible: false, // 文件权限弹窗
      preview: false,//视频预览弹窗
      haslist: false,//视频预览弹窗用（无列表）
      controller: null, // 控制取消导入
      defaultProps: {
        children: 'children',
        label: 'folderName',
        isLeaf: 'hasChild'
      },
      ProductFileTable: [],
      subjecuFilePayload: {
        moduleId: "",
        subjectId: "",
        fileName: ""
      },
      activeNodeId: '',
      dragFile: null,//左边拖拽的文件
      isDownloading: false,//插件是否正在下载
      percentage: 0,
      queryUploadData: {},
      uploading: false,// 插件是否有正在上传的文件
      localfilename: "",
      plugvisible: false,//插件下载弹窗
      filereceiveId: ( new Date().getTime().toString(16) + Math.random().toString(16).substring(2) ).substring(2, 18),//用来上传本地文件的id
    };
  },
  watch: {
    current: {
      handler(newValue) {
        this.getSubjectFile()
        this.queryId.subjectColumnId = newValue.id;
        this.queryFile.folderId = newValue.fileColumnFolderId
        if (newValue.id.indexOf("new-") === -1) {
          this.getFirstCategory();
        } else {
          this.firstCategoryList = [];
          this.firstCategory = {};
          this.secondCategory = null;
          this.tableData = [];
          this.breadcrumbList = [];
          this.queryFile.name = "";
        }
      },
      deep: true,
    },
    isconnected(val) {
      if (val) {
        this.init();
      }
    },
  },
  computed: {
    ...mapGetters(["token", "userInfo", "activeMenu","isconnected"]),
    showdialog: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    filterFirstCategoryList() {
      return this.firstCategoryList.filter(item => item.show);
    }
  },
  created() {
    this.queryId.moduleId = this.moduleId ? this.moduleId : this.activeMenu.id;
    this.queryId.subjectId = this.subject.id;
    this.queryId.subjectColumnId = this.current.id;
    this.queryFile.folderId = this.current.fileColumnFolderId
    this.subjecuFilePayload = {
      moduleId: this.moduleId ? this.moduleId : this.activeMenu.id,
      subjectId: this.subject.id,
      fileName: ""
    }
  },
  async mounted() {
    this.getFirstCategory();
    this.$nextTick(() => {
      this.fileTableHeight = this.$refs.fileTableWrapper.offsetHeight;
      // this.cloudTableHeight = this.$refs.cloudTableWrapper.offsetHeight;
    })
    this.getSubjectFile();
    this.init();
  },
  beforeDestroy() {
    this.$messageService.$off("websocketMessage");
  },
  methods: {
    /**
     * @description: 获取文件栏目一级分类列表
     * @return {void}
     */
    async getFirstCategory() {
      this.categoryLoading = true;
      const res = await File.queryFileByFirstCategory({ dataType: 1, ...this.queryId });
      const list = [this.allItem, ...res];
      this.setShow(list);
      list.shift();
      this.firstCategoryList = list;
      this.secondCategoryList = list;
      this.firstCategory = this.allItem;
      this.categoryLoading = false;
      this.getTableData(1, true);
    },
    /**
     * @description: 更新分类列表
     * @return {void}
     */
    async updateCategoryList() {
      let res = await File.queryFileByFirstCategory({ dataType: 1, ...this.queryId });
      if (this.firstCategory.id && this.firstCategory.id !== "all") {
        const index = res.findIndex(i => i.id === this.firstCategory.id);
        if (index !== -1) {
          this.firstCategory = res[index];
          res.splice(index, 1);
          res.unshift(this.firstCategory);
        }
      }
      const list = [this.allItem, ...res];
      this.setShow(list);
      list.shift();
      this.firstCategoryList = list;
      if (this.firstCategory.id && this.firstCategory.id !== "all") {
        this.getSecondCategory(this.firstCategory.id);
      } else {
        this.firstCategory = this.allItem;
        this.secondCategory = {};
        this.secondCategoryList = this.firstCategoryList;
        this.getTableData(1);
      }
    },
    /**
     * @description: 更新分类的统计数量
     * @return {void}
     */
    async updateCountNum() {
      const firstList = await File.queryFileByFirstCategory({ dataType: 1, ...this.queryId });
      firstList.forEach(element => {
        const index = this.firstCategoryList.findIndex(i => i.id === element.id);
        this.$set(this.firstCategoryList[index], "countNum", element.countNum);
      })
      if (this.secondCategory.id) {
        const secondList = await File.queryFileBySecondCategory({ dictId: this.firstCategory.id, dataType: 1, ...this.queryId });
        secondList.forEach(element => {
          const index = this.secondCategoryList.findIndex(i => i.id === element.id);
          this.$set(this.secondCategoryList[index], "countNum", element.countNum);
        })
      }
    },
    /**
     * @description: 更新分类的统计数量及文件列表
     * @return {void}
     */
    updateTableData() {
      this.updateCountNum();
      this.getTableData(null, true);
      this.getSubjectFile()
    },
    /**
     * @description: 根据一级分类获取文件栏目二级分类列表
     * @return {void}
     */
    async getSecondCategory(dictId) {
      this.categoryLoading = true;
      this.tableLoading = true;
      this.showAttribute = false;
      this.secondCategoryList = await File.queryFileBySecondCategory({ dictId, dataType: 1, ...this.queryId });
      this.queryFile.folderId = "";
      this.breadcrumbList = [];
      if (this.secondCategoryList.length) {
        if (!(this.secondCategory.id && this.secondCategoryList.find(i => i.id === this.secondCategory.id))) {
          this.secondCategory = this.secondCategoryList[0];
        }
        this.getTableData(1);
      } else {
        this.secondCategory = {};
        this.tableData = [];
        this.tableLoading = false;
      }
      this.categoryLoading = false;
    },
    /**
     * @description: 选择一级分类
     * @param {Object} value 选择项
     * @return {void}
     */
    handleSelectFirstCategory(value) {
      this.firstCategory = value;
      if (value.id === "all") {
        this.secondCategory = {};
        this.secondCategoryList = this.firstCategoryList;
        this.breadcrumbList = [];
        this.queryFile.folderId = "";
        this.getTableData();
      } else {
        this.getSecondCategory(value.id);
      }
    },
    /**
     * @description: 选择二级分类
     * @param {Object} value 选择项
     * @return {void}
     */
    handleSelectSecondCategory(value) {
      if (this.firstCategory.id === "all") {
        this.firstCategory = value;
        this.getSecondCategory(value.id);
        if (!value.show) {
          const list = [this.allItem, value, ...this.firstCategoryList.filter(i => i.id !== value.id)];
          this.setShow(list);
          list.shift();
          this.firstCategoryList = list;
        }
      } else {
        this.secondCategory = value;
        this.breadcrumbList = [];
        this.getTableData(1);
      }
    },
    /**
     * @description: 选择展开的一级分类
     * @param {Object} value
     * @return {void}
     */
    selectRemainCategory(value) {
      this.$refs.categoryPopover && this.$refs.categoryPopover.doClose();
      if (value.id === this.firstCategory.id) {
        return
      }
      if (!value.show) {
        let list = this.firstCategoryList.filter(item => item.id !== value.id);
        list.unshift(value);
        list.unshift(this.allItem);
        this.setShow(list);
        list.shift();
        this.firstCategoryList = list;
      }
      this.firstCategory = value;
      this.getSecondCategory(value.id);
    },
    /**
     * @description: 显示分类弹窗
     * @return {void}
     */
    showCategoryDialog() {
      if (this.current.id.indexOf("new-") === -1) {
        this.categoryDialogVisible = true;
      }
    },
    /**
     * @description: 设置一级分类在顶部是否可见
     * @param {Array} array 一级分类数组
     * @return {Number} 数组长度
     */
    setShow(array) {
      let sum = 0;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const width = (this.getElementWidth(element.dictName) * 100 + 1600) / 100; // 16 = 左右 padding 3 + margin-right 10
        // 238: 一级分类的容器宽度 212: 显示展开 icon 时一级分类的容器宽度
        if (sum + width > 238) {
          element.show = false;
          array[index - 1].show = sum <= 212;
        } else {
          element.show = true;
        }
        sum += width;
      }
    },
    /**
     * @description: 获取文本元素宽度
     * @param {String} text 文本
     * @param {String} id 文本id
     * @return {Number} 文本元素宽度
     */
    getElementWidth(text, id = "virtualSpan") {
      let el = document.createElement("span");
      el.setAttribute('id', id);
      el.innerHTML = text;
      el.style.setProperty('font-size', "14px");
      el.style.setProperty('font-weight', 400);
      el.style.setProperty('height', 0);
      el.style.setProperty('padding', 0);
      el.style.setProperty('border', "none");
      el.style.setProperty('margin', 0);
      document.body.appendChild(el);
      let el2 = document.getElementById(id);
      const width = Math.ceil(parseInt(el2.getBoundingClientRect().width * 1000) / 10) / 100;
      document.body.removeChild(el);
      return width
    },
    /**
     * @description: 获取文件目录下的文件列表
     * @return {void}
     */
    async getTableData(pageNo, updateCloudTable) {
      this.tableLoading = true;
      pageNo && (this.queryFile.pageNo = pageNo);
      this.queryFile.dictItemIds = this.secondCategory.id;
      this.showAttribute = false;
      this.currentItem = null;
      this.$refs.table && this.$refs.table.setCurrentRow();
      let payload = _.omit(this.queryFile, [this.breadcrumbList.length ? "dictItemIds" : "folderId"]);
      if (this.breadcrumbList.length) {
        payload = _.omit(this.queryFile, ["dictItemIds"]);
      } else {
        payload = { dataType: 1, ...this.queryId, ..._.omit(this.queryFile, ["folderId"]) };
      }
      const res = await File[this.breadcrumbList.length ? "queryFileByFolderId" : "queryFileByCategory"](payload);
      res.records.forEach(element => {
        // element.createBy = this.common.getJSONObject(element.createBy);
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
        } else {
          element.aliasType = "folder";
        }
        element.showprogress = false;
        element.progress = 0;
      })
      this.tableData = res.records;
      this.pagingData = res;
      this.tableLoading = false;
      // updateCloudTable && this.getCloudDiskList();
      if (updateCloudTable && !this.isPreview) {
        this.getCloudDiskList();
      }
    },
    /**
     * @description: currentPage 改变时会触发
     * @param {Number} value 当前页
     * @return {void}
     */
    handleCurrentChange(value) {
      this.queryFile.pageNo = value;
      this.getTableData();
    },
    /**
     * @description: 格式化文件大小
     * @param {Number} size 文件大小
     * @return {String} 格式化后的文件大小
     */
    formatFileSize(size) {
      if (typeof size !== "number") {
        return "--"
      }
      if (size < 1024) {
        return size + "B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        return Math.ceil(size / 1024 * 100) / 100 + "KB";
      } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
        return Math.ceil(size / 1024 / 1024 * 100) / 100 + "MB";
      } else {
        return Math.ceil(size / 1024 / 1024 / 1024 * 100) / 100 + "GB";
      }
    },
    /**
     * @description: 点击面包屑获取文件列表
     * @param {Object} value 点击项
     * @param {Number} index 索引
     * @return {void}
     */
    jumpTo(value, index) {
      if (index === this.breadcrumbList.length - 1) {
        return
      }
      if (value) {
        this.breadcrumbList.splice(index + 1);
        this.queryFile.folderId = value.id;
      } else {
        this.breadcrumbList = [];
        this.queryFile.folderId = "";
      }
      this.queryFile.name = "";
      this.getTableData(1);
    },
    /**
     * @description: 当某一行被双击时会触发该事件
     * @param {Object} row 点击行
     * @return {void}
     */
    rowClick(row) {
      this.currentItem = row;
      this.handleCheck(row);
    },
    /**
     * @description: 打开文件夹
     * @param {Object} value 文件夹
     * @return {void}
     */
    handleOpenfolder(value) {
      const index = this.breadcrumbList.findIndex(item => item.id === value.id);
      index === -1 && this.breadcrumbList.push(value);
      this.queryFile.folderId = value.id;
      this.queryFile.name = "";
      this.getTableData(1);
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleView(value) {
      if (value.aliasType === "office") {
        officeView(value.id);
      }
      if (value.aliasType === "image") {
        this.$elImageViewer({ urlList: [value.fileSavePath], initialIndex: 0 });
      }
      if (value.aliasType === "video") {
        this.currentItem = value
        this.preview = true
      }
    },
    /**
     * @description: 下载
     * @param {Object} value 点击项
     * @return {void}
     */
    async handleDownload(value) {
      DownloadFile(value, 1)
    },
    /**
     * @description: 打开 更新版本 弹窗
     * @param {Object} value 要更新版本的文件
     * @return {void}
     */
    handleUpdateVersion(value) {
      this.clickItem = value;
      this.uploadDialogVisible = true;
    },
    /**
     * @description: 重命名、编辑说明
     * @param {Object} value 点击项
     * @param {Number} type 类型
     * @return {void}
     */
    handleUpdate(value, type) {
      this.clickItem = value;
      this.updateFolderType = type;
      this.updateFolderVisible = true;
    },
    /**
     * @description: 查看属性
     * @param {Object} value 文件/文件夹
     * @return {void}
     */
    async handleCheck(value) {
      this.$refs.table.setCurrentRow(value);
      this.currentItem = value;
      this.activeTab = "attribute";
      this.attributeDetail = {};
      this.associateList = [];
      this.operationLog = [];
      this.showAttribute = true;
      this.getAttributeDetail();
    },
    /**
     * @description: 获取文件/文件夹属性
     * @return {void}
     */
    async getAttributeDetail() {
      if(!this.currentItem){
        return;
      }
      this.attributeLoading = true;
      this.defaultSelect = [];
      this.disabledList = [];
      let payload = {
        dataId: this.currentItem.id,
        moduleId: this.moduleId,
        subjectColumnId: this.current.id,
        subjectId: this.subject.id,
        type: 1,
      }
      const res = await File[this.currentItem.fileType == "1" ? "getFileAttribute" : "getFolderAttribute"](payload);
      this.secondCategory.id && this.defaultSelect.push(this.secondCategory.id);
      // 获取默认分类
      const firstCategory = res.fsDictQueryVOList.find(item => item.dictName === "演示材料");
      // if (firstCategory) {
      //   const videoItem = firstCategory.detailDTOList.find(item => item.itemText === "演示视频");
      //   const modelItem = firstCategory.detailDTOList.find(item => item.itemText === "演示模型");
      //   if (this.currentItem.fileType === "1") {
      //     // 文件
      //     if (this.currentItem.aliasType === "video") {
      //       // 视频文件不能选择默认的 演示模型 分类
      //       this.disabledList.push(modelItem.id);
      //     } else if (this.currentItem.aliasType === "model") {
      //       // 模型文件不能选择默认的 演示视频 分类
      //       this.disabledList.push(videoItem.id);
      //     } else {
      //       // 其他类型的文件不能选择默认的 演示视频 演示模型 分类
      //       this.disabledList = [videoItem.id, modelItem.id]
      //     }
      //   } else {
      //     // 文件夹
      //     // 属于默认的演示视频分类则无法选择默认的演示模型分类
      //     // 属于默认的演示模型分类则无法选择默认的演示视频分类
      //     // 属于其他分类则无法选择默认的演示视频和演示模型分类
      //     if (videoItem.isSelected) {
      //       this.disabledList.push(modelItem.id);
      //     } else if (modelItem.isSelected) {
      //       this.disabledList.push(videoItem.id);
      //     } else {
      //       this.disabledList = [videoItem.id, modelItem.id]
      //     }
      //   }
      // }
      res.fsDictQueryVOList.forEach(element => {
        element.id = element.dictId;
        element.dataId = res.id;
        const selected = element.detailDTOList ? element.detailDTOList.filter(item => item.isSelected) : [];
        if (this.defaultSelect.length === 0 && selected.length) {
          this.defaultSelect.push(selected[0].id);
        }
        if (element.dictItemType === 1) {
          element.dictUseType = 2
          // 自由输入
          element.itemText = element.detailDTOList[0] ? element.detailDTOList[0].itemText : '';
          element.description = element.detailDTOList[0] ? element.detailDTOList[0].description : '';
        } else if (element.dictItemType === 2) {
          if (element.dictName != "演示材料") {
            element.dictUseType = 2
          }
          // 固定值
          // element.itemTextId = selected.length ? selected[0].id : "";
          element.itemTextId = [];
          selected.forEach(item => element.itemTextId.push(item.id));
          // element.description = selected.length ? selected[0].description : "";
        } else if (element.dictItemType === 0) {
          element.dictUseType = 1
          element.itemTextId = [];
          selected.forEach(item => element.itemTextId.push(item.id));
        }
        // if (element.dictItemType === 1) {
        //   // 自由输入
        //   element.itemText = element.detailDTOList[0]?element.detailDTOList[0].itemText:'';
        //   element.description = element.detailDTOList[0]?element.detailDTOList[0].description:'';
        // } else if (element.dictItemType === 2) {
        //   if(element.dictName != "演示材料"){
        //      element.dictUseType = 2
        //   }
        //   // 固定值
        //   // element.itemTextId = selected.length ? selected[0].id : "";
        //   element.itemTextId = [];
        //   selected.forEach(item => element.itemTextId.push(item.id));
        //   element.description = selected.length ? selected[0].description : "";
        // } else {
        //   // 分类
        //   element.itemTextId = [];
        //   selected.forEach(item => element.itemTextId.push(item.id));
        // }
      })
      this.attributeDetail = res;
      // 又说不需要分类了，这里暂时先过滤掉
      this.attributeDetail.fsDictQueryVOList = this.attributeDetail.fsDictQueryVOList.filter(item => item.dictUseType != 1 && item.dictName != "演示材料")
      this.attributeLoading = false;
      this.setResize();
    },
    /**
     * @description: 拖动改变属性名称的宽度
     * @return {void}
     */
    setResize() {
      let leftDiv = this.$refs.resizeLeft;
      const resizeDom = this.$refs.resizeDiv;
      resizeDom.onmousedown = (e) => {
        let startX = e.clientX;
        const resizeLeft = leftDiv.offsetWidth;
        const maxWidth = this.$refs.resizeBox.offsetWidth;
        document.onmousemove = (e) => {
          const endX = e.clientX;
          let offsetWidth = endX - startX + resizeLeft;
          if (offsetWidth < 30) {
            offsetWidth = 30;
          }
          if (offsetWidth > maxWidth - 60) {
            offsetWidth = maxWidth - 60;
          }
          leftDiv.style.width = `${offsetWidth}px`;
          this.attributeLabelWidth = offsetWidth;
        };
        document.onmouseup = () => {
          document.onmousemove = null;
          document.onmouseup = null;
        };
        return false;
      }
    },
    /**
     * @description: 获取关联事项列表
     * @return {void}
     */
    async getFileRelated() {
      this.associateList = await File.getFileRelatedMatters(this.attributeDetail.identifyId);
      this.attributeLoading = false;
    },
    /**
     * @description: 查看历史版本
     * @param {Object} value 文件/文件夹
     * @return {void}
     */
    async showDialog(value, key) {
      this.clickItem = value;
      this[key] = true;
    },
    /**
     * @description: 分享
     * @param {Object} value 文件/文件夹
     * @return {void}
     */
    handleShare(value) {
      // 分享...
    },
    /**
     * @description: 删除
     * @param {Object} value 点击项
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage({
        message: `是否确认删除 <span style="font-weight: 1000;">${value.folderName}</span> ?`,
        ...this.$global.deleteMessage
      }, async (done) => {
        if (value.fileType == 1) {
          // 删除文件
          const res = await File.deleteFile(value.id);
          done(res);
          res && this.updateTableData();
        } else {
          // 删除文件夹
          const res = await File.deleteFolder(value.id);
          done(res);
          res && this.updateTableData();
        }
      });
    },
    /**
     * @description: 获取租户下文件系统目录列表(右侧云盘)
     * @return {void}
     */
    async getCloudDiskList() {
      this.cloudTableLoading = false;
      this.cloudTable = await File.queryFileSystemList();
      this.cloudTableLoading = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.setDraggable(this.cloudTable);
      })
    },
    /**
     * @description: 云盘表格一维化
     * @param {*} array
     * @param {*} newArray
     * @return {*}
     */
    flatten(array, newArray) {
      array.forEach(element => {
        newArray.push(_.pick(element, ["id", "parentId", "folderName", "fileType", "fileSize"]));
        if (element.children && element.children.length) {
          this.flatten(element.children, newArray);
        }
      })
    },
    /**
     * @description: 设置企业云盘表格可拖拽
     * @param {Array} tableData 云盘表格数据(一维数组)
     * @return {void}
     */
    setDraggable(tableData) {
      // 设置相关文件表格为拖拽放置区域
      const fileTableWrapper = this.$refs.table.$el.querySelector(".el-table__body-wrapper");
      fileTableWrapper.ondrop = async (event) => {
        if (this.isPreview) {
          return
        }
        event.preventDefault();
        if(event.dataTransfer.getData("localpath")){
          this.localfilename=event.dataTransfer.getData("localname")
          this.handleLocalupload(event.dataTransfer.getData("localpath"))
          return
        }
        const id = event.dataTransfer.getData("Text"); // 拖拽的文件/文件夹的id
        const source = id.split("-");
        let payload = {
          newFolderId: this.queryFile.folderId ? this.queryFile.folderId : '-1',
          optType: 2,//（1：分享，2：镜像，3，复制）默认镜像
          targetType: [1],
          type:1,//(1：栏目文件，2：产品文件，3：演示文件)
        }
        if (!this.queryFile.folderId) {
          payload = { ...payload, ...this.queryId }
          payload.columnId = this.queryId.subjectColumnId
        }
        if (id) {
          payload.targetId = [source[0]];
          if (source[1] === "1") {
            payload.targetType = [1];
          } else {
            payload.targetType = [2];
          }
        } else {
          payload.targetId = [this.dragFile.id];
          if (this.dragFile.fileType == 1) {
            payload.targetType = [1];
          } else {
            payload.targetType = [2];
          }
        }
        this.tableLoading = true;
        const res = await File.createFileRelation(payload);
        this.dragFile = null;
        this.tableLoading = false
        if (res) {
          // const payload = {
          //   addEvenDetailtDTOS: [
          //     { dataId: source[0], dataType: source[1] },
          //   ],
          //   eventName: this.subject.subjectName,
          //   eventType: "产品",
          //   eventDetail: JSON.stringify({
          //     subjectId: this.subject.id,
          //     mirrorId: this.subject.mirrorId,
          //     versionId: this.subject.versionId,
          //     moduleId: this.subject.moduleId,
          //   }),
          //   oldEventName: "",
          //   optType: 3,
          // }
          // await File.addFileEvent(payload);
          this.$nextTick(() => {
            this.getTableData(null, true);
            this.getSubjectFile()
          })
        }
      }
      fileTableWrapper.ondragover = function (event) {
        event.preventDefault();
      }
    },
    /**
     * @description: 关闭属性区域
     * @return {void}
     */
    closeAttribute() {
      this.$refs.table.setCurrentRow();
      this.showAttribute = false;
      this.activeTab = "attribute";
      this.currentItem = null;
    },
    /**
     * @description: 自定义属性-固定值-选择文件属性值
     * @param {String} valueId 选择项
     * @return {void}
     */
    async changeAttrValue(valueId, attr) {
      const attribute = attr.detailDTOList.find(item => item.id === valueId);
      attr.description = attribute ? attribute.description : "";
      const payload = {
        // dataId: this.currentItem.id,
        // dictId: attr.dictId,
        // dictItemId: valueId
        "dataId": this.currentItem.id,
        "dataType": 0,
        "dictId": attr.id,
        "dictItemIds": valueId
      }
      const res = await File.updateAttributeValueSelect(payload)
      // const payload= {
      //   dataId: this.currentItem.id,
      //   dataType: 0,
      //   dictId: "",
      //   dictItemIds: []
      // }
      // const res = await File.updateFileValue(payload);
      const pre = attr.detailDTOList.find(item => item.isSelected);
      if (res) {
        this.$message.success('成功')
        pre && (pre.isSelected = 0);
        attribute && (attribute.isSelected = 1);
      } else {
        attr.itemTextId = pre ? pre.id : "";
        attr.description = pre ? pre.description : "";
      }
    },
    /**
     * @description: 修改文件/文件夹分类
     * @return {void}
     */
    async changeItemCategory(ids, value) {
      const payload = {
        dataId: this.currentItem.id,
        dictId: value.dictId,
        dictItemIds: ids,
      }
      await File.updateFileCategory(payload);
      this.updateCountNum();
    },
    /**
     * @description: 修改文件/文件夹分类
     * @param {Array} ids 选择的分类
     * @param {Object} value 一级分类
     * @return {void}
     */
    async changeCheckbox(ids, value) {
      const payload = {
        dataId: this.currentItem.id,
        dictId: value.dictId,
        dictItemIds: ids,
      }
      await File.updateFileCategory(payload);
      this.updateCountNum();
    },
    /**
     * @description: 删除属性
     * @param {Object} value 属性
     * @return {void}
     */
    deleteAttribute(value) {
      this.$showMessage({
        message: `是否确认删除 <span style="font-weight: 1000;">${value.dictName}</span> ?`,
        ...this.$global.deleteMessage
      }, async (done) => {
        const res = await File.deleteAttribute(this.currentItem.id, value.dictId);
        done(res);
        res && this.getAttributeDetail();
      });
    },
    /**
     * @description: 显示 自定义属性 弹窗
     * @return {void}
     */
    showAttributeDialog(value) {
      this.clickedAttribute = value;
      this.customAttributeVisible = true;
    },
    /**
     * @description: 属性 tab 被选中时触发
     * @param {VueComponent} tab 被选中的标签 tab 实例
     * @return {void}
     */
    attributeTabClick(tab) {
      if (tab.name === "log") {
        this.getLogs();
      }
      if (tab.name === "associate") {
        if (this.attributeDetail.identifyId) {
          this.getFileRelated();
        } else {
          this.associateList = [];
        }
      }
    },
    /**
     * @description: 获取操作日志
     * @return {void}
     */
    async getLogs() {
      const payload = {
        dataId: this.currentItem.id,
        sort: "",
        sortType: "",
        pageNo: 1,
        pageSize: 9999,
      }
      const res = await File.getFileLog(payload);
      this.operationLog = res.records;
    },
    /**
     * @description: 获取主题文件
     * @return {void}
     */
    async getSubjectFile() {
      const res = await File.queryFileBySubject(this.subjecuFilePayload)
      res.records.forEach(element => {
        element.hasChild = element.fileType == 2 ? false : true;
        // element.hasChild = !(element.fileType == 2 && element.hasChild == 1);
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
        } else {
          element.aliasType = "folder";
        }
        element.color = this.common.getFileColor(element.aliasType);
      })
      this.ProductFileTable = res.records;
    },
    /**
     * @description: 主题文件节点点击
     * @return {void}
     */
    handleNodeClick(data) {
      this.activeNodeId = data.id
    },
    /**
     * @description: 加载更多主题文件
     * @return {void}
     */
    async loadNode(node, resolve) {
      if (node.data.id) {
        const res = await File.queryFileByFolderId({
          folderId: node.data.id,
          pageNo: 1,
          pageSize: 9999,
        });
        res.records.forEach(element => {
          element.hasChild = element.fileType == 2 ? false : true;
          // element.hasChild = !(element.fileType == 2 && element.hasChild == 1);
          if (element.fileType == 1) {
            element.fileSize = this.common.formatFileSize(element.fileSize);
            element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
          } else {
            element.aliasType = "folder";
          }
          element.color = this.common.getFileColor(element.aliasType);
        })
        node.children = res.records;
        resolve(res.records);
      }
    },
    end() {
      console.log('end');
    },
    /*
     * @description:  添加拖拽过来的文件内容到富文本
     * @return {void}
     */
    drop(event) {
      event.preventDefault();
    },
    /**
    * @description: 列表行拖拽
    * @param {Object} value 拖拽项
    * @return {void}
    */
    dragStart(value) {
      // 设置拖动数据
      this.dragFile = value;
    },
    dragEnd(event) {
      // this.dragFile=value;
      event.target.style.opacity = "1"; // 恢复原始样式
    },
    init() {
      this.$messageService.$on('websocketMessage', (data) => {
        // 处理接收到的消息
        const parsedMessage = JSON.parse(data);
        if (parsedMessage.funcname == "uploadingFile" && parsedMessage.data[0].status==="DONE") {
          setTimeout(()=>{
            this.updateTableData();
            this.uploading = false;
          },500)
        }else if(parsedMessage.funcname == "downloadingFile") {
          this.isDownloading=true;
          const index = this.tableData.findIndex(i => i.id === parsedMessage.msgId);
          this.$set(this.tableData, index, { ...this.tableData[index], showprogress: true });
          // this.percentage=Number(parsedMessage.data[0].progress.replace('%',''))
          let percentage=Number(parsedMessage.data[0].progress.replace('%',''))
          this.$set(this.tableData, index, { ...this.tableData[index], progress: percentage });
          if(parsedMessage.data[0].status==="DONE" && parsedMessage.data[0].progress){
            this.isDownloading=false;
            this.$set(this.tableData[index],'showprogress',false)
          }
        }
      });
    },
    /**
    * @description: 行双击下载文件并在本地打开
    * @param {Object} row 点击项
    * @return {void}
    */
    rowdbClick(row){
      if(row.fileType == 2){
        return
      }
      if(!this.isconnected){
        this.plugvisible = true;
        return
      }
      this.percentage = 0
      this.currentItem = row;
      if (this.$websocket.readyState === WebSocket.OPEN) {
        // 发送消息
        let queryDownloadData= {
          msgId: row.id,
          funcname: "downloadfile",
          downloadType: "1", //下载消息类型，1:开始，2:暂停,3:取消
          systemType: "1",
          token: this.token ?this.token : "",
          tenantId: this.userInfo ? this.userInfo.tenantIdNow: "",
          baseurl: process.env.VUE_APP_URL_NETDISK,
          fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
          fileId: row.id,
          isCurrent: "1", //是否下载当前(1:是当前，2：是历史)(如果是历史版本必填)
          sysToken:"JqjdLEtaFOas5whS8hro2w=="
        };
        this.$websocket.send(JSON.stringify(queryDownloadData));
      }
    },
    /**
    * @description: 本地文件上传到栏目
    * @param {String} path 地址
    * @return {void}
    */
    handleLocalupload(path){
      // 使用不同的msgId来实现上传多个不同文件
      this.filereceiveId = ( new Date().getTime().toString(16) + Math.random().toString(16).substring(2) ).substring(2, 18);
      this.queryUploadData= {
        msgId: this.filereceiveId,
        funcname: "uploadfile",
        uploadType: "1", //上传消息类型，1:开始，2:暂停,3:取消
        systemType: "1",
        token: this.token ?this.token : "",
        tenantId: this.userInfo ? this.userInfo.tenantIdNow: "",
        baseurl: process.env.VUE_APP_URL_NETDISK,
        fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
        folderId: this.queryFile.folderId,
        file: path,
        sysToken:"JqjdLEtaFOas5whS8hro2w=="
      }
      this.$store.commit("saveUploadList", this.queryUploadData);
      this.$websocket.send(JSON.stringify(this.queryUploadData));
    }
  },
};
</script>
<style scoped lang="scss">
.attribute-header {
  width: 100%;
  height: 32px;
  border-radius: 5px 5px 0 0;
  margin-bottom: 16px;
  background-color: #fafbfc;
  display: flex;
  align-items: center;

  .resize-box {
    flex: 1;
    display: flex;
    align-items: center;

    .resize-box-cell {
      width: 76px;
      padding-left: 10px;
    }

    .resize-box-div {
      width: 2px;
      height: 12px;
      border-radius: 2px;
      background-color: #e6ebf2;
      cursor: col-resize;
    }

    .resize-box-cell-right {
      box-sizing: border-box;
      flex: 1;
      padding-left: 40px;
    }
  }
}

.file {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // padding: 18px 10px 0;
  display: flex;
  overflow-x: auto;
}

::v-deep .el-button {
  border-radius: 5px;
}

.productfile-wrapper {
  flex-shrink: 0;
  width: 280px;
  // height: calc(100% - 20px);
  height: 100%;
  margin-right: 14px;
  // margin-top: 20px;
  padding: 0 8px;
  box-sizing: border-box;
  background-color: #fff;

  .productfile-title {
    height: 54px;
    line-height: 54px;
    text-indent: 26px;
    border-bottom: 1px solid #E3E3E3;
  }

  .productfile-list {
    height: calc(100% - 100px);
    overflow-y: auto;
    
    ::v-deep .el-tree{
      width: 260px;
      overflow-x: auto;
    }
    ::v-deep .el-tree-node__content {
      height: 42px;
      line-height: 42px;
    }

    .active-node {
      color: var(--color-primary);
      .svg-icon{
        color: var(--color-primary)!important;
      }
    }
  }

  ::v-deep .el-input {
    margin-top: 10px;
    border-radius: 10px !important;
    background: #F5F7FA;
  }
}

.arrow-down {
  width: 16px;
  height: 16px;
  background-image: url("@/assets/images/productLibrary/arrow-right.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-image: url("@/assets/images/productLibrary/arrow-right__hover.png");
  }
}

.setting {
  width: 16px;
  height: 16px;
  background-image: url("@/assets/images/productLibrary/setting.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-image: url("@/assets/images/productLibrary/setting__hover.png");
  }
}

.file-content {
  // margin-top: 20px;
  box-sizing: border-box;
  // width: 1080px;
  // width: calc(100% - 296px);
  flex: 1;
  min-width: 860px;
  padding: 24px 10px 0;
  border-radius: 5px 5px 0 0;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  .file-content-top {
    height: 32px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    .file-content-top-left {
      display: flex;
      align-items: center;

      .el-button {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 15px;
      }

      .folder-button {
        margin-right: 10px;
        background-color: #42cfc5;
        border-color: #42cfc5;

        &:hover {
          background-color: rgba(66, 207, 197, 0.8);
          border-color: rgba(66, 207, 197, 0.8);
        }
      }

      .upload-tip {
        font-size: 12px;
        font-weight: 500;
        color: #cfd7e6;
        padding-left: 16px;
      }
    }

    .file-content-top-right {
      display: flex;

      .file-content-tr-filter {
        box-sizing: border-box;
        width: fit-content;
        height: 32px;
        line-height: 32px;
        padding: 0 25px 0 35px;
        border: 1px solid #d3dae6;
        border-radius: 5px;
        margin: 0 10px;
        font-size: 14px;
        font-weight: 500;
        color: #344563;
        cursor: pointer;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 10px;
          top: 8px;
          width: 16px;
          height: 16px;
          background-image: url("@/assets/images/common/screen.png");
          background-size: 16px 16px;
          background-repeat: no-repeat;
        }

        &::after {
          content: "";
          position: absolute;
          right: 10px;
          top: 14px;
          width: 6px;
          height: 5px;
          background-image: url("@/assets/images/common/triangle_down.png");
          background-size: 6px 5px;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .file-content-table {
    flex: 1;
    overflow-y: auto;

    .file-table-row {

      &:hover {
        .file-information {
          display: none;
        }

        .file-operation {
          display: flex;
        }

        .describes-icon {
          display: inline-block;
        }
      }
    }

    ::v-deep .el-table {
      &::before {
        height: 0;
      }

      .el-table__row {
        cursor: pointer;
      }

      .el-table__body tr:hover,
      tr.current-row {
        td {
          background-color: #f5f7fa;
        }
      }
    }
  }
}

.file-breadcrumb {
  // height: 18px;
  line-height: 18px;
  margin-bottom: 16px;
  color: #5e6c84;
  font-size: 12px;
  display: flex;
  align-items: center;

  .fw-400 {
    font-weight: 400;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-700 {
    font-weight: 700;
  }
}

.file-table {
  font-size: 14px;
  font-weight: 500;
  color: #344563;

  ::v-deep .el-table__body-wrapper {
    padding-bottom: 60px;
  }

  .describes-icon {
    display: none;
    vertical-align: top;
  }
}

.file-name__wrapper {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  .file-icon {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .file-name {
    line-height: 20px;
    padding-bottom: 4px;
    color: var(--color-text-regular);
  }

  .file-information {
    line-height: 20px;
    color: #cfd7e6;
    margin-top: 6px;
  }

  .file-operation {
    display: none;

    .file-operation-item {
      line-height: 26px;
      padding: 0 6px;
      border-radius: 5px;
      margin-right: 6px;
      color: var(--color-text-secondary);
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }

      &:not(.disabled):hover {
        color: #fff;
        background-color: var(--color-primary);
      }
    }
  }
}

.demoview {
  display: none;
}

.demominview {
  flex: 1;
  min-width: 400px;
  padding-bottom: 0;
}

.my-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  .my-pagination-tip {
    font-size: 14px;
    font-weight: 500;
    color: #cfd7e6;
  }

  .app-pagination {
    padding: 0;

    ::v-deep .btn-prev {
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
      border: 1px solid #d3dce6;
    }

    ::v-deep .btn-next {
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
      border: 1px solid #d3dce6;
    }

    ::v-deep .el-pager {
      li {
        height: 30px;
        line-height: 30px;
        border-radius: 2px;
        border: 1px solid #d3dce6;
      }

      .number {
        font-size: 12px;
        font-weight: 500;
        color: #586677;
        background-color: #fff;

        &:hover {
          // background: #dbeeff;
          border-color: #7948EA;
        }
      }

      li:not(.disabled).active {
        background: var(--color-primary);
        border-color: #7948EA;
      }
    }
  }
}

.file-disk {
  box-sizing: border-box;
  // flex: 1;
  min-width: 600px;
  // width: 512px;
  margin-left: 14px;
  position: relative;
  // margin-top: 20px;

  .file-disk-item {
    box-sizing: border-box;
    height: 30%;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;

    &.cloud-disk {
      height: calc(70% - 14px);
      margin-top: 14px;
    }

    .file-disk-item__title {
      line-height: 20px;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-text-regular);
    }

    .file-disk-item__content {
      height: calc(100% - 36px);
      overflow-y: auto;
    }
  }
}

.attribute-container {
  box-sizing: border-box;
  // flex: 1;
  // min-width: 400px;
  width: 512px;
  padding: 20px;
  border-radius: 5px 5px 0 0;
  margin-left: 14px;
  background-color: #fff;
  position: relative;
  // margin-top: 20px;

  .file-disk-item {
    height: 30%;

    &.cloud-disk {
      height: 70%;
    }

    .file-disk-item__title {
      line-height: 20px;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-text-regular);
    }

    .file-disk-item__content {
      height: calc(100% - 36px);
      overflow-y: auto;
    }
  }

  .right-close {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 30px;
    height: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;

    .svg-icon+.svg-icon {
      margin-right: 16px;
    }

    .svg-icon {
      &:hover {
        color: var(--color-primary) !important;
      }
    }

  }

  ::v-deep(.el-tabs) {
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-tabs__nav-wrap::after {
      height: 0;
    }

    .el-tabs__content {
      flex: 1;

      .el-tab-pane {
        height: 100%;
        overflow: auto;
      }
    }
  }

  .attribute-wrapper {
    overflow: auto;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-regular);

    .attribute-row {
      line-height: 20px;
      border-radius: 5px;
      margin-bottom: 16px;
      display: flex;
      position: relative;

      &.custom-attribute {
        line-height: 32px;
        padding-right: 56px;

        &:hover {
          background-color: #fafbfc;

          .custom-attribute-icon {
            display: flex;
            align-items: center;
          }
        }

        .custom-attribute-icon {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: none;
        }
      }

      .attribute-row__content {
        flex: 1;
        word-break: break-all;

        .descr {
          padding-left: 16px;
          color: #9facc2;
        }
      }
    }

    .file-attribute {
      display: flex;

      .file-attribute__label {
        width: fit-content;
        line-height: 32px;
        padding-left: 10px;
      }

      .file-attribute__content {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .xs-checkbox-group {
          .el-checkbox {
            margin: 0 16px 16px 0;
          }

          .el-checkbox.is-bordered {
            border-radius: 5px;
          }

          .el-checkbox.is-bordered+.el-checkbox.is-bordered {
            margin-left: 0;
          }
        }
      }
    }

    .file-info {
      box-sizing: border-box;
      padding: 20px;
      border-radius: 10px;
      background: #fafbfc;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .file-info-row {
        width: 100%;
      }

      .file-info-item {
        width: 50%;
        margin-top: 12px;
        word-break: break-all;
      }
    }

    .attr-item {
      font-size: 14px;
      display: flex;
      border-top: 1px solid #f7f7f7;
      border-bottom: 1px solid #f7f7f7;

      .attr-item-label {
        flex-shrink: 0;
        box-sizing: border-box;
        width: 100px;
        min-height: 32px;
        padding: 6px 8px;
        text-align: center;
        background-color: #f2f2f2;

        >span {
          line-height: 20px;
          word-break: break-all;
        }
      }

      .attr-item-label-value {
        box-sizing: border-box;
        width: calc(100% - 100px);
        min-height: 32px;
        padding: 0 6px;
        background-color: #fff;
        display: flex;
        align-items: center;
        position: relative;

        &.custom-attribute {
          padding-right: 34px;
        }

        >span {
          word-break: break-all;
        }

        &:hover {
          .attr-action {
            display: block;
          }
        }

        .descr {
          padding-left: 6px;
          color: #c0c4cc;
        }

        .attr-action {
          display: none;
          position: absolute;
          top: calc(50% - 8px);
          right: 6px;
          font-size: 16px;
          cursor: pointer;

          &.attr-edit {
            right: 28px;
          }
        }
      }
    }
  }

  .associate-wrapper {
    height: 30%;
    overflow: auto;
  }

  .log-item {
    padding: 30px 0 28px;
    border-bottom: 1px solid #e6ebf2;

    &:last-child {
      border: none;
    }

    .log-item-top {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .log-item-top-left {
        display: flex;
        align-items: center;

        .log-item-top__avatar {
          width: 16px;
          height: 16px;
          border-radius: 5px;

          &.no-avatar {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            line-height: 1;
            background-color: var(--color-primary);
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              transform: scale(0.83);
            }
          }
        }

        .log-item-top__name {
          padding-left: 8px;
        }
      }

      .log-item-top__date {
        color: #cfd7e6;
      }
    }

    .log-item-content {
      padding-left: 24px;
      word-break: break-all;
    }

    .log-item__value {
      flex: 1;
      font-size: 14px;
      color: #666;
      word-break: break-all;
    }

    .log-item__user {
      display: flex;
      align-items: center;
      padding-left: 12px;
    }

    .log-item__date {
      position: absolute;
      right: 16px;
      bottom: 0;
    }
  }
}

.dialog-content {
  padding: 0 30px;

  .attr-value {
    display: flex;
    align-items: center;
  }
}

.inner-dialog-content {
  padding: 20px 30px;
  display: flex;

  .attr-list {
    box-sizing: border-box;
    width: 240px;
    height: 240px;
    padding: 8px;
    border: 1px solid #f7f7f7;
    overflow-y: auto;

    .attr-list-row {
      display: flex;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      color: #344563;

      &.is-active {
        color: #fff;
        background-color: var(--color-primary);
      }

      .attr-list-item {
        box-sizing: border-box;
        width: 50%;
        padding: 6px 8px;

        >span {
          line-height: 20px;
          word-break: break-all;
        }

        &.border-right {
          border-right: 1px solid #f7f7f7;
        }
      }
    }
  }

  .attr-button {
    margin-left: 24px;
    display: flex;
    flex-direction: column;

    .el-button {
      margin-bottom: 8px;
    }

    .el-button+.el-button {
      margin-left: 0;
    }
  }
}

.cloud-table {
  &::before {
    height: 0;
  }

  .el-table__expand-icon {
    margin-right: 0;
  }

  ::v-deep(.cell) {
    display: flex;

    .el-table__expand-icon {
      margin-right: 0;
    }

    .el-table__placeholder {
      flex-shrink: 0;
    }
  }
}

.cloud-file-name {
  word-break: break-all;
  line-height: 20px;

  &.folder {
    padding-left: 24px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 2px;
      top: 5px;
      width: 14px;
      height: 11px;
      background-image: url("@/assets/images/productLibrary/file-name-icon.png");
      background-size: 14px 11px;
      background-repeat: no-repeat;
    }
  }
}

.filename {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 7px;
}
</style>

<style lang="scss">
.category-popover {
  @include popover(96px);
  width: fit-content !important;
}

.category-popover-item {
  @include popover-item;
  box-sizing: border-box;
  text-align: left;
  padding: 0 12px;
  font-size: 14px;
  cursor: pointer;
}
</style>