<template>
  <div class="fd-main">
    <div class="preview-detail" element-loading-background="rgba(40, 47, 57,0.8)">
      <div class="detail-main">
        <div class="file-card" @drop="handleDrop" @dragover="allowDrop">
          <div class="file-search flex-between align-items-center" style="width:100%">
            <el-input ref="searchInput" v-model="queryFile.name" clearable placeholder="请输入关键字搜索" size="small"
              style="width: 168px;margin-right:14px" @keyup.enter.native="getTableData(1)" @clear="getTableData(1)">
              <i slot="prefix" class="el-input__icon el-icon-search" @click="getTableData(1)"></i>
            </el-input>
            <div class="flex file-tool align-items-center">
              <template v-if="!readonly">
                <div class="file-tool-item flex align-items-center" @click="folderDialogVisible = true">
                  <svg-icon icon="file-folder-16" color="#344563" width="16" height="16"></svg-icon>
                  <span>新建文件夹</span>
                </div>
                <div class="file-tool-item flex align-items-center">
                  <svg-icon icon="file-upload-16" color="#344563" width="16" height="16"></svg-icon>
                  <UploadFile :folderid="queryFile.folderId" :subject="subject" :isPersonal="false" :text="'上传文件'"
                    @finish="updateTableData"></UploadFile>
                </div>
                <div class="file-tool-item flex align-items-center">
                  <svg-icon icon="floder-upload-16" color="#344563" width="16" height="16"></svg-icon>
                  <UploadFile :folderid="queryFile.folderId" :subject="subject" :isPersonal="false" :text="'上传文件夹'"
                    @finish="updateTableData"></UploadFile>
                </div>
                <div class="file-tool-item flex align-items-center" @click="handeShare">
                  <svg-icon icon="file-share-16" color="#344563" width="16" height="16"></svg-icon>
                  <span>分享</span>
                </div>
                <div class="file-tool-item flex align-items-center" @click="handleDownload" v-if="viewType === 'grid'">
                  <svg-icon icon="file-download-16" color="#344563" width="16" height="16"></svg-icon>
                  <span>下载</span>
                </div>
                <div class="file-tool-item flex align-items-center" @click="handleDeleteBatch">
                  <svg-icon icon="file-batchdel-16" color="#344563" width="16" height="16"></svg-icon>
                  <span>批量删除</span>
                </div>
              </template>
              <div class="filelist-view">
                <el-tooltip effect="dark" content="网格视图" placement="bottom">
                  <div class="filelist-view-item" :class="{ 'is-active': viewType === 'grid' }"
                    @click="changeViewType('grid')">
                    <svg-icon icon="card-mode" width="16" height="16"
                      :color="viewType === 'grid' ? '4f5d74' : '#9facc2'"></svg-icon>
                  </div>
                </el-tooltip>
                <el-tooltip effect="dark" content="列表视图" placement="bottom">
                  <div class="filelist-view-item" :class="{ 'is-active': viewType === 'list' }"
                    @click="changeViewType('list')">
                    <svg-icon icon="list-mode" width="16" height="16"
                      :color="viewType === 'list' ? '4f5d74' : '#9facc2'"></svg-icon>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="file-breadcrumb">
            <span v-if="breadcrumbList.length" class="pointer" @click="jumpTo(null, -1)">全部</span>
            <template v-for="(item, index) in breadcrumbList">
              <span :key="item.id">&nbsp;/&nbsp;</span>
              <span class="pointer" :key="'breadcrumb' + item.id" @click="jumpTo(item, index)">{{ item.folderName
                }}</span>
            </template>
          </div>
          <div class="card-box" v-loading="loading" v-if="viewType === 'grid'">
            <div class="card-main">
              <div v-for="item in tableData" :key="item.id"
                class="card-item flex-column align-items-center flex-between"
                :class="{ 'card-item-file': item.fileType != 2 && !readonly, 'activecard': checkSelect(item) }">
                <svg-icon class="file-type file-icon" :icon="item.icon" color="#ffdc53" width="48" height="48" @click.native="handleOpenfolder(item)"></svg-icon>
                <!-- <img class="file-icon pointer" @click="handleOpenfolder(item)"
                  :class="{ 'disabled': item.fileType == 1 && item.aliasType != 'office' && item.aliasType != 'image' && item.aliasType != 'video' }"
                  :src="require(`@/assets/images/file/file-${item.aliasType}.png`)" style="width:48px;height:48px"
                  v-if="item.describes != '系统默认创建'">
                <div class="file-default" v-else @click="handleOpenfolder(item)">
                  <svg-icon icon="file-folder-48" color="#ffdc53" width="48" height="48"></svg-icon>
                </div> -->
                <div class="one-line item-name" :title="item.folderName">{{ item.folderName }}</div>
                <div class="item-info two-lines">
                  <span>{{ item.createBy ? item.createBy : '' }}{{ item.fileType == 1 ? "上传" : "创建" }}于{{
          Object.dayjs(item.createTime) }}&nbsp;</span>
                </div>
                <svg-icon class="icon-check" @click.native="handleSelect(item)" icon="check-purple" color="#ccc" width="22"
                  height="18"></svg-icon>
              </div>
            </div>
          </div>
          <div class="file-content-table" v-loading="loading" v-if="viewType === 'list'">
            <el-table highlight-current-row class="file-table" header-cell-class-name="app-header-cell" ref="table"
              row-class-name="file-table-row" row-key="id" :data="tableData" height="100%"
              @selection-change="handleSelectionChange" @row-click="handleRowClick" @row-dblclick="rowdbClick">
              <!-- <el-table-column type="selection" width="55" v-if="!readonly"
                :selectable="row => row.describes != '系统默认创建'"></el-table-column> -->
              <el-table-column type="selection" width="55" v-if="!readonly"></el-table-column>
              <el-table-column label="名称" prop="folderName" min-width="350px">
                <template slot-scope="{ row }">
                  <div class="file-name__wrapper">
                    <svg-icon class="file-type file-icon" :icon="row.icon" color="#ffdc53" width="48" height="48"></svg-icon>
                    <!-- <img class="file-icon" :src="require(`@/assets/images/file/file-${row.aliasType}.png`)"> -->
                    <div>
                      <!-- <div class="file-name one-line">{{ row.folderName }}</div> -->
                      <div v-if="!row.showprogress" class="file-name">{{ row.folderName }}</div>
                      <el-progress v-else color="#4ed4cb" :percentage="row.progress" style="width: 300px"></el-progress>
                      <div class="file-information ">
                        <span v-if="row.fileType == 1">{{ row.fileSize }}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span>{{ row.createBy ? row.createBy : '' }}&nbsp;于&nbsp;{{
          Object.dayjs(row.createTime) }}&nbsp;{{ row.fileType == 1 ? "上传" : "创建" }}</span>
                      </div>
                      <div class="file-operation">
                        <div v-if="row.fileType == 2" class="file-operation-item" @click.stop="handleOpenfolder(row)">
                          <svg-icon icon="file-open-16" color="#344563" width="16" height="16"></svg-icon>
                          <span>打开</span>
                        </div>
                        <div v-if="row.fileType == 1" class="file-operation-item"
                          @click.stop="handleView(row)">
                          <svg-icon icon="file-preview-16" color="#344563" width="14" height="14"></svg-icon>
                          <span>预览</span>
                        </div>
                        <div v-if="row.fileType == 1" class="file-operation-item"
                          @click.stop="handlesingleDownload(row)">
                          <svg-icon icon="file-uploadsingle-16" color="#344563" width="14" height="14"></svg-icon>
                          <span>下载</span>
                        </div>
                        <div class="file-operation-item" @click.stop="handleUpdate(row, row.fileType == 1 ? 1 : 2)"
                          v-if="row.describes != '系统默认创建'&&!readonly">
                          <svg-icon icon="file-rename-14" color="#344563" width="14" height="14"></svg-icon>
                          <span>重命名</span>
                        </div>
                        <div class="file-operation-item" @click.stop="handleUpdate(row, row.fileType == 1 ? 3 : 4)"
                          v-if="row.describes != '系统默认创建'&&!readonly">
                          <svg-icon icon="edit" color="#344563" width="14" height="14"></svg-icon>
                          <span>编辑说明</span>
                        </div>
                        <div class="file-operation-item" @click.stop="handleDelete(row)"
                          v-if="row.describes != '系统默认创建'&&!readonly">
                          <svg-icon icon="delete" color="#344563" width="14" height="14"></svg-icon>
                          <span>删除</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="版本" min-width="50">
                <template slot-scope="{ row }">
                  <span>{{ row.fileType == 1 ? row.fileVersion : "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="文件类型" width="100">
                <template slot-scope="{ row }">
                  <span>{{ row.fileType == 1 ? row.contentType : "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="创建人" min-width="100">
                <template slot-scope="{ row }">
                  <span>{{ row.createBy ? row.createBy : '' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="说明" min-width="100" prop="describes" show-overflow-tooltip>
                <template slot-scope="{ row }">
                  <span style="line-height: 20px;">
                    <span>{{ row.describes }}</span>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <FilePreview v-if="preview" :visible.sync="preview" :haslist="false" :currentitem.sync="currentItem" />
        </div>
      </div>
    </div>
    <FolderDialog v-if="folderDialogVisible" :visible.sync="folderDialogVisible" :query-id="queryId" :item="subject"
      :parent-id="breadcrumbList.length ? queryFile.folderId : ''" @finish="updateTableData"
      :api="'createSubjectFileFolder'" />
    <!-- <UploadProgress v-if="progressVisible" :visible.sync="progressVisible" :filename="uploadFile.name"
      :percentage="uploadPercent" @cancel="handleAbort" /> -->
    <!-- <AppShare v-if="shareVisible" :subject-id="queryId.subjectId" :visible.sync="shareVisible" :shareinfo="sharedata"
      ref="AppShare"></AppShare> -->
    <CommonShare v-if="shareVisible" :visible.sync="shareVisible" :bizType="2" :shareinfo="sharedata"/>
    <UpdateFolder v-if="updateFolderVisible" :visible.sync="updateFolderVisible" :current="clickItem"
      :dialog-type="updateFolderType" @finish="updateTableData" />
    <FilePermission v-if="filePermissionVisible" :visible.sync="filePermissionVisible" />
    <!-- <LocalUploadProgress v-if="localupload" :visible.sync="localupload" :query="queryUploadData" :uploading="uploading"
      :filename="localfilename" @finish="updateTableData" :filereceiveid="filereceiveId"/> -->
    <PlugInDialog v-if="plugvisible" :visible.sync="plugvisible" @cancel="uploading = 'false'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { File } from "@/api/File";
import { Share } from "@/api/Share";
import { deleteBatch, DownloadFile, handleFileVersion, officeView, checkFileIcon } from '@/utils/file';
import FilePreview from "@/components/dialog/FilePreview.vue";
import FolderDialog from "@/components/filecompanies/Folder.vue";
import UploadProgress from "@/views/ProdudtEdito/dialog/UploadProgress.vue";
import AppShare from "@/components/AppShare/index.vue";
import UpdateFolder from "@/components/filecompanies/UpdateFolder.vue";
import UploadFile from "@/components/filecompanies/uploadFile.vue";
import FilePermission from "@/components/filecompanies/FilePermission.vue";
import PlugInDialog from "@/components/dialog/PlugIn.vue";
import CommonShare from "../../../components/AppShare/CommonShare.vue"

export default {
  components: {
    FilePreview,
    FolderDialog,
    UploadProgress,
    AppShare,
    UpdateFolder,
    UploadFile,
    FilePermission,
    PlugInDialog,
    CommonShare
  },
  props: {
    subject: Object, // 主题详情
    readonly:{
      type:Boolean,
      default:false//只读
    },
    crumblist: Array,//传入的面包屑
    loginrequired:{
      type:Boolean,
      default:true//是否需要登录
    },
  },
  data() {
    return {
      loading: false,
      direction: "rtl",
      queryData: {
        dictItemIds: [],
        pageNo: 1,
        pageSize: 10,
        name: '',
        showLevel: 0,//展示层级数(0：一层,1:二层，2：三层...)
        sort: null,
        sortType: 'desc',//排序的类型（asc: 正序 desc: 倒序)
        sort: "createTime",
      },
      tableData: [],
      subjecuFilePayload: {
        moduleId: "",
        subjectId: "",
        fileName: "",
        pageSize: 999,
        pageNo: 1,
      },
      queryId: {
        moduleId: "",
        subjectId: "",
      },
      queryFile: {
        dictItemIds: "", // 二级分类id
        folderId: "",
        name: "",
        pageNo: 1,
        pageSize: 999,
      },
      breadcrumbList: [],
      preview: false,
      selectList: [], // 选择的文件列表	
      folderDialogVisible: false,//创建文件夹弹窗
      sharedata: [],
      shareVisible: false,
      fileTableHeight: 650,
      viewType: 'list',
      updateFolderType: 1,
      updateFolderVisible: false,//重命名和编辑说明的弹窗
      clickItem: null,//点击项
      dragFile: null,
      filePermissionVisible: false,//权限弹窗
      permissions: [],//当前列表权限
      isDownloading: false,
      // localupload: false,// 插件上传弹窗
      uploading: false,// 插件是否有正在上传的文件
      localfilename: "",
      plugvisible: false,
      filereceiveId:( new Date().getTime().toString(16) + Math.random().toString(16).substring(2) ).substring(2, 18)
    };
  },
  watch: {
    shareVisible(val) {
      if (!val) {
        this.sharedata = [];
        this.selectList = []
      }
    },
    isconnected(val) {
      if (val) {
        this.init();
      }
    },
    crumblist(val){
      if(val.length){
        this.breadcrumbList = val;
        this.queryFile.folderId = val[val.length-1].id;
        this.queryFile.name = "";
        this.getTableData(1);
      }else{
        this.breadcrumbList = [];
        this.getFirstTableData();
      }
    }
  },
  computed: {
    ...mapGetters(["token", "userInfo", "isconnected"]),
  },
  created() {
    this.queryId.moduleId = this.subject.moduleId;
    this.queryId.subjectId = this.subject.id;
    this.subjecuFilePayload.moduleId = this.subject.moduleId;
    this.subjecuFilePayload.subjectId = this.subject.id;
    if(!this.loginrequired){
      this.subjecuFilePayload.shareId = this.subject.shareId;
      this.queryId.shareId = this.subject.shareId;
      this.queryFile.shareId = this.subject.shareId;
    }
  },
  mounted() {
    // this.getFirstTableData();
    if(this.crumblist&&this.crumblist.length){
      this.breadcrumbList = this.crumblist;
      this.queryFile.folderId = this.crumblist[this.crumblist.length-1].id;
      this.queryFile.name = "";
      this.getTableData(1);
    }else{
      this.getFirstTableData();
      this.queryFile.folderId = this.subject.fileSubjectFolderId;
    }
    // 这里发布一个方法,可以在其他组件调用this.$Pubsub.publish("update-table-data", {});
    this.$Pubsub.subscribe("update-table-data", (val) => {
      this.updateTableData();
    });
    this.init();
    // this.queryFile.folderId = this.subject.fileSubjectFolderId;
  },
  beforeDestroy() {
    this.$messageService.$off("websocketMessage");
  },
  methods: {
    /**
     * @description:获取列表
     * @return {void}
     */
    async getFirstTableData() {
      this.$emit('fileclick', null, true)
      this.loading = true;
      const res = await File[this.loginrequired ?"queryFileBySubject":"queryFileBySubjectNoToken"](this.subjecuFilePayload);
      let powerlist = [];
      res.records.forEach(element => {
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.aliasType = "folder";
          element.icon = "filetype-floder"
        }
        element.showprogress = false;
        element.progress = 0;
        element.permCodes = []
        powerlist.push({
          targetId: element.id,
          targetType: element.fileType == 1 ? 1 : 2
        })
      })
      if (powerlist.length) {
        // this.permissions = await NETSYSTEM.queryPermissionByTargetId({children:powerlist});
      }
      this.loading = false;
      this.tableData = res.records;
      // this.tableData = this.handlePowerData(res.records, this.permissions);
    },
    /**
     * @description: 获取文件目录下的文件列表
     * @return {void}
     */
    async getTableData(pageNo) {
      if(!this.breadcrumbList.length){
        this.subjecuFilePayload.fileName = this.queryFile.name;
        this.getFirstTableData()
        return
      }
      this.$emit('fileclick', null, true)
      this.loading = true;
      pageNo && (this.queryFile.pageNo = pageNo);
      this.showAttribute = false;
      this.currentItem = null;
      this.$refs.table && this.$refs.table.setCurrentRow();
      let payload = _.omit(this.queryFile, [this.breadcrumbList.length ? "dictItemIds" : "folderId"]);
      payload = _.omit(this.queryFile, ["dictItemIds"]);
      // if (this.breadcrumbList.length) {
      //   payload = _.omit(this.queryFile, ["dictItemIds"]);
      // } else {
      //   payload = { dataType: 1, ...this.queryId, ..._.omit(this.queryFile, ["folderId"]) };
      // }
      const res = await File[this.loginrequired ?"queryFileByFolderId":"queryFileByFolderIdNoToken"](payload)
      let powerlist = [];
      res.records.forEach(element => {
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.aliasType = "folder";
          element.icon = "filetype-floder"
        }
        element.showprogress = false;
        element.progress = 0;
        // powerlist.push({
        //   targetId: element.id,
        //   targetType: element.fileType == 1 ? 1 : 2
        // })
      })
      // this.permissions = await NETSYSTEM.queryPermissionByTargetId({children: powerlist});
      this.tableData = res.records;
      this.loading = false;
    },
    /**
     * @description: 打开文件夹
     * @param {Object} value 文件夹
     * @return {void}
     */
    handleOpenfolder(value) {
      if (value.fileType != 2) {
        this.handleView(value)
        return
      }
      const index = this.breadcrumbList.findIndex(item => item.id === value.id);
      index === -1 && this.breadcrumbList.push(value);
      this.queryFile.folderId = value.id;
      this.queryFile.name = "";
      this.getTableData(1);
      this.$emit('fileclick', value, true)
    },
    /**
     * @description: 点击面包屑获取文件列表
     * @param {Object} value 点击项
     * @param {Number} index 索引
     * @return {void}
     */
    jumpTo(value, index) {
      if (index === this.breadcrumbList.length - 1) {
        return
      }
      if (value) {
        this.breadcrumbList.splice(index + 1);
        this.queryFile.folderId = value.id;
        this.queryFile.name = "";
        this.getTableData(1);
      } else {
        this.breadcrumbList = [];
        this.queryFile.folderId = this.subject.fileSubjectFolderId;
        this.getFirstTableData()
      }
      // this.queryFile.name = "";
      // this.getTableData(1);
    },
    /**
     * @description:清空搜索字段
     * @return {void}
     */
    handleClear() {
      this.queryData.name = '';
    },
    checkSelect(value) {
      const index = this.selectList.findIndex(item => item.id === value.id);
      return index != '-1'
    },
    handleSelect(value) {
      if (value.fileType == 2) {
        return
      }
      const index = this.selectList.findIndex(item => item.id === value.id);
      if (index !== -1) {
        this.selectList.splice(index, 1);
      } else {
        this.selectList.push(value);
      }
    },
    /**
   * @description: 更新列表
   * @return {void}
   */
    updateTableData() {
      if (this.breadcrumbList.length) {
        this.getTableData()
      } else {
        this.getFirstTableData()
      }
    },
    /**
     * @description: 下载
     * @return {void}
     */
    async handleDownload() {
      if (this.selectList.length <= 0) {
        this.$message.warning("请选择下载文件");
        return
      }
      this.selectList.forEach(item => {
        DownloadFile(item, 1)
      })
      this.selectList = []
    },
    /**
     * @description: 单个文件下载
     * @return {void}
     */
    handlesingleDownload(value) {
      DownloadFile(value, 1)
    },
    /**
     * @description: 分享文件
     * @param {Object} rowData 点击项
     * @return {void}
     */
    async handeShare() {
      if (this.selectList.length != 0) {
        this.selectList.forEach(element => {
          this.sharedata.push({
            "createName": element.creator,
            "fileCreateTime": element.createTime,
            "fileName": element.folderName,
            "fileType": element.fileType,
            "fileUpdateTime": element.updateTime,
            "fileUrl": element.fileSavePath,
            // "id": element.,
            "shareId": "",
            "size": element.fileSize,
            "subjectColumnId": element.id
          })
        });
        this.shareVisible = true;

      } else {
        this.$message.warning("请选择要分享的文件");
      }
    },
    /**
     * @description: 批量删除文件
     * @return {void}
     */
    async handleDeleteBatch() {
      if (this.selectList.length <= 0) {
        this.$message.warning("请选择文件");
      } else {
        this.$showMessage({
          message: `是否删除选择的文件和文件夹 <span style="font-weight: 1000;"></span> ?`,
          ...this.$global.deleteMessage,
        }, async (done) => {
          this.loading = true
          let payload = {
            isDeleteChilds: "1",
            fileIds: [],
            folderIds: [],
            isClear: "0",
            isDelete:"1",
            isRecycle:0,//是否回收站删除
          }
          this.selectList.forEach(item => {
            if (item.fileType === "1") {
              payload.fileIds.push(item.id);
            } else if (item.fileType === "2") {
              payload.folderIds.push(item.id);
            }
          });
          const res = await deleteBatch(payload)
          if(res){
            this.$message.success('删除成功');
            setTimeout(() => {
              this.updateTableData();
              this.loading = false
            }, 1000)
          } 
          done(res);
        });
      }
    },
    /**
     * @description: 单个删除
     * @param {Object} value 点击项
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage({
        message: `是否确认删除 <span style="font-weight: 1000;">${value.folderName}</span> ?`,
        ...this.$global.deleteMessage
      }, async (done) => {
        if (value.fileType == 1) {
          // 删除文件
          const res = await File.deleteFile(value.id);
          done(res);
          res && this.updateTableData();
        } else {
          // 删除文件夹
          const res = await File.deleteFolder(value.id);
          done(res);
          res && this.updateTableData();
        }
        this.selectList = []
      });
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleView(value) {
      if (value.aliasType === "image") {
        this.$elImageViewer({ urlList: [value.fileSavePath], initialIndex: 0 });
      }else{
        this.currentItem = value
        this.preview = true
      }
    },
    /**
     * @description: 切换格子/列表视图
     * @param {Object} value 点击项
     * @return {void}
     */
    changeViewType(type) {
      this.selectList = []
      this.viewType = type;
    },
    handleSelectionChange(val) {
      this.selectList = val
    },
    /**
     * @description: 重命名、编辑说明
     * @param {Object} value 点击项
     * @param {Number} type 类型
     * @return {void}
     */
    handleUpdate(value, type) {
      this.clickItem = value;
      this.updateFolderType = type;
      this.updateFolderVisible = true;
    },
    // 拖拽事件
    allowDrop(event) {
      event.preventDefault();
    },
    /**
     * @description: 添加拖拽过来的文件
     * @return {void}
     */
    async handleDrop(event) {
      event.preventDefault();
      if (event.dataTransfer.getData("localpath")) {

        this.localfilename = event.dataTransfer.getData("localname")
        this.handleLocalupload(event.dataTransfer.getData("localpath"))
        return
      }
      const id = event.dataTransfer.getData("Text"); // 拖拽的文件/文件夹的id
      const source = id.split("-");
      let payload = {
        newFolderId: this.queryFile.folderId ? this.queryFile.folderId : '-1',
        optType: 2,//（1：分享，2：镜像，3，复制）默认镜像
        targetType: [1],
        type: 2,//(1：栏目文件，2：产品文件，3：演示文件)
      }
      if (!this.queryFile.folderId) {
        payload = { ...payload, ...this.queryId }
        payload.columnId = this.queryId.subjectColumnId
      }
      if (id) {
        payload.targetId = [source[0]];
        if (source[1] === "1") {
          payload.targetType = [1];
        } else {
          payload.targetType = [2];
        }
      } else {
        payload.targetId = [this.dragFile.id];
        if (this.dragFile.fileType == 1) {
          payload.targetType = [1];
        } else {
          payload.targetType = [2];
        }
      }
      this.loading = true;
      const res = await File.createFileRelation(payload);
      this.dragFile = null;
      this.loading = false
      if (res) {
        this.$nextTick(() => {
          this.updateTableData();
        })
      }
    },
    handleRowClick(row, column, event) {
      this.$emit('fileclick', row)
    },
    // /**
    //  * @description: 权限弹窗
    //  * @return {void}
    //  */
    // handlePower(value) {
    //   this.filePermissionVisible = true
    // },
    // /**
    //  * @description: 将权限添加到列表中
    //  * @return {void}
    //  */
    // handlePowerData(arr, power) {
    //   const map = {};
    //   arr.forEach(item => {
    //     map[item.id] = { ...item };
    //   });
    //   power.forEach(item => {
    //     if (map[item.targetId]) {
    //       map[item.targetId].permCodes.push(...item.permCodes);
    //     }
    //   });
    //   const result = Object.values(map);
    //   return result
    // },
    /**
     * @description: 初始化消息
     * @return {void}
     */
    init() {
      this.$messageService.$on('websocketMessage', (data) => {
        // 处理接收到的消息
        const parsedMessage = JSON.parse(data);
        if (parsedMessage.funcname == "uploadingFile" && parsedMessage.data[0].status === "DONE") {
          setTimeout(() => {
            this.updateTableData();
            this.uploading = false;
          }, 800)
        } else if (parsedMessage.funcname == "downloadingFile") {
          this.isDownloading=true;
          const index = this.tableData.findIndex(i => i.id === parsedMessage.msgId);
          this.$set(this.tableData, index, { ...this.tableData[index], showprogress: true });
          // this.percentage=Number(parsedMessage.data[0].progress.replace('%',''))
          let percentage=Number(parsedMessage.data[0].progress.replace('%',''))
          this.$set(this.tableData, index, { ...this.tableData[index], progress: percentage });
          if(parsedMessage.data[0].status==="DONE" && parsedMessage.data[0].progress){
            this.isDownloading=false;
            this.$set(this.tableData[index],'showprogress',false)
          }
        }else if (parsedMessage.funcname == "uploadDone"){
          setTimeout(() => {
            this.updateTableData();
          }, 800)
        }
      });
    },
    /**
    * @description: 行双击下载文件并在本地打开
    * @param {Object} row 点击项
    * @return {void}
    */
    rowdbClick(row) {
      if (row.fileType == 2) {
        return;
      }
      if (!this.isconnected) {
        this.plugvisible = true;
        return;
      }
      this.percentage = 0
      if (this.$websocket.readyState === WebSocket.OPEN) {
        this.clickItem = row
        // 发送消息
        let queryDownloadData = {
          msgId:  row.id,
          funcname: "downloadfile",
          downloadType: "1", //下载消息类型，1:开始，2:暂停,3:取消
          systemType: "1",
          token: this.token ? this.token : "",
          tenantId: this.userInfo ? this.userInfo.tenantIdNow : "",
          baseurl: process.env.VUE_APP_URL_NETDISK,
          fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
          fileId: row.id,
          isCurrent: "1", //是否下载当前(1:是当前，2：是历史)(如果是历史版本必填)
          sysToken:"JqjdLEtaFOas5whS8hro2w=="
        };
        this.$websocket.send(JSON.stringify(queryDownloadData));
      }
    },
    /**
    * @description: 本地文件上传到栏目
    * @param {String} path 地址
    * @return {void}
    */
    handleLocalupload(path) {
      // 使用不同的msgId来实现上传多个不同文件
      this.filereceiveId = ( new Date().getTime().toString(16) + Math.random().toString(16).substring(2) ).substring(2, 18);
      this.queryUploadData = {
        msgId: `sale4-${this.filereceiveId}`,
        // msgId: this.filereceiveId,
        funcname: "uploadfile",
        uploadType: "1", //上传消息类型，1:开始，2:暂停,3:取消
        systemType: "1",
        token: this.token ? this.token : "",
        tenantId: this.userInfo ? this.userInfo.tenantIdNow : "",
        baseurl: process.env.VUE_APP_URL_NETDISK,
        fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
        folderId: this.queryFile.folderId,
        file: path,
        sysToken:"JqjdLEtaFOas5whS8hro2w=="
      }
      this.$store.commit("saveUploadList", this.queryUploadData);
      this.$websocket.send(JSON.stringify(this.queryUploadData));
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";

.fd-main {
  min-width: 800px;
  height: 100%;
}

.preview-detail {
  height: 100%;
  width: 100%;
  z-index: 12;
  display: flex;
  padding: 20px;
  // color: #fff;
  box-sizing: border-box;
  border-radius: 5px;

  .detail-main {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;

    .file-default {
      height: 48px;
      width: 48px;
      overflow: hidden;

      .svg-icon {
        display: block;
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
}

.file-tool {
  .file-tool-item {
    font-size: 12px;
    color: var(--color-text-primary);
    cursor: pointer;
    margin-left: 20px;

    &:hover {
      color: var(--color-primary);

      .svg-icon {
        color: var(--color-primary) !important;
      }
    }
  }
}

.file-content-table {
  flex: 1;
  height: calc(100% - 80px);
  overflow-y: auto;
  margin-top: 10px;

  .describes-icon {
    display: none;
  }

  .file-table-row {

    &:hover {
      .file-information {
        display: none;
      }

      .file-operation {
        display: flex;
      }

      .describes-icon {
        display: inline-block;
      }
    }
  }

  ::v-deep .el-table {
    &::before {
      height: 0;
    }

    .el-table__row {
      cursor: pointer;
    }

    .el-table__body tr:hover,
    tr.current-row {
      td {
        background-color: #f5f7fa;
      }
    }
  }
}

.file-name__wrapper {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  .file-icon {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .file-name {
    line-height: 20px;
    padding-bottom: 4px;
    color: var(--color-text-regular);
  }

  .file-information {
    line-height: 20px;
    color: #cfd7e6;
    margin-top: 6px;
    font-size: 12px;
  }

  .file-operation {
    font-size: 12px;
    display: none;

    .file-operation-item {
      display: flex;
      align-items: center;
      line-height: 26px;
      padding: 0 6px;
      border-radius: 5px;
      color: #8997B0;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }

      &:not(.disabled):hover {
        color: var(--color-primary);

        .svg-icon {
          color: var(--color-primary) !important;
        }
      }
    }
  }
}

.filelist-view {
  height: 24px;
  border-radius: 5px;
  border: 1px solid #d3dae6;
  margin: 0 10px;
  background: #fff;
  display: flex;
  overflow: hidden;

  .filelist-view-item {
    box-sizing: border-box;
    width: 30px;
    height: 24px;
    border-right: 1px solid #d3dae6;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      border-right: none;
    }

    &:hover {
      background-color: #fcfdfe;
    }

    &.is-active {
      background-color: #f0f3f7;
    }
  }
}

.disabled {
  cursor: not-allowed;
}
</style>