<template>
  <div class="scheme-title-list-wrapper" v-infinite-scroll="loadResource">
    <div
      v-for="item in tableData"
      :key="item.id"
      class="scheme-title-list-items pointer align-items-center move"
      :class="{ 'items-active prohibit-item': selectids.includes(item.id) }"
      :draggable="selectids.includes(item.id) ? false : true"
      @dragstart="(e) => dragstart(e, item)"
    >
      <template v-if="categorytype == 1 || categorytype == 2">
        <img
          v-if="
            item.subjectCoverVos && item.subjectCoverVos.length > 0 && item.type === 1
          "
          class="scheme-title-list-items-cover"
          :src="item.subjectCoverVos[0].coverUrl"
          :draggable="false"
        />
        <img
          v-if="
            item.subjectCoverVos && item.subjectCoverVos.length === 0 && item.type === 1
          "
          class="scheme-title-list-items-cover"
          :src="require(`@/assets/images/sample-${common.getLastChar(item.id)}.png`)"
          :draggable="false"
        />
      </template>
      <template v-if="categorytype == 5">
        <img
          class="scheme-title-list-items-cover"
          :draggable="false"
          :src="
            item.coverList && item.coverList[0].coverUrl
              ? item.coverList[0].coverUrl
              : require('@/assets/images/sample-1.png')
          "
          alt=""
        />
      </template>
      <div class="scheme-items-name pd-12 one-line">{{ item.name }}</div>
      <div class="scheme-items-time pd-12 one-line">
        {{ Object.dayjs(item.updateAt || item.createAt) }}
      </div>
      <svg-icon class="check-icon" icon="check-icon" color="#365EFF"></svg-icon>
    </div>
  </div>
</template>
<script>
import { ProductLibrary } from "@/api/ProductLibrary";
import { Article } from "@/api/Article";
import { mapGetters } from "vuex";
export default {
  props: {
    menuid: String,
    menuname: String,
    // categoryId:String,
    productvisible: {
      type: Boolean,
      default: false,
    },
    categorytype: {
      type: Number,
      default: 1,
    }, //模块分类，1内容5文章
    selectids: Array,
  },
  components: {},
  data() {
    return {
      // 二级分类列表
      CategoryList: [],
      allcategoryList: [],
      tableData: [],
      collectionId: "-1",
      queryData: {
        moduleId: this.menuid,
        categoryIds: [],
        sortByUpdateAtFlag: false,
        keyWord: "",
        sortDesc: "update_at", // 降序
        sortAsc: "", // 升序
        pageNo: 1,
        pageSize: 20,
        shelfFlag: true,
        hideFlag: true,
        status: 2,
      },
      // 栏目列表
      dataList: [],
      loading: false, // 加载状态
      Treevisible: false,
      itemdata: "",
      visible: false,
      pagingData: new this.common.Pagination(),
      clickItem: null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.productvisible;
      },
      set(val) {
        this.$emit("update:productvisible", val);
      },
    },
    ...mapGetters(["userInfo", "activeMenu"]),
  },
  watch: {
    menuid(val) {
      this.queryData.moduleId = val;
      this.queryData.pageNo = 1;
      this.tableData = [];
      this.clickItem = null;
      this.getList(1);
    },
  },
  mounted() {
    this.getList(1);
    // this.getCategoryList();
  },
  methods: {
    /**
     * @description: 获取产品库分类列表
     * @return {void}
     */
    async getCategoryList() {
      this.allcategoryList = await ProductLibrary.getSubjectCategoryList({
        moduleId: this.menuid,
        tenantId: this.userInfo.tenantIdNow,
      });
      this.allcategoryList.push({
        id: "-1",
        categoryName: "全部",
        moduleId: this.menuid,
        privateFlag: 0,
        collectionCount: 0,
        children: [],
      });
      this.CategoryList = this.allcategoryList[0].children;
    },
    /**
     * @description: 获取产品列表
     * @return {void}
     */
    async getList(pageNo) {
      pageNo && (this.queryData.pageNo = pageNo);
      if (this.categorytype == 5) {
        this.getArticleList();
        return;
      }
      if (this.collectionId && this.collectionId !== "-1") {
        // 根据合集id获取合集数据
        this.tableData = await ProductLibrary.getSubjectListByCollectId(
          this.collectionId
        );
      } else {
        const res = await ProductLibrary.getSubjectList(this.queryData);
        this.tableData = [...this.tableData, ...res.records];
        this.pagingData = res;
      }
    },
    /**
     * @description: 获取文章列表
     * @return {void}
     */
    async getArticleList() {
      let payload = {
        moduleId: this.queryData.moduleId,
        tenantId: this.userInfo.tenantIdNow,
        articleName: this.queryData.keyWord,
        categoryIds: this.queryData.categoryIds, //	分类id
        isAll: true,
        isCheckCategory: false, //是否选择未分类(是true,否false)
        pageNo: this.queryData.pageNo,
        pageSize: this.queryData.pageSize,
      };
      const res = await Article.queryArticlePageList(payload);
      res.records.forEach((element) => {
        element.name = element.articleName;
      });
      this.tableData = [...this.tableData, ...res.records];
      this.pagingData = res;
    },
    /**
     * @description: 点击主题
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(value) {
      this.clickItem = value;
    },
    /**
     * @description: currentPage 改变时会触发
     * @param {Number} value 当前页
     * @return {void}
     */
    handleCurrentChange(value) {
      this.queryData.pageNo = value;
      this.getList();
    },
    /**
     * @description: 拖拽赋值
     * @param {Object} value 点击项
     * @return {void}
     */
    dragstart(e, value) {
      value.moduleName = this.menuname;
      const data = JSON.stringify(value);
      e.dataTransfer.setData("dragsubject", data);
    },
    /**
     * @description: 下拉加载
     * @return {void}
     */
    loadResource() {
      if (this.queryData.pageNo * this.queryData.pageSize > this.pagingData.total) {
        return;
      }
      this.queryData.pageNo++;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.scheme-title-list {
  height: calc(100% - 78px);
  width: 100%;
  padding: 0 10px 10px 10px;
  box-sizing: border-box;
  overflow-y: auto;
  .scheme-title-list-wrapper {
    // margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  .scheme-title-list-items {
    width: 143px;
    height: 130px;
    border: 1px solid #cfd7e6;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    .scheme-title-list-items-cover {
      width: 100%;
      height: 83px;
    }
    .pd-12 {
      padding: 0 12px;
    }
    .scheme-items-name {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
    }
    .scheme-items-time {
      font-size: 12px;
      color: #8997b0;
    }
  }
  .check-icon {
    display: none;
    position: absolute;
    top: -1px;
    right: -1px;
  }
  .items-active {
    border: 2px solid var(--color-primary);
    position: relative;
    .check-icon {
      display: block;
    }
  }
}
.prohibit-item {
  cursor: not-allowed !important;
}
.move {
  cursor: move;
}
</style>
