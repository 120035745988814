<template>
  <div class="association">
    <div v-if="!isPreview" class="btn" @click="dialogVisible = true">添加关联内容</div>
    <div class="wrapper">
      <div v-for="(item, index) in associatedVos" class="item" :key="`${item.targetMirrorId}-${index}`">
        <div class="item-cover">
          <el-carousel v-if="item.coverList&&item.coverList.length > 1" height="173px" indicator-position="none" :autoplay="false">
            <el-carousel-item v-for="i in item.coverList" :key="i.id">
              <div class="carousel-item">
                <div v-if="!isPreview" class="item-close" @click="handleDelete(item)">
                  <i class="el-icon-circle-close"></i>
                </div>
                <img alt="" class="object-contain" :src="i.coverUrl">
              </div>
            </el-carousel-item>
          </el-carousel>
          <img v-if="item.coverList && item.coverList.length === 1" class="object-contain" :src="item.coverList[0].coverUrl">
        </div>
        <div v-if="item.coverList && item.coverList.length <= 1 && !isPreview" class="item-close" @click="handleDelete(item)">
          <i class="el-icon-circle-close"></i>
        </div>
        <div class="item-name">{{ item.targetSubjectName }}</div>
        <div class="item-date">最新更新：{{ Object.dayjs(item.updateAt, "YYYY-MM-DD") }}</div>
      </div>
    </div>
    <AssociatedContentDialog v-if="dialogVisible" :visible.sync="dialogVisible" :current="current"
      :origin-associated-vos="associatedVos" :origin-associated-vos-map="associatedVosMap" @finish="updateAssociated" />
  </div>
</template>

<script>
import AssociatedContentDialog from "../dialog/AssociatedContent.vue";
import { cloneDeep } from 'lodash';
export default {
  props: {
    current: Object,
    associatedVos: Array,
    associatedVosMap: Map,
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  components: { AssociatedContentDialog },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    /**
     * @description: 更新关联内容列表
     * @param {Object} value
     * @return {void}
     */
    updateAssociated(value) {
      this.$emit('update:associatedVos', value.associatedVos);
      this.$emit('update:associatedVosMap', value.associatedVosMap);
    },
    /**
     * @description: 删除
     * @param {Object} value
     * @return {void}
     */
    handleDelete(value) {
      let associated = cloneDeep(this.associatedVos);
      let associatedMap = cloneDeep(this.associatedVosMap);
      associatedMap.delete(value.targetMirrorId);
      const index = this.associatedVos.findIndex(item => item.targetMirrorId === value.targetMirrorId);
      index !== -1 && associated.splice(index, 1);
      this.$emit('update:associatedVos', associated);
      this.$emit('update:associatedVosMap', associatedMap);
    }
  },
};
</script>
<style lang="scss" scoped>
.association {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1px 0;

  .btn {
    width: fit-content;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #344563;
    padding: 0 4px 0 28px;
    margin: 24px auto 0;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 4px;
      width: 16px;
      height: 16px;
      background-image: url("@/assets/images/common/plus.png");
      background-size: 16px 16px;
      background-repeat: no-repeat;
    }
  }

  .wrapper {
    box-sizing: border-box;
    padding-top: 20px;
    height: calc(100% - 64px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;

    .item {
      width: 372px;
      height: 278px;
      margin: 0 9px 18px;
      border-radius: 10px;
      background-color: #fff;
      position: relative;

      &:hover {
        .item-close {
          display: block;
        }
      }

      .item-cover {
        width: 100%;
        height: 184px;
        display: block;

        .carousel-item {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }

      .item-close {
        display: none;
        position: absolute;
        z-index: 40px;
        top: 12px;
        right: 12px;
        cursor: pointer;
        font-size: 18px;

        &:hover {
          color: #1644ff;
        }
      }

      .item-name {
        font-size: 18px;
        font-weight: 700;
        color: #344563;
        text-align: center;
        padding: 20px 0 10px;
      }

      .item-date {
        font-size: 14px;
        font-weight: 700;
        color: #abb4c4;
        text-align: center;
      }
    }
  }
}

.object-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>