<template>
  <div class="fd-main app-scheme-wrapper flex">
    <div class="flex fd-linkbox">
      <div class="app-scheme-left">
        <div class="scheme-title flex-between align-items-center">
          <div class="scheme-title-name">链接列表</div>
          <div class="flex align-items-center">
            <svg-icon
              v-if="!ispre"
              class="mr-10 pointer"
              icon="mind-add"
              color="#5E6C84"
              :height="12"
              :width="12"
              @click.native="handleEdit(null)"
            ></svg-icon>
          </div>
        </div>
        <div class="scheme-title-list" id="edittabs">
          <div
            v-for="i in linklist"
            :key="i.id"
            @click="handleCurrent(i)"
            class="flex scheme-title-list-items pointer align-items-center"
            :class="{ 'active-link': activelink && i.id === activelink.id }"
          >
            <svg-icon
              class="scheme-items-icon"
              icon="column-icon-5"
              :height="16"
              :width="16"
              color="#344563"
            ></svg-icon>
            <div class="scheme-items-name one-line">{{ i.name ? i.name : "链接" }}</div>
            <el-popover
              v-if="!ispre"
              popper-class="toolbar-item-popover popover"
              placement="right-start"
              width="120"
              trigger="click"
              :ref="'toolbar-' + i.id"
              :visible-arrow="false"
            >
              <div class="toolbar-more" slot="reference" @click.stop></div>
              <div class="popover-item" @click="handleEdit(i)">编辑</div>
              <div class="popover-divider"></div>
              <div class="popover-item" @click="handleDelete(i)">删除</div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="netweb">
        <iframe
          v-if="activeurl"
          class="w-h-100"
          :src="activeurl"
          frameborder="0"
        ></iframe>
        <el-empty v-else :image-size="200"></el-empty>
      </div>
    </div>
    <UpdateLinkDialog
      v-if="linkvisible"
      :visible.sync="linkvisible"
      :demo-link="clickitem"
      @finish="getEditlink"
    />
  </div>
</template>

<script>
import UpdateLinkDialog from "@/views/ProductLibrary/dialog/UpdateLinkDialog.vue";
import { ProductLibrary } from "@/api/ProductLibrary";
import { Playeditor } from "@/api/Playeditor";
export default {
  components: { UpdateLinkDialog },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ispre: {
      type: Boolean,
      default: false,
    },
    queryIds: Object,
    nodeid: String,
    demoId: String,
    loginrequired: {
      type: Boolean,
      default: true,//是否需要登录
    },
  },
  data() {
    return {
      clickitem: null,
      linkvisible: false,
      linklist: [],
      tablekey: 1,
      activeurl: "",
      leftvisible: true,
      activelink: {},
    };
  },
  computed: {},
  watch: {
    nodeid() {
      this.getNodeDetail();
    },
  },
  mounted() {
    this.getNodeDetail();
    this.$Pubsub.publish("update-resource-visible", { visible: true, type: [] });
  },
  methods: {
    /**
     * @description: 获取节点关联详情
     * @return {void}
     */
    async getNodeDetail() {
      const res = await Playeditor[this.loginrequired?"querySubjectDemoModeNodeDetail":"querySubjectDemoModeNodeDetailNoLogin"](
        this.demoId,
        this.nodeid,
        4
      );
      this.linklist = _.sortBy(res, "sortNo"); // 按 sortNo 排序
      if (res.length) {
        this.handleCurrent(res[0]);
      } else {
        this.activelink = {};
        this.activeurl = "";
      }
    },
    /**
     * @description:编辑链接
     * @param {Object} value 点击项
     * @return {void}
     */
    async handleEdit(value) {
      this.clickitem = value;
      if (value) {
        this.clickitem = await Playeditor.getDemoLink(value.linkId);
      }
      this.linkvisible = true;
    },
    handleDelete(value) {
      this.$showMessage(
        {
          message: `是否确认删除该链接 ?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await Playeditor.deleteShowSubjectDemoLink(value.linkId);
          res && this.getNodeDetail();
          done(true);
        }
      );
    },
    /**
     * @description:获取编辑后的链接
     * @param {Object} value 编辑项
     * @return {void}
     */
    async getEditlink(value) {
      const index = this.linklist.findIndex((item) => item.linkId == value.id);
      if (index != "-1" && value.id) {
        const res = await ProductLibrary.editLink(value);
        res && this.getNodeDetail();
      } else {
        let payload = value;
        payload.id = null;
        payload.outlineNodeId = this.nodeid;
        payload.subjectId = this.queryIds.subjectId;
        const res = await Playeditor.saveShowSubjectToLink(payload);
        res && this.getNodeDetail();
      }
    },
    /**
     * @description:行点击事件
     * @param {Object} value 点击项
     * @return {void}
     */
    async handleCurrent(value) {
      this.activelink = value;
      const res = await Playeditor.getDemoLink(value.linkId);
      if (res) {
        this.activeurl = `${res.demoLink.startsWith("http") ? "" : "//"}${res.demoLink}${
          res.loginAccount || res.loginPassword ? "?" : ""
        }${res.loginAccount ? `account=${res.loginAccount}` : ""}${
          res.loginPassword ? `&password=${res.loginPassword}` : ""
        }`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";

.fd-main {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 12px;
}
.fd-linkbox {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .netweb {
    flex: 1;
  }
}
.active-link {
  color: var(--color-primary);
  .svg-icon {
    color: var(--color-primary) !important;
  }
}
</style>
