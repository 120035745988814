<template>
  <div class="second-column" :class="{ 'is-preview': isPreview }">
    <div class="second-column-header" v-if="!ispre">
      <div>
        <el-button size="small" type="info" @click="readonly = !readonly">{{
          readonly ? "退出预览" : "预&nbsp;览"
        }}</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="confirmLoading"
          @click="confirmSave"
          >确&nbsp;定</el-button
        >
      </div>
    </div>
    <div class="second-column-main">
      <div class="edit-wrapper">
        <TinyMce
          :readonly="readonly"
          :value.sync="richText"
          :datatype="1"
          :key="editkey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TinyMce from "@/components/TinyMce.vue";
import { Playeditor } from "@/api/Playeditor";
export default {
  components: { TinyMce },
  props: {
    ispre: {
      type: Boolean,
      default: false,
    },
    queryIds: Object,
    nodeid: String,
    loginrequired: {
      type: Boolean,
      default: true,//是否需要登录
    },
  },
  data() {
    return {
      richText: "",
      confirmLoading: false,
      queryData: {
        content: "",
        id: "",
        outlineId: "",
      },
      readonly: false,
      editkey: 1,
    };
  },
  watch: {
    nodeid(val) {
      val && this.getDetail();
    },
  },
  computed: {
    isPreview() {
      this.editkey++;
      this.readonly = this.ispre;
      return this.ispre;
    },
  },
  mounted() {
    this.nodeid && this.getDetail();
    this.$Pubsub.publish("update-resource-select-data", []);
    this.$Pubsub.publish("update-resource-visible", { visible: true, type: [] });
  },
  methods: {
    async getDetail() {
      let res;
      if(this.loginrequired){
        res = await Playeditor.queryDemoOutLineDetail(this.nodeid);
      }else{
        res = await Playeditor.queryDemoOutLineDetailNoLogin(this.nodeid,this.queryIds.shareId);
      }
      // const res = await Playeditor.queryDemoOutLineDetail(this.nodeid);
      
      if (res[0]) {
        this.richText = res[0].content;
        this.queryData.outlineId = res[0].outlineId;
        this.queryData.id = res[0].id;
      } else {
        this.richText = "";
        this.queryData.id = null;
        this.queryData.outlineId = this.nodeid;
      }
    },
    async confirmSave() {
      this.confirmLoading = true;
      this.queryData.content = this.richText;
      const res = await Playeditor.saveSubjectDemoModeNodeContent(this.queryData);
      if (res && !this.queryData.id) {
        this.getDetail();
      }
      this.confirmLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.second-column {
  width: 100%;
  min-width: 800px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  // background-color: #bdbcbc;

  ::v-deep .ck.ck-content {
    padding-bottom: 110px;
  }
  .second-column-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebeef2;
    .column-navigation-bar {
      .breadcrumb-item {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-text-placeholder);
        display: flex;
        align-items: center;
        user-select: none;

        &:last-child {
          color: var(--color-text-secondary);

          &:hover {
            color: var(--color-text-primary);
          }

          .breadcrumb-separator {
            display: none;
          }
        }

        .breadcrumb-inner {
          line-height: 22px;
        }
      }
    }
  }
  .second-column-main {
    flex: 1;
    .edit-wrapper {
      height: 100%;
      max-width: 1080px;
      margin: 0 auto;
    }
  }
}
.bold {
  font-weight: bold;
  color: var(--color-text-primary);
}
</style>
