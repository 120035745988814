<template>
  <el-dialog append-to-body class="ss-dialog" width="820px" :visible.sync="dialogVisible">
    <div slot="title">
      <div class="dialog-header">
        <template v-if="!showInput">
          <span class="header-title" :title="subjectName">{{ subjectName || "请输入名称" }}</span>
          <svg-icon icon="edit" class="header-edit" @click.native="handleEdit"></svg-icon>
        </template>
        <el-input v-if="showInput" v-model="subjectName" class="header-input" placeholder="请输入名称" ref="subjectNameInput"
          @blur="showInput = false" @keyup.enter.native="showInput = false"></el-input>
      </div>
    </div>
    <div class="dialog-body">
      <div class="half">
        <div class="box-title">
          <span>演示链接</span>
          <svg-icon class="box-title-icon" icon="add-circle" width="16" height="16"
            @click.native="showDemoDialog(null)"></svg-icon>
        </div>
        <div class="demo-box">
          <div v-for="(item, index) in demoLinks" class="flex align-items-center" :key="item.id">
            <el-link icon="el-icon-link" :title="item.demoLink">{{ item.name }}</el-link>
            <el-dropdown placement="bottom" trigger="click" @command="handleCommand">
              <i class="el-icon-arrow-down el-icon--right pointer"></i>
              <el-dropdown-menu class="cmn-dropdown" slot="dropdown">
                <el-dropdown-item :command="{ type: 'check', value: item }">查看用户密码</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'edit', value: item }">编辑</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'delete', value: item, index }">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="box-title">
          <span>标签</span>
          <svg-icon class="box-title-icon" icon="add-circle" width="16" height="16"
            @click.native="tagDialogVisible = true"></svg-icon>
        </div>
        <div class="tag-box">
          <div v-for="(item, index) in tags" class="tag" :key="item.id" @click="removeTag(index)">
            <div class="tag-text">{{ item.tagName }}</div>
            <div class="tag-close"></div>
          </div>
        </div>
        <div class="box-title">分享权限</div>
        <div class="share-box">
          <el-radio-group v-model="shareAuth">
            <el-radio :label="1">允许</el-radio>
            <el-radio :label="2">不允许</el-radio>
            <el-radio :label="3">需要审批<span class="approve" @click="showApprove">审批工作流程</span></el-radio>
          </el-radio-group>
        </div>
        <div class="box-title">下载权限<span class="subtitle">（管理员及创建人不受限制）</span></div>
        <div style="padding: 16px 0;">
          <el-radio-group v-model="downloadAuth">
            <el-radio :label="1">允许</el-radio>
            <el-radio :label="2">不允许</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="half">
        <div class="box-title">
          <span>技术支持人员</span>
          <svg-icon class="box-title-icon" icon="add-circle" width="16" height="16"
            @click.native="showSelectDialog(2)"></svg-icon>
        </div>
        <div class="auth-box">
          <div v-for="(item, index) in dataAuth2" class="tag" :key="item.id" @click="removeAuth('dataAuth2', index)">
            <div class="tag-text">{{ item.realName }}</div>
            <div class="tag-close"></div>
          </div>
        </div>
        <div class="box-title">
          <span>商务支持人员</span>
          <svg-icon class="box-title-icon" icon="add-circle" width="16" height="16"
            @click.native="showSelectDialog(3)"></svg-icon>
        </div>
        <div class="auth-box">
          <div v-for="(item, index) in dataAuth3" class="tag" :key="index" @click="removeAuth('dataAuth3', index)">
            <div class="tag-text">{{ item.realName }}</div>
            <div class="tag-close"></div>
          </div>
        </div>
        <div class="box-title">
          <span>协作人员</span>
          <svg-icon class="box-title-icon" icon="add-circle" width="16" height="16"
            @click.native="showSelectDialog(1)"></svg-icon>
        </div>
        <div class="auth-box">
          <div v-for="(item, index) in dataAuth1" class="tag" :key="index" @click="removeAuth('dataAuth1', index)">
            <div class="tag-text">{{ item.realName }}</div>
            <div class="tag-close"></div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
      <el-button style="width: 112px;margin-left: 24px;" type="primary" @click="handleConfirm">确&nbsp;定</el-button>
    </div>

    <el-dialog v-if="demoDialogVisible" append-to-body class="ss-dialog no-header-bottom" title="演示链接" width="600px"
      :visible.sync="demoDialogVisible">
      <div class="demo-dialog-body">
        <el-form label-width="100px" ref="demoLink" :model="demoLink">
          <el-form-item label="名称" prop="name" :rules="setRules('请输入名称')">
            <el-input v-model="demoLink.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="登录方式" prop="loginType" :rules="setRules('请选择登录方式')">
            <el-select v-model="demoLink.loginType" placeholder="请选择登录方式" style="width: 100%;">
              <el-option disabled label="SaaS单点登录" value="1"></el-option>
              <el-option label="账号密码登录" value="2"></el-option>
              <el-option disabled label="不支持单点登录" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="链接" prop="demoLink" :rules="setRules('请输入链接')">
            <el-input v-model="demoLink.demoLink" placeholder="请输入链接"></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="loginAccount" :rules="setRules('请输入用户名')">
            <el-input v-model="demoLink.loginAccount" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="loginPassword" :rules="setRules('请输入密码')">
            <el-input v-model="demoLink.loginPassword" placeholder="请输入密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="info" @click="demoDialogVisible = false">取&nbsp;消</el-button>
        <el-button style="width: 112px;margin-left: 24px;" type="primary" @click="confirmDemoLink">确&nbsp;定</el-button>
      </div>
    </el-dialog>

    <el-dialog v-if="checkDialogVisible" append-to-body class="ss-dialog" title="查看用户密码" width="400px"
      :visible.sync="checkDialogVisible">
      <div class="check-dialog-body">
        <div class="flex align-items-center" style="margin-bottom: 16px;">
          <span>用户名：{{ demoLink.loginAccount }}</span>
          <el-button size="small" style="margin-left: 24px;" type="primary"
            @click="handleCopy(demoLink.loginAccount)">复制</el-button>
        </div>
        <div class="flex align-items-center">
          <span>密码：{{ demoLink.loginPassword }}</span>
          <el-button size="small" style="margin-left: 24px;" type="primary"
            @click="handleCopy(demoLink.loginPassword)">复制</el-button>
        </div>
      </div>
    </el-dialog>

    <TagDialog v-if="tagDialogVisible" :visible.sync="tagDialogVisible" :module-id="subject.moduleId"
      :subject-id="subject.id" :list="tags" :type="2" @finish="selectTag" />
    <SelectOrCreateUser v-if="selectDialogVisible" :visible.sync="selectDialogVisible" :auth-type="authType"
      :subject-id="subject.id" @finish="addAuth" />
  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash';
import SelectOrCreateUser from "./SelectOrCreateUser.vue";
import TagDialog from "@/views/ProductLibrary/dialog/TagDialog.vue";
export default {
  name: "MoreSetting",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    subject: Object,
  },
  components: { TagDialog, SelectOrCreateUser },
  data() {
    return {
      subjectName: "", // 主题名称
      showInput: false,
      demoLinks: [], // 演示链接
      demoDialogVisible: false, // 演示链接弹窗
      demoLink: {
        id: "",
        name: "",
        loginType: "2",
        demoLink: "",
        loginAccount: "",
        loginPassword: "",
        subjectId: "",
      },
      checkDialogVisible: false, // 查看链接账号密码弹窗
      tags: [], // 标签
      tagDialogVisible: false, // 标签弹窗
      shareAuth: 1,
      downloadAuth: 1,
      dataAuth1: [], // 协作人员
      dataAuth2: [], // 技术支持人员
      dataAuth3: [], // 商务支持人员
      selectDialogVisible: false, // 选择人员/手动输入 弹窗
      authType: 1, // 授权类型(1:参与者 2:技术支持人员 3:商务支持人员)
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  mounted() {
    this.subjectName = this.subject.subjectName;
    this.demoLinks = cloneDeep(this.subject.demoLinks);
    this.shareAuth = this.subject.shareAuth;
    this.downloadAuth = this.subject.downloadAuth;
    this.tags = cloneDeep(this.subject.tags);
    this.dataAuth1 = this.subject.dataAuths.filter(item => item.authType === 1);
    this.dataAuth2 = this.subject.dataAuths.filter(item => item.authType === 2);
    this.dataAuth3 = this.subject.dataAuths.filter(item => item.authType === 3);
  },
  methods: {
    /**
     * @description: 设置表单验证规则
     * @param {String} message 校验错误信息
     * @return {Object} 表单验证规则
     */
    setRules(message) {
      return { required: true, message, trigger: ["blur", "change"] }
    },
    /**
     * @description: 编辑主题名称
     * @return {void}
     */
    handleEdit() {
      this.showInput = true;
      this.$nextTick(() => this.$refs.subjectNameInput.focus());
    },
    /**
     * @description: 显示演示链接弹窗
     * @param {Object} value 演示链接
     * @return {void}
     */
    showDemoDialog(value) {
      if (value) {
        this.demoLink = { ...value };
      } else {
        Object.keys(this.demoLink).forEach(key => this.demoLink[key] = "");
        this.demoLink.loginType = "2";
        this.demoLink.subjectId = this.subject.id;
      }
      this.demoDialogVisible = true;
    },
    /**
     * @description: 点击菜单项触发的事件回调
     * @param {Object} command dropdown-item 的指令
     * @return {void}
     */
    handleCommand(command) {
      if (command.type === "edit") {
        this.showDemoDialog(command.value);
      } else if (command.type === "delete") {
        this.demoLinks.splice(command.index, 1);
      } else if (command.type === "check") {
        this.demoLink = { ...command.value };
        this.checkDialogVisible = true;
      }
    },
    /**
     * @description: 确定添加、编辑演示链接
     * @return {void}
     */
    confirmDemoLink() {
      this.$refs.demoLink.validate(valid => {
        if (!valid) {
          return
        }
        if (this.demoLink.id) {
          const index = this.demoLinks.findIndex(item => item.id === this.demoLink.id);
          this.$set(this.demoLinks, index, this.demoLink);
        } else {
          const obj = { ...this.demoLink };
          obj.id = `new-${Date.now()}`;
          this.demoLinks.push(obj);
        }
        this.demoDialogVisible = false;
      })
    },
    /**
     * @description: 复制
     * @param {String} text 复制项
     * @return {void}
     */
    handleCopy(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$message.success("复制成功");
      }, () => {
        this.$message.error("复制失败");
      });
    },
    /**
     * @description: 选择标签
     * @param {Array} value 标签列表
     * @return {void}
     */
    selectTag(value) {
      this.tags = value;
    },
    /**
     * @description: 移除标签
     * @param {Number} index 索引
     * @return {void}
     */
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    /**
     * @description: 显示审批流程
     * @return {void}
     */
    showApprove() {
      // 审批工作流程
    },
    /**
     * @description: 显示 选择人员 弹窗
     * @param {Number} type 人员类型
     * @return {void}
     */
    showSelectDialog(type) {
      this.authType = type;
      this.selectDialogVisible = true;
    },
    /**
     * @description: 添加人员
     * @param {Array} value 人员列表
     * @return {void}
     */
    addAuth(value) {
      const key = "dataAuth" + this.authType;
      this[key] = this[key].concat(value);
    },
    /**
     * @description: 移除人员
     * @param {String} key 人员列表字段
     * @param {Number} index 索引
     * @return {void}
     */
    removeAuth(key, index) {
      this[key].splice(index, 1);
    },
    /**
     * @description: 确定
     * @return {void}
     */
    handleConfirm() {
      if (!this.subjectName) {
        return this.$message.warning("请输入名称");
      }
      let newDemoLinks = cloneDeep(this.demoLinks);
      newDemoLinks.forEach(element => {
        if (element.id.startsWith("new-")) {
          element.id = "";
        }
      })
      const obj = {
        subjectName: this.subjectName,
        dataAuths: [...this.dataAuth1, ...this.dataAuth2, ...this.dataAuth3],
        demoLinks: newDemoLinks,
        downloadAuth: this.downloadAuth,
        shareAuth: this.shareAuth,
        tags: this.tags,
      }
      this.$emit("finish", obj);
      this.dialogVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-header {
  display: flex;
  align-items: center;

  .header-title {
    font-size: 18px;
    font-weight: 500;
    max-width: 90%;
    color: #344563;
    // overflow: hidden;
    height: 24px;
    // text-overflow: ellipsis;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .header-edit {
    margin-left: 8px;
    cursor: pointer;
  }

  .header-input {
    ::v-deep(.el-input__inner) {
      // width: 200px;
      width: 95%;
      height: 24px;
      line-height: 24px;
      padding: 0;
      border: none;
      font-size: 18px;
      font-weight: 500;
      color: #344563;
    }
  }
}

.dialog-body {
  padding: 22px 30px;
  display: flex;

  .half {
    box-sizing: border-box;
    width: calc(50% - 12px);
    padding-right: 12px;
    margin-right: 12px;
    max-height: 360px;
    overflow-y: auto;

    .box-title {
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      font-weight: 700;
      color: #344563;
      display: flex;
      align-items: center;

      .box-title-icon {
        margin-left: 8px;
        cursor: pointer;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 500;
        color: #abb4c4;
      }
    }

    .demo-box {
      padding: 16px 0;
    }

    .tag-box {
      padding: 16px 0 36px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    .share-box {
      padding: 16px 0 36px;
    }

    .approve {
      margin-left: 16px;
      font-size: 12px;
      font-weight: 500;
      color: #8997b0;
      text-decoration: underline;
      cursor: pointer;
    }

    .auth-box {
      padding: 16px 0 36px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
    }
  }
}

.tag {
  width: fit-content;
  height: 32px;
  padding: 0 8px 0 16px;
  margin: 0 10px 14px 0;
  border-radius: 5px;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    .tag-close {
      background-image: url("@/assets/images/common/close_red.png");
    }
  }

  .tag-text {
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-primary);
    max-width: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tag-close {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    background-image: url("@/assets/images/common/close_16.png");
    background-size: 16px 16px;
    background-repeat: no-repeat;
  }
}

.demo-dialog-body {
  padding: 0 30px;
}

.check-dialog-body {
  padding: 20px 46px;
}
</style>