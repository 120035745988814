<template>
  <div
    class="global-title-list-wrapper"
    :class="{ nopadding: accept.includes('column') }"
  >
    <div class="scheme-title-list-wrapper" v-if="!accept.includes('column')">
      <div
        v-for="i in tableData"
        :key="i.id"
        class="scheme-title-list-items pointer align-items-center move"
        :class="{ 'items-active prohibit-item': selectids.includes(i.id) }"
        :draggable="selectids.includes(i.id) ? false : true"
        @dragstart="(e) => dragstart(e, i)"
      >
        <template v-if="!accept.includes(5)">
          <img
            class="scheme-title-list-items-cover"
            :src="
              i.subjectCoverVos[0]
                ? i.subjectCoverVos[0].coverUrl
                : require(`@/assets/images/sample-${common.getLastChar(i.id)}.png`)
            "
            alt=""
          />
          <div class="scheme-items-name pd-12 one-line">{{ i.subjectName }}</div>
        </template>
        <template v-if="accept.includes(5)">
          <img
            class="scheme-title-list-items-cover"
            :src="
              i.coverList[0]
                ? i.coverList[0].coverUrl
                : require(`@/assets/images/sample-1.png`)
            "
            alt=""
          />
          <div class="scheme-items-name pd-12 one-line">{{ i.articleName }}</div>
        </template>
        <div class="scheme-items-time pd-12 one-line">
          {{ Object.dayjs(i.updateAt || i.createAt) }}
        </div>
        <svg-icon class="check-icon" icon="check-icon" color="#365EFF"></svg-icon>
      </div>
    </div>
    <ColumnResourceView
      v-if="accept.includes('column')"
      :selectids="selectids"
      :existingdata="tableData"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Module } from "@/api/Module";
import { Esengine } from "@/api/Esengine";
import ColumnResourceView from "./ColumnResourceView.vue";

export default {
  components: { ColumnResourceView },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectids: Array,
    accept: Array,
    keywords: String,
  },
  data() {
    return {
      queryData: {
        isFile: 0, //查询类型(0:非文件,1:文件)
        value: "",
        moduleId: "",
        pageNo: 1,
        pageSize: 99,
        sortType: "desc",
      },
      menuList: [],
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(["activeMenu", "Moduleclassification"]),
  },
  watch: {
    keywords(val) {
      if (val) {
        this.queryData.value = val;
        this.handleSelect();
      }
    },
  },
  mounted() {
    if (!this.accept.includes(5)) {
      this.getModuleList();
    }
    if (this.keywords) {
      this.queryData.value = this.keywords;
    }
    this.$nextTick(() => {
      this.handleSelect();
    });
  },
  methods: {
    /**
     * @description: 获取模块列表
     * @return {void}
     */
    async getModuleList() {
      const res = await Module.getModuleList();
      let list = [];
      res.forEach((element) => {
        element.moduleEntityList.forEach((item) => {
          // item.total = 0;
          list.push(item);
        });
      });
      // 这里过滤掉文章的
      if (list.length) {
        // const accept = [1, 2]; //需要显示的模块类型"1"内容类 "2"演示方案类 "3"文件资源类 "4"教学教程类 "5"文章类
        this.menuList = [];
        let selectType = this.Moduleclassification.filter((item) =>
          this.accept.includes(item.type)
        );
        selectType = selectType.map((item) => item.id);
        this.menuList.forEach((item) => {
          const type = this.Moduleclassification.filter(
            (menu) => menu.id == item.categoryId
          );
          type.length && (item.categoryType = type[0].type);
        });
        this.menuList = list.filter((item) => selectType.includes(item.categoryId));
      }
    },
    /**
     * @description: 选择搜索建议
     * @return {void}
     */
    async handleSelect() {
      if (!this.accept.length) {
        return;
      }
      if (!this.queryData.value.trim()) {
        return this.$message.warning("搜索名称不能为空！");
      }
      this.loading = true;
      const res = await Esengine.boolQueryHighlight(this.queryData);
      if (this.accept.includes(5)) {
        this.tableData = res.articleMap.data;
      } else {
        let sumData = this.matchDataWithKey(this.menuList, res);
        sumData.forEach((item) => {
          item.searchValue.data.forEach((child) => {
            child.moduleName = item.moduleName;
            child.name = child.subjectName;
          });
        });
        let list = sumData.map((item) => item.searchValue.data);
        this.tableData = list.flat();
      }
    },
    /**
     * @description: 将获取的搜索结果和模块进行匹配
     * @param {Array} dataArray 模块列表
     * @param {Array} keyObject 搜索结果列表
     * @return {void}
     */
    matchDataWithKey(dataArray, keyObject) {
      for (let i = 0; i < dataArray.length; i++) {
        const obj = dataArray[i];
        if (obj.id in keyObject) {
          obj.searchValue = keyObject[obj.id];
        }
      }
      return dataArray;
    },
    /**
     * @description: 拖拽赋值
     * @param {Object} value 点击项
     * @return {void}
     */
    dragstart(e, value) {
      const data = JSON.stringify(value);
      e.dataTransfer.setData("dragsubject", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.nopadding {
  padding: 0 !important;
}
.global-title-list-wrapper {
  height: calc(100% - 38px);
  width: 100%;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
.scheme-title-list-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.scheme-title-list-items {
  width: 143px;
  height: 130px;
  border: 1px solid #cfd7e6;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  .scheme-title-list-items-cover {
    width: 100%;
    height: 83px;
  }
  .pd-12 {
    padding: 0 12px;
  }
  .scheme-items-name {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
  }
  .scheme-items-time {
    font-size: 12px;
    color: #8997b0;
  }
}
.check-icon {
  display: none;
  position: absolute;
  top: -1px;
  right: -1px;
}
.items-active {
  border: 2px solid var(--color-primary);
  position: relative;
  .check-icon {
    display: block;
  }
}
.prohibit-item {
  cursor: not-allowed !important;
}
.move {
  cursor: move;
}
</style>
