<template>
  <div class="app-scheme-resource">
    <div class="scheme-title flex-between align-items-center">
      <div class="scheme-title-name">
        {{ accept.includes("file") ? "文件中心" : "知识库" }}
      </div>
      <div class="flex align-items-center">
        <svg-icon
          class="mr-10 pointer"
          icon="xs-search"
          color="#5E6C84"
          :height="12"
          :width="12"
          @click.native="showsearch = true"
          v-if="!showsearch && !accept.includes('file')"
        ></svg-icon>
        <el-input
          v-if="showsearch"
          class="search-input"
          size="mini"
          v-model="Searchvalue"
          @blur="handleCloseInput"
          @keyup.enter.native="handleglobalSearch"
          placeholder="请输入名称"
          clearable
          @clear="handleClear"
        >
          <i
            style="font-size: 10px; color: #5e6c84"
            slot="prefix"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>
        <svg-icon
          class="mr-10 pointer"
          icon="xs-icon-close"
          color="#5E6C84"
          :height="12"
          :width="12"
          @click.native="handleClose"
        ></svg-icon>
      </div>
    </div>
    <template v-if="!globalvisible && !accept.includes('file')">
      <el-tabs v-model="activeName" @tab-click="SelectModel" type="card">
        <el-tab-pane
          v-for="i in assmenuList"
          :key="i.id"
          :label="i.name"
          :name="i.id"
        ></el-tab-pane>
      </el-tabs>
      <div class="scheme-title-list" v-if="!showcolumn">
        <ResourceView
          v-if="activemenu"
          :categorytype="activemenu.categoryType"
          :menuid.sync="activemenu.id"
          :menuname="activemenu.name"
          :selectids="select"
        />
      </div>
      <ColumnResourceView
        v-if="showcolumn"
        :categorytype="activemenu.categoryType"
        :menuid.sync="activemenu.id"
        :menuname="activemenu.name"
        :selectids="select"
      />
    </template>
    <GlobalView
      v-if="globalvisible && !accept.includes('file')"
      :visible.sync="globalvisible"
      :selectids="select"
      :accept="accept"
      :keywords="keywords"
    />
    <FileResuorce v-if="accept.includes('file')" />
    <!-- 弹窗的全局搜索 -->
    <!-- <SearchDialog
      v-if="showsearch"
      :visible.sync="showsearch"
      :selectids="select"
      :accept="accept"
    /> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Module } from "@/api/Module";
import ResourceView from "./ResourceView.vue";
import ColumnResourceView from "./ColumnResourceView.vue";
import SearchDialog from "./SearchDialog.vue";
import GlobalView from "./GlobalView.vue";
import FileResuorce from "./FileResuorce.vue";
export default {
  components: {
    ResourceView,
    ColumnResourceView,
    SearchDialog,
    GlobalView,
    FileResuorce,
  },
  props: {
    accept: {
      type: Array,
      default: [1], //需要显示的模块类型"1"内容类 "2"演示方案类 "3"文件资源类 "4"教学教程类 "5"文章类 "column"栏目类
    },
    selectids: {
      type: Array,
      default: [], //已经选择的产品或者文章id
    },
  },
  data() {
    return {
      assmenuList: [],
      activeName: "",
      activemenu: null,
      showcolumn: false, //需要显示产品栏目
      showsearch: false,
      Searchvalue: "",
      keywords: "", //请求的参数
      globalvisible: false,
    };
  },
  watch: {
    accept() {
      this.getModuleList();
      this.handleClear();
      this.Searchvalue = "";
    },
    activemenu(value) {
      // 这个把当前选择的模块给出去
      this.$emit("provide-model", value);
    },
  },
  computed: {
    ...mapGetters(["token", "menuList", "Moduleclassification"]),
    select() {
      return this.selectids;
    },
  },
  mounted() {
    this.getModuleList();
  },
  methods: {
    /**
     * @description: 获取模块列表
     * @return {void}
     */
    async getModuleList() {
      if (this.accept.includes("column")) {
        this.showcolumn = true;
      } else {
        this.showcolumn = false;
      }
      this.assmenuList = [];
      let selectType = this.Moduleclassification.filter((item) =>
        this.accept.includes(item.type)
      );
      selectType = selectType.map((item) => item.id);
      this.menuList.forEach((item) => {
        const type = this.Moduleclassification.filter(
          (menu) => menu.id == item.categoryId
        );
        type.length && (item.categoryType = type[0].type);
      });
      this.assmenuList = this.menuList.filter((item) =>
        selectType.includes(item.categoryId)
      );
      this.activemenu = this.assmenuList[0];
      this.activeName = this.activemenu ? this.activemenu.id : "";
    },
    /**
     * @description: 选择模块
     * @param {Object} value 点击项
     * @return {void}
     */
    SelectModel(tab, event) {
      const menu = this.assmenuList.filter((item) => item.id == tab.name);
      this.activemenu = menu[0];
    },
    handleClose() {
      this.$Pubsub.publish("update-resource-visible", { visible: false });
    },
    handleglobalSearch() {
      if (!this.Searchvalue.trim()) {
        this.$message.warning("搜索名称不能为空！");
        this.globalvisible = false;
        return;
      }
      this.globalvisible = true;
      this.keywords = _.cloneDeep(this.Searchvalue);
    },
    /**
     * @description:关闭
     * @return {void}
     */
    handleCloseInput() {
      if (!this.Searchvalue) {
        this.showsearch = false;
        this.globalvisible = false;
      }
    },
    handleClear() {
      this.showsearch = false;
      this.globalvisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.app-scheme-resource {
  width: 100%;
  height: 100%;
  border-right: 1px solid #ebeef2;
  flex-shrink: 0;
  color: var(--color-text-primary);
  .scheme-title {
    height: 30px;
    border-bottom: 1px solid #d3dbeb;
    .scheme-title-name {
      margin-left: 14px;
      font-size: 12px;
      font-weight: 400;
    }
    .mr-10 {
      margin-right: 10px;
    }
  }

  .scheme-title-list {
    height: calc(100% - 78px);
    width: 100%;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  ::v-deep .el-tabs__item {
    font-size: 12px;
    color: #a7b4cc;
    height: 33px;
    line-height: 33px;
    border: none;
    background-color: #f7f9fc;
  }
  ::v-deep .el-tabs__nav-next,
  ::v-deep .el-tabs__nav-prev {
    line-height: 33px;
  }
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #fff;
    color: var(--color-text-primary);
  }
  ::v-deep .el-tabs--card > .el-tabs__header,
  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: none;
    background-color: #f7f9fc;
  }
}
.search-input {
  height: 30px;
  margin-right: 10px;
  ::v-deep .el-input__inner {
    margin-top: 2px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  ::v-deep .el-input__icon {
    line-height: 24px;
  }
}
</style>
