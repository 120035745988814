<template>
  <el-drawer :visible.sync="dialogVisible" direction="rtl" :modal="false" :size="400">
    <div slot="title">
      <div class="title">
        <span class="flabel">动态</span>
      </div>
    </div>
    <div class="my-logs">
      <div class="logs-item" v-for="i in logList" :key="i.id">
        <div class="item-top">
          <div class="item-top-left">
            {{ i.createTime.replace("-", ".").replace("-", ".") }}
          </div>
          <div class="item-top-right">
            <div class="avatar">
              {{ i.createRealName ? i.createRealName.substring(0, 1) : " " }}
            </div>
            <div>{{ i.createRealName }}</div>
          </div>
        </div>
        <div class="item-bottom">
          <div>
            <span class="type">{{ handleType(i.optType) }}</span
            >{{ i.busiContent }}
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { Logging } from "@/api/Logging";
export default {
  name: "AttributeDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 1,
    },
    current: Object,
  },
  data() {
    return {
      queryData: {
        busiType: 0, //1.产品，2.案例，3.演示方案，4.好友
        businessId: "",
        moduleId: "",
        sortType: "desc",
      },
      logList: [],
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["userInfo", "Moduleclassification"]),
  },
  watch: {
    current(val) {
      this.queryData.busiType = val.type == 1 ? 1 : 3;
      this.queryData.moduleId = val.moduleId;
      this.queryData.businessId = val.id;
      this.getList();
    },
  },
  created() {},
  mounted() {
    //this.current.type主题类型：1、内容类，2、演示方案类，3、文件资源类，4、教学课程类
    this.queryData.busiType = this.type;
    this.queryData.moduleId = this.current.moduleId;
    this.queryData.businessId = this.current.id;

    const type = this.Moduleclassification.filter(
      (menu) => menu.id == this.current.categoryId
    );
    this.getList();
  },
  methods: {
    /**
     * @description: 获取日志列表
     * @return {void}
     */
    async getList() {
      this.logList = await Logging.getLogs(this.queryData);
    },
    /**
     * @description: 处理日志类型,1.新增 2.修改 3.删除 4:上传 5:下载 6:移动 7:分享
     * @return {void}
     */
    handleType(type) {
      const typeObj = {
        1: "新增",
        2: "修改",
        3: "删除",
        4: "上传",
        5: "下载",
        6: "移动",
        7: "分享",
        8: "修改",
        9: "修改",
      };
      return typeObj[type] || "未知类型";
    },
  },
};
</script>

<style lang="scss" scoped>
.my-logs {
  height: 100%;
  width: 100%;
  font-size: 12px;
  padding-top: 26px;
  padding-left: 16px;
  box-sizing: border-box;
  color: var(--color-text-primary);
  overflow-y: auto;
  .logs-item {
    width: 100%;

    // border-left: 1px solid #CFD7E6;
    .item-top {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .item-top-left {
        position: relative;
        padding-left: 14px;
        box-sizing: border-box;
        color: #809fb8;

        &::before {
          content: "";
          width: 16px;
          height: 16px;
          background: url("@/assets/images/productLibrary/logs-time.png") no-repeat;
          background-size: 16px;
          position: absolute;
          top: 0;
          left: -7px;
        }
      }

      .item-top-right {
        display: flex;
        margin-right: 4px;

        .avatar {
          width: 16px;
          height: 16px;
          font-size: 10px;
          line-height: 16px;
          margin-right: 7px;
          text-align: center;
          border-radius: 5px;
          background: #7948ea;
          color: #fff;
        }
      }
    }

    .item-bottom {
      padding: 13px 54px 13px 32px;
      border-left: 1px solid #cfd7e6;

      div {
        line-height: 24px;
      }
    }
  }

  .type {
    font-weight: 700;
    padding-right: 8px;
  }
}
::v-deep .el-drawer__header {
  height: 40px;
  margin-bottom: 0;
  .title {
    font-weight: 700;
    font-size: 16px;
  }
}
::v-deep .el-drawer__body {
  padding: 0 20px;
}
</style>
