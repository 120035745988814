<template>
  <div class="demo article-preview">
    <div class="preview-top flex align-items-center" v-if="showheader && currentItem">
      <div class="pointer el-icon-arrow-left mr-9" @click="goback">返回</div>
      <img
        class="article-cover mr-9"
        :src="
          currentItem.article.coverUrl
            ? currentItem.article.coverUrl
            : require('@/assets/images/sample-1.png')
        "
        alt=""
      />
      <div>
        {{ currentItem.article.articleName }}
      </div>
    </div>
    <div class="article-preview-main">
      <iframe id="myiframe" src="" frameborder="0" height="100%" width="100%"></iframe>
    </div>
  </div>
</template>
<script>
import { Article } from "../../../../api/Article";
export default {
  props: {
    // currentItem: Object,
    dataid: String,
    // visible: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  computed: {
    // dialogVisible: {
    //   get() {
    //     return this.visible;
    //   },
    //   set(val) {
    //     this.$emit("update:visible", val);
    //   },
    // },
  },
  data() {
    return {
      currentItem: null,
      showheader: true,
    };
  },
  watch: {},
  mounted() {
    // if (this.$route.query && this.$route.query.preveiew) {
    //   this.showheader = false;
    // }
    // this.currentItem && this.handleLoad();
    if (this.dataid) {
      this.getarticleDetail(this.dataid);
    }
  },
  methods: {
    goback() {
      // this.dialogVisible = false;
      this.$emit("close");
    },
    /**
     * @description:获取文章详情
     * @return {void}
     */
    async getarticleDetail(id) {
      const res = await Article.queryArticleDetail(id);
      res && (this.currentItem = res);
      this.currentItem && this.handleLoad();
    },
    handleLoad() {
      let iframe = document.getElementById("myiframe");
      if (!iframe) return;
      iframe.src = "about:blank";
      // 添加一下顶部标题介绍
      const text =
        `<div style="display:flex">
        <img
        style="width:30%;margin-right:10px"
          src="
            ${
              this.currentItem.article.coverUrl
                ? this.currentItem.article.coverUrl
                : require("@/assets/images/sample-1.png")
            }
          "
          alt=""
        />
        <div class="top-text">
          <h2>${this.currentItem.article.articleName}</h2>
          <div>
          ${this.currentItem.article.introduce}
          </div>
        </div>
      </div>` + this.currentItem.article.detailContent;
      setTimeout(() => {
        iframe && iframe.contentDocument.write(text);
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/demo.scss";

.article-preview {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 13;
  overflow: hidden;
  background-color: #fff;

  .article-preview-main {
    height: calc(100% - 40px);
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    overflow: auto;
  }
  .article-cover {
    width: 37px;
    height: 22px;
    flex-shrink: 0;
  }
  .mr-9 {
    margin-right: 9px;
  }
  iframe {
    height: calc(100% - 10px);
  }
}
</style>
