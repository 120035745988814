var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"demo-contain demo w-h-100 flex-colomn",class:{ 'unit-preview': _vm.unitqueryids&&_vm.showheader }},[(_vm.showheader)?_c('div',{staticClass:"w-100 app-edit-header",class:{ 'app-preview-header': _vm.isPreview && !_vm.titlefixed }},[_c('div',{staticClass:"header-firstfloor flex align-items-center"},[_c('div',{staticClass:"w-h-100 flex align-items-center"},[_c('ProductBack',{attrs:{"currentpayload":_vm.unitqueryids},on:{"handleback":_vm.handleBack}}),_c('div',{staticClass:"header-title flex align-items-center"},[_c('img',{attrs:{"src":_vm.coverList[0] && _vm.coverList[0].coverUrl,"alt":""}}),(_vm.outlinedata && !_vm.isPreview)?_c('div',{staticClass:"header-name one-line preview-name"},[_c('ProductName',{attrs:{"name":_vm.outlinedata.subjectName,"subject":_vm.outlinedata},on:{"update:name":function($event){return _vm.$set(_vm.outlinedata, "subjectName", $event)}}})],1):_vm._e(),(_vm.outlinedata && _vm.isPreview)?_c('div',{staticClass:"header-name one-line fz-12 preview-name",attrs:{"title":_vm.outlinedata.subjectName}},[_vm._v(" "+_vm._s(_vm.outlinedata.subjectName)+" ")]):_vm._e()])],1),(!_vm.readonly)?_c('div',{staticClass:"fixed-oprate flex-between align-items-center"},[_c('ProductStatusButton',{on:{"statuschange":_vm.handlestatusChange}}),(_vm.isPreview)?_c('ProductHeaderHide',{attrs:{"titlefixed":_vm.titlefixed},on:{"update:titlefixed":function($event){_vm.titlefixed=$event}}}):_vm._e()],1):_vm._e()]),(!_vm.isPreview)?_c('div',{staticClass:"header-interlayer-tools flex align-items-center"},[(_vm.acceptType.length)?_c('div',{staticClass:"header-interlayer-tools-right flex align-items-center",class:{
          'tool-active': _vm.showrightbox,
        },on:{"click":function($event){_vm.showrightbox = !_vm.showrightbox}}},[_c('svg-icon',{staticStyle:{"margin-left":"5px"},attrs:{"icon":"edit-fun","color":"#344563"}})],1):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex app-scheme-wrapper comprehensive-scheme-wrapper"},[_c('SplitPane',{attrs:{"initialLeftWidth":_vm.showdemo ? 300 : 0},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showdemo),expression:"showdemo"}],staticClass:"scheme-title flex-between align-items-center"},[_c('div',{staticClass:"scheme-title-name"},[_vm._v("演示提纲")]),_c('div',{staticClass:"flex align-items-center"},[_c('svg-icon',{staticClass:"mr-10 pointer",attrs:{"icon":"close-side","color":"#5E6C84","height":16,"width":16},nativeOn:{"click":function($event){_vm.showdemo = false}}})],1)]),(_vm.queryIds)?_c('MindView',{staticStyle:{"height":"calc(100% - 31px)"},attrs:{"clickoutlineid":_vm.clickoutlineid,"ispreview":_vm.isPreview,"query-ids":_vm.queryIds},on:{"editornode":_vm.editornode}}):_vm._e()]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"w-h-100 app-scheme-detail"},[(_vm.outlinedata)?_c('div',{staticClass:"app-scheme-main demo-main"},[(!_vm.showdemo)?_c('svg-icon',{staticClass:"retract-icon pointer",attrs:{"icon":"mind-close","color":"#5E6C84","height":36,"width":48},nativeOn:{"click":function($event){_vm.showdemo = true}}}):_vm._e(),(
                _vm.activeNode &&
                (_vm.activeNode.data.outlineType == 1 ||
                  _vm.activeNode.data.outlineType == 0 ||
                  _vm.activeNode.isroot)
              )?_c('ContentView',{attrs:{"nodeid":_vm.clickoutlineid,"ispre":_vm.isPreview}}):_vm._e(),(_vm.activeNode && _vm.activeNode.data.outlineType == 2)?_c('ProductView',{attrs:{"nodeid":_vm.clickoutlineid,"demoId":_vm.outlinedata.id,"ispre":_vm.isPreview}}):_vm._e(),(_vm.activeNode && _vm.activeNode.data.outlineType == 3)?_c('ColumnView',{attrs:{"nodeid":_vm.clickoutlineid,"demoId":_vm.outlinedata.id,"ispre":_vm.isPreview}}):_vm._e(),(_vm.activeNode && _vm.activeNode.data.outlineType == 4)?_c('ArticleView',{attrs:{"nodeid":_vm.clickoutlineid,"demoId":_vm.outlinedata.id,"ispre":_vm.isPreview,"active-node":_vm.activeNode,"type":2}}):_vm._e(),(_vm.activeNode && _vm.activeNode.data.outlineType == 5)?_c('LinkView',{attrs:{"nodeid":_vm.clickoutlineid,"demoId":_vm.outlinedata.id,"query-ids":_vm.queryIds,"ispre":_vm.isPreview}}):_vm._e(),(_vm.activeNode && _vm.activeNode.data.outlineType == 6)?_c('FileView',{attrs:{"nodeid":_vm.clickoutlineid,"demoId":_vm.outlinedata.id,"query-ids":_vm.queryIds,"ispre":_vm.isPreview,"active-node":_vm.activeNode}}):_vm._e()],1):_vm._e(),(_vm.outlinedata && _vm.isPreview)?_c('NavigationTool',{attrs:{"nodeid":_vm.clickoutlineid,"onlineTree":_vm.outlinedata.showProjectOutlineVos}}):_vm._e()],1)]},proxy:true}])}),(!_vm.isPreview && _vm.showrightbox && _vm.acceptType.length)?_c('div',{staticClass:"association-library-wrapper"},[_c('Toolcenter',{attrs:{"accept":_vm.acceptType,"selectids":_vm.selectids}})],1):_vm._e()],1),(_vm.showLogs)?_c('LogsView',{attrs:{"current":_vm.outlinedata,"visible":_vm.showLogs},on:{"update:visible":function($event){_vm.showLogs=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }