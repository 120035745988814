<template>
  <div class="file-main" id="myfile" @drop="handleDrop" @dragover="allowDrop">
    <FileTemPreview
      v-if="clickitem"
      :haslist="false"
      :currentitem.sync="clickitem"
      :color="'#344563'"
      :loginrequired="loginrequired"
    />
  </div>
</template>

<script>
import { File } from "@/api/File";
import { Playeditor } from "@/api/Playeditor";
import { mapGetters } from "vuex";
import axios from "axios";
import FileTemPreview from "../../../components/filecompanies/FileTemPreview.vue";
export default {
  components: { FileTemPreview },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ispre: {
      type: Boolean,
      default: false,
    },
    queryIds: Object,
    activeNode: Object,
    nodeid: String,
    demoId: String,
    loginrequired: {
      type: Boolean,
      default: true,//是否需要登录
    },
  },
  data() {
    return {
      clickitem: null,
      linkvisible: false,
      tablekey: 1,
      activeurl: "",
      leftvisible: true,
      activelink: {},
      tools: [
        { icon: "el-icon-zoom-in", id: 1 },
        { icon: "el-icon-zoom-out", id: 2 },
        { icon: "el-icon-full-screen", id: 3 },
        { icon: "el-icon-refresh-left", id: 4 },
        { icon: "el-icon-refresh-right", id: 5 },
      ],
      style: "scale(1) rotate(0deg)",
      scale: 1,
      rotate: 0,
      isvideo: false, //视频类预览
      isfile: false, //文档类预览
      isimg: false,
      unsupported: false,
      fileUrl: "",
      fileList: [],
    };
  },
  computed: {
    ...mapGetters(["token", "userInfo", "isconnected"]),
  },
  watch: {
    nodeid() {
      if (this.isimg) {
        this.handleTansform(3);
      }
      this.getNodeDetail();
      if (this.activeNode.data.dataType == 1) {
        this.$Pubsub.publish("update-resource-visible", { visible: false, type: [] });
      } else {
        this.$Pubsub.publish("update-resource-visible", {
          visible: true,
          type: ["file"],
        });
      }
    },
  },
  mounted() {
    this.getNodeDetail();
    this.$Pubsub.publish("update-resource-visible", { visible: true, type: ["file"] });
    if (this.activeNode.data.dataType == 1) {
      this.$Pubsub.publish("update-resource-visible", { visible: false, type: [] });
    }
  },
  beforeDestroy() {},
  methods: {
    /**
     * @description: 添加拖拽过来的文件
     * @return {void}
     */
    async handleDrop(event) {
      if (this.ispre) {
        return;
      }
      const filedata = JSON.parse(event.dataTransfer.getData("dragfile"));
      let payload = {
        saveOutlineToResource: {
          fileId: filedata.id,
          name: filedata.folderName,
          outlineNodeId: this.nodeid,
          type: 5,
        },
      };
      const res = await Playeditor.subjectDemoModeToResource(payload);
      if (res) {
        if (this.fileList.length || this.activeNode.data.dataType == 2) {
          this.$Pubsub.publish("update-mind-data", this.nodeid);
          this.getNodeDetail();
        } else {
          this.$Pubsub.publish("update-mind-name-data", {
            id: this.nodeid,
            name: payload.saveOutlineToResource.name,
          });
          this.getNodeDetail();
        }
      }
    },
    // 拖拽事件
    allowDrop(event) {
      // 如果为默认创建的节点就不能再添加文件
      if (this.activeNode.data.dataType == 1) {
        return;
      }
      event.preventDefault();
    },
    /**
     * @description: 获取节点关联详情
     * @return {void}
     */
    async getNodeDetail() {
      const res = await Playeditor[this.loginrequired?"querySubjectDemoModeNodeDetail":"querySubjectDemoModeNodeDetailNoLogin"](
        this.demoId,
        this.nodeid,
        5
      );
      this.fileList = res;
      if (res.length) {
        this.handleCurrent(res[0]);
      } else {
        this.clickitem = null;
      }
    },
    async handleCurrent(value) {
      // const url = await File.downLoadFile({
      //   fileId: value.fileId,
      //   isCurrent: 1,
      //   optType: 2, //1.下载，2.预览
      // });
      let filedata = {
        // fileSavePath: url.downUrl,
        id: value.fileId,
        // name: url.fileName,
      };
      if(!this.loginrequired){
        filedata.shareId = this.queryIds.shareId;
      }
      this.clickitem = filedata;
      // this.previewFile(filedata);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-main {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  overflow: hidden;
}
</style>
