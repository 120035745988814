<template>
  <el-dialog class="ss-dialog" title="分享配置" top="188px" width="840px" :close-on-click-modal="false"
    :visible.sync="dialogVisible" append-to-body>
    <div class="content">
      <div class="qr-code-wrapper">
        <div class="qr-code">
          <QrCode :id="'QrCode'" :text="codeUrl" />
        </div>
        <div class="qr-code-tip">APP或微信扫码直接浏览</div>
        <span class="expires">
          <span class="expires-label">有效期：</span>
          <el-popover v-show="!expiresCustomVisible" v-model="expiresVisible"
            popper-class="share-popover expires-popover" placement="bottom-end" width="103" trigger="click"
            :visible-arrow="false">
            <span class="expires-value" slot="reference">
              {{ expiresItem ? expiresItem.realName || expiresItem.name : "" }}
            </span>
            <div v-for="item in expiresList" class="share-popover-item" :class="{
    'is-active': expiresItem && expiresItem.id === item.id,
  }" :key="item.id" @click="selectExpires(item)">
              <span>{{ item.name }}</span>
            </div>
          </el-popover>
          <template v-if="expiresCustomVisible">
            <el-input v-model="expiresTime" class="expires-input" size="small"
              @input="expiresTime = expiresTime.replace(/[^0-9]/g, '')"></el-input>
            <el-popover v-model="expiresTypeVisible" popper-class="share-popover" placement="bottom-end" width="94"
              trigger="click" :visible-arrow="false">
              <div class="expires-type" slot="reference">
                <span class="expires-type-label">{{
    expiresTypeItem ? expiresTypeItem.name : ""
  }}</span>
                <img class="expires-type-icon" src="@/assets/images/common/triangle_down.png" />
              </div>
              <div v-for="item in expiresTypeList" class="share-popover-item" :key="item.id"
                @click="selectExpiresType(item)">
                <div class="share-popover-item__label">{{ item.name }}</div>
                <img v-if="expiresTypeItem && expiresTypeItem.id === item.id" class="share-popover-item__icon"
                  src="@/assets/images/common/check_blue.png" />
              </div>
            </el-popover>
            <img class="expires-check" src="@/assets/images/common/check_blue.png" @click="setselftime" />
          </template>
        </span>
      </div>
      <div class="content-right">
        <div class="content-right-title" style="padding-top:20px">浏览权限</div>
        <el-radio-group v-model="queryData.authWay" @change="handleauthWayChange">
          <el-radio :label="1">需要登录系统</el-radio>
          <el-radio :label="2" v-if="!mustlogin">无须安全验证</el-radio>
        </el-radio-group>

        <div class="content-right-title" style="padding-top:20px">权限</div>
        <el-checkbox-group v-model="checkedPower" @change="handleCheckedChange">
          <el-checkbox v-for="power in powers" :label="power.id" :key="power.id"
            :disabled="power.id != 1 && !checkedPower.includes(2) || power.id != 1 && queryData.authWay != 1">{{ power.name }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="dialog-footer">
      <el-input v-model="codeUrl2" class="footer-input" placeholder="请输入内容" @click.native="inputClick"
        @focus="handleFocus" readonly>
        <template slot="append">复制链接</template>
      </el-input>
      <!-- <div class="footer-btn wechat" @click="seddToWechat">
        <img class="footer-btn-icon_wechat" src="@/assets/images/common/wechat.png" alt="" />
        <span class="footer-btn-label">发送到聊天</span>
        <img class="footer-btn-arrow" src="@/assets/images/common/arrow_right.png" alt="" />
      </div> -->
      <div class="footer-btn i3v" @click="friendsVisible = true">
        <img class="footer-btn-icon_i3v" src="@/assets/images/common/i3v.png" alt="" />
        <span class="footer-btn-label">指定好友</span>
        <img class="footer-btn-arrow" src="@/assets/images/common/arrow_right.png" alt="" />
      </div>
    </div>
    <el-dialog class="select_dialog" :show-close="false" :visible.sync = "friendsVisible" width="510px" append-to-body>
      <Web_TopicSelector v-if="friendsVisible" @close="friendsVisible = false" @confirm="selectorGet"/>
    </el-dialog>
  </el-dialog>
</template>

<script>
import QrCode from "@/components/QrCode.vue";
// import SelectFriends from "./SelectFriends.vue";
import { Share } from "@/api/Share";
import { mapGetters } from "vuex";
import { Web_TopicSelector, $tinode } from "i3v-chat-system"
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subjectId: String,
    shareinfo: Array,//这里用于请求参数的shareDetails内容，因为不同类型对应的参数不同，所以由使用的父组件传进来
    bizType: {
      type: Number,
      default: 1,
    }, //分享内容类型：1、主题产品，2文件,3、公司名片,4、个人名片，5、演示方案 6.展示方案 7.栏目，8.自定义(综合管理)
  },
  components: { QrCode, Web_TopicSelector },
  data() {
    return {
      codeUrl: "", //访问链接,二维码使用
      codeUrl2: "", //访问链接
      expiresVisible: false,
      expiresList: [
        { id: "1", name: "3天", time: 3, timeUnit: "h" },
        { id: "2", name: "7天", time: 7, timeUnit: "d" },
        { id: "3", name: "1个月", time: 1, timeUnit: "m" },
        { id: "4", name: "6个月", time: 6, timeUnit: "m" },
        { id: "5", name: "长期有效", time: 0, timeUnit: "y" },
        { id: "6", name: "自定义", realName: "自定义", timestamp: -1 },
      ],
      expiresItem: null,

      expiresCustomVisible: false,
      expiresTime: "",
      expiresTypeVisible: false,
      expiresTypeList: [
        { id: "1", name: "小时", timeUnit: "h" },
        { id: "2", name: "天", timeUnit: "d" },
        { id: "3", name: "月", timeUnit: "m" },
        { id: "4", name: "年", timeUnit: "y" },
      ],
      expiresTypeItem: null,
      checkboxGroup: [],
      contentList: [],
      checkboxAllGroup: ["1"],
      indeterminate: false,
      radio: 2,
      pwd: "WWWW",
      friendsVisible: false,//好友弹窗
      // 选择的栏目
      selectColums: [],
      subjectName: "111",
      // 分享详情
      sharedetail: null,
      checkedPower: [],
      powers: [
        {
          id: 1,
          name: "下载",
        },
        {
          id: 3,
          name: "收录",
        },
        {
          id: 2,
          name: "编辑",
        },
      ],
      queryData: {
        accessCode: "",
        authDownload: 0,//下载
        authEdit: 0,//编辑
        authEmploy: 0,//收录
        authWay: 1,//认证方式（1、需要登录，2、无需安全验证，3、指定访问码）
        bizType: 1,//分享内容类型：1、主题产品，2文件,3、公司名片,4、个人名片，5、演示方案 6.展示方案 7.栏目，8.自定义(综合管理)
        delFlag: 0,
        // expireTime: "",
        id: "",//分享id
        shareDetails: [
          // {
          //   createAt: "",
          //   createBy: "",
          //   createName: "",
          //   fileCreateTime: "",
          //   fileName: "",
          //   fileType: "",
          //   fileUpdateTime: "",
          //   fileUrl: "",
          //   id: "",
          //   shareId: "",
          //   size: "",
          //   subjectColumnId: "",//主题/演示/展示/栏目各种id
          // },
        ],
        shareWay: 1,
        status: 1,
        tenantId: "",
        timeUnit: "d",
        validDays: 3,
      },
      mustlogin:false,//是否为必须登录的
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters([
      "userInfo","token"
    ]),
  },
  watch: {
  },
  mounted() {
    this.queryData.shareDetails = this.shareinfo;
    this.queryData.tenantId = this.userInfo.tenantIdNow
    this.queryData.bizType = this.bizType
    if (this.bizType == 1||this.bizType == 5) {
      this.queryData.subjectId = this.shareinfo[0].subjectColumnId
    }else if(this.bizType == 7){
      this.queryData.subjectId = this.subjectId
      // // 如果分享类型为栏目，且栏目为文件，则必须登录--20240912不需要了
      // if(this.shareinfo[0].subjectColumnId=="-1"){
      //   this.mustlogin = true;
      // }
    }
    this.expiresItem = this.expiresList[0];
    this.expiresTypeItem = this.expiresTypeList[0];
    this.toShare();
  },
  methods: {
    /**
     * @description: 点击分享,新增分享
     * *@param {Object} value 当前项
     * @return {void}
     */
    async toShare() {
      // 拿到分享的id
      const res = await Share.addMyShare(this.queryData);
      this.queryData.id = res;
      this.codeUrl = `${process.env.VUE_APP_URL_SHARE}getdetailbyshareid?shareId=${res}&scanFlag=true`;
      this.codeUrl2 = `${process.env.VUE_APP_URL_SHARE}getdetailbyshareid?shareId=${res}`;
    },
    /**
     * @description: 关闭分享弹窗
     * @return {void}
     */
    finish() {
      this.dialogVisible = false;
    },
    /**
     * @description: 选择时间
     * @return {void}
     */
    async selectExpires(value) {
      if (this.expiresItem && this.expiresItem.id === value.id) {
        if (value.id === "6") {
          this.expiresCustomVisible = true;
        } else {
          return;
        }
      }
      this.expiresItem = value;
      this.expiresVisible = false;
      if (value.id === "6") {
        this.expiresCustomVisible = true;
        this.queryData.timeUnit = this.expiresTypeList[0].timeUnit;
        this.queryData.validDays = this.expiresTime;
        return;
      }
      this.queryData.validDays = this.expiresItem.time;
      this.queryData.timeUnit = this.expiresItem.timeUnit;
      // await Share.editShare(this.queryData);
      // const res2 = await Share.getInfoById(this.queryData.id);
    },
    /**
     * @description: 自定义时间选择时间类型
     * @return {void}
     */
    selectExpiresType(value) {
      if (this.expiresTypeItem && this.expiresTypeItem.id === value.id) {
        return;
      }
      this.expiresTypeItem = value;
      this.expiresTypeVisible = false;
      this.queryData.timeUnit = value.timeUnit;
    },
    randomNumber(max, min) {
      return Math.round(Math.random() * (max - min) + min);
    },
    // 自定义时间完成
    async setselftime() {
      if (!this.expiresTime) {
        this.$message.warning("时间不能为空");
        return;
      }
      this.queryData.validDays = this.expiresTime
      this.expiresCustomVisible = false;
      // await Share.editShare(this.queryData);
      // const res2 = await Share.getInfoById(this.queryData.id);
    },
    /**
     * @description: 底部输入框点击事件，用于复制链接
     * @param {Event} event
     * @return {void}
     */
    async inputClick(event) {
      this.sharedetail = await Share.getInfoById(this.queryData.id);
      let list = []
      this.queryData.shareDetails.forEach(element => {
        list.push({
          fileId: element.subjectColumnId,
          fileName: element.fileName,
        })
      });
      let data = {
        bizType: this.queryData.bizType,
        delFlag: this.queryData.delFlag,
        expireTime: this.sharedetail.expireTime,
        shareDetailSaveRequests: list,
        shareId: this.queryData.id,
        status: 1,
        // subjectId: this.sharedetail.subjectId,
        // subjectName: this.subjectName,
        // targetUsers: [
        //   {
        //     userId: "",
        //     userName: "",
        //   },
        // ],
      };
      const res = await Share.addShare(data);
      if (event.target.className === "el-input-group__append") {
        const res = this.common.handleCopy(this.codeUrl2);
        res && this.$message.success("复制成功");
      }
    },
    /**
     * @description: 发送到微信
     * @return {void}
     */
    seddToWechat() {
      // do something
    },
    /**
     * @description: 指定好友
     * @return {void}
     */
    async seedToFriends() {
      // this.sharedetail = await Share.getInfoById(this.queryData.id);
      // this.friendsVisible = true;
    },
    /**
     * @description: 链接框聚焦时全选
     * @param {FocusEvent} event 聚焦事件
     * @return {void}
     */
    handleFocus(event) {
      event.currentTarget.select();
    },
    async handleCheckedChange() {
      this.queryData.authDownload = this.checkedPower.includes(1) ?1:0
      this.queryData.authEdit = this.checkedPower.includes(2) ? 1 : 0
      this.queryData.authEmploy = this.checkedPower.includes(3) ? 1 : 0
      // this.toShare()
      await Share.editShare(this.queryData)
    },
    /**
     * @description: 修改登录方式
     * @return {void}
     */
    async handleauthWayChange(val){
      this.queryData.id=""
      this.toShare()
    },
    /**
     * @description: 获取当前选择人员id列表
     * @param {Array} list 当前选择人员id列表
     * @return {void}
     */
    async selectorGet(list){
      const payload = {
        targetUserId:this.userInfo.id,//用户id
        titleName: this.queryData.shareDetails.length>1?this.queryData.shareDetails[0].fileName+'等多个文件':this.queryData.shareDetails[0].fileName,//产品名字
        url:this.codeUrl2,//分享链接
      }
      $tinode.Chat_SendMessageToTargetTopics(list, 12, payload);
      let detaillist = []
      this.queryData.shareDetails.forEach(element => {
        detaillist.push({
          fileId: element.subjectColumnId,
          fileName: element.fileName,
        })
      });
      this.sharedetail = await Share.getInfoById(this.queryData.id);
      let targetUserslist = list.map(item => ({ userId: item }));
      let data = {
        bizType: this.queryData.bizType,
        delFlag: this.queryData.delFlag,
        expireTime: this.sharedetail.expireTime,
        shareDetailSaveRequests: detaillist,
        shareId: this.queryData.id,
        status: 1,
        // subjectId: this.sharedetail.subjectId,
        // subjectName: this.subjectName,
        targetUsers:  targetUserslist,
      };
      await Share.addShare(data);
      this.$message.success('分享成功')
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="scss">
.share-popover {
  @include popover(94px);

  .share-popover-item {
    @include popover-item;
    padding: 0 18px 0 6px;
    cursor: pointer;
    text-align: left;
    position: relative;

    &.is-active {
      &::before {
        content: "";
        position: absolute;
        top: 11px;
        right: 0;
        width: 13px;
        height: 10px;
        background-image: url("@/assets/images/common/check_blue.png");
        background-size: 13px 10px;
        background-repeat: no-repeat;
      }
    }

    .share-popover-item__icon {
      width: 13px;
      position: absolute;
      right: 8px;
      top: 11px;
    }
  }
}

.expires-popover {
  margin-top: 13px !important;
}
</style>

<style lang="scss" scoped>
.expires {
  font-size: 14px;
  font-weight: 500;
  // margin-left: 16px;
  display: flex;
  justify-content: center;

  .expires-label {
    line-height: 32px;
    color: var(--color-text-regular);
  }

  .expires-value {
    line-height: 32px;
    color: #ff5733;
    font-weight: 700;
    text-decoration: underline dashed;
    cursor: pointer;
  }
}

.expires-input {
  width: 72px;
  margin-left: 10px;
}

.expires-type {
  width: 75px;
  height: 32px;
  margin-left: 10px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d3dae6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .expires-type-label {
    font-size: 14px;
    font-weight: 500;
    color: #344563;
  }

  .expires-type-icon {
    width: 6px;
    height: 5px;
    padding: 0 12px;
  }
}

.expires-check {
  width: 13px;
  height: 10px;
  margin-top: 10px;
  margin-left: 12px;
  cursor: pointer;
}

.configuration {
  box-sizing: border-box;
  min-width: 92px;
  height: 26px;
  padding: 0 6px;
  margin-left: 12px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d3dae6;
  display: flex;
  align-items: center;
  cursor: pointer;

  .configuration-label {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #344563;
  }

  .configuration-icon {
    width: 6px;
    height: 5px;
    padding-left: 12px;
  }
}

.content {
  margin: 30px 0 30px 44px;
  display: flex;

  .qr-code-wrapper {
    width: 295px;
    height: 309px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 6px 6px rgba(199, 199, 199, 0.1);

    .qr-code {
      width: 221px;
      height: 242px;
      padding: 18px 37px 0;
    }

    .qr-code-tip {
      font-size: 12px;
      font-weight: 500;
      color: #5e6c84;
      text-align: center;
      padding: 8px 0;
    }
  }

  .content-right {
    margin-left: 43px;
    flex: 1;
    text-align: left;

    .content-right-title {
      font-size: 16px;
      font-weight: 700;
      color: #344563;
      margin-bottom: 19px;
      display: flex;
      align-items: center;
    }

    .share-checkbox {
      ::v-deep .el-checkbox {
        // width: 120px;
        margin-right: 18px;
        margin-bottom: 14px;
        margin-left: 0 !important;
      }
    }

    .pwd-input {
      width: 128px;
      color: #344563;
      margin: 16px;
    }

    .pwd-check {
      width: 13px;
      height: 10px;
      margin-left: 12px;
    }
  }
}

.dialog-footer {
  height: 90px;
  background-color: #f5f7fa;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer-input {
    width: 390px;

    ::v-deep .el-input__inner {
      font-size: 14px;
      font-weight: 500;
      color: #c5d0e3;
    }

    ::v-deep .el-input-group__append {
      width: 92px;
      padding: 0;
      text-align: center;
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      color: #344563;
      cursor: pointer;
    }
  }

  .footer-btn {
    width: 154px;
    height: 40px;
    border-radius: 5px;
    margin-left: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .footer-btn-icon_wechat {
      width: 30px;
      height: 30px;
    }

    .footer-btn-icon_i3v {
      width: 23px;
      height: 23px;
    }

    .footer-btn-label {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 0 2px 0 6px;
    }

    .footer-btn-arrow {
      width: 16px;
      height: 16px;
    }

    &.wechat {
      background-color: #00baad;
    }

    &.i3v {
      background-color: var(--color-primary);
    }
  }
}

.select_dialog{
  ::v-deep .el-dialog .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog__body{
    padding: 0;
  }
}

</style>
