import dayjs from "dayjs";
import Vue from "vue";

Object.dayjs = (value, format = "YYYY-MM-DD HH:mm") => {
  if (value === null || value === '' || value === undefined) {
    return '';
  }
  return dayjs(value).format(format);
}

class Pagination {
  constructor(current = 1, size = 10, records = [], pages = 0, total = 0) {
    this.current = current;
    this.records = records;
    this.size = size;
    this.pages = pages;
    this.total = total;
  }
}

class ProductLibQuery {
  constructor(tenantId, userId, moduleId, categoryId = "", sortByUpdateAtFlag = false, keyWord = "", sortDesc = "update_at", sortAsc = "", pageNo = 1, pageSize = 10) {
    this.tenantId = tenantId;
    this.userId = userId;
    this.moduleId = moduleId;
    this.categoryId = categoryId;
    this.sortByUpdateAtFlag = sortByUpdateAtFlag;
    this.keyWord = keyWord;
    this.sortDesc = sortDesc;
    this.sortAsc = sortAsc;
    this.pageNo = pageNo;
    this.pageSize = pageSize;
  }
}


/**
 * @description: 格式化文件大小
 * @param {Number} size 文件大小
 * @return {String} 格式化后的文件大小
 */
function formatFileSize(size, defaultReturn = "--") {
  if (typeof size !== "number" || size === 0) {
    return defaultReturn
  }
  if (size < 1024) {
    return size + "B";
  } else if (size >= 1024 && size < 1024 * 1024) {
    return Math.ceil(size / 1024 * 100) / 100 + "KB";
  } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
    return Math.ceil(size / 1024 / 1024 * 100) / 100 + "MB";
  } else {
    return Math.ceil(size / 1024 / 1024 / 1024 * 100) / 100 + "GB";
  }
}

function getSvgData(svg, width = 20, height = 20) {
  if (svg && typeof svg === "string") {
    // 设置 rotate(NaN) => rotate(0) 防止控制台报错
    // 设置 fill 为 currentColor
    // 设置 svg 的 width 和 height
    // const reg = new RegExp('<svg' + '[^>]*(' + 'xmlns' + '=[\'\"](\\w*%?)[\'\"])?[^>]*>', 'gi');
    const reg = new RegExp('<svg.*?>', 'gi');
    return svg.replace(/NaN*/ig, "0")
      .replace(/<svg /ig, "<svg style=\"display: block;\" ")
      .replace(/fill[:|=]\s{0,1}#[a-zA-Z0-9]{3,6}/ig, "fill:currentColor")
      .replace(/fill=[\"|']#FFFFFF[\"|']/ig, "fill=\"_\"")
      .replace(/fill=[\"|']#FFF[\"|']/ig, "fill=\"_\"")
      .replace(/fill=[\"|']#ffffff[\"|']/ig, "fill=\"_\"")
      .replace(/fill=[\"|']#fff[\"|']/ig, "fill=\"_\"")
      .replace(/fill=[\"|']#[a-zA-Z0-9]{3,6}[\"|']/ig, "style=\"fill:currentColor\"")
      .replace(/fill=\"_\"/g, "fill=\"#FFFFFF\"")
      .replace(reg, (match) => {
        return match.replace(/width=[\"|']\d{1,}[\"|']/g, `width="${width}"`).replace(/height=[\"|']\d{1,}[\"|']/g, `height="${height}"`)
      })
    // .replace(/width=[\"|']\d{1,}[\"|']/g, `width="${width}"`)
    // .replace(/height=[\"|']\d{1,}[\"|']/g, `height="${height}"`);
  } else {
    return svg
  }
}
// 将树形数据的空子节点设置为null
function setEmptyChildrenToNull(array, value = "children") {
  array.forEach(element => {
    if (element[value] && element[value].length) {
      setEmptyChildrenToNull(element[value], value);
    } else {
      element[value] = null;
    }
  });
  return array;
}

/**
 * @description: 获取 url 参数对象
 * 如传入 www.baidu.com/?a=1&b=2 返回 { a: "1", b: "2" }
 * @param {String} url 携带参数的url地址
 * @return {Object} 参数对象
 */
function getUrlParamsObject(url) {
  const index = url.lastIndexOf("?");
  if (index !== -1) {
    const params = url.substring(index + 1);
    const searchParams = new URLSearchParams(params);
    return Object.fromEntries(searchParams);
  }
  return {}
}

/**
 * @description: 根据 contentType 和 url 获取文件类型
 * @param {String} contentType 后端返回的类型
 * @param {String} url 文件地址
 * @return {String} 返回的文件类型
 */
function getFileType(contentType, url) {
  if (contentType === "image") {
    return "image";
  } else if (contentType === "video") {
    return "video";
  }
  if (!url) {
    return "other";
  }
  const suffix = "." + url.split(".").pop();
  const _this = Vue.prototype.$global;
  if (_this.onlyOfficeType.indexOf(suffix) !== -1) {
    return "office";
  } else if (_this.modelType.indexOf(suffix) !== -1) {
    return "model";
  } else if (_this.videoType.indexOf(suffix) !== -1) {
    return "video";
  } else if (_this.ImageType.indexOf(suffix) !== -1) {
    return "image";
  } else {
    return "other";
  }
}

/**
 * @description: 根据文件了类型获取图标颜色
 * @param {String} contentType 后端返回的类型
 * @param {String} url 文件地址
 * @return {String} 返回的文件类型
 */
function getFileColor(contentType) {
  if (contentType === "office") {
    return "#43cf7c";
  } else if (contentType === "video") {
    return "#6772e6";
  } else if (contentType === "image") {
    return "#00baad";
  } else if (contentType === "model") {
    return "#8997b0";
  } else if (contentType === "folder") {
    return "#fa952f";
  } else {
    return "#8997B0"
  }
}

/**
 * @description: 获取字典名称
 * @param {Number|String} dictValue 字典值
 * @param {Array} dict 字典列表
 * @return {String} 字典名称
 */
function getDictName(dictValue, dict) {
  if (dictValue === undefined || dictValue === null || dictValue === "") {
    return ""
  }
  const dictItem = dict.find(i => i.dictValue === dictValue);
  return dictItem ? dictItem.dictName : "";
}

function hexToRgba(hex, opacity = 0.8) {
  return `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt('0x' + hex.slice(3, 5))},${parseInt('0x' + hex.slice(5, 7))},${opacity})`;
}

/**
 * @description: 格式化 JSON 字符串为 JSON 对象
 * @param {String} JSONString JSON字符串
 * @return {Object} JSON 对象
 */
function getJSONObject(JSONString) {
  if (typeof JSONString !== "string" || JSONString === "") {
    return {};
  }
  try {
    return JSON.parse(JSONString);
  } catch (error) {
    return {};
  }
}

/**
 * @description: 获取字符串的第一个字符
 * @param {String} str 字符串
 * @return {String} 第一个字符
 */
function getFirstChat(str) {
  if (typeof str !== "string") {
    return {};
  }
  return str.charAt(0);
}

/**
 * @description: 获取字符串一个字符
 * @param {String} str 字符串
 * @return {Number} 最后一个字符代表的数字
 */
function getLastChar(str) {
  if (typeof str !== "string" || str === "") {
    return 0;
  }
  const lastChar = str.charAt(str.length - 1);
  if (/^\d+$/.test(lastChar)) {
    return Number(lastChar);
  }
  return 0;
}

/**
 * @description: 根据图片地址转base64
 * @param {String} imgPath 图片地址
 * @return {DOMString} base64
 */
function convertImageToBase64(imgPath) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imgPath;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const base64Data = canvas.toDataURL();
      canvas.remove();
      resolve(base64Data)
    };
    img.onerror = () => {
      reject("Error loading image");
    };
  }
  )
}
/**
 * @description: base64转File
 * @param {String} dataURL base64
 * @param {String} filename 文件名称
 * @return {File} File 文件
 */
function dataURLtoFile(dataURL, filename) {
  const arr = dataURL.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  //转换成file对象
  return new File([u8arr], filename, { type: mime });
  //转换成成blob对象
  //return new Blob([u8arr],{type:mime});
}
/**
 * @description: 获取文本宽度
 * @param {String} text 文本
 * @param {String} id 文件 id
 * @return {Number} 文本宽度
 */
function getElementWidth(text, id = "virtualSpan") {
  let el = document.createElement("span");
  el.setAttribute('id', id);
  el.innerHTML = text;
  el.style.setProperty('font-size', "14px");
  el.style.setProperty('font-weight', 400);
  el.style.setProperty('height', 0);
  el.style.setProperty('padding', 0);
  el.style.setProperty('border', "none");
  el.style.setProperty('margin', 0);
  document.body.appendChild(el);
  let el2 = document.getElementById(id);
  const width = Math.ceil(parseInt(el2.getBoundingClientRect().width * 1000) / 10) / 100;
  document.body.removeChild(el);
  return width
}

/**
 * @description: 根据 id 获取目标项
 * @param {Array} array 源数组
 * @param {String} id 目标id
 * @return {Object}
 */
function findItemById(array, id) {
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element.id === id) {
      return element;
    }
    if (element.children && element.children.length) {
      let result = findItemById(element.children, id);
      if (result) {
        return result;
      }
    }
  }
}
/**
 * @description: 复制
 * @param {String} text 复制项
 * @return {void}
 */
function handleCopy(text) {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(text).then(() => {
      return true
    });
  } else {
    // 创建text area
    let textArea = document.createElement("textarea");
    textArea.value = text;
    // 使text area不在viewport，同时设置不可见
    textArea.style.position = "absolute";
    textArea.style.opacity = 0;
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // 执行复制命令并移除文本框
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    }).then(() => {
      return true
    });
  }
}

/**
 * @description: 表单校验特殊字符的方法
 * @param {String} value input框里输入的内容
 * @return {void}
 */
function checkSpecialChars(rule, value, callback) {
  const specialCharacters = /[ \t\n\r?:*/\=&&||'"\\\\<>]/g;

  if (specialCharacters.test(value)) {
    callback(new Error('输入不允许包含特殊字符'));
  } else {
    callback(); // 校验通过
  }
}

const common = {
  Pagination,
  ProductLibQuery,
  formatFileSize,
  getSvgData,
  setEmptyChildrenToNull,
  getUrlParamsObject,
  getFileType,
  hexToRgba,
  getDictName,
  getJSONObject,
  getFirstChat,
  getLastChar,
  convertImageToBase64,
  dataURLtoFile,
  getElementWidth,
  findItemById,
  handleCopy,
  getFileColor,
  checkSpecialChars
}

export default common