<template>
  <div class="ed-middle">
    <div class="product-item">
      <div class="product-item-img__wrapper">
        <div class="product-item-img">
          <div v-if="currentCover" class="carousel-img">
            <img class="image" :src="currentCover.coverType == 1 ? currentCover.coverUrl : currentCover.videoCoverUrl">
            <div v-if="currentCover.coverType == 2" class="play-icon"></div>
          </div>
          <div v-if="subject && subject.tags" class="product-item-tags">
            <div v-for="item in subject.tags" class="product-item-tag" :key="item.id">{{ item.tagName }}</div>
          </div>
        </div>
      </div>
      <div class="product-item-name">{{ subject ? subject.subjectName : "" }}</div>
      <div class="product-item-date">最新更新：{{ subject ? Object.dayjs(subject.updateAt) : "" }}</div>
    </div>
    <div class="img-wrapper">
      <div v-for="(item, index) in showList" :key="item.id" class="img-item" @click="handleSelect(index, item)">
        <img v-if="item.coverType == 1" class="img-content" :src="item.coverUrl">
        <img v-if="item.coverType == 2" class="img-content" :src="item.videoCoverUrl">
        <div v-if="item.coverType == 2" class="play"></div>
        <div v-if="showList.length > 1" class="delete" title="删除" @click.stop="handleRemove(index, item)">
          <i class="el-icon-close"></i>
        </div>
        <div v-if="currentCover && currentCover.id === item.id" class="is-active"></div>
      </div>
      <el-upload v-show="showList.length < 5" accept=".jpg,.jpeg,.png,.svg,.tif,.mp4,.webm" action="" ref="upload"
        :auto-upload="false" :multiple="false" :on-change="onChange" :show-file-list="false">
        <i class="el-icon-plus uploader-icon"></i>
      </el-upload>
    </div>
    <span class="tips">图片建议尺寸4:3，支持JPG、PNG、SVG、TIF格式，不超过2M<br />视频支持MP4格式，最大不超过10M</span>
    <el-input v-model="textarea" show-word-limit class="textarea" maxlength="300" placeholder="请输入产品简要说明" type="textarea"
      :autosize="{ minRows: 5 }"></el-input>
  </div>
</template>
<script>
export default {
  props: {
    subject: Object, // 主题详情
    covers: Array,
    remark: String,
  },
  data() {
    return {
      fileList: [], // 图片/视频列表
      showList: [],
      currentCover: null,
      textarea: "",
    };
  },
  watch: {
    textarea(newValue) {
      this.$emit("update:remark", newValue);
    },
  },
  mounted() {
    this.textarea = this.remark;
    this.fileList = this.covers;
    if (this.fileList.length) {
      this.currentCover = this.fileList[0];
      this.showList = this.fileList.filter(item => item.type !== "delete");
    }
  },
  methods: {
    /**
     * @description: 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
     * @param {Object} file 文件
     * @return {void}
     */
    async onChange(file) {
      if (file.raw.type.indexOf("image") !== -1) {
        if (file.size > 2 * 1024 * 1024) {
          this.$message.error("封面图片大小不能超过 2MB!");
          this.$refs.upload.handleRemove(file);
          return
        }
      }
      if (file.raw.type.indexOf("video") !== -1) {
        if (file.size > 10 * 1024 * 1024) {
          this.$message.error("视频大小不能超过 10MB!");
          this.$refs.upload.handleRemove(file);
          return
        }
      }
      let cover = {
        coverType: null,
        coverUrl: URL.createObjectURL(file.raw),
        id: file.uid,
        file: file,
        type: "ready",
      }
      if (file.raw.type.indexOf("image") !== -1) {
        cover.coverType = 1;
        this.fileList.push(cover);
        this.showList = this.fileList.filter(item => item.type !== "delete");
        !this.currentCover && (this.currentCover = this.showList[0]);
        this.$emit("change", this.fileList);
      } else if (file.raw.type.indexOf("video") !== -1) {
        cover.coverType = 2;
        const video = document.createElement("video");
        video.src = cover.coverUrl;
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        video.crossOrigin = 'anonymous';
        video.setAttribute('preload', 'auto');
        video.currentTime = 1;
        video.onloadeddata = () => {
          canvas.width = video.videoWidth ? video.videoWidth : 250; // 获取视频宽度
          canvas.height = video.videoHeight ? video.videoHeight : 173; //获取视频高度
          // 利用canvas对象方法绘图
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          // 转换成base64形式
          cover.videoCoverUrl = canvas.toDataURL("image/png"); // 截取后的视频封面
          this.fileList.push(cover);
          this.showList = this.fileList.filter(item => item.type !== "delete");
          !this.currentCover && (this.currentCover = this.showList[0]);
          this.$emit("change", this.fileList);
          video.remove();
          canvas.remove();
        }
      }
    },
    /**
     * @description: 移除图片/视频
     * @param {Number} index 目标图片/视频索引
     * @param {Object} value 当前项
     * @return {void}
     */
    handleRemove(index, value) {
      if (value.type === "success") {
        value.type = "delete";
      } else {
        const fileIndex = this.fileList.findIndex(item => item.id === value.id);
        this.fileList.splice(fileIndex, 1);
      }
      this.showList = this.fileList.filter(item => item.type !== "delete");
      if (this.currentCover.id === value.id) {
        if (this.showList.length) {
          this.currentCover = index + 1 <= this.showList.length ? this.showList[index] : this.showList[index - 1];
        } else {
          this.currentCover = null;
        }
      }
      this.$emit("change", this.fileList);
    },
    /**
     * @description: 选择封面
     * @param {Number} index 选择项索引
     * @param {Object} value 选择项
     * @return {void}
     */
    handleSelect(index, value) {
      this.currentCover = value;
    },
    /**
     * @description: 播放视频
     * @param {Object} value 目标视频
     * @return {void}
     */
    handlePlay(value) {
      console.log("play:", value);
    },
  }
};
</script>

<style scoped lang="scss">
.product-item {
  width: 250px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;

  .product-item-img__wrapper {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;

    .product-item-img {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .carousel-img {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .play-icon {
          width: 64px;
          height: 64px;
          background-image: url("@/assets/images/editor/play-icon.png");
          background-size: 64px 64px;
          background-repeat: no-repeat;
          position: absolute;
          z-index: 4;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }

      .product-item-tags {
        position: absolute;
        z-index: 10;
        max-width: 236px;
        bottom: 4px;
        right: 7px;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;

        .product-item-tag {
          width: fit-content;
          flex-shrink: 0;
          height: 23px;
          line-height: 23px;
          padding: 0 8px;
          margin: 3px;
          border-radius: 2px;
          background: rgba(0, 186, 173, 0.8);
          font-size: 12px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }

  .product-item-name {
    box-sizing: content-box;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 700;
    color: #344563;
    padding: 18px 20px 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .product-item-date {
    box-sizing: content-box;
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    font-weight: 500;
    color: #8997b0;
    padding: 0 20px 16px;
  }
}

.img-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 25px 0 20px;

  .img-item {
    box-sizing: border-box;
    width: 68px;
    height: 68px;
    border-radius: 5px;
    margin: 5px;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-content {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .play {
      width: 32px;
      height: 32px;
      background-image: url("@/assets/images/editor/play-icon.png");
      background-size: 32px 32px;
      background-repeat: no-repeat;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .delete {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      border-radius: 0 5px 0 5px;
      background-color: rgba(0, 0, 0, .5);
      display: none;
      cursor: pointer;

      i {
        font-size: 12px;
        color: #fff;
      }

      &:hover {
        i {
          color: #1664ff !important;
        }
      }
    }

    &:hover {
      .delete {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .is-active {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      box-sizing: border-box;
      width: 68px;
      height: 68px;
      border-radius: 5px;
      border: 3px solid #1664ff;
    }
  }
}

.uploader-icon {
  font-size: 12px;
  color: #abb4c4;
  width: 68px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  margin: 5px;
}

.tips {
  font-size: 12px;
  font-weight: 500;
  color: #8997b0;
  text-align: center;
}

.textarea {
  width: 380px;
  margin-top: 30px;

  ::v-deep .el-textarea__inner {
    border-color: #fff;
    border-radius: 5px;
  }
}

.ed-middle {
  padding: 32px 32px 138px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>