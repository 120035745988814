<template>
  <div ref="rightcolumnwrapper" class="w-h-100 flex app-scheme-wrapper border-left">
    <div class="app-scheme-sidebar" v-if="!showscheme">
      <svg-icon
        class="scheme-sidebar-icon pointer"
        :class="{ 'scheme-sidebar-icon-active': showscheme }"
        icon="icon-scheme"
        :height="20"
        :width="20"
        @click.native="showscheme = !showscheme"
      ></svg-icon>
    </div>
    <div class="app-scheme-left" v-if="showscheme">
      <div class="scheme-title flex-between align-items-center">
        <div class="scheme-title-name">展示方案</div>
        <div class="flex align-items-center">
          <svg-icon
            v-if="!ispre"
            class="mr-10 pointer"
            icon="mind-add"
            color="#5E6C84"
            :height="12"
            :width="12"
            @click.native="handleCreat(null)"
          ></svg-icon>
          <svg-icon
            class="mr-10 pointer"
            icon="close-side"
            color="#5E6C84"
            :height="16"
            :width="16"
            @click.native="showscheme = false"
          ></svg-icon>
        </div>
      </div>
      <div class="scheme-list">
        <div
          v-for="i in schemeList"
          :key="i.id"
          @click="handleClick(i)"
          class="flex-between scheme-list-items pointer align-items-center"
          :class="{ 'items-active': activeScheme && activeScheme.id == i.id }"
        >
          <div class="flex w-100">
            <svg-icon
              class="scheme-items-icon"
              icon="icon-scheme"
              :height="16"
              :width="16"
              color="#344563"
              v-if="!i.isDefault"
            ></svg-icon>
            <div class="scheme-items-icon-default" v-if="i.isDefault">默认</div>
            <div class="scheme-items-name one-line">{{ i.name }}</div>
          </div>
          <div v-if="showrightbtn" class="flex oprate-btn">
            <svg-icon
              class="pointer"
              icon="preview"
              :width="16"
              :height="16"
              @click.native.prevent.stop="handlePreview(i)"
            ></svg-icon>
            <svg-icon
              class="pointer"
              icon="edit"
              :width="16"
              :height="16"
              @click.native.prevent.stop="handleCreat(i)"
            ></svg-icon>
            <svg-icon
              class="pointer"
              icon="delete"
              :width="16"
              :height="16"
              @click.native.prevent.stop="handleDelete(i)"
            ></svg-icon>
          </div>
          <el-popover
            popper-class="toolbar-item-popover popover"
            placement="right-start"
            width="120"
            trigger="click"
            :ref="'toolbar-' + i.id"
            :visible-arrow="false"
            v-if="!showrightbtn"
          >
            <div class="toolbar-more" slot="reference" @click.stop="showMore(i)"></div>
            <div class="popover-item" @click="handleDefault(i)">
              {{ i.isDefault ? "取消默认" : "设为默认" }}
            </div>
            <div class="popover-item" @click="handlePreview(i)">预览</div>
            <div class="popover-item" @click="handleCreat(i)">编辑</div>
            <div class="popover-divider"></div>
            <div class="popover-item" @click="handleDelete(i)">删除</div>
          </el-popover>
        </div>
      </div>
      <div class="scheme-detail" v-loading="columnLoading">
        <el-tree
          :data="columnList"
          :default-expand-all="true"
          node-key="id"
          :props="defaultProps"
        >
          <div
            class="custom-tree-node flex align-items-center"
            slot-scope="{ node, data }"
            @click="handleLabelClick(node, data)"
          >
            <div class="one-line" :title="node.label">{{ node.label }}</div>
            <i
              v-if="node.level == 2"
              class="el-icon-error"
              @click.stop.prevent="handlerelevanceDelete(data, node)"
            ></i>
          </div>
        </el-tree>
      </div>
    </div>
    <Scheme
      v-if="addvisible"
      :visible.sync="addvisible"
      :subject="subject"
      :clickitem="clickScheme"
      @finish="getScheme"
    />
    <SchemePreview
      v-if="previewvisible"
      :visible.sync="previewvisible"
      :subject="subject"
      :scheme="clickScheme"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import SchemePreview from "../dialog/SchemePreview.vue";
import Scheme from "../dialog/Scheme.vue";
import NETSYSTEM from "@/api/NETSYSTEM";

export default {
  name: "ShowProject2",
  components: {
    Scheme,
    SchemePreview,
  },
  props: {
    ispre: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    showaside: {
      type: Boolean,
      default: false,
    }, //显示侧边栏
    subject: Object,
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "columnName",
      },
      columnLoading: false,
      schemeList: [], //方案列表
      columnList: [],
      activeScheme: null, //当前展示方案
      clickScheme: null, //编辑的方案
      addvisible: false, //添加/编辑方案名称弹窗
      queryselectData: [],
      temporaryfile: [],
      selectsaveloading: false, //选择数据保存loading
      previewvisible: false,
      scheme: null, //需要刷新的方案
      pubsubToken: [],
      resizeObserver: null,
      showrightbtn: false, //显示右边的操作按钮
    };
  },
  watch: {
    activeScheme(val) {
      this.getSchemeColumn();
    },
    ispre(val) {
      this.isSchemeEdit = !val;
    },
    subject(val) {
      if (val) {
        this.getScheme();
      }
    },
  },
  computed: {
    isPreview() {
      return this.ispre;
    },
    showscheme: {
      get() {
        return this.showaside;
      },
      set(val) {
        this.$emit("update:showaside", val);
      },
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["userInfo", "columnCategoryTypeDict"]),
  },
  mounted() {
    if (this.subject) {
      this.getScheme();
    }
    if (this.isPreview) {
      this.showscheme = false;
      this.isSchemeEdit = false;
    }
    this.pubsubToken.push(
      // 发布一个更新资源选择id的方法
      this.$Pubsub.subscribe("update-column-select", (val) => {
        this.handleSaverelevance(val);
      })
    );
    this.resizeObserver = new ResizeObserver(this.calculateLeftWidth);
    this.resizeObserver.observe(this.$refs.rightcolumnwrapper);
  },
  beforeDestroy() {
    this.pubsubToken.forEach((token) => {
      this.$Pubsub.unsubscribe(token);
    });
    this.pubsubToken = [];
  },
  methods: {
    /**
     * @description: 获取容器宽度
     * @return {void}
     */
    calculateLeftWidth() {
      if (!this.$refs.rightcolumnwrapper) {
        return;
      }
      const containerWidth = this.$refs.rightcolumnwrapper.offsetWidth; // 获取容器宽度
      if (containerWidth > 240) {
        this.showrightbtn = true;
      } else {
        this.showrightbtn = false;
      }
    },
    /**
     * @description: 获取展示方案列表
     * @return {void}
     */
    async getScheme() {
      const res = await ProductLibrary.SchemequeryList(this.subject.id);
      this.schemeList = res;
      if (this.schemeList.length) {
        let defaultdata = this.schemeList.filter((item) => item.isDefault);
        if (defaultdata.length) {
          this.activeScheme = defaultdata[0];
        } else {
          this.activeScheme = this.schemeList[0];
        }
        this.scheme && (this.activeScheme = this.scheme);
        this.getSchemeColumn();
      }
    },
    /**
     * @description: 获取展示方案关联栏目列表
     * @return {void}
     */
    async getSchemeColumn() {
      this.columnLoading = true;
      const res = await ProductLibrary.getSchemeSubjectColumnList({
        schemeId: this.activeScheme.id,
        subjectVersionId: this.subject.versionId,
      });
      this.columnList = _.sortBy(res, "sortNo");
      if (this.columnList.length) {
        this.columnList.forEach(async (item) => {
          item.columntype = this.columnCategoryTypeDict[item.columnCategoryId];
          item.children = await this.handleColumnDetail(item);
        });
      }
      const filelist = await this.getFileTableData();
      const fileindex = this.columnList.findIndex((item) => item.id == "-1");
      if (filelist.length) {
        if (fileindex != "-1") {
          this.columnList[fileindex].children = filelist;
        } else {
          this.columnList.push({
            columnName: "文件",
            columntype: 2,
            id: "-1",
            children: filelist,
          });
        }
      }
      this.$emit("selectdetail", this.columnList, this.activeScheme.id);
      this.columnLoading = false;
    },
    /**
     * @description: 根据类型查询栏目数据
     * @param {object} value 方案关联栏目
     * @return {void}
     */
    handleColumnDetail(value) {
      const type = this.columnCategoryTypeDict[value.columnCategoryId];
      switch (type) {
        case "1":
          return this.getTableData(value);
        case "5":
          return this.getacticleList(value);
        case "3":
          return this.getContentTableData(value);
        case "4":
          return this.getLinklist(value);
        default:
          return [];
      }
    },
    /**
     * @description: 创建展示方案
     * @return {void}
     */
    handleCreat(value) {
      this.clickScheme = value;
      this.addvisible = true;
    },
    showDialog(visible) {
      this[visible] = true;
    },
    /**
     * @description: 切换方案
     * @return {void}
     */
    handleClick(value) {
      this.activeScheme = value;
    },
    /**
     * @description: 设置默认
     * @return {void}
     */
    async handleDefault(value) {
      const props = ["id", "isDefault", "name", "tenantId", "dataId", "dataType"];
      let payload = _.pick(value, props);
      payload.isDefault ? (payload.isDefault = 0) : (payload.isDefault = 1);
      const res = await ProductLibrary.UpdateScheme("edit", payload);
      if (res) {
        this.$message.success("成功");
        this.getScheme();
      }
    },
    /**
     * @description: 删除
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `确定删除 <span style="font-weight: 1000;">${value.name}</span> 吗?<br>此次操作会将所选数据永久删除，请谨慎操作！`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await ProductLibrary.SchemeDelete(value.id);
          if (res) {
            this.$message.success("删除成功");
            this.getScheme();
          }
          done(true);
        }
      );
    },
    /**
     * @description: 数据筛选
     * @return {void}
     */
    updateDataArray(dataArray, newData) {
      const foundItem = dataArray.filter(
        (item) => item.subjectColumnId === newData.subjectColumnId
      )[0];
      if (foundItem) {
        Object.assign(foundItem, newData);
      } else {
        dataArray.push(newData);
      }
      return dataArray;
    },
    /**
     * @description: 保存选择栏目数据
     * @return {void}
     */
    async handleSelectSave() {
      if (this.queryselectData.length) {
        this.queryselectData.forEach((item) => {
          item.schemeId = this.activeScheme.id;
        });
        this.selectsaveloading = true;
        const res = await ProductLibrary.addrelateBatch(this.queryselectData);
        if (res) {
          this.$message.success("成功");
          this.queryselectData = [];
          this.$emit("finish");
          this.dialogVisible = false;
        }
        this.selectsaveloading = false;
      }
    },
    /**
     * @description:获取方案介绍栏目子栏目列表
     * @param {object} Column 方案关联栏目
     * @return {void}
     */
    async getTableData(Column) {
      if (!Column) return;
      let payload = {
        schemeId: this.activeScheme.id,
        pageNo: 1,
        pageSize: 999,
        subjectVersionId: this.subject.versionId,
        subjectColumnId: Column.id,
      };
      const res = await ProductLibrary.getSchemeSubjectColumnChildPageList(payload);
      res.records.forEach((item) => {
        item.dataType = 1;
        item.subjectColumnId = Column.id;
        delete item.children;
      });
      return res.records.length ? res.records : [];
    },
    /**
     * @description: 关联文章列表
     * @param {object} Column 方案关联栏目
     * @return {void}
     */
    async getacticleList(Column) {
      const res = await ProductLibrary.getSchemeToArticleList({
        schemeId: this.activeScheme.id,
        subjectColumnId: Column.id,
      });
      res.forEach((item) => {
        item.id = item.dataId;
        item.columnName = item.toArticleName;
      });
      return res;
    },
    /**
     * @description:获取关联内容列表
     * @param {object} Column 方案关联栏目
     * @return {void}
     */
    async getContentTableData(Column) {
      const res = await ProductLibrary.getSchemeToContentList({
        schemeId: this.activeScheme.id,
        subjectColumnId: Column.id,
      });
      res.forEach((item) => {
        item.id = item.dataId;
        item.columnName = item.toSubjectName;
      });
      return res;
    },
    /**
     * @description:获取链接列表
     * @param {object} Column 方案关联栏目
     * @return {void}
     */
    async getLinklist(Column) {
      const res = await ProductLibrary.getSchemeToLinkList({
        schemeId: this.activeScheme.id,
        subjectColumnId: Column.id,
      });
      res.forEach((item) => {
        item.id = item.dataId;
        item.columnName = item.linkName;
      });
      return res;
    },
    /**
     * @description:获取关联文件列表
     * @return {void}
     */
    async getFileTableData() {
      let previewpayload = {
        moduleId: this.subject.moduleId,
        schemeId: this.activeScheme.id,
        subjectId: this.subject.id,
        fileName: "",
      };
      const res = await ProductLibrary.queryFileBySubjectScheme(previewpayload);
      res.forEach((item) => {
        item.columnName = item.fileName;
        item.dataType = 2;
      });
      return res;
    },
    /**
     * @description:删除关联数据
     * @return {void}
     */
    async handlerelevanceDelete(data, node) {
      const children = node.parent.data.children.map((item) => item.id);
      const selectdata = {
        dataIds: children.filter((item) => item != data.id),
        dataType: data.dataType, //主体类型（1.介绍 2.文件 3.案例 4.链接 5.文章）
        schemeId: this.activeScheme.id,
        subjectColumnId: data.subjectColumnId ? data.subjectColumnId : null,
        subjectId: this.subject.id,
      };
      const res = await ProductLibrary.Schemeaddrelate(selectdata);
      this.scheme = this.activeScheme;
      res && this.getScheme();
    },
    /**
     * @description:保存关联
     * @param {Object} val 关联的提交参数
     * @return {void}
     */
    async handleSaverelevance(val) {
      const res = await ProductLibrary.Schemeaddrelate(val);
      this.scheme = this.activeScheme;
      res && this.getScheme();
    },
    /**
     * @description:预览方案
     * @param {Object} value 点击的方案
     * @return {void}
     */
    handlePreview(value) {
      this.clickScheme = value;
      this.previewvisible = true;
    },
    /**
     * @description: 点击右上角“更多”图标
     * @param {Object} value 点击项
     * @return {void}
     */
    showMore(value) {
      // 隐藏点击其他项时显示的 popover
      this.schemeList.forEach((element) => {
        if (value.id !== element.id && this.$refs["toolbar-" + element.id]) {
          this.$refs["toolbar-" + element.id][0].doClose();
        }
      });
    },
    /**
     * @description: 子项点击事件
     * @return {void}
     */
    handleLabelClick(node, data) {
      if (node.parent.data.id == "-1") {
        this.getFloder(data);
      }
    },
    /**
     * @description: 获取文件所在的父级文件夹列表
     * @return {void}
     */
    async getFloder(value) {
      const parentid = value.parentFolderStr.match(/[^/]*$/)[0];
      const res = await NETSYSTEM.getParentFolderListById(parentid);
      const breadcrumbList = res.slice(4);
      this.$emit("labelclick", 2, breadcrumbList);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";

.app-scheme-wrapper {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;

  .app-scheme-left {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .scheme-list {
    width: 100%;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 165px;
    border-bottom: 1px solid #ebeef2;

    .scheme-list-items {
      margin-top: 4px;
      width: 100%;
      height: 36px;
      border-radius: 5px;
      position: relative;

      &:hover {
        background-color: #f7f9fc;

        .toolbar-more {
          display: block;
        }

        .share-icon {
          display: block;
        }
      }

      .share-icon {
        // margin-left: 10px;
        display: none;
        position: absolute;
        top: 7px;
        right: 16px;
      }

      .toolbar-more {
        display: none;
        position: absolute;
        top: 11px;
        right: 4px;
        width: 12px;
        height: 12px;
        background-image: url("@/assets/images/common/more_vertical.png");
        background-size: 12px 12px;
        background-repeat: no-repeat;
      }

      .scheme-items-icon {
        margin-left: 12px;
        flex-shrink: 0;
      }

      .scheme-items-icon-default {
        width: 30px;
        height: 18px;
        margin-left: 10px;
        flex-shrink: 0;
        text-align: center;
        line-height: 18px;
        background-color: #ebeef2;
        font-size: 12px;
        color: #5e6c84;
      }

      .scheme-items-name {
        margin-left: 6px;
        position: relative;
      }
    }

    .items-active {
      border: 1px solid #5e6c84;
      box-sizing: border-box;
    }

    .oprate-btn {
      .svg-icon {
        margin-left: 4px;

        &:hover {
          color: var(--color-primary) !important;
        }
      }
    }
  }

  .scheme-detail {
    overflow-y: auto;

    ::v-deep .el-tree-node__content {
      height: 30px;
    }

    .custom-tree-node {
      width: calc(100% - 60px);
      height: 30px;
      &:hover {
        i {
          display: inline-block;
        }
      }
      i {
        display: none;
        margin-left: 8px;

        &:hover {
          color: red;
        }
      }
    }
  }
}

.border-left {
  border: none;
  border-left: 1px solid #ebeef2;
}
</style>
