<template>
  <el-dialog append-to-body class="ss-dialog" title="选择人员" width="840px" :visible.sync="dialogVisible">
    <div v-loading="loading" class="content">
      <div class="content-left">
        <el-input v-model="searchName" class="search-input" placeholder="请输入关键字搜索"></el-input>
        <div class="tree-name">
          <div class="tree-name__line"></div>
          <div class="tree-name__label">组织架构</div>
        </div>
        <div class="tree-container">
          <el-tree highlight-current class="tree" :data="categoryTree" :props="defaultProps" @node-click="handleNodeClick"
            ref="tree" node-key="id"></el-tree>
        </div>
      </div>
      <div class="content-mid">
        <div class="content-mid-top">
          <span>组织成员</span>
          <div class="content-mid-top-check" :class="{ 'is-checked': isChecked }" @click="checkAll">
            <div class="content-mid-top-check__icon"></div>
            <div class="content-mid-top-check__label">全选</div>
          </div>
        </div>
        <div class="content-mid-member">
          <div class="content-mid-member-item" v-for="(i, index) in tableData" :class="i.isChecked ? 'is-active' : ''"
            :key="i.userId" @click="chosefriend(i, index)">{{ i.realname }}</div>
        </div>
      </div>
      <div class="content-right">
        <div class="content-right-item" v-for="(i, index) in friendList" :key="i.userId" @click="deletechose(i, index)">
          <div class="content-right-item__label">{{ i.realname }}</div>
          <div class="content-right-item__icon"></div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <div class="custom-footer">
        <span class="summary">已选择 {{ friendList.length }} 个好友</span>
        <el-button class="custom-footer-btn" type="primary" @click="handleConfirm">确&nbsp;定</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { Contacts } from "@/api/Contacts";
export default {
  name: "share",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: true,
      searchName: "",
      currentCategory: null,
      tableData: [],
      categoryTree: [],
      queryData: {
        categoryId: "",
        keyWord: "",
        sortAsc: "",
        sortDesc: "",
        pageNo: 1,
        pageSize: 10,
      },
      name: "",
      defaultProps: {
        children: "children",
        label: "title",
      },
      isChecked: false,
      friendList: [], // 已选择的成员列表
      shareList: {
        bizType: "",
        delFlag: "",
        expireTime: "",
        shareDetailSaveRequests: [],
        shareId: "",
        status: 1,
        subjectId: "",
        targetUsers: [
          {
            userId: "",
            userName: "",
          },
        ],
      }
    };
  },
  mounted() {
    if (this.selectedList.length) {
      this.friendList = _.cloneDeep(this.selectedList);
    }
    this.getTree();
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    handleNodeClick(data) {
      this.getTableData(data.sysUserDepartDtos);
    },
    /**
     * @description: 获取成员列表
     * @param {*} array
     * @return {*}
     */
    getTableData(array) {
      if (array.length) {
        let checkedAll = true;
        let list = [];
        array.forEach(element => {
          const index = this.friendList.findIndex(item => item.userId === element.id);
          list.push({
            userId: element.id,
            realname: element.realname,
            isChecked: index === -1 ? false : true,
          })
          index === -1 && (checkedAll = false);
        })
        this.isChecked = checkedAll;
        this.tableData = list;
      } else {
        this.tableData = [];
      }
    },
    /**
     * @description: 选择好友
     * @param {value}:下标
     * @return {void}
     */
    chosefriend(value) {
      if (value.isChecked) {
        const index = this.friendList.findIndex(item => item.userId === value.userId);
        index !== -1 && this.friendList.splice(index, 1);
        value.isChecked = false;
        this.isChecked = false;
      } else {
        const index = this.friendList.findIndex(item => item.userId === value.userId);
        index === -1 && this.friendList.push(value);
        value.isChecked = true;
        let checkedAll = true;
        for (let index = 0; index < this.tableData.length; index++) {
          const element = this.tableData[index];
          if (!element.isChecked) {
            checkedAll = false;
            break;
          }
        }
        this.isChecked = checkedAll;
      }
    },
    /**
     * @description: 删除选择的好友
     * @param {value}:下标
     * @return {void}
     */
    deletechose(value, i) {
      this.isChecked = false
      this.friendList.splice(i, 1);
      const index = this.tableData.findIndex(item => item.userId === value.userId);
      if (index !== -1) {
        this.$set(this.tableData[index], "isChecked", false);
        this.isChecked = false;
      }
    },
    /**
     * @description: 获取好友分类树
     * @return {void}
     */
    async getTree() {
      this.loading = true;
      this.categoryTree = await Contacts.queryTreeList();
      this.loading = false;
      this.$nextTick(() => {
        if (this.currentCategory) {
          this.$refs.tree.setCurrentKey(this.currentCategory.id);
        } else {
          if (this.categoryTree.length) {
            this.currentCategory = this.categoryTree[0];
            this.$refs.tree.setCurrentKey(this.currentCategory.id);
            this.getTableData(this.currentCategory.sysUserDepartDtos)
          }
        }
      });
    },
    /**
     * @description: 全选/反选
     * @return {void}
     */
    checkAll() {
      this.isChecked = !this.isChecked;
      if (this.isChecked) {
        this.tableData.forEach(item => {
          item.isChecked = true;
          const index = this.friendList.findIndex(v => v.userId === item.userId);
          index === -1 && this.friendList.push(item);
        })
      } else {
        this.tableData.forEach(item => {
          item.isChecked = false;
          const index = this.friendList.findIndex(v => v.userId === item.userId);
          index !== -1 && this.friendList.splice(index, 1);
        })
      }
    },
    /**
     * @description: 确定
     * @return {void}
     */
    handleConfirm() {
      this.$emit("finish", this.friendList);
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.friends-title {
  width: fit-content;
  height: 24px;
  cursor: pointer;

  .friends-title__icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }

  .friends-title__label {
    margin-left: 5px;
    font-size: 14px;
    vertical-align: middle;
  }
}

.content {
  display: flex;

  .content-left {
    width: 322px;
    height: 370px;
    padding: 16px 22px 16px 40px;
    box-sizing: border-box;
    box-shadow: 2px 0px 6px 0px rgba(52, 69, 99, 0.08);
    display: flex;
    flex-direction: column;

    .search-input {
      ::v-deep(.el-input__inner) {
        border-color: #f5f7fa;
        border-radius: 10px !important;
        background-color: #f5f7fa;

        &:focus {
          border-color: var(--color-primary);
        }
      }
    }

    .tree-input {
      width: 240px;

      ::v-deep .el-input__inner {
        height: 42px;
        line-height: 42px;
        border: none;
        border-radius: 10px;
        background: #f5f7fa;
      }
    }

    .tree-name {
      padding: 20px 0 8px;
      display: flex;
      align-items: center;

      .tree-name__line {
        width: 2px;
        height: 14px;
        background: #abb4c4;
      }

      .tree-name__label {
        font-size: 14px;
        font-weight: 500;
        color: #344563;
        margin-left: 5px;
      }
    }

    .tree-container {
      flex: 1;
      overflow: auto;

      .tree {
        color: #344563 !important;

        ::v-deep .el-tree-node__content {
          height: 43px;
          border-radius: 5px;

          &:hover {
            background-color: #fdfdfe;
            color: #6A87FC !important;
          }
        }

        ::v-deep .is-current>.el-tree-node__content {
          background-color: #e6efff;
        }
      }
    }
  }

  .content-mid {
    box-sizing: border-box;
    width: 360px;
    padding: 20px 0 20px 20px;
    border-right: 1px solid #e6ebf2;

    .content-mid-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #8997b0;
      margin-left: 5px;
      margin-right: 26px;
      margin-bottom: 20px;

      .content-mid-top-check {
        display: flex;
        align-items: center;
        cursor: pointer;

        .content-mid-top-check__icon {
          width: 18px;
          height: 18px;
          background-image: url("@/assets/images/common/success.png");
          background-size: 18px 18px;
          background-repeat: no-repeat;
        }

        .content-mid-top-check__label {
          margin-left: 6px;
          user-select: none;
        }

        &.is-checked {
          color: #344563;

          .content-mid-top-check__icon {
            background-image: url("@/assets/images/common/success_colored.png");
          }
        }
      }
    }

    .content-mid-member {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .content-mid-member-item {
        width: fit-content;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        margin: 0 5px 10px 5px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #e6ebf2;
        position: relative;
        cursor: pointer;

        &:hover {
          background-color: #fcfdfe;
        }

        &.is-active {
          background-color: #d8f2f0;
          border-color: #00baad;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 11px;
            height: 8px;
            background-image: url("@/assets/images/common/check_tag.png");
            background-size: 11px 8px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .content-right {
    padding: 20px 0 20px 20px;

    .content-right-item {
      width: fit-content;
      box-sizing: border-box;
      height: 32px;
      padding: 0 8px 0 16px;
      border-radius: 5px;
      background: #f5f7fa;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        .content-right-item__icon {
          background-image: url("@/assets/images/common/close_red.png");
        }
      }

      &+.content-right-item {
        margin-top: 18px;
      }

      .content-right-item__label {
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-weight: 500;
        padding-right: 8px;
        max-width: 74px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content-right-item__icon {
        width: 16px;
        height: 16px;
        background-image: url("@/assets/images/common/close.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
    }
  }
}

.custom-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .summary {
    font-size: 14px;
    color: #8997b0;
    font-weight: 500;
  }

  .custom-footer-btn {
    width: 112px;
  }
}
</style>