<template>
  <div class="resource">
    <div class="close">

    </div>
    <div class="header">
      <div v-if="menuname!='文件资源'" class="header-search flex align-items-center flex-between">
        <el-input placeholder="请输入查询条件" v-model="queryData.keyWord" style="width: 400px; height: 32px" @keyup.enter.native="getList">
          <i @click="getList" slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <!-- <div class="header-filter"></div> -->
      </div>
    </div>
    <div class="container" v-if="menuname!='文件资源'">
      <div class="row" v-for="(box, index) in rows" :key="index">
        <div class="box" v-for="(item, boxIndex) in box" :key="boxIndex">
          <div class="content-item" :key="item.id" @click="handleClick(item)">
            <div class="content-item-wrapper" :class="{ collection: item.type === 2 }" >
              <div class="product-item pointer" :class="{'active-item':clickItem&&clickItem.id==item.id}">
                <!-- <div v-if="item.newFlag == 0" class="product-item-new"></div> -->
                <div class="product-item-img">
                  <img v-if="item.subjectCoverVos.length >0&&item.type === 1" class="w-h-100 border-5" :src="item.subjectCoverVos[0].coverUrl" :draggable="false">
                  <img v-if="item.subjectCoverVos.length === 0&&item.type === 1" class="w-h-100 border-5" :src="require(`@/assets/images/sample-${common.getLastChar(item.id)}.png`)"  :draggable="false">
              </div>
              <div class="product-item-name">{{ item.name }}</div>
              <div class="product-item-date">
                最新更新：{{ Object.dayjs(item.updateAt || item.createAt) }}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-pagination">
        <!-- <span class="my-pagination-tip">共 {{ pagingData.total }} 个内容，当前展示数量 {{ pagingData.records.length }} </span> -->
        <el-pagination background class="app-pagination" layout="prev, pager, next"
          :current-page="pagingData.current" :page-size="pagingData.size" :total="pagingData.total"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
    <div class="container h100" v-if="menuname=='文件资源'">
      <!-- <ColudDisk/> -->
    </div>
  </div>
</template>
<script>
import { ProductLibrary } from "@/api/ProductLibrary";
import { mapGetters } from 'vuex';
// import ColudDisk from './ColudDisk.vue';
export default {
  props: {
    menuid: String,
    menuname: String,
    // categoryId:String,
    productvisible: {
      type: Boolean,
      default: false
    },
  },
  // components: { ColudDisk },
  data() {
    return {
      rows: [], // 排列用
      subjectid: "",
      mirrorId: "",
      versionId: "",
      // 是否关联的弹窗
      keepVisible: false,
      whichIndex: -1,
      whichIndex2: 0,
      // value: "1",
      // 二级分类列表
      CategoryList: [],
      allcategoryList: [],
      breadcrumbList: [{ id: "-1", name: this.menuname }],
      tableData: [],
      tableList: [], //暂存所有列表的
      collectionId: "-1",
      queryData: {
        moduleId: this.menuid,
        categoryIds: [],
        sortByUpdateAtFlag: false,
        keyWord: "",
        sortDesc: "update_at", // 降序
        sortAsc: "", // 升序
        pageNo: 1,
        pageSize:6,
        shelfFlag: true,
        hideFlag:true,
        status:2
      },
      category: {
        "1665910853276692481": "product", // 内容类 => 产品库、解决方案
        "1665916043493371905": "product", // 文件资源类 => 资源管理
        "1665916134451048450": "stream", // 视频直播类 => 直播管理
        "1665916240717934594": "demonstration", // 教学教程类 => 演示类
        "1665916367629185026": "solution", // 更多分类
      },
      // 栏目列表
      dataList: [],
      loading: false, // 加载状态
      loadend: false, // 全部加载结束
      // value: "",
      // value2:"",
      Treevisible:false,
      itemdata:'',
      visible:false,
      pagingData: new this.common.Pagination(),
      clickItem:null
    };
  },
  computed: {
    moduleId: {
      get() {
        this.getCategoryList()
        return this.menuid;
      },
    },
    dialogVisible: {
      get() {
        return this.productvisible;
      },
      set(val) {
        this.$emit('update:productvisible', val);
      }
    },
    ...mapGetters(['userInfo', 'activeMenu']),
  },
  watch: {
    moduleId(val) {
      if(val=='file'){
        return
      }
      this.queryData.moduleId = val;
      this.breadcrumbList[0].name = this.menuname;
      this.queryData.pageNo = 1;
      this.loadend = false;
      this.tableList = [];
      // this.value=''; 
      this.clickItem=null  
      this.getList();
      
    },
    'queryData.keyWord': {
      handler(newVal, oldVal) {
        if(!newVal){
          this.keyWord=''
          this.getList()
        }  
      },
      deep: true // 设置为 true 以深度监听对象属性的变化  
    }
  },
  mounted() {
    if(this.menuid!='file'){
      this.getList();
      this.getCategoryList();
    }
    
  },
  methods: {
    /**
     * @description: 获取产品库分类列表
     * @return {void}
     */
    async getCategoryList() {
      this.allcategoryList = await ProductLibrary.getSubjectCategoryList({
        moduleId:this.menuid,
        tenantId: this.userInfo.tenantIdNow,
      });
      this.allcategoryList.push({
        id: "-1",
        categoryName: "全部",
        moduleId: this.menuid,
        privateFlag: 0,
        collectionCount: 0,
        children: []
      })
      this.CategoryList = this.allcategoryList[0].children
    },
    /**
     * @description: 获取产品列表
     * @return {void}
     */
    async getList() {
      if (this.collectionId && this.collectionId !== "-1") {
        // 根据合集id获取合集数据
        this.tableData = await ProductLibrary.getSubjectListByCollectId(
          this.collectionId
        );
      } else {
        const res = await ProductLibrary.getSubjectList(this.queryData);
        this.tableData = res.records;
        this.pagingData=res
      }
      this.tableData.forEach((element,index) => {
        if(element.id==this.$route.query.subjectId){
          this.tableData.splice(index,1)
        }
        element.cover = Math.floor(Math.random() * 3 + 1);
        element.collectNumber = 0;
        element.subjectNumber = 0;
        if (element.children && element.children.length) {
          const collectList = element.children.filter(
            (item) => item.type === 2
          );
          const subjectList = element.children.filter(
            (item) => item.type === 1&&item.id!=this.$route.query.subjectId
          );
          element.collectNumber = collectList.length;
          element.subjectNumber = subjectList.length;
        }
      });
      this.rows = this.splitIntoRows(this.tableData, 2);
    },
    /**
     * @description: 点击主题
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(value) {
      this.clickItem=value
    },
    /**
     * @description: 根据主题id获取详情
     * @return {void}
     */
    async getsubjectDetail(data) {
      let res = await ProductLibrary.getSubjectDetail(data);
      this.dataList = res.subjectColumnVos;
    },
    /**
     * @description: 栏目列表
     * @return {void}
     */
    addTopreview(value) {
      let data = {
        subjectId: value.id,
        mirrorId: value.mirrorId,
        versionId: value.versionId,
      };
      this.getsubjectDetail(data);
    },
    /**
     * @description:获取关联的主题
     * @return {void}
     */
    getlinkdata(value) {
      this.$emit("linkinfo", value);
    },
    /**
     * @description:两列
     * @return {void}
     */
    splitIntoRows(array, itemsPerRow) {
      const rows = [];
      let row = [];
      array.forEach((item, index) => {
        row.push(item);
        if ((index + 1) % itemsPerRow === 0 || index === array.length - 1) {
          rows.push(row);
          row = [];
        }
      });
      return rows;
    },
    /**
     * @description:添加关联弹窗
     * @param {Object} value 点击项
     * @return {void}
     */
    Addlink(value){
      // 演示类1665916240717934594，内容类1665910853276692481
      this.subjectid = value.id;
      this.mirrorId = value.mirrorId;
      this.versionId = value.versionId;
      this.visible=true
      this.itemdata=value
    },
    // 关联产品弹窗
    keepchose(item) {
      this.keepVisible = true;
      this.subjectid = item.id;
      this.mirrorId = item.mirrorId;
      this.versionId = item.versionId;
    },
    close(){
      this.$emit('close')
    },   
    /**
     * @description: currentPage 改变时会触发
     * @param {Number} value 当前页
     * @return {void}
     */
    handleCurrentChange(value) {
      this.queryData.pageNo = value;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.resource {
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  position: relative;
  .header {
    // display: flex;
    // justify-content: space-between;
    // padding: 0 18px;
    padding: 0 16px 0 18px;
    font-size: 14px;
    .header-name{
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      font-weight: 400;
    }
    .header-search{
      height: 32px;
      margin-bottom: 24px;
    }
    .header-filter{
      width: 32px;
      height: 32px;
      border-radius: 5px;
      background: #FFFFFF;
      border: 1px solid #D3DAE6;
      background-image: url("@/assets/images/productLibrary/filter.png");
      background-size: 16px 16px;
      background-position: center;
      background-repeat: no-repeat;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  .tab {
    display: flex;
    // justify-content: space-between;
    padding: 0 14px;
    margin-top: 26px;
    font-size: 14px;
    overflow: hidden;
    color: #8997b0;

    div {
      margin-right: 20px;
    }

    .active {
      color: #344563;
    }
  }

  .breadcrumb {
    padding: 26px 18px;
    color: #8997b0;
  }

  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  ::v-deep .el-input__icon {
    height: 32px;
    line-height: 32px;
  }
}


// 产品样式
.content-item {
  width: 200px;
  height: 200px;
  // padding: 8px;
  // margin: 1px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  // margin-top: 10px;
  // border: 1px solid #ebeef2;
  border-radius: 5px;
  background-color: #fff;

  .content-item-wrapper {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #fff;
    &:hover {
      z-index: 10;

      .product-item {
        .product-item-more {
          display: block;
        }
      }
    }

    .product-item {
      width: 200px;
      border-radius: 5px;
      position: relative;
      height: 100%;
      border: 1px solid #ebeef2;
      box-sizing: border-box;
      // .product-item-new {
      //   position: absolute;
      //   z-index: 10;
      //   top: 0;
      //   left: 0;
      //   width: 31px;
      //   height: 15px;
      //   background: url("@/assets/images/productLibrary/new_content.png");
      //   background-size: 31px 15px;
      //   background-repeat: no-repeat;
      // }

      .product-item-img {
        width: 100%;
        height: 120px;
        position: relative;

        .product-item-multi {
          width: 100%;
          height: 120px;
          padding-top: 6px;
          overflow: hidden;
          position: relative;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          .product-item-multi-empty {
            width: 118px;
            height: 101px;
            font-size: 16px;
            font-weight: 500;
            color: #ccc;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url("@/assets/images/productLibrary/folder.png");
            background-size: 118px 101px;
            background-repeat: no-repeat;
          }

          .product-item-multi-img {
            width: 100%;
            height: 126px;
          }

          .product-item-multi-container {
            width: 200px;
            height: 157px;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            .product-item-multi-img {
              width: 87px;
              height: 67px;
              // margin: 11px 10px 0 0;
            }
          }

          .product-item-tags {
            z-index: 10;
            right: -3px;
            bottom: 4px;
            width: 223px;
          }
        }

        .product-item-tags {
          position: absolute;
          z-index: 10;
          width: 200px;
          // bottom: 4px;
          // right: 6px;
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: wrap-reverse;

          .product-item-tag {
            // width: fit-content;
            padding: 4px 8px;
            margin: 3px;
            border-radius: 2px;
            background: rgba(0, 186, 173, 0.8);
            font-size: 12px;
            font-weight: 500;
            color: #fff;
          }
        }
      }

      .product-item-name {
        height: 40px;
        line-height: 18px;
        font-size: 14px;
        font-weight: 700;
        color: #344563;
        // background-color: #fff;
        padding: 18px 20px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .product-item-date {
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        font-weight: 500;
        color: #8997b0;
        // background-color: #fff;
        padding: 8px 14px 0;
      }
    }
    .active-item{
      border: 2px solid #8B6BFF;
      &::before{
        content: '';
        z-index: 2;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 30px;
        height: 21px;
        background-image: url("@/assets/images/productLibrary/template_checked.png");
        background-size: 30px 21px;
        background-repeat: no-repeat;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #8997b0;
}

.container {
  width: 100%;
  // padding: 0 18px;
  padding: 0 8px 0 18px;
  box-sizing: border-box;
  // background-color: #ccc;
  overflow: scroll;
  height: calc(100% - 140px);
}

.h100{
  height: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.box {
  width: 200px;
  height: 200px;
}
.my-pagination{
  width: 100%;
  overflow-x: auto;
  position: absolute;
  bottom: 26px;
  left: 50%;
  padding: 0 10px;
  box-sizing: border-box;
  transform: translateX(-50%);
  ::v-deep .el-pager {
    li {
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
      border: 1px solid #d3dce6;
    }

    .number {
      font-size: 12px;
      font-weight: 500;
      color: #586677;
      background-color: #fff;

      &:hover {
        // background: #dbeeff;
        border-color: #7948EA;
      }
    }

    li:not(.disabled).active {
      background: var(--color-primary);
      border-color: #7948EA;
    }
  }
}

</style>