import request from "@/utils/appServer";
class Logging {
  // 产品操作日志列表查询
  static async getLogs(data) {
    try {
      const res =  await request.post('/sales-show-4.0/businessOperations/log/queryList',data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return []
    }
  }
  // 产品操作日志列表查询-分页
  static async queryPageList(data) {
    try {
      const res =  await request.post('/sales-show-4.0/businessOperations/log/queryPageList',data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return []
    }
  }
}

export { Logging }