import { render, staticRenderFns } from "./GlobalView.vue?vue&type=template&id=a082deee&scoped=true"
import script from "./GlobalView.vue?vue&type=script&lang=js"
export * from "./GlobalView.vue?vue&type=script&lang=js"
import style0 from "./GlobalView.vue?vue&type=style&index=0&id=a082deee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a082deee",
  null
  
)

export default component.exports