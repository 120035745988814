<template>
  <div class="header-backtool">
    <i class="el-icon-arrow-left pointer" @click="handleBack">返回</i>
    <el-divider class="el-divider" direction="vertical"></el-divider>
    <i class="el-icon-s-home home-icon pointer" @click="handleBackHome"></i>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Users } from "@/api/Users";
import Mousetrap from "mousetrap";
export default {
  props: {
    currentpayload: Object,
  },
  data() {
    return {
      keyresult: "", //快捷键
    };
  },
  computed: {
    ...mapGetters(["token", "userInfo", "menuList", "sidesmenulist"]),
  },
  mounted() {
    this.getKey();
  },
  beforeDestroy() {
    Mousetrap.unbind(this.keyresult);
  },
  methods: {
    handleBack() {
      if (this.currentpayload) {
        this.$emit("handleback");
      } else {
        this.$router.back();
      }
    },
    /**
     * @description: 返回首页
     * @return {void}
     */
    handleBackHome() {
      this.$router.push("/hometable");
      // if (this.$route.params.id == this.menuList[0].id) {
      //   this.$emit("handleback");
      //   return;
      // }
      // const url = `/${this.sidesmenulist[0].moduleEntityList[0].url}/${this.sidesmenulist[0].moduleEntityList[0].id}`
      // this.$router.push(url);
      // this.$store.commit("saveActiveMenu", this.menuList[0]);
    },
    /**
     * @description: 获取设置的快捷键
     * @return {void}
     */
    async getKey() {
      const res = await Users.getShortcutKey();
      let hotkey;
      if (res && res.length) {
        hotkey = res.filter((item) => item.keyType == 3);
      }
      if (hotkey && hotkey[0]) {
        let result = hotkey[0].key1;
        if (hotkey[0].key2) {
          result += "+" + hotkey[0].key2;
        }
        if (hotkey[0].key3) {
          result += "+" + hotkey[0].key3;
        }
        this.keyresult = result;
      } else {
        this.keyresult = "ctrl+q"; //未设置时给个默认值
      }
      Mousetrap.bind(this.keyresult, this.onCtrlSShortcut);
    },
    /**
     * @description: 触发快捷键操作
     * @return {void}
     */
    onCtrlSShortcut(event) {
      event.preventDefault(); // Prevent the default browser action
      this.handleBackHome();
    },
  },
};
</script>
<style lang="scss" scoped>
.header-backtool {
  color: #fff;
  margin-right: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  // font-size: 12px;
  .home-icon {
    font-size: 16px;
  }
}

.el-divider {
  background-color: rgba(255, 255, 255, 0.4);
}
</style>
