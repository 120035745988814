<template>
  <div
    class="demo-contain demo w-h-100 flex-colomn"
    v-loading="loading"
    :class="{ 'unit-preview': unitqueryids&&showheader }"
  >
    <div
      v-if="showheader"
      class="w-100 app-edit-header"
      :class="{ 'app-preview-header': isPreview && !titlefixed }"
    >
      <div class="header-firstfloor flex align-items-center">
        <div class="w-h-100 flex align-items-center">
          <ProductBack @handleback="handleBack" :currentpayload="unitqueryids" />
          <div class="header-title flex align-items-center">
            <img :src="coverList[0] && coverList[0].coverUrl" alt="" />
            <!-- <el-upload accept=".jpg,.jpeg,.png" action="" ref="upload" :auto-upload="false" :multiple="false"
                :on-change="onChange" :show-file-list="false">
                <div class="cover-tip"></div>
              </el-upload> -->
            <div
              class="header-name one-line preview-name"
              v-if="outlinedata && !isPreview"
            >
              <ProductName :name.sync="outlinedata.subjectName" :subject="outlinedata" />
            </div>
            <div
              class="header-name one-line fz-12 preview-name"
              v-if="outlinedata && isPreview"
              :title="outlinedata.subjectName"
            >
              {{ outlinedata.subjectName }}
            </div>
          </div>
        </div>
        <div class="fixed-oprate flex-between align-items-center" v-if="!readonly">
          <ProductStatusButton @statuschange="handlestatusChange" />
          <ProductHeaderHide v-if="isPreview" :titlefixed.sync="titlefixed" />
        </div>
      </div>
      <!-- <div class="header-interlayer"></div> -->
      <div class="header-interlayer-tools flex align-items-center" v-if="!isPreview">
        <div
          v-if="acceptType.length"
          class="header-interlayer-tools-right flex align-items-center"
          @click="showrightbox = !showrightbox"
          :class="{
            'tool-active': showrightbox,
          }"
        >
          <svg-icon icon="edit-fun" color="#344563" style="margin-left: 5px"></svg-icon>
        </div>
      </div>
    </div>
    <div class="flex app-scheme-wrapper comprehensive-scheme-wrapper">
      <SplitPane :initialLeftWidth="showdemo ? 300 : 0">
        <template v-slot:left>
          <div class="scheme-title flex-between align-items-center" v-show="showdemo">
            <div class="scheme-title-name">演示提纲</div>
            <div class="flex align-items-center">
              <svg-icon
                class="mr-10 pointer"
                icon="close-side"
                color="#5E6C84"
                :height="16"
                :width="16"
                @click.native="showdemo = false"
              ></svg-icon>
            </div>
          </div>
          <MindView
            style="height: calc(100% - 31px)"
            @editornode="editornode"
            :clickoutlineid="clickoutlineid"
            :ispreview="isPreview"
            :query-ids="queryIds"
            v-if="queryIds"
          />
        </template>
        <template v-slot:right>
          <div class="w-h-100 app-scheme-detail">
            <div v-if="outlinedata" class="app-scheme-main demo-main">
              <svg-icon
                v-if="!showdemo"
                class="retract-icon pointer"
                icon="mind-close"
                color="#5E6C84"
                :height="36"
                :width="48"
                @click.native="showdemo = true"
              ></svg-icon>
              <ContentView
                v-if="
                  activeNode &&
                  (activeNode.data.outlineType == 1 ||
                    activeNode.data.outlineType == 0 ||
                    activeNode.isroot)
                "
                :nodeid="clickoutlineid"
                :ispre="isPreview"
              />
              <ProductView
                v-if="activeNode && activeNode.data.outlineType == 2"
                :nodeid="clickoutlineid"
                :demoId="outlinedata.id"
                :ispre="isPreview"
              />
              <ColumnView
                v-if="activeNode && activeNode.data.outlineType == 3"
                :nodeid="clickoutlineid"
                :demoId="outlinedata.id"
                :ispre="isPreview"
              />
              <ArticleView
                v-if="activeNode && activeNode.data.outlineType == 4"
                :nodeid="clickoutlineid"
                :demoId="outlinedata.id"
                :ispre="isPreview"
                :active-node="activeNode"
                :type="2"
              />
              <LinkView
                v-if="activeNode && activeNode.data.outlineType == 5"
                :nodeid="clickoutlineid"
                :demoId="outlinedata.id"
                :query-ids="queryIds"
                :ispre="isPreview"
              />
              <FileView
                v-if="activeNode && activeNode.data.outlineType == 6"
                :nodeid="clickoutlineid"
                :demoId="outlinedata.id"
                :query-ids="queryIds"
                :ispre="isPreview"
                :active-node="activeNode"
              />
            </div>
            <NavigationTool
              v-if="outlinedata && isPreview"
              :nodeid="clickoutlineid"
              :onlineTree="outlinedata.showProjectOutlineVos"
            />
          </div>
        </template>
      </SplitPane>
      <div
        class="association-library-wrapper"
        v-if="!isPreview && showrightbox && acceptType.length"
      >
        <Toolcenter :accept="acceptType" :selectids="selectids" />
      </div>
    </div>
    <LogsView v-if="showLogs" :current="outlinedata" :visible.sync="showLogs" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import API from "@/utils/apiRequest";
import MindView from "./component/MindView.vue";
import TinyMce from "@/components/TinyMce.vue";
import { Playeditor } from "@/api/Playeditor";
import { ProductLibrary } from "@/api/ProductLibrary";
import ProductName from "@/components/ProductName.vue";
import ContentView from "../../views/DemoMode/component/ContentView.vue";
import ColumnView from "../../views/DemoMode/component/ColumnView.vue";
import ArticleView from "../../views/DemoMode/component/ArticleView.vue";
import LinkView from "../../views/DemoMode/component/LinkView.vue";
import ProductView from "../../views/DemoMode/component/ProductView.vue";
import FileView from "../../views/DemoMode/component/FileView.vue";
import SplitPane from "@/components/SplitPane.vue";
import Toolcenter from "../../components/ResourceTool/Toolcenter.vue";
import LogsView from "../../components/LogsView.vue";
import ProductBack from "../../components/Product/ProductBack.vue";
import ProductStatusButton from "@/components/Product/ProductStatusButton.vue";
import NavigationTool from "./component/NavigationTool.vue";
import ProductHeaderHide from "@/components/Product/ProductHeaderHide.vue";

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false, //是否只读
    },
    unitqueryids: Object,
    showheader: {
      type: Boolean,
      default: true,
    },//是否显示页头
    visible: {
      type: Boolean,
      default: false,
    },
    previewtype: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MindView,
    TinyMce,
    ProductName,
    ContentView,
    ColumnView,
    ArticleView,
    LinkView,
    ProductView,
    SplitPane,
    Toolcenter,
    LogsView,
    FileView,
    ProductBack,
    ProductStatusButton,
    NavigationTool,
    ProductHeaderHide,
  },
  data() {
    return {
      loading: false,
      moduleId: "",
      // queryIds: {
      //   // // moduleId: "",
      //   // subjectId: "",
      //   // versionId: "",
      // },
      queryIds: null,
      clickoutlineid: "", // 点击的节点id
      demoList: [], //演示模式列表
      activeDemo: null,
      ispre: false,
      showdemo: true,
      activeNode: null,
      coverList: [], // 封面列表
      outlinedata: null, //方案详情
      acceptType: [5],
      showrightbox: true,
      selectids: [],
      pubsubToken: [],
      showLogs: false,
      isPreview: false,
      current: null,
      titlefixed: false, //是否固定页头
    };
  },
  computed: {
    // isPreview() {
    //   return this.readonly;
    // },
    ...mapGetters(["moduleTypeDict", "hasNopowerList", "Viewstatus"]),
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {},
  mounted() {
    this.isPreview =
      this.$route.fullPath.includes("playeditorpreview") || this.previewtype;
    this.isPreview && (this.showdemo = false);
    if (this.unitqueryids) {
      this.queryIds = {
        subjectId: this.unitqueryids.subjectId,
        versionId: this.unitqueryids.versionId,
        mirrorId: this.unitqueryids.mirrorId,
      };
      this.current = this.unitqueryids;
    } else {
      this.queryIds = {
        subjectId: this.$route.query.subjectId,
        versionId: this.$route.query.versionId,
        mirrorId: this.$route.query.mirrorId,
      };
      this.current = this.$route.query;
    }
    this.getsubjectDetail();
    this.pubsubToken.push(
      // 发布一个更新资源选择id的方法
      this.$Pubsub.subscribe("update-resource-select-data", (val) => {
        this.selectids = val;
      }),
      // 发布一个资源库显示隐藏的方法
      this.$Pubsub.subscribe("update-resource-visible", (val) => {
        if (val.type) {
          this.acceptType = val.type;
        }
        this.showrightbox = val.visible;
      })
    );
  },
  beforeDestroy() {
    this.pubsubToken.forEach((token) => {
      this.$Pubsub.unsubscribe(token);
    });
    this.pubsubToken = [];
  },
  watch: {},
  methods: {
    /**
     * @description:  获取演示详情
     * @return {void}
     */
    async getsubjectDetail() {
      this.loading = true;
      let res = await Playeditor.getShowProjectDetail(this.queryIds);
      this.outlinedata = res;
      this.coverList = res.subjectCovers;
      if (this.coverList.length) {
        this.coverList.forEach((element) => (element.type = "success"));
      } else {
        const index = this.common.getLastChar(res.id);
        const objectURL = await this.common.convertImageToBase64(
          require(`@/assets/images/sample-${index}.png`)
        );
        this.coverList.push({
          coverType: 1,
          coverUrl: objectURL,
          id: Date.now(),
          file: { raw: this.common.dataURLtoFile(objectURL, "默认封面.png") },
          type: "ready",
        });
      }
      this.loading = false;
    },
    /**
     * @description:  编辑提纲
     * @param {Object} value 接收的编辑类型及节点信息
     * @param {Object} node 接收的节点详情
     * @return {void}
     */
    editornode(value, node) {
      if (value.evt === "select_node") {
        this.clickoutlineid = value.node;
        this.activeNode = node;
      }
      if (
        value.evt === "insert_node_after" ||
        value.evt === "add_node" ||
        value.evt === "remove_node"
      ) {
        this.getsubjectDetail();
      }
    },
    /**
     * @description: 发布
     * @return {void}
     */
    async handlePublish() {
      await ProductLibrary.updateShelfFlag({
        subjectId: this.outlinedata.id,
        ShelfFlag: 1,
      });
    },
    /**
     * @description: 返回
     * @return {void}
     */
    goback() {
      if (this.current.moduleId) {
        this.$router.push(`/demonstration/${this.current.moduleId}`);
      } else {
        this.$router.back();
      }
    },
    /**
     * @description:  新开一个编辑页面
     * @return {void}
     */
    goEdit() {
      const routeData = this.$router.resolve({
        path: "/playeditor",
        query: this.queryIds,
      });
      window.open(routeData.href);
    },
    handleBack() {
      this.dialogVisible = false;
      this.$emit("close");
    },
    /**
     * @description: 编辑/展示状态切换
     * @return {void}
     */
    handlestatusChange(val) {
      if (this.readonly) {
        this.handleNavigate(this.outlinedata.showProjectOutlineVos[0].id);
        this.isPreview = true;
        this.showdemo = false;
      } else {
        this.isPreview = val != 3 ? true : false;
        this.showdemo = !this.isPreview;
      }
    },
    /**
     * @description: 节点选择
     * @return {void}
     */
    handleNavigate(id) {
      this.$Pubsub.publish("update-mind-select-data", id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/demo.scss";
@import "~@/scss/common.scss";

.app-scheme-wrapper {
  background-color: #fff;
}

.flex-colomn {
  display: flex;
  flex-direction: column;
}

.comprehensive-scheme-wrapper {
  width: 100%;
  // height: calc(100% - 82px);
  flex: 1;
  overflow: hidden;
}

.demo-main {
  flex: 1;
  position: relative;
  overflow-x: auto;
  // height: 100%;
  width: 100% !important;
  // border-left: 1px solid #d3dbeb;
}

.app-scheme-detail {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d3dbeb;
  overflow: hidden;
}

.retract-icon {
  position: absolute;
  left: -18px;
  top: 50%;
  line-height: 22px;
  color: var(--color-text-primary);
  text-align: right;
  z-index: 3;
}

.oprate-btn {
  height: 100%;
  line-height: 40px;
  color: #fff;
  width: 88px;
  text-align: center;
  flex-shrink: 0;
  position: relative;

  .logs-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1px;
    height: 14px;
    transform: translateY(-50%) translateX(-50%);
  }
}

.oprate-right {
  &:hover {
    background-color: rgba(184, 153, 255, 0.2);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 1px;
    height: 14px;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.oprate-hover {
  &:hover {
    background-color: rgba(184, 153, 255, 0.2);
  }
}

.association-library-wrapper {
  height: 100%;
  width: 323px;
  border-left: 1px solid #ebeef2;
  background-color: #fff;
  flex-shrink: 0;
}

.scheme-title {
  height: 30px;
  border-bottom: 1px solid #d3dbeb;

  .scheme-title-name {
    margin-left: 14px;
    font-size: 12px;
    font-weight: 400;
  }
}

.app-preview-header {
  position: absolute;
  top: 0;
  height: 8px;
  width: 100%;
  opacity: 0;
  z-index: 10;
  overflow: hidden;
  transition: height 0.3s ease;

  &:hover {
    position: relative;
    opacity: 1;
    height: 40px;
  }
}

.fz-12 {
  font-size: 12px !important;
}

.unit-preview {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
}

.mr-10 {
  margin-right: 10px;
}
</style>
