import request from "@/utils/appServer";
import common from "../utils/common"

class ProductLibrary {
  // 模块分类列表
  static async getCategoryList() {
    try {
      const res = await request.get('/sales-show-4.0/module/category/queryList', {});
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 新增、编辑模块分类
  static async updateCategory(url, data) {
    try {
      const res = await request.post('/sales-show-4.0/module/category/' + url, data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 新增、编辑模块
  static async updateModule(url, data) {
    try {
      const res = await request.post('/sales-show-4.0/module/' + url, data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取主题分类列表
  static async getSubjectCategoryList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/category/querySubjectCategoryList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 保存主题分类
  static async saveSubjectCategory(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/category/saveSubjectCategory', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 主题分类 - 新增、编辑
  static async updateSubjectCategory(type, data) {
    try {
      const res = await request[type](`/sales-show-4.0/subject/category/${type === 'post' ? 'add' : 'edit'}SubjectCategory`, data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 主题分类 - 删除
  static async deleteSubjectCategory(data) {
    try {
      const res = await request.delete('/sales-show-4.0/subject/category/deleteSubjectCategoryById', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 新增、编辑栏目
  static async updateColumn(url, data) {
    try {
      const res = await request.post(`/sales-show-4.0/subject/${url}Column`, data, true);
      return res.data.code === 200 ? (url === 'add' ? res.data.result : true) : false;
    } catch (error) {
      return false;
    }
  }
  // 编辑栏目
  static async editColumn(data) {
    try {
      const res = await request.post(`/sales-show-4.0/subject/editColumn`, data);
      return res.data.code === 200 ? (url === 'add' ? res.data.result : true) : false;
    } catch (error) {
      return false;
    }
  }
  // 删除栏目
  static async deleteColumn(data) {
    try {
      const res = await request.post("/sales-show-4.0/subject/deleteColumn", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }

  // 创建、编辑合集
  static async updateCollect(url, data) {
    try {
      const res = await request.post(`/sales-show-4.0/collect/${url}Collect`, data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除合集
  static async deleteCollect(data) {
    try {
      const res = await request.get('/sales-show-4.0/collect/deleteCollect', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 修改合集分类
  static async editCollectCategory(data) {
    try {
      const res = await request.post('/sales-show-4.0/collect/editCollectCategory', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 移动合集到合集或最外层
  static async moveCollect(data) {
    try {
      const res = await request.get('/sales-show-4.0/collect/moveCollect', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 查询集合列表
  static async getCollectList(data) {
    try {
      const res = await request.post('/sales-show-4.0/collect/queryCollectList', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 查合集内所有主题
  static async querySubjectListByCollectionId(collectionId) {
    try {
      const res = await request.get('/sales-show-4.0/subject/querySubjectListByCollectionId', { params: { collectionId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }

  // 获取产品列表
  static async getSubjectList(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/querySubjectListByModuleId', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 筛选产品列表
  static async selectSubjectList(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/selectSubjectList', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 根据合集id获取合集数据（有更改）
  static async getSubjectListByCollectId(collectionId) {
    try {
      const res = await request.get('/sales-show-4.0/subject/querySubjectListByCollectionId', { params: { collectionId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }

  // 选择模板新增主题
  static async addSubject(url, data) {
    try {
      const res = await request.post(`/sales-show-4.0/subject/${url}Subject`, data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除主题镜像
  static async deleteSubject(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/deleteSubjectMirror', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 编辑主题
  static async editSubject(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/editSubject', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 修改主题分类
  static async editSubjectCategory(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/editSubjectCategory', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 复制主题镜像到集合
  static async copySubjectToCollect(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/copySubjectToCollect', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 移动主题到集合或最外层
  static async moveSubjectToCollect(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/moveSubjectToCollect', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 查看主题详情
  static async getSubjectDetail(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/getSubjectDetail', data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 查看主题详情(未登录)
  static async getSubjectDetailNoLogin(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/getSubjectDetailNoLogin', data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 通过分享id查看主题详情-无需安全认证
  static async getDetailByShareIdNOCheck(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getDetailByShareIdNOCheck', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 通过分享id查看主题详情-指定访问码
  static async getDetailByShareIdNeedCode(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getDetailByShareIdNeedCode', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 通过分享id查看主题详情-需要登录系统
  static async getDetailByShareIdNeedLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getDetailByShareIdNeedLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 产品上架下架
  static async updateShelfFlag(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/updateShelfFlag', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 产品隐藏显示
  static async updateHideFlag(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/updateHideFlag', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 根据主题原始版本id查询版本信息
  static async getVersions(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getVersions', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 标记主版本
  static async updateMasterFlag(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/updateMasterFlag', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 标记显示版本
  static async updateViewVersionFlag(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/updateViewVersionFlag', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除版本
  static async deleteSubjectVersion(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/deleteSubjectVersion', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }


  // 主题模板 - 新增
  static async addTemplate(data) {
    try {
      const res = await request.post('/sales-show-4.0/template/add', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }

  // 查询标签
  static async getTagList(moduleId) {
    try {
      const res = await request.get('/sales-show-4.0/tag/queryList', { params: { moduleId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 新增、编辑标签
  static async updateTag(url, data) {
    try {
      const res = await request.post(`/sales-show-4.0/tag/${url}Tag`, data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 新增、编辑标签
  static async deleteTag(tagId) {
    try {
      const res = await request.get("/sales-show-4.0/tag/deleteTag", { params: { tagId } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 修改主题、合集标签
  static async editSubjectTag(data) {
    try {
      const res = await request.post("/sales-show-4.0/subject/editSubjectTag", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 全局搜索产品与合集
  static async queryAll(name) {
    try {
      const res = await request.get("/sales-show-4.0/subject/queryAll", { params: { name } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return false;
    }
  }
  // 查询搜索历史
  static async getHistoryList() {
    try {
      const res = await request.get("/sales-show-4.0/search/history/list");
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return false;
    }
  }
  // 删除搜索历史
  static async deleteHistory(id) {
    try {
      const res = await request.get("/sales-show-4.0/search/history/delete", { params: { id } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return false;
    }
  }
  //创建空白模板
  static async addBlankSubject(data) {
    try {
      const res = await request.post("/sales-show-4.0/subject/addBlankSubject", data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  //主题配置栏目
  static async settingColumn(data) {
    try {
      const res = await request.post("/sales-show-4.0/subject/settingColumn", data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  //获取主题设置栏目列表
  static async getSettingSubjectColumnList(subjectVersionId) {
    try {
      const res = await request.get("/sales-show-4.0/subject/getSettingSubjectColumnList", { params: { subjectVersionId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  //后台管理-查产品——分类列表
  static async queryBackendSubjectListByModuleId(data) {
    try {
      const res = await request.post("/sales-show-4.0/subject/queryBackendSubjectListByModuleId", data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  //后台管理-设置主题分类是否显示
  static async settingSubjectCategory(data) {
    try {
      const res = await request.post("/sales-show-4.0/subject/category/settingSubjectCategory", data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取产品列表数量
  static async getSubjectListNumber(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/querySubjectListByModuleId', data);
      return res.data.code === 200 && res.data.result ? res.data.result.total : 0;
    } catch (error) {
      return 0;
    }
  }
  // 获取主题设置栏目内容详情
  static async getSettingSubjectColumnContent(subjectColumnId) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getSettingSubjectColumnContent', { params: { subjectColumnId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取主题设置栏目内容详情(非登录)
  static async getSettingSubjectColumnContentNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getSettingSubjectColumnContentNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取主题栏目子栏目列表
  static async getSubjectColumnChildList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getSubjectColumnChildList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 获取主题栏目子栏目列表(非登录)
  static async getSubjectColumnChildListNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getSubjectColumnChildListNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 保存内容类子栏目内容
  static async saveChildColumnContent(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/saveChildColumnContent', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 保存产品关联文章
  static async saveSubjectToArticle(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/saveSubjectToArticle', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取主题关联文章列表
  static async getSubjectToArticleList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getSubjectToArticleList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取主题关联文章列表(非登录)
  static async getSubjectToArticleListNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getSubjectToArticleListNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 删除产品关联文章
  static async deleteSubjectToArticle(id) {
    try {
      const res = await request.post('/sales-show-4.0/subject/deleteSubjectToArticle?id=' + id);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 移动排序产品关联文章
  static async moveSubjectToArticle(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/moveSubjectToArticle', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }

  // 保存产品关联内容
  static async saveSubjectToContent(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/saveSubjectToContent', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取主题关联内容列表
  static async getSubjectToContentList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getSubjectToContentList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 删除产品关联内容
  static async deleteSubjectToContent(id) {
    try {
      const res = await request.post('/sales-show-4.0/subject/deleteSubjectToContent?id=' + id, null, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }

  // 保存产品关联链接
  static async saveSubjectToLink(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/saveSubjectToLink', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 编辑链接
  static async editLink(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/editLink', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取产品栏目链接列表
  static async queryLinkPageList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/queryLinkPageList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 获取产品栏目链接列表(非登录)
  static async queryLinkPageListNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/queryLinkPageListNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }

  // 删除演示链接
  static async deleteSubjectDemoLink(demoLinkId) {
    try {
      const res = await request.delete('/sales-show-4.0/subject/deleteSubjectDemoLink', { params: { demoLinkId } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }

  // 新增展示方案add/编辑展示方案edit
  static async UpdateScheme(url, data) {
    try {
      const res = await request.post(`/sales-show-4.0/subject/display/scheme/${url}`, data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除展示方案
  static async SchemeDelete(id) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/delete', { params: { id } });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 查询展示方案
  static async SchemequeryList(subjectId) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/queryList', { params: { subjectId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 查询展示/演示方案(非登录)
  static async SchemequeryListNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/queryListNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 展示方案关联数据
  static async Schemeaddrelate(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/display/scheme/to/add', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 展示方案批量关联数据
  static async addrelateBatch(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/display/scheme/to/addBatch', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }

  // 获取方案关联文章列表
  static async getSchemeToArticleList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeToArticleList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取方案关联文章列表(非登录)
  static async getSchemeToArticleListNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeToArticleListNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取方案关联内容列表
  static async getSchemeToContentList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeToContentList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取方案关联链接列表
  static async getSchemeToLinkList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeToLinkList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取方案关联链接列表(非登录)
  static async getSchemeToLinkListNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeToLinkListNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取产品下方案文件列表
  static async queryFileBySubjectScheme(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/queryFileBySubjectScheme', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取产品下方案文件列表(没有token的)
  static async queryFileBySubjectSchemeNoToken(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/queryFileBySubjectSchemeNoToken', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取方案栏目下子栏目列表
  static async getSchemeSubjectColumnChildPageList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeSubjectColumnChildPageList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 获取方案栏目下子栏目列表(非登录)
  static async getSchemeSubjectColumnChildPageListNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeSubjectColumnChildPageListNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 根据方案id查询绑定的栏目
  static async getSchemeSubjectColumnList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeSubjectColumnList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 根据方案id查询绑定的栏目(非登录)
  static async getSchemeSubjectColumnListNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/display/scheme/getSchemeSubjectColumnListNoLogin', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 保存栏目分类
  static async saveCategory(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/column/type/saveCategory', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 编辑分类
  static async editCategory(data) {
    try {
      const res = await request.post('/sales-show-4.0/subject/column/type/editCategory', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 根据方案id查询绑定的栏目
  static async columnTypequeryList(columnId) {
    try {
      const res = await request.get('/sales-show-4.0/subject/column/type/queryList', { params: { columnId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 主题栏目分类 - 删通过id删除分类
  static async deleteCategoryById(categoryId) {
    try {
      const res = await request.delete('/sales-show-4.0/subject/column/type/deleteCategoryById', { params: { categoryId } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 移动产品前获取产品所在类似模块
  static async getMoveProductModule(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/getMoveProductModule', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 移动产品模块
  static async moveModule(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/moveModule', { params: data });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 栏目排序
  static async moveSubjectSort(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/moveSubjectSort', { params: data });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
}

export { ProductLibrary }