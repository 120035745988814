<template>
  <el-dialog
    append-to-body
    class="ss-dialog no-header"
    width="556px"
    :close-on-click-modal="true"
    :visible.sync="dialogVisible"
  >
    <div class="content">
      <el-input
        v-model="queryData.value"
        placeholder="请输入名称"
        @keyup.enter.native="handleSelect"
        clearable
      >
        <i
          style="font-size: 18px; color: #5e6c84"
          slot="prefix"
          class="el-input__icon el-icon-search"
        ></i>
      </el-input>
      <div class="content-result flex-between align-items-center">
        <div class="content-result-items" v-for="i in tableData" :key="i.id">
          <template v-if="!accept.includes(5)">
            <img
              class="content-result-items-cover"
              :src="
                i.subjectCoverVos[0]
                  ? i.subjectCoverVos[0].coverUrl
                  : require(`@/assets/images/sample-${common.getLastChar(i.id)}.png`)
              "
              alt=""
            />
            <div class="content-result-items-info">
              <div style="line-height: 24px" class="one-line">{{ i.subjectName }}</div>
              <div class="info-second">{{ i.updateAt }}</div>
            </div>
          </template>
          <template v-if="accept.includes(5)">
            <img
              class="content-result-items-cover"
              :src="
                i.coverList[0]
                  ? i.coverList[0].coverUrl
                  : require(`@/assets/images/sample-1.png`)
              "
              alt=""
            />
            <div class="content-result-items-info">
              <div style="line-height: 24px" class="one-line">{{ i.articleName }}</div>
              <div class="info-second">{{ i.updateAt }}</div>
            </div>
          </template>
          <div class="content-result-items-oprate">
            <el-tooltip class="item" effect="dark" content="添加" placement="top">
              <div
                v-if="!selectids.includes(i.id)"
                class="oprate"
                @click="handleClick(i)"
              >
                <svg-icon icon="arrow-long-right" :height="16" :width="16"></svg-icon>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="unknown">
          <img
            v-if="!tableData.length"
            class="content-result-unknown"
            src="@/assets/images/productLibrary/unknown.png"
            alt=""
          />
          <div v-if="!tableData.length" style="color: #bfcade">
            暂无数据
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import { Module } from "@/api/Module";
import { Esengine } from "@/api/Esengine";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectids: Array,
    accept: Array,
  },
  data() {
    return {
      queryData: {
        isFile: 0, //查询类型(0:非文件,1:文件)
        value: "",
        moduleId: "",
        pageNo: 1,
        pageSize: 99,
        sortType: "desc",
      },
      menuList: [],
      tableData: [],
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["activeMenu", "Moduleclassification"]),
  },
  mounted() {
    if (!this.accept.includes(5)) {
      this.getModuleList();
    }
  },
  methods: {
    handleClick(value) {
      // value.moduleName = "";
      this.$store.commit("SaveseleceassData", value);
      this.dialogVisible = false;
    },
    /**
     * @description: 获取模块列表
     * @return {void}
     */
    async getModuleList() {
      const res = await Module.getModuleList();
      let list = [];
      res.forEach((element) => {
        element.moduleEntityList.forEach((item) => {
          // item.total = 0;
          list.push(item);
        });
      });
      // 这里过滤掉文章的
      if (list.length) {
        // const accept = [1, 2]; //需要显示的模块类型"1"内容类 "2"演示方案类 "3"文件资源类 "4"教学教程类 "5"文章类
        this.menuList = [];
        let selectType = this.Moduleclassification.filter((item) =>
          this.accept.includes(item.type)
        );
        selectType = selectType.map((item) => item.id);
        this.menuList.forEach((item) => {
          const type = this.Moduleclassification.filter(
            (menu) => menu.id == item.categoryId
          );
          type.length && (item.categoryType = type[0].type);
        });
        this.menuList = list.filter((item) => selectType.includes(item.categoryId));
      }
    },
    /**
     * @description: 选择搜索建议
     * @return {void}
     */
    async handleSelect() {
      if (!this.accept.length) {
        return;
      }
      if (!this.queryData.value.trim()) {
        return this.$message.warning("搜索名称不能为空！");
      }
      this.loading = true;
      const res = await Esengine.boolQueryHighlight(this.queryData);
      if (this.accept.includes(5)) {
        this.tableData = res.articleMap.data;
      } else {
        let sumData = this.matchDataWithKey(this.menuList, res);
        sumData.forEach((item) => {
          item.searchValue.data.forEach((child) => {
            child.moduleName = item.moduleName;
            child.name = child.subjectName;
          });
        });
        let list = sumData.map((item) => item.searchValue.data);
        this.tableData = list.flat();
      }
    },
    /**
     * @description: 将获取的搜索结果和模块进行匹配
     * @param {Array} dataArray 模块列表
     * @param {Array} keyObject 搜索结果列表
     * @return {void}
     */
    matchDataWithKey(dataArray, keyObject) {
      for (let i = 0; i < dataArray.length; i++) {
        const obj = dataArray[i];
        if (obj.id in keyObject) {
          obj.searchValue = keyObject[obj.id];
        }
      }
      return dataArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  max-height: 60vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  box-sizing: border-box;
  ::v-deep .el-input__inner {
    border: none;
  }
  .content-result {
    flex: 1;
    flex-direction: column;
    border-top: 1px solid #d3dbeb;
    min-height: 220px;
    overflow-y: auto;

    .content-result-items {
      width: 529px;
      height: 62px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      &:hover {
        background-color: #f7f9fc;
        border-radius: 5px;
      }
      .content-result-items-cover {
        margin-left: 8px;
        width: 79px;
        height: 46px;
      }
      .content-result-items-info {
        color: var(--color-text-primary);
        margin-left: 14px;
        flex: 1;
        height: 46px;
        overflow: hidden;
        .info-second {
          font-size: 12px;
          color: #8997b0;
        }
      }
      .content-result-items-oprate {
        padding: 0 20px;
        .oprate {
          height: 24px;
          width: 24px;
          border-radius: 5px;
          background: #ffffff;
          border: 1px solid #a7b4cc;
          .svg-icon {
            margin-left: 4px;
            margin-top: 3px;
          }
        }
      }
    }
    .unknown{
      text-align: center;
      .content-result-unknown {
        width: 123px;
        height: 106px;
        margin-top: 40px;
      }
    }
    
  }
}
</style>
