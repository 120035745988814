import axios from 'axios';
import router from '../router';
import store from '../store';
import { Message } from 'element-ui';

const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_URL,
});

apiInstance.interceptors.request.use(
  request => {
    request.headers['X-Access-Token'] = store.state.local.token ? store.state.local.token : "";
    request.headers['Tenant-Id'] = store.state.local.userInfo ? store.state.local.userInfo.tenantIdNow : "";
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  response => {
    if (response.data && response.data.code === 405) {
      if (router.currentRoute.matched[0].path !== "/login") {
        window.clearVuexAlong();
        router.push("/login");
      }
    }
    return response;
  },
  err => {
    err.message && Message.error(err.message);
    return Promise.reject({
      message: err.message
    });
  }
);

/**
 * 系统Post调用
 * @param {string} url 系统API
 * @param {object} param 参数
 * @param {boolean} showcode msg提示
 * @param {boolean} layout 加载层
 */
const Post = (url, param, showcode = false, layout = false, options) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(url, param, options)
      .then(res => {
        resolve(res);
        if (res.data.code === 200 || res.data.code === 0) {
          showcode && Message.success(res.data.message);
        } else {
          if (res.data.message.includes("请重新登录")) {
            router.push("/login");
          }
          Message.error(res.data.message);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 系统Get调用
 * @param {string} url 系统API
 * @param {object} param 参数
 * @param {boolean} showcode msg提示
 * @param {boolean} layout 加载层
 */
const Get = (url, params, showcode = false, layout = false) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .get(url, params)
      .then(res => {
        resolve(res);
        if (res.data.code === 200 || res.data.code === 0 || res.data.code === 1014) {
          showcode && Message.success(res.data.message);
        } else {
          if (res.data.message.includes("请重新登录")) {
            router.push("/login");
          }
          Message.error(res.data.message);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 系统Put调用
 * @param {string} url 系统API
 * @param {object} param 参数
 * @param {boolean} showcode msg提示
 * @param {boolean} layout 加载层
 */
const Put = (url, params, showcode = false, layout = false) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .put(url, params)
      .then(res => {
        resolve(res);
        if (res.data.code === 200 || res.data.code === 0) {
          showcode && Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 系统Delete调用
 * @param {string} url 系统API
 * @param {object} param 参数
 * @param {boolean} showcode msg提示
 * @param {boolean} layout 加载层
 */
const Delete = (url, params, showcode = false, layout = false) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .delete(url, params)
      .then(res => {
        resolve(res);
        if (res.data.code === 200 || res.data.code === 0) {
          showcode && Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 文件上传
 * @param {string} url 系统API
 * @param {FormData} form FormData对象
 * @param {boolean} showcode msg提示
 * @param {boolean} layout 加载层
 */
const Upload = (url, form, showcode = false, layout = false) => {
  return new Promise((resolve, reject) => {
    apiInstance
      .post(url, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/**
 * 获取二进制文件流
 * @param {string} url 系统API
 * @param {object} param 参数
 * @param {boolean} showcode msg提示
 * @param {boolean} layout 加载层
 */
const buffer = (url, param, showcode = false, layout = false) => {
  return new Promise((resolve, reject) => {
    apiInstance({
      method: 'post',
      url: url,
      data: param,
      responseType: 'arraybuffer'
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default { Post, Get, Put, Delete, buffer, Upload, apiInstance };
