import request from "@/utils/appServer";
class Playeditor {
  // 演示方案 -  新增演示方案
  static async addShowProject(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/addShowProject', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 演示方案 -  选择模板新增演示方案
  static async addShowProjectFromTemplate(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/addShowProjectFromTemplate', data);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return [];
    }
  }
  // 演示方案 - 编辑演示方案
  static async editShowProject(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/editShowProject', data);
      return res;
    } catch (error) {
      return [];
    }
  }
  // 演示方案 - 查看演示方案详情
  static async getShowProjectDetail(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/getShowProjectDetail', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 演示方案 - 查看演示方案详情(非登录查看)
  static async getShowProjectDetailNoLogin(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/getShowProjectDetailNoLogin', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }

  // 演示模式 - 保存主题演示模式
  static async saveSubjectDemoMode(data) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/saveSubjectDemoMode', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 获取主题演示模式详情
  static async querySubjectDemoModeDetail(id) {
    try {
      const res = await request.post(`/sales-show-4.0/showProject/querySubjectDemoModeDetail?demoId=${id}`);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 演示模式 - 获取提纲详情
  static async queryDemoOutLineDetail(id) {
    try {
      const res = await request.post(`/sales-show-4.0/showProject/queryDemoOutLineDetail?outLineId=${id}`);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 演示模式 - 获取提纲详情
  static async queryDemoOutLineDetailNoLogin(id,shareId) {
    try {
      const res = await request.post(`/sales-show-4.0/showProject/queryDemoOutLineDetailNoLogin?outLineId=${id}&shareId=${shareId}`);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 演示模式 - 删除主题演示模式
  static async deleteSubjectDemoMode(demoId) {
    try {
      const res = await request.delete('/sales-show-4.0/showProject/deleteSubjectDemoMode', { params: demoId }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 设置演示模式状态
  static async settingSubjectDemoMode(data) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/settingSubjectDemoMode', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 演示模式移动排序
  static async moveSubjectDemoMode(data) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/moveSubjectDemoMode', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 保存主题演示模式节点
  static async saveSubjectDemoModeNode(data) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/saveSubjectDemoModeNode', data);
      return  res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 保存主题演示模式节点内容
  static async saveSubjectDemoModeNodeContent(data) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/saveSubjectDemoModeNodeContent', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 演示模式关联资源
  static async subjectDemoModeToResource(data) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/subjectDemoModeToResource', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 删除主题演示模式节点
  static async deleteSubjectDemoModeNode(nodeId) {
    try {
      const res = await request.delete('/sales-show-4.0/showProject/deleteSubjectDemoModeNode', { params: {nodeId} });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 获取主题演示模式节点详情
  static async querySubjectDemoModeNodeDetail(demoModeId,id,outlineType) {
    try {
      const res = await request.post(`/sales-show-4.0/showProject/querySubjectDemoModeNodeDetail?demoModeId=${demoModeId}&id=${id}&outlineType=${outlineType}`);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
    // 演示模式 - 获取提纲详情(非登录)
    static async querySubjectDemoModeNodeDetailNoLogin(demoModeId,id,outlineType) {
      try {
        const res = await request.post(`/sales-show-4.0/app/showProject/querySubjectDemoModeNodeDetailNoLogin?demoModeId=${demoModeId}&id=${id}&outlineType=${outlineType}`);
        return res.data.code === 200 && res.data.result ? res.data.result : [];
      } catch (error) {
        return [];
      }
    }
  // 演示模式 - 获取主题演示模式节点详情
  static async saveShowSubjectToLink(data) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/saveShowSubjectToLink',data);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 删除演示方式链接
  static async deleteShowSubjectDemoLink(demoLinkId) {
    try {
      const res = await request.delete('/sales-show-4.0/showProject/deleteShowSubjectDemoLink', { params: {demoLinkId} });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 获取主题演示模式节点详情
  static async getDemoLink(id) {
    try {
      const res = await request.post(`/sales-show-4.0/subject/getDemoLink?id=${id}`);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 演示模式 - 移动排序模式关联资源
  static async moveDemoModeToResource(data) {
    try {
      const res = await request.post('/sales-show-4.0/showProject/moveDemoModeToResource',data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
}

export {
  Playeditor
}