<template>
  <el-dialog append-to-body class="ss-dialog version-dialog" title="版本清单" top="185px" width="900px"
    :visible.sync="dialogVisible">
    <div class="content">
      <el-table cell-class-name="version-cell" header-cell-class-name="version-header" max-height="360px"
        style="width: 100%;" :data="versionList">
        <el-table-column prop="remark" label="版本说明" min-width="340">
          <template slot-scope="{ row }">
            <span :class="{ 'v-master': row.masterFlag === 1 }">{{ row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateAt" label="更新时间" min-width="150">
          <template slot-scope="{ row }">
            <span v-if="row.updateAt || row.updateName" :class="{ 'v-master': row.masterFlag === 1 }">{{
              Object.dayjs(row.updateAt) }}</span>
            <span v-else :class="{ 'v-master': row.masterFlag === 1 }">{{ Object.dayjs(row.createAt) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateName" label="更新人" min-width="120">
          <template slot-scope="{ row }">
            <span v-if="row.updateAt || row.updateName" :class="{ 'v-master': row.masterFlag === 1 }">{{ row.updateName
            }}</span>
            <span v-else :class="{ 'v-master': row.masterFlag === 1 }">{{ row.createName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作" min-width="100">
          <template slot-scope="{ row }">
            <span v-if="currentVersion.id === row.id" class="actions current">当前版本</span>
            <span v-else class="actions" @click="selectVersion(row)">选择此版本</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "PreviewVersionList",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    versionList: Array,
    currentVersion: Object,
  },
  data() {
    return {
      tableData: [],
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  methods: {
    /**
     * @description: 选择版本
     * @param {Object} value 点击项
     * @return {void}
     */
    selectVersion(value) {
      this.$emit("finish", value);
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss">
.version-cell {
  font-size: 14px;
  font-weight: 500;
}

.version-header {
  font-size: 14px;
  font-weight: 500;
  color: #344563;
  background-color: #f5f7fa !important;
}
</style>

<style lang="scss" scoped>
.version-dialog {
  ::v-deep .el-dialog__header {
    border-bottom-color: #fff;
  }
}

.content {
  padding: 0 30px 30px;

  .v-master {
    font-size: 14px;
    font-weight: 700;
    color: #1664ff;
  }
}

.actions {
  font-size: 14px;
  font-weight: 500;
  color: #344563;
  cursor: pointer;
  text-decoration: underline;

  &.current {
    color: #1664ff;
    cursor: unset;
    text-decoration: none;
  }
}
</style>