import Vue from 'vue'
import App from './App.vue'
// @ts-ignore
import router from './router'
// @ts-ignore
import store from './store'
import "./assets/styles/reset.css"
import "./assets/styles/public.css"
// @ts-ignore
import element from './element/index'; // element-ui 按需引入
import 'element-ui/lib/theme-chalk/index.css';
import '../src/element/element-variables.scss';
import './assets/styles/fonts.css';
import axios from "axios";
// @ts-ignore
import EventBus from '@/utils/EventBus';
import ElementUI from "element-ui";
// @ts-ignore
import InitEditor from 'custom-web-editor'
Vue.use(InitEditor)

let websocket: any = null;
let messageService: any = null;


Vue.use(ElementUI);
Vue.use(element);
Vue.config.productionTip = false
// @ts-ignore
import AppRouter from './utils/AppRouter';
// @ts-ignore
router.beforeEach((to, from, next) => {
  AppRouter.beforeEach(to, from, next);
})
// 解决报ResizeObserver loop completed with undelivered notifications错误
// @ts-ignore
const debounce = (fn, delay) => {
  let timer: any = null;
  return function () {
    // @ts-ignore
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  // @ts-ignore
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
// @ts-ignore
import common from './utils/common';
Vue.prototype.common = common;
// @ts-ignore
import Pubsub from "./utils/Pubsub";
Vue.prototype.$Pubsub = Pubsub;

function initWebSocket() {
  websocket = new WebSocket("ws://127.0.0.1:21662");
  Vue.prototype.$websocket = websocket;

  messageService = new Vue();
  Vue.prototype.$messageService = messageService;

  // 监听 WebSocket open 事件，设置连接状态为 true
  websocket.onopen = () => {
    store.commit("saveConnected", true);
  };

  // 监听 WebSocket 消息
  // @ts-ignore
  websocket.onmessage = (event) => {
    messageService.$emit('websocketMessage', event.data);
  };

  // 监听 WebSocket 关闭事件，实现自动重连
  websocket.onclose = () => {
    store.commit("saveConnected", false);
    console.log('WebSocket connection closed. Reconnecting in 5 seconds...');
    setTimeout(() => {
      console.log('Reconnecting...');
      initWebSocket(); // 重新初始化 WebSocket
    }, 5000); // 5秒后重新连接
  };
}

initWebSocket()

import "./icons/index"
import SvgIcon2 from '@/components/SvgIcon2.vue' // 引入项目中的SvgIcon2组件
Vue.component('svg-icon-2', SvgIcon2) // 全局注册svg-icon-2组件

import "@/utils/defaultSelect";
import _ from 'lodash'; // 引入lodash


// 修复按住鼠标左键移动到遮罩层时Dialog和Drawer会关闭的bug
// @ts-ignore
import PATCH_ElOverlayAutoClose from "@/utils/ElOverlayAutoClose.js";
Vue.use(PATCH_ElOverlayAutoClose);
// @ts-ignore
import JhMessage from "@/utils/directive/JhMessage.js";
Vue.prototype.$showMessage = JhMessage;

import DynamicPopup from "@/utils/directive/DynamicPopup.js";
Vue.prototype.$JhDialog = DynamicPopup;

// @ts-ignore
import ElImageViewer from "@/utils/directive/ElImageViewer";
Vue.prototype.$elImageViewer = ElImageViewer;
// @ts-ignore
import global from './utils/global';
Vue.prototype.$global = global;


// @ts-ignore
import { setBaseAxios } from "@/flexible-table-module/output.js";
// @ts-ignore
import AxiosInterceptors from '@/api/tableapi/_AxiosInterceptors';
// @ts-ignore

const baseAxios = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/sales-show-4.0`, // `baseURL` will be prepended to `url` unless `url` is absolute. 
  timeout: 60000
});

AxiosInterceptors.setInterceptors(baseAxios);

setBaseAxios(baseAxios);

Vue.config.productionTip = false;
Vue.config.devtools = false;
// 全局错误处理
window.onerror = (message, source, lineno, colno, error) => {
  console.error("捕获到错误:", message);
  console.error("来源:", source);
  if (error) {
      console.error("错误对象:", error);
  }
  return true; // 阻止默认错误提示
};
new Vue({
  // @ts-ignore
  router,
  store,
  render: h => h(App),
  // 在这里注册事件总线
  beforeCreate() {
    Vue.prototype.$EventBus = EventBus;
    // @ts-ignore
    this.$eventBus = EventBus;
  },
}).$mount('#app');