<template>
  <div
    class="second-column"
    :class="{ 'is-preview': isPreview }"
    @drop="handleDrop"
    @dragover="allowDrop"
  >
    <div class="second-column-main">
      <TextEditor
        v-if="
          activeColumn && columnCategoryTypeDict[activeColumn.columnCategoryId] === '1'
        "
        :ispre="isPreview"
        :rich-text.sync="richText"
        :module-id="subject.moduleId"
        :subject-id="subject.id"
        :version-id="subject.versionId"
        :active-column="activeColumn"
        :loginrequired="loginrequired"
        :shareId="queryIds.shareId"
      />
      <AssociateArticle
        v-if="
          activeColumn && columnCategoryTypeDict[activeColumn.columnCategoryId] === '5'
        "
        :active-column="activeColumn"
        :subject="subject"
        :ispre="isPreview"
        :loginrequired="loginrequired"
      />
      <AssociateProduct
        v-if="
          loginrequired &&
          activeColumn &&
          columnCategoryTypeDict[activeColumn.columnCategoryId] === '3'
        "
        :active-column="activeColumn"
        :subject="subject"
        :ispre="isPreview"
        :loginrequired="loginrequired"
        :shareId="queryIds.shareId"
      />
      <LinkColumn
        v-if="
          activeColumn && columnCategoryTypeDict[activeColumn.columnCategoryId] === '4'
        "
        :subject="subject"
        :active-column="activeColumn"
        :ispre="isPreview"
        :loginrequired="loginrequired"
        :shareId="queryIds.shareId"
      />
      <div
        v-if="
          !loginrequired &&
          activeColumn &&
          columnCategoryTypeDict[activeColumn.columnCategoryId] === '3'
        "
      >关联的产品需要登录后查看</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Playeditor } from "@/api/Playeditor";
import { ProductLibrary } from "@/api/ProductLibrary";
import TextEditor from "@/views/ProdudtEdito/components/TextEditor.vue";
import AssociateArticle from "@/views/ProdudtEdito/components/AssociateArticle.vue";
import AssociateProduct from "@/views/ProdudtEdito/components/AssociateProduct.vue";
import LinkColumn from "@/views/ProdudtEdito/components/LinkColumn.vue";
export default {
  components: { TextEditor, AssociateArticle, AssociateProduct, LinkColumn },
  props: {
    ispre: {
      type: Boolean,
      default: false,
    },
    nodeid: String,
    demoId: String,
    queryIds: Object,
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录
    },
  },
  data() {
    return {
      richText: "",
      confirmLoading: false,
      queryData: {
        content: "",
        id: "",
        outlineId: "",
      },
      readonly: false,
      activeColumn: null,
      clickitem: null,
      subject: null,
      isPreview: true,
    };
  },
  watch: {
    nodeid(val) {
      val && this.getNodeDetail();
    },
  },
  computed: {
    // isPreview() {
    //   this.readonly = this.ispre;
    //   return this.ispre;
    // },
    ...mapGetters(["columnCategoryTypeDict"]),
  },
  mounted() {
    this.nodeid && this.getNodeDetail();
    this.$Pubsub.publish("update-resource-visible", {
      visible: true,
      type: [1, "column"],
    });
  },
  methods: {
    /**
     * @description: 获取节点关联详情
     * @return {void}
     */
    async getNodeDetail() {
      const res = await Playeditor[
        this.loginrequired
          ? "querySubjectDemoModeNodeDetail"
          : "querySubjectDemoModeNodeDetailNoLogin"
      ](this.demoId, this.nodeid, 2);
      if (res.length) {
        this.clickitem = res[0];
        this.getDetail(res);
        this.$Pubsub.publish("update-resource-select-data", [res[0].columnId]);
      } else {
        this.clickitem = null;
        this.activeColumn = null;
        this.$Pubsub.publish("update-resource-select-data", []);
      }
    },
    /**
     * @description: 获取主题详情
     * @return {void}
     */
    async getDetail(value) {
      this.loading = true;
      this.activeColumn = null;
      let payload = {
        mirrorId: value[0].mirrorId,
        subjectId: value[0].subjectId,
        versionId: value[0].versionId,
      };
      // let res = await ProductLibrary.getSubjectDetail(payload);
      if (!this.loginrequired) {
        payload.shareId = this.queryIds.shareId;
      }
      let res = await ProductLibrary[
        this.loginrequired ? "getSubjectDetail" : "getSubjectDetailNoLogin"
      ](payload);
      if (res) {
        this.subject = res;
        if (!this.loginrequired) this.subject.shareId = this.queryIds.shareId;
        const column = res.subjectColumnVos.filter(
          (item) => item.id == value[0].columnId
        );
        column.length && (this.activeColumn = column[0]);
      }
      this.loading = false;
    },
    // 拖拽事件
    allowDrop(event) {
      event.preventDefault();
    },
    /**
     * @description: 添加栏目
     * @return {void}
     */
    async handleDrop(event) {
      if (this.ispre) {
        return;
      }
      event.preventDefault();
      const subject = JSON.parse(event.dataTransfer.getData("dragsubject"));
      let asspayload = {
        saveOutlineToResource: {
          name: subject.name,
          outlineNodeId: this.nodeid,
          type: 2,
          subjectId: subject.id,
          versionId: subject.versionId,
          mirrorId: subject.mirrorId,
          columnId: subject.selectColumn.id,
        },
      };
      if (this.clickitem) {
        this.$showMessage(
          {
            message: `已经有关联栏目，此操作将会覆盖之前的选择`,
            ...this.$global.deleteMessage,
            title: "确定覆盖？",
            confirmButtonText: "确定",
          },
          async (done) => {
            asspayload.saveOutlineToResource.id = this.clickitem.id;
            const res = await Playeditor.subjectDemoModeToResource(asspayload);
            if (res) {
              this.getNodeDetail();
              this.$Pubsub.publish("update-mind-name-data", {
                id: this.nodeid,
                name: `${subject.selectColumn.columnName}(${subject.name})`,
              });
            }
            done(true);
          }
        );
      } else {
        const res = await Playeditor.subjectDemoModeToResource(asspayload);
        if (res) {
          this.getNodeDetail();
          this.$Pubsub.publish("update-mind-name-data", {
            id: this.nodeid,
            name: `${subject.selectColumn.columnName}(${subject.name})`,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.second-column {
  width: 100%;
  // min-width: 800px;
  display: flex;
  // flex-direction: column;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;

  ::v-deep .ck.ck-content {
    padding-bottom: 110px;
  }

  .second-column-main {
    flex: 1;
  }
  .resource-column {
    width: 323px;
    height: 100%;
  }
}
.bold {
  font-weight: bold;
  color: var(--color-text-primary);
}
</style>
