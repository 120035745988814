<template>
  <el-dialog append-to-body class="ss-dialog no-header-bottom" title="选择需要添加的属性" width="600px"
    :visible.sync="dialogVisible">
    <div class="custom-body">
      <el-table class="attribute-table" header-cell-class-name="app-header-cell" :data="tableData">
        <el-table-column align="center" type="selection" width="50"></el-table-column>
        <el-table-column show-overflow-tooltip label="名称" prop="eventName" min-width="200"></el-table-column>
        <el-table-column label="值" prop="eventType" min-width="120"></el-table-column>
        <el-table-column label="描述" prop="responsibleBy" min-width="120"></el-table-column>
        <div slot="empty">
          <img src="@/assets/images/common/table-empty.png"
            style="width: 250px;height: 210px;margin-top: 24px;display: block;">
        </div>
      </el-table>
    </div>
    <div class="text-center" slot="footer">
      <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
      <el-button type="primary">新增属性</el-button>
      <el-button type="primary">确&nbsp;定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData: [],
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.custom-body {
  padding: 0 30px;

  .attribute-table {
    &::before {
      height: 0;
    }
  }
}
</style>