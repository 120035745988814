<template>
  <el-tree
    :props="fileprops"
    :load="loadNode"
    lazy
    :expand-on-click-node="false"
    @node-expand="handleClick"
    @node-collapse="handleClick"
  >
    <div class="scheme-title-grouplist-noline" slot-scope="{ node, data }">
      <div
        v-if="node.level == 1"
        class="scheme-title-group-parent flex align-items-center"
        :class="{
          'active-child': ischeck,
        }"
        @click="handleClick(data, node)"
      >
        <div class="flex-between w-100 align-items-center">
          <div class="one-line">{{ data.folderName }}</div>
        </div>
        <el-popover
          v-if="!ispre && !showscheme && !showleftbtn"
          popper-class="toolbar-item-popover popover"
          placement="right-start"
          width="80"
          trigger="click"
          :ref="'toolbar-' + data.id"
          :visible-arrow="false"
        >
          <div class="toolbar-more" slot="reference" @click.stop></div>
          <div class="popover-item" @click.prevent.stop="handleColumnShare(data)">
            分享
          </div>
        </el-popover>
      </div>
      <div v-else>
        <div
          class="scheme-title-group-child flex-between align-items-center scheme-title-group-child_file"
          @click="handleClick(data, node.parent.data)"
        >
          <div class="scheme-title-group-child-name one-line flex align-items-center">
            <img
              class="img-icon scheme-items-icon"
              :src="require('@/assets/images/file/file-folder.png')"
            />
            <div :title="data.folderName" class="one-line">
              {{ data.folderName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-tree>
</template>
<script>
import { File } from "../../../api/File";
import NETSYSTEM from "../../../api/NETSYSTEM";

export default {
  props: {
    showleftbtn: {
      type: Boolean,
      default: false,
    },
    ispre: {
      type: Boolean,
      default: false,
    },
    showscheme: {
      type: Boolean,
      default: false,
    },
    ischeck: {
      type: Boolean,
      default: false,
    }, //是否选中文件
    subject: Object,
  },
  data() {
    return {
      fileprops: {
        label: "folderName",
        children: "children",
      },
      clicknode: null,//记录当前点击的节点
    };
  },
  methods: {
    /**
     * @description: 加载节点
     * @return {void}
     */
    async loadNode(node, resolve) {
      if (!this.subject) return;
      if (node.level === 0) {
        return resolve([
          {
            id: this.subject.fileSubjectFolderId,
            folderName: "文件",
          },
        ]);
      } else {
        let secondfolders = await File.getFolderList(node.data.id);
        resolve(secondfolders);
      }
    },
    /**
     * @description: 节点点击
     * @return {void}
     */
    async handleClick(data, node) {
      if (this.clicknode && this.clicknode.id == node.id) return;
      this.clicknode = node;
      if (node.level == 1) {
        this.$emit("filelabelclick", 2, []);
      } else {
        const res = await NETSYSTEM.getParentFolderListById(data.id);
        const breadcrumbList = res.slice(4);
        this.$emit("filelabelclick", 2, breadcrumbList);
      }
    },
    /**
     * @description: 分享
     * @return {void}
     */
    handleColumnShare() {
      this.$emit("share", {
        id: "-1",
        columnName: "文件",
      });
    },
  },
};
</script>
<style lang="scss">
.scheme-title-grouplist-noline {
  width: calc(100% - 20px);
  box-sizing: border-box;
  // font-size: 12px;
  color: var(--color-text-primary);

  .scheme-title-group-parent {
    height: 30px;
    line-height: 30px;
    width: 100%;
    padding: 0 4px;
    position: relative;
    box-sizing: border-box;

    &:hover {
      .toolbar-more {
        display: block;
      }

      background-color: #fcfdfe;
    }

    .toolbar-more {
      display: none;
      position: absolute;
      top: 50%;
      right: 16px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      background-image: url("@/assets/images/common/more_vertical.png");
      background-size: 12px 12px;
      background-repeat: no-repeat;
    }
  }

  .scheme-title-group-child {
    // width: calc(100% - 12px);
    width: 100%;
    height: 30px;
    line-height: 30px;
    // margin-left: 16px;
    position: relative;
    // padding: 0 4px 0 10px;
    padding: 0 4px 0 0;
    box-sizing: border-box;
    left: -10px;

    &:hover {
      .toolbar-more {
        display: block;
      }

      background-color: #fcfdfe;
    }

    .scheme-title-group-child-name {
      width: calc(100% - 12px);
    }

    img {
      width: 20px;
      height: 16px;
      margin-right: 5px;
      flex-shrink: 0;
    }

    .toolbar-more {
      display: none;
      position: absolute;
      top: 50%;
      right: 0px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      background-image: url("@/assets/images/common/more_vertical.png");
      background-size: 12px 12px;
      background-repeat: no-repeat;
    }

    .select-icon {
      flex-shrink: 0;
    }
  }

  .scheme-title-group-child_file {
    left: 0px;
  }

  .active-child {
    // background-color: #f1ebff;
    color: var(--color-primary);
    font-weight: 600;

    &:hover {
      // background-color: #f1ebff;
      color: var(--color-primary);
    }
  }

  .svg-icon {
    margin-left: 4px;

    &:hover {
      color: var(--color-primary) !important;
    }
  }
}
</style>
