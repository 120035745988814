import store from "@/store";
import { isNewVersion } from '@/utils/versionUpdate';
// 更新当前激活菜单
function updateActiveMenu(activeMenu, toName) {
  if (toName) {
    function getMenuItem(nodes, url) {
      for (let i = 0; i < nodes.length; i++) {
        if (url == nodes[i].url || "/" + url == nodes[i].url) {
          return nodes[i];
        }
        if (nodes[i].children.length) {
          let findResult = getMenuItem(nodes[i].children, url);
          if (findResult) {
            return findResult;
          }
        }
      }
    }
    const menuItem = getMenuItem(store.getters.menuList, toName);
    menuItem && store.commit("saveActiveMenu", menuItem);
  } else {
    store.commit("saveActiveMenu", activeMenu);
  }
}
export default {
  beforeEach(to, from, next) {
    isNewVersion();
    store.commit("saveIsLoginPage", to.meta.isframe);
    if (to.meta.iscache && !store.getters.cacheList.includes(to.name)) {
      store.commit('addCacheList', to.name);
    }
    document.title = `知识库${to.meta.title ? '-' + to.meta.title : ''}`;
    // 不需要验证 token 的页面路由
    const excludeList = ["/login", "/getdetailbyshareid","/text"];
    const islogin= to.path.startsWith('/login/')
    if (excludeList.includes(to.path)||islogin) {
      next();
    } else {
      if (store.getters.token) {
        const menuList = store.getters.menuList.filter(item => !item.default).filter(item => item.url === to.name);
        if (to.path.includes('backstage-managed')) {
          next()
          return
        }
        if (menuList.length) {
          // 跳转到自定义菜单
          const menuItem = menuList.find(item => item.id === to.params.id);
          if (menuItem) {
            updateActiveMenu(menuItem);
            next();
          } else {
            // 地址栏与菜单列表不匹配时自动跳转到菜单第一项
            const firstMenu = store.getters.menuList[0];
            if (firstMenu.children.length) {
              updateActiveMenu(firstMenu.children[0]);
              next(firstMenu.children[0].url)
            } else {
              updateActiveMenu(firstMenu);
              next({
                name: firstMenu.url,
                params: {
                  id: firstMenu.id,
                }
              })
            }
          }
        } else {
          // 跳转到本地菜单
          updateActiveMenu(null, to.name);
          next();
        }
      } else {
        next("/login");
      }
    }
  }
}