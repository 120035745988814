<template>
  <el-dialog append-to-body class="ss-dialog no-header" width="900px" :visible.sync="dialogVisible">
    <div class="content">
      <el-table v-loading="loading" header-cell-class-name="app-header-cell" max-height="360px" style="width: 100%;"
        :data="tableData">
        <el-table-column label="文件名" prop="fileName" min-width="180"></el-table-column>
        <el-table-column label="版本号" prop="fileVersion" min-width="80"></el-table-column>
        <el-table-column label="文件大小" min-width="120">
          <template slot-scope="{ row }">
            <span>{{ formatFileSize(row.fileSize) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="createBy" min-width="120"></el-table-column>
        <el-table-column label="创建时间" min-width="160">
          <template slot-scope="{ row }">
            <span>{{ Object.dayjs(row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="{ row }">
            <el-dropdown placement="bottom" trigger="click">
              <div style="padding: 0;" @click.stop>
                <svg-icon color="#000" icon="product-tool11" width="16" height="16"></svg-icon>
              </div>
              <el-dropdown-menu class="more-dropdown" slot="dropdown">
                <el-dropdown-item>
                  <div class="filter-dropdown-item">
                    <span class="pointer" @click="handleDownload(row)">下载</span>
                  </div>
                </el-dropdown-item> 
                <el-dropdown-item v-if="!isPreview && row.id != tableData[0].id">
                  <div class="filter-dropdown-item">
                    <span class="pointer" @click="resumeFileVersion(row)">设为主版本</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="!isPreview && row.id != tableData[0].id">
                  <div class="filter-dropdown-item">
                    <span class="pointer" @click="handleDelete(row)">删除</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="text-center" slot="footer">
      <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { File } from "@/api/File";
import NETSYSTEM from '@/api/NETSYSTEM';
export default {
  name: "FileVersion",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: Object,
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      tableData: [],
      queryData:{
        isCurrent:1,
        fileId:''
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  mounted() {
    this.getFileVersion();
  },
  methods: {
    /**
     * @description: 获取文件版本
     * @return {void}
     */
    async getFileVersion() {
      this.loading = true;
      this.tableData = await File.getByFileVersion(this.current.id);
      this.loading = false;
    },
    /**
     * @description: 格式化文件大小
     * @param {Number} size 文件大小
     * @return {String} 格式化后的文件大小
     */
    formatFileSize(size) {
      if (size < 1024) {
        return size + "B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        return Math.ceil(size / 1024 * 100) / 100 + "KB";
      } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
        return Math.ceil(size / 1024 / 1024 * 100) / 100 + "MB";
      } else {
        return Math.ceil(size / 1024 / 1024 / 1024 * 100) / 100 + "GB";
      }
    },
    /**
     * @description: 下载
     * @param {Object} value 点击项
     * @return {void}
     */
    async handleDownload(value) {
      this.queryData.isCurrent=1
      if(value.id!=this.tableData[0].id){
        this.queryData.isCurrent=2
      }
      this.queryData.fileId=value.id
      const res=await File.downLoadFile(this.queryData)
      if (res) {
        const suffix = res.downUrl.split(".").pop();
        const fileNameSuffix = res.fileName.split(".").pop();
        let fileName = res.fileName;
        if (suffix !== fileNameSuffix) {
          fileName = fileName + "." + suffix;
        }
        const xhr = new XMLHttpRequest();
        xhr.open('GET', res.downUrl, true);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
          const url = window.URL.createObjectURL(xhr.response);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.setAttribute('href', url);
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        xhr.send();
      }
    },
    /**
     * @description: 删除
     * @param {Object} value 
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage({
        message: `是否确认删除 <span style="font-weight: 1000;">${value.fileVersion}</span> ?`,
        ...this.$global.deleteMessage
      }, async (done) => {
        const res = await File.deleteFile(value.id, 0, 2);
        done(res);
        res && this.getFileVersion();
      });
    },
    async resumeFileVersion(value){
      const res = await NETSYSTEM.resumeFileVersion({
        fileId:value.id
      })
      if(res){
        this.getFileVersion();
        this.$message.success('设置成功');
        this.$emit('finish')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 24px 30px;

  .el-button--text {
    padding: 0;
  }
}
</style>