var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadResource),expression:"loadResource"}],staticClass:"scheme-title-list-wrapper"},_vm._l((_vm.tableData),function(item){return _c('div',{key:item.id,staticClass:"scheme-title-list-items pointer align-items-center move",class:{ 'items-active prohibit-item': _vm.selectids.includes(item.id) },attrs:{"draggable":_vm.selectids.includes(item.id) ? false : true},on:{"dragstart":(e) => _vm.dragstart(e, item)}},[(_vm.categorytype == 1 || _vm.categorytype == 2)?[(
          item.subjectCoverVos && item.subjectCoverVos.length > 0 && item.type === 1
        )?_c('img',{staticClass:"scheme-title-list-items-cover",attrs:{"src":item.subjectCoverVos[0].coverUrl,"draggable":false}}):_vm._e(),(
          item.subjectCoverVos && item.subjectCoverVos.length === 0 && item.type === 1
        )?_c('img',{staticClass:"scheme-title-list-items-cover",attrs:{"src":require(`@/assets/images/sample-${_vm.common.getLastChar(item.id)}.png`),"draggable":false}}):_vm._e()]:_vm._e(),(_vm.categorytype == 5)?[_c('img',{staticClass:"scheme-title-list-items-cover",attrs:{"draggable":false,"src":item.coverList && item.coverList[0].coverUrl
            ? item.coverList[0].coverUrl
            : require('@/assets/images/sample-1.png'),"alt":""}})]:_vm._e(),_c('div',{staticClass:"scheme-items-name pd-12 one-line"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"scheme-items-time pd-12 one-line"},[_vm._v(" "+_vm._s(Object.dayjs(item.updateAt || item.createAt))+" ")]),_c('svg-icon',{staticClass:"check-icon",attrs:{"icon":"check-icon","color":"#365EFF"}})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }