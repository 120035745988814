<template>
  <div class="file-right">
    <!-- 视频 -->
    <div style="height: 100%; width: 100%; overflow: hidden" v-show="isvideo">
      <video ref="videoPlayer" class="video-js vjs-default-skin"></video>
    </div>
    <!-- 图片 -->
    <div
      class="imgview"
      id="containerId"
      style="height: 100%; width: 100%; overflow: hidden"
      v-show="isimg"
    >
      <img
        class="myimg"
        ref="draggableElement"
        :src="imgUrl"
        alt=""
        :style="{ transform: style }"
        @mousewheel="handleWheel"
      />
      <div class="tool" :style="`color: ${color};`">
        <i
          v-for="item in tools"
          :key="item.id"
          @click="handleTansform(item.id)"
          :class="item.icon"
        ></i>
      </div>
    </div>
    <!-- 文档 -->
    <div id="filepreview" style="height: 100%; width: 100%" v-show="isfile">
      <iframe
        id="myiframe"
        :src="fileUrl"
        frameborder="0"
        height="100%"
        width="100%"
      ></iframe>
    </div>
    <div class="unsupported" v-if="unsupported && !iswebh5">
      <div>
        该文件暂时无法在线预览，是否
        <el-link style="" @click="handleDownload"
          >下载文件<i class="el-icon-download"></i>
        </el-link>
      </div>
    </div>
    <el-empty
      class="w-h-100 bg-white"
      v-if="unsupported && iswebh5"
      :image-size="139"
      :image="require('@/assets/images/file/unsupport.png')"
      description="该文件暂时无法在线预览"
    ></el-empty>
    <!-- 不支持的类型 -->
    <!-- <div class="unsupported" v-if="unsupported">
      <div></div>
    </div> -->
    <!-- <div class="file-close" @click="dialogVisible = false">
      <img src="@/assets/images/common/close.png" alt="" />
    </div> -->
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
import { mapGetters } from "vuex";
import { File } from "@/api/File";
import { ProductLibrary } from "@/api/ProductLibrary";
import NETSYSTEM from "@/api/NETSYSTEM";
import axios from "axios";
import interact from "interactjs";
import { DownloadFile } from "@/utils/file";
export default {
  name: "ProductFileList",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    currentitem: Object,
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录，非登录的currentitem里面传参多加一个shareId
    },
    iswebh5: {
      type: Boolean,
      default: false, //是否是移动端查看
    },
  },
  data() {
    return {
      confirmLoading: false,
      videoUrl: "",
      isvideo: false, //视频类预览
      isfile: false, //文档类预览
      isimg: false,
      visibleList: [], //用来显示的文件列表
      type: "",
      fileUrl: "",
      close: true, //左侧列表的显示
      unsupported: false, //是否为不支持的播放类型
      clickitem: { id: "-1" }, //当前项
      player: null,
      tools: [
        { icon: "el-icon-zoom-in", id: 1 },
        { icon: "el-icon-zoom-out", id: 2 },
        { icon: "el-icon-full-screen", id: 3 },
        { icon: "el-icon-refresh-left", id: 4 },
        { icon: "el-icon-refresh-right", id: 5 },
      ],
      style: "scale(1) rotate(0deg)",
      scale: 1,
      rotate: 0,
      isDownloading: false,
      percentage: 0,
      plugvisible: false,
      downloadfile: null,
      imgUrl: null, //图片预览地址
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo"]),
  },
  watch: {
    isimg(val) {
      if (val) {
        this.imginit();
      }
    },
    currentitem(val) {
      val && this.previewFile(val);
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  created() {},
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, {
      controls: true, // 启用默认播放控件
      muted: true, //静音
    });
    this.previewFile(this.currentitem);
  },
  methods: {
    playVideo(url) {
      this.player.src(url);
      this.player.play();
    },
    /**
     * @description: 预览文件
     * @param {Object} value 点击项
     * @return {void}
     */
    async previewFile(value) {
      if (this.clickitem.id == value.id) {
        return;
      }
      this.clickitem = value;
      // const type = value.fileSavePath.split(".").pop();
      const payload = {
        fileId: value.id,
        isCurrent: 1,
        optType: 2, //1.下载，2.预览
      };
      if (!this.loginrequired) {
        payload.shareId = value.shareId;
      }
      const res = await File[this.loginrequired ? "downLoadFile" : "downLoadFileNoToken"](
        payload
      );
      // 如果返回来是不支持的类型，就提示是否下载
      if (res == "notsupport") {
        this.unsupported = true;
        this.isimg = false;
        this.isvideo = false;
        this.isfile = false;
        return;
      }
      if (!res) return;
      let url = res.downUrl;
      const type = url.split(".").pop();
      // ||type == "avi"||type == "rmvb"
      if (type == "mp4" || type == "webm") {
        this.isvideo = true;
        this.isfile = false;
        this.unsupported = false;
        this.isimg = false;
        this.playVideo(url);
      } else if (type == "png" || type === "jpg" || type == "svg" || type === "jpeg") {
        this.isvideo = false;
        this.isfile = false;
        this.unsupported = false;
        this.imgUrl = res.downUrl;
        this.style = "scale(1) rotate(0deg)";
        this.isimg = true;
      } else {
        this.isimg = false;
        this.isvideo = false;
        this.isfile = true;
        this.fileUrl = url;
        this.unsupported = false;
      }
    },
    // 文件预览
    officeView(id) {
      const url = `${process.env.VUE_APP_URL_NETDISK}/public-file-system/fs/file/preview/viewOrEdit?fileId=${id}&optType=view`;
      // 发送请求
      axios(url, {
        headers: {
          "Tenant-Id": this.userInfo.tenantIdNow,
          "Sys-Access-Token": "JqjdLEtaFOas5whS8hro2w==",
          "X-Access-Token": this.token,
        },
      }).then((response) => {
        if (response.status == 200) {
          this.fileUrl = response.data;
        }
      });
    },
    async PPTView(id) {
      const res = await NETSYSTEM.viewOrEditV2({
        fileId: id,
        isCurrent: 1,
        optType: "view", //操作类型（view:预览，edit:编辑）
      });
      return res.fileLocalPath ? res.fileLocalPath : "";
    },
    /**
     * @description: 关闭左侧列表
     * @return {void}
     */
    closeList() {
      this.close = !this.close;
    },
    /**
     * @description: 图片预览操作按钮
     * @param {Object} value 点击项
     * @return {void}
     */
    handleTansform(idx) {
      switch (idx) {
        case 1:
          if (this.scale < 5) this.scale += 0.1;
          break;
        case 2:
          if (this.scale > 0.15) this.scale -= 0.1;
          break;
        case 3:
          this.scale = 1;
          this.rotate = 0;
          break;
        case 4:
          this.rotate -= 90;
          break;
        case 5:
          this.rotate += 90;
          break;
        default:
          break;
      }
      this.style = `scale(${this.scale}) rotate(${this.rotate}deg)`;
    },
    /**
     * @description: 控制图片放大缩小
     * @return {void}
     */
    handleWheel(event) {
      event.preventDefault();
      const deltaY = event.deltaY;
      if (deltaY > 0) {
        this.handleTansform(2);
      } else {
        this.handleTansform(1);
      }
    },
    imginit() {
      interact(this.$refs.draggableElement).draggable({
        onmove: (event) => {
          const target = event.target;
          const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
          target.style.transform = `translate(${x}px, ${y}px) ${this.style}`;
          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },
      });
    },
    async handleDownload() {
      DownloadFile(this.currentitem, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-right {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  // background-color: #ccc;
  overflow: hidden;

  video {
    max-height: 900px;
  }

  .imgview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .tool {
      z-index: 3000;
      height: 60px;
      width: 250px;
      border-radius: 60px;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      // color: #fff;
      // color: var(--color-text-primary);
      font-size: 25px;
      display: flex;
      justify-content: space-around;

      i {
        cursor: pointer;
      }
    }
  }
}

.file-right-closeicon {
  position: absolute;
  left: 11px;
  top: 16px;
  z-index: 10;

  img {
    height: 24px;
    width: 24px;
  }
}

.file-close {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 5px;
  z-index: 14;

  img {
    height: 20px;
    width: 20px;
    margin-left: 5px;
    margin-top: 5px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.unsupported {
  height: 100%;
  width: 100%;
  position: relative;
  font-size: 16px;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ::v-deep .el-link {
    font-size: 16px;
    margin-right: 20px;
    color: #0962e7;
  }
}

.video-js[tabindex="-1"] {
  outline: none;
  height: 100%;
  width: 100%;
}

::v-deep .el-breadcrumb__inner {
  color: #fff;
}
</style>
