<template>
  <el-dialog append-to-body class="ss-dialog" title="选择角色" width="642px" :visible.sync="dialogVisible">
    <div style="padding: 20px 30px;">
      <el-transfer v-model="roles" :data="rolesList"></el-transfer>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
      <el-button type="primary" @click="handleConfirm">确&nbsp;定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      roles: [],
      rolesList: [
        { key: 1, label: '角色1' },
        { key: 2, label: '角色2' },
        { key: 3, label: '角色3' },
        { key: 4, label: '角色4' },
      ],
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  methods: {
    handleConfirm() {
      // this.$emit('confirm', this.roles); // 传递给父组件
      // this.dialogVisible = false; // 关闭弹窗
    }
  }
}
</script>

<style lang="scss" scoped></style>