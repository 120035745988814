import request from "@/utils/appServer";
class Departmen {
  // 部门管理-添加
  static async addDepartment(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/add', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 部门管理-通过id删除
  static async deleteById(id) {
    try {
      const res = await request.delete('/sales-show-4.0/sys/depart/delete?id=' + id);
      return res.data.code === 200 && res.data.success ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 部门管理-获取部门树
  static async departTree() {
    try {
      const res = await request.get('/sales-show-4.0/sys/depart/departTree');
      return res.data.code === 200 || res.data.code === 0 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 部门管理-根据公司Id获取部门树
  static async departTreeByCompanyId(id) {
    try {
      const res = await request.get('/sales-show-4.0/sys/depart/departTreeByCompanyId?companyId=' + id);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 部门管理-编辑
  static async editDepartment(data = {}) {
    try {
      const res = await request.put('/sales-show-4.0/sys/depart/edit', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 部门管理-获取列表
  static async getDepartmentList(data = {}) {
    try {
      const res = await request.get('/sales-show-4.0/sys/depart/list', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 部门管理-通过id查询
  static async getDepartmenById(id) {
    try {
      const res = await request.get('/sales-show-4.0/sys/depart/queryById?id=' + id);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 部门管理-根据部门id获取用户列表
  static async getUserListByDepartId(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/queryUserListByDepartId', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 部门管理-根据部门id获取用户分页列表
  static async getUserPageByDepartId(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/queryUserPageByDepartId', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 根据租户Id获取组织结构树
  static async departTreeByTenantId(data) {
    try {
      const res = await request.get('/sales-show-4.0/sys/depart/departTreeByTenantId', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 添加部门
  static async addDepart(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/add',data,true );
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 删除部门
  static async deleteDepart(id) {
    try {
      const res = await request.delete('/sales-show-4.0/sys/depart/delete',{ params: {id} });
      return res.data.code === 200
    } catch (error) {
      return false;
    }
  }
  // 根据部门id获取用户分页列表
  static async queryUserPageByDepartId(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/queryUserPageByDepartId', data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 部门添加成员
  static async departAddUser(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/departAddUser', data,true);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 从部门移除成员
  static async departRemoveUser(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/departRemoveUser', data,true);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
}

export { Departmen }