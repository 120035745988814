<template>
  <div class="w-h-100 flex app-scheme-wrapper">
    <div class="app-scheme-sidebar">
      <svg-icon
        class="scheme-sidebar-icon pointer"
        icon="icon-scheme"
        :class="{ 'scheme-sidebar-icon-active': showscheme }"
        :height="20"
        :width="20"
        @click.native="showscheme = !showscheme"
      ></svg-icon>
    </div>
    <div class="app-scheme-left" v-if="showscheme">
      <div class="scheme-title flex-between align-items-center">
        <div class="scheme-title-name">演示方案</div>
        <div class="flex align-items-center">
          <el-dropdown placement="right-start" trigger="click" v-if="!isPreview">
            <div>
              <svg-icon
                class="mr-10 pointer"
                icon="mind-add"
                color="#5E6C84"
                :height="12"
                :width="12"
              ></svg-icon>
            </div>
            <el-dropdown-menu slot="dropdown" class="more-dropdown">
              <el-dropdown-item>
                <div class="edit-dropdown-item-120" @click="handleUpdate(null, 0)">
                  添加演示模式
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="edit-dropdown-item-120" @click="handleUpdate(null, 1)">
                  自定义演示模式
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <svg-icon
            class="mr-10 pointer"
            icon="xs-icon-close"
            color="#5E6C84"
            :height="12"
            :width="12"
            @click.native="showscheme = false"
          ></svg-icon>
        </div>
      </div>
      <div class="scheme-title-list" id="edittabs">
        <div
          v-for="(i, index) in demoList"
          :key="i.id"
          @click="handleCurrent(i)"
          class="flex scheme-title-list-items pointer align-items-center"
          :class="{ 'items-active': activeDemo && i.id === activeDemo.id }"
        >
          <svg-icon
            class="scheme-items-icon"
            icon="icon-scheme"
            :height="16"
            :width="16"
            color="#344563"
            v-if="!i.isDefault && !i.authType"
          ></svg-icon>
          <div class="scheme-items-icon-default" v-if="i.authType">私有</div>
          <div class="scheme-items-icon-default" v-if="i.isDefault">默认</div>
          <div class="scheme-items-name one-line">{{ i.demoModeName }}</div>
          <el-popover
            v-if="!isPreview"
            popper-class="toolbar-item-popover popover"
            placement="right-start"
            width="120"
            trigger="click"
            :ref="'toolbar-' + i.id"
            :visible-arrow="false"
          >
            <div class="toolbar-more" slot="reference" @click.stop></div>
            <div
              v-if="!i.authType"
              class="popover-item"
              @click="handleChangeStatus(i, 'isDefault')"
            >
              {{ i.isDefault ? "取消默认" : "设为默认" }}
            </div>
            <div class="popover-item" @click="handleUpdate(i, i.authType)">编辑</div>
            <div class="popover-divider"></div>
            <div class="popover-item" @click="handleDelete(i, index)">删除</div>
          </el-popover>
        </div>
      </div>
    </div>
    <SplitPane :initialLeftWidth="showdemo ? 300 : 0">
      <template v-slot:left>
        <!-- 左侧内容 -->
        <div
          v-show="demoList.length && showdemo"
          class="app-scheme-left app-scheme-left-mind"
        >
          <div class="scheme-title flex-between align-items-center">
            <div class="scheme-title-name">方案提纲</div>
            <div class="flex align-items-center">
              <svg-icon
                class="mr-10 pointer"
                icon="xs-icon-close"
                color="#5E6C84"
                :height="12"
                :width="12"
                @click.native="showdemo = false"
              ></svg-icon>
              <!-- <svg-icon
            class="mr-10 pointer"
            icon="mind-zoonmax"
            color="#5E6C84"
            :height="12"
            :width="12"
          ></svg-icon> -->
            </div>
          </div>
          <div class="scheme-left-outline">
            <MindView
              @editornode="editornode"
              :clickoutlineid="clickoutlineid"
              :ispreview="isPreview"
              :active-demo="activeDemo"
            />
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div v-if="demoList.length" class="app-scheme-main demo-main">
          <svg-icon
            v-if="!showdemo"
            class="retract-icon pointer"
            icon="mind-close"
            color="#5E6C84"
            :height="36"
            :width="48"
            @click.native="showdemo = true"
          ></svg-icon>
          <ContentView
            v-if="
              activeNode &&
              (activeNode.data.outlineType == 1 ||
                activeNode.data.outlineType == 0 ||
                activeNode.isroot)
            "
            :nodeid="clickoutlineid"
            :ispre="isPreview"
          />
          <ProductView
            v-if="activeNode && activeNode.data.outlineType == 2"
            :nodeid="clickoutlineid"
            :demoId="activeDemo.id"
            :ispre="isPreview"
          />
          <ColumnView
            v-if="activeNode && activeNode.data.outlineType == 3"
            :nodeid="clickoutlineid"
            :demoId="activeDemo.id"
            :ispre="isPreview"
          />
          <ArticleView
            v-if="activeNode && activeNode.data.outlineType == 4"
            :nodeid="clickoutlineid"
            :demoId="activeDemo.id"
            :ispre="isPreview"
            :active-node="activeNode"
          />
          <LinkView
            v-if="activeNode && activeNode.data.outlineType == 5"
            :nodeid="clickoutlineid"
            :demoId="activeDemo.id"
            :query-ids="queryIds"
            :ispre="isPreview"
          />
          <FileView
            v-if="activeNode && activeNode.data.outlineType == 6"
            :nodeid="clickoutlineid"
            :demoId="activeDemo.id"
            :query-ids="queryIds"
            :ispre="isPreview"
            :active-node="activeNode"
          />
        </div>
      </template>
    </SplitPane>
    <UpdateDialog
      v-if="updatevisible"
      :visible.sync="updatevisible"
      :current="currentmode"
      :query-ids="queryIds"
      :type="authType"
      @finish="getColumnList"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import API from "@/utils/apiRequest";
import MindView from "./component/MindView.vue";
import TinyMce from "@/components/TinyMce.vue";
import { Playeditor } from "@/api/Playeditor";
import { ProductLibrary } from "@/api/ProductLibrary";
import UpdateDialog from "./dialog/UpdateDialog.vue";
import ProductName from "@/components/ProductName.vue";
import ContentView from "./component/ContentView.vue";
import ColumnView from "./component/ColumnView.vue";
import ArticleView from "./component/ArticleView.vue";
import LinkView from "./component/LinkView.vue";
import ProductView from "./component/ProductView.vue";
import FileView from "./component/FileView.vue";
import SplitPane from "@/components/SplitPane.vue";
import Sortable from "sortablejs";
export default {
  props: {
    isunit: {
      type: Boolean,
      default: false, //是否作为组件
    },
    readonly: {
      type: Boolean,
      default: false, //是否只读
    },
    unitqueryids: Object,
  },
  components: {
    MindView,
    TinyMce,
    UpdateDialog,
    ProductName,
    ContentView,
    ColumnView,
    ArticleView,
    LinkView,
    ProductView,
    SplitPane,
    FileView,
  },
  data() {
    return {
      moduleId: "",
      queryIds: {
        moduleId: "",
        subjectId: "",
        versionId: "",
      },
      authType: 0, //演示模式授权 (0.租户共有，1.个人所有)
      outlineTree: {}, // 演示提纲树
      clickoutlineid: "", // 点击的节点id
      demoList: [], //演示模式列表
      activeDemo: null,
      // isPreview: false,
      ispre: false,
      updatevisible: false, //编辑/添加弹窗
      currentmode: null,
      temporaryTree: {}, //暂存的演示树
      isfullscreen: false,
      sortData: {}, // 拖拽数据
      isResizing: false,
      startX: 0,
      showscheme: true,
      showdemo: true,
      activeNode: null,
    };
  },
  computed: {
    isPreview() {
      return this.readonly;
    },
    ...mapGetters(["moduleTypeDict", "hasNopowerList"]),
  },
  mounted() {
    if (this.unitqueryids) {
      this.queryIds.subjectId = this.unitqueryids.subjectId;
      this.queryIds.versionId = this.unitqueryids.versionId;
    } else {
      this.queryIds.subjectId = this.$route.query.subjectId;
      this.queryIds.versionId = this.$route.query.versionId;
    }
    this.getColumnList();
    this.$nextTick(() => {
      this.handleSort();
    });
    if (this.isPreview) {
      this.showscheme = false;
    }
  },
  watch: {},
  methods: {
    handleSort() {
      var that = this;
      var el = document.getElementById("edittabs");
      // 常用
      new Sortable(el, {
        onEnd: function (evt) {
          that.sortData = that.demoList[evt.oldIndex];
          that.Sort(that.sortData.id, evt.newIndex);
          // 获取排序之后的data数据
          that.demoList.splice(evt.newIndex, 0, that.demoList.splice(evt.oldIndex, 1)[0]);
          var newArray = that.demoList.slice(0);
          newArray.forEach((item, index) => {
            item.sortNo = index;
          });
          that.demoList = [];
          that.$nextTick(function () {
            that.demoList = newArray;
          });
        },
      });
    },
    async Sort(id, index) {
      await Playeditor.moveSubjectDemoMode({
        demoModeId: id,
        sortNo: index,
      });
    },
    /**
     * @description: 切换演示
     * @return {void}
     */
    async handleCurrent(value) {
      this.activeDemo = value;
      const res = await Playeditor.querySubjectDemoModeDetail(value.id);
      if (res && res.showProjectOutlineVos) {
        this.outlineTree = res.showProjectOutlineVos[0];
        this.temporaryTree = _.pick(
          res,
          "demoName",
          "subjectId",
          "versionId",
          "moduleId"
        );
        this.temporaryTree.authType = value.authType;
        this.temporaryTree.showProjectOutlineVo = res.showProjectOutlineVos[0];
        this.temporaryTree.demoModeId = value.id;
        this.clickoutlineid = res.showProjectOutlineVos[0].id;
      }
    },
    /**
     * @description: 添加/编辑演示
     * @return {void}
     */
    handleUpdate(value, type) {
      this.authType = type;
      this.currentmode = value;
      this.updatevisible = true;
    },
    /**
     * @description: 删除
     * @param {Object} value 删除项
     * @param {Number} index
     * @return {void}
     */
    handleDelete(value, index) {
      this.$showMessage(
        {
          message: `是否确认删除 <span style="font-weight: 1000;">${value.demoModeName}</span> ?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await Playeditor.deleteSubjectDemoMode({ demoId: value.id });
          done(res);
          if (res) {
            if (this.activeDemo && this.activeDemo.id === value.id) {
              this.activeDemo = index > 0 ? this.demoList[index - 1] : null;
            }
            this.demoList.splice(index, 1);
          }
        }
      );
    },
    /**
     * @description: 更改演示模式状态
     * @param value 点击项
     * @param type 状态
     * @return {void}
     */
    async handleChangeStatus(value, type) {
      let payload = {
        demoModeId: value.id,
        isDefault: value.isDefault,
        isShow: value.isShow,
      };
      payload[type] = value[type] ? 0 : 1;
      value[type] = value[type] ? 0 : 1;
      await Playeditor.settingSubjectDemoMode(payload);
    },
    /**
     * @description: 获取演示列表
     * @return {void}
     */
    async getColumnList() {
      const res = await ProductLibrary.getSubjectDetail(
        this.unitqueryids ? this.unitqueryids : this.$route.query
      );
      this.queryIds.moduleId = res.moduleId;
      this.demoList = _.sortBy(res.demoModes ? res.demoModes : [], "sortNo"); // 按 sortNo 排序
      if (this.demoList && this.demoList.length) {
        this.activeDemo = this.demoList[0];
        this.handleCurrent(this.demoList[0]);
      }
    },
    /**
     * @description:  编辑提纲
     * @param {Object} value 接收的编辑类型及节点信息
     * @param {Object} node 接收的节点详情
     * @return {void}
     */
    editornode(value, node) {
      if (value.evt === "select_node") {
        this.clickoutlineid = value.node;
        this.activeNode = node;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/demo.scss";
@import "~@/scss/common.scss";
.app-scheme-wrapper {
  background-color: #fff;
}
.demo-main {
  position: relative;
  overflow-x: auto;
  height: 100%;
  width: 100% !important;
}
.retract-icon {
  position: absolute;
  left: -18px;
  top: 50%;
  line-height: 22px;
  color: var(--color-text-primary);
  text-align: right;
  z-index: 3;
}
.scheme-left-outline-bar {
  width: 10px;
  height: 100%;
  background-color: #ebeef2;
}
.app-scheme-left-mind {
  width: 100% !important;
}
</style>
