<template>
  <div class="second-column">
    <div class="second-column-main">
      <div class="w-h-100" :class="{ preview: edittype == 2 || !isEdit }">
        <iframe v-if="!isEdit" id="mytextiframe" src="" frameborder="0" height="100%" width="100%"></iframe>
        <template v-else>
          <TinyMce v-if="queryIds && edittype == 2" :value.sync="richText" :module-id="queryIds.moduleId"
            :subject-id="queryIds.subjectId" :datatype="1" />
          <WebEdit v-if="queryIds && edittype == 1" ref="webedit" :value.sync="richText" :module-id="queryIds.moduleId"
            :subject-id="queryIds.subjectId" :datatype="1" />
        </template>
      </div>
    </div>
    <div class="second-column-header" v-if="!ispre">
      <div v-if="!isEdit" class="icon-edit">
        <svg-icon class="pointer" icon="bianji" :width="30" :height="30" @click.native="isEdit = !isEdit"></svg-icon>
      </div>
      <template v-else>
        <svg-icon class="pointer" icon="preview" :width="30" :height="30" @click.native="readonly = !readonly"></svg-icon>
        <svg-icon class="pointer" icon="save-icon" :width="26" :height="26" @click.native="confirmSave"></svg-icon>
      </template>
    </div>
    <el-dialog append-to-body class="ss-dialog" width="1000px" :visible.sync="readonly" title="预览">
      <div class="dialog-body">
        <iframe id="mytextiframe" src="" frameborder="0" height="100%" width="100%"></iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ProductLibrary } from "@/api/ProductLibrary";

import WebEdit from "@/components/WebEdit.vue";
import TinyMce from "@/components/TinyMce.vue";
export default {
  components: { WebEdit, TinyMce },
  props: {
    ispre: {
      type: Boolean,
      default: false,
    },
    clickitem: Object,
    parentcolumn: Object,
    queryIds: Object,
    productIds: Array,
    edittype: {
      type: String,
      default: "2",
    },
  },
  data() {
    return {
      richText: "",
      confirmLoading: false,
      queryData: {
        content: "",
        id: "",
        subjectColumnId: "",
      },
      readonly: false,
      // edittype: 1, //编辑器类型 1:网页编辑器 2:富文本编辑器
      isEdit: false,
    };
  },
  watch: {
    clickitem(val) {
      this.getDetail();
      this.isEdit = false;
    },
    readonly(val) {
      if (val) {
        this.$nextTick(() => {
          this.handleRender();
        });
      }
    },
    isEdit(val) {
      if (!val) {
        this.$nextTick(() => {
          this.handleRender();
        });
      }
    },
    ispre(val) {
      val && (this.isEdit = false)
    }
  },
  computed: {
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    /**
     * @description:获取介绍详情
     * @return {void}
     */
    async getDetail() {
      const res = await ProductLibrary.getSettingSubjectColumnContent(this.clickitem.id);
      this.queryData.subjectColumnId = res[0].subjectColumnId;
      this.queryData.id = res[0].id;
      this.richText = res[0].content ? res[0].content : "";
      if (this.readonly || !this.isEdit) {
        this.handleRender(this.richText);
      }
    },
    /**
     * @description:保存
     * @return {void}
     */
    async confirmSave() {
      this.confirmLoading = true;
      this.queryData.content =
        this.edittype == 1 ? this.$refs.webedit.getHTMLText() : this.richText;
      await ProductLibrary.saveChildColumnContent(this.queryData);
      this.getDetail();
      this.isEdit = false;
      this.confirmLoading = false;
    },
    /**
     * @description:使用iframe进行加载预览
     * @return {void}
     */
    handleRender() {
      let iframe = document.getElementById("mytextiframe");
      if (!iframe) return
      iframe.src = "about:blank";
      const text =
        this.edittype == 1 && this.$refs.webedit
          ? this.$refs.webedit.getHTMLText()
          : this.richText;
      setTimeout(() => {
        iframe && iframe.contentDocument.write(text);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.second-column {
  width: 100%;
  min-width: 800px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden;

  ::v-deep .ck.ck-content {
    padding-bottom: 110px;
  }

  .second-column-header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebeef2;
    .icon-edit{
      // background-color: rgba(82, 116, 253, 0.5);
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .svg-icon {
        margin: 0;
      }
    }
    .svg-icon {
      margin-right: 8px;

      &:hover {
        color: var(--color-primary) !important;
      }
    }
  }

  .second-column-main {
    flex: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .preview {
      max-width: 1080px;
    }
  }
}

.dialog-body {
  width: 100%;
  height: 65vh;
}
</style>
