<template>
  <div
    class="filelist"
    :class="{'pd-0':iswebh5}"
    v-if="dialogVisible"
    element-loading-background="rgba(40, 47, 57,0.8)"
  >
    <div class="file-right">
      <FileTemPreview
        :haslist="false"
        :currentitem.sync="currentitem"
        :color="'#344563'"
        :loginrequired="loginrequired"
        :iswebh5="iswebh5"
      />
      <div class="file-close" @click="dialogVisible = false">
        <img src="@/assets/images/common/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileTemPreview from "../filecompanies/FileTemPreview.vue";
export default {
  name: "ProductFileList",
  components: { FileTemPreview },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subject: Object,
    dialogTitle: String,
    haslist: {
      type: Boolean,
      default: true,
    },
    currentitem: Object,
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录，非登录的currentitem里面传参多加一个shareId
    },
    iswebh5: {
      type: Boolean,
      default: false,//是否是移动端查看
    },
  },
  data() {
    return {};
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo"]),
  },
  watch: {},
  beforeDestroy() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.filelist {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(40, 47, 57, 0.8);
  display: flex;
  padding: 80px;
  color: #fff;
  box-sizing: border-box;

  .file-right {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #838c93;
  }

  .file-close {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    z-index: 14;
    img {
      height: 20px;
      width: 20px;
      margin-left: 5px;
      margin-top: 5px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
.pd-0{
  padding: 0;
}
</style>
