<template>
  <el-dialog append-to-body class="ss-dialog" title="权限管理" width="460px" :visible.sync="dialogVisible">
    <div class="custom-body">
      <div class="permission-item pointer" @click="selectMembersVisible = true">选择人员</div>
      <div class="permission-item pointer mt-10" @click="selectDepartmentsVisible = true">选择部门</div>
      <div class="permission-item pointer mt-10" @click="selectRolesVisible = true">选择角色</div>
      <div class="permission-count">已设置 10</div>
      <div class="permission-wrapper">
        <div v-for="i in 10" class="permission-wrapper__item" :key="i">
          <div class="permission-item flex-1">人员/部门/角色</div>
          <el-popover placement="left" trigger="click">
            <div class="permission-wrapper__item-type" slot="reference">查看</div>
            <ul class="ul-permission">
              <li v-for="per in permissionList" class="li-permission" :key="per.key">
                <div>{{ per.label }}</div>
                <div class="description">{{ per.tips }}</div>
              </li>
              <el-divider></el-divider>
              <li class="li-permission delete">删除</li>
            </ul>
          </el-popover>
        </div>
      </div>
    </div>
    <SelectMembers v-if="selectMembersVisible" :visible.sync="selectMembersVisible" />
    <SelectDepartments v-if="selectDepartmentsVisible" :visible.sync="selectDepartmentsVisible" />
    <SelectRoles v-if="selectRolesVisible" :visible.sync="selectRolesVisible" />
  </el-dialog>
</template>

<script>
import SelectMembers from '../FilePermission/SelectMembers.vue';
import SelectDepartments from '../FilePermission/SelectDepartments.vue';
import SelectRoles from '../FilePermission/SelectRoles.vue';
import { FILE_PERMISSIONS } from "@/const.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  components: {
    SelectMembers,
    SelectDepartments,
    SelectRoles,
  },
  data() {
    return {
      selectMembersVisible: false,
      selectDepartmentsVisible: false,
      selectRolesVisible: false,
      show: false,
      permissionList: FILE_PERMISSIONS,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-body {
  padding: 20px 30px;

  .permission-item {
    width: fit-content;
    height: 30px;
    line-height: 30px;
    margin-left: 40px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -40px;
      top: 0;
      width: 30px;
      height: 30px;
      background-image: url("@/assets/images/file/permission.png");
      background-size: 30px 30px;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }

  .permission-count {
    font-size: 12px;
    color: var(--color-text-secondary);
    margin: 10px 0;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .permission-wrapper {
    height: 320px;
    overflow-y: auto;

    .permission-wrapper__item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .permission-wrapper__item-type {
        line-height: 30px;
        margin-left: 16px;
        padding-right: 20px;
        cursor: pointer;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          right: 7px;
          top: 13px;
          width: 6px;
          height: 5px;
          background-image: url("@/assets/images/common/triangle_down.png");
          background-size: 6px 5px;
          background-repeat: no-repeat;
          pointer-events: none;
          transition: all .3s;
        }

        &.show-popover {
          &::before {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.ul-permission {
  list-style: none;
  margin: 0;

  .li-permission {
    list-style-type: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #e6efff;
    }

    &.delete {
      &:hover {
        color: var(--color-danger-hover);
      }
    }

    .description {
      font-size: 12px;
      color: var(--color-text-secondary);
    }
  }

  .el-divider {
    margin: 4px 0;
  }
}
</style>