<template>
  <el-dialog append-to-body class="ss-dialog" title="选择部门" width="520px" :visible.sync="dialogVisible">
    <div v-loading="loading" class="content">
      <div class="content-left">
        <el-input v-model="searchName" clearable class="search-input" placeholder="请输入关键字搜索"
          @keyup.enter.native="handleSearch" @clear="handleSearch"></el-input>
        <div class="tree-name">
          <div class="tree-name__line"></div>
          <div class="tree-name__label">组织架构</div>
        </div>
        <div class="tree-container">
          <el-tree show-checkbox check-strictly class="tree" node-key="id" ref="tree" :data="treeData"
            :default-expanded-keys="expandedKeys" :expand-on-click-node="false" :filter-node-method="handleFilter"
            :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </div>
      </div>
      <div class="content-right">
        <div class="content-right-item" v-for="(i, index) in checkedList" :key="i.id" :title="i.title"
          @click="removeItem(i, index)">
          <div class="content-right-item__label">{{ i.title }}</div>
          <div class="content-right-item__icon"></div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <div class="custom-footer">
        <span class="summary">已选择 {{ checkedList.length }} 个部门</span>
        <el-button class="custom-footer-btn" type="primary" @click="handleConfirm">确&nbsp;定</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { Contacts } from "@/api/Contacts";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedList: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: true,
      searchName: "", // 搜索的关键字
      expandedKeys: [], // 默认展开的节点的 key 的数组
      treeData: [], // 展示数据
      defaultProps: {
        children: "children",
        label: "title",
      },
      checkedList: [], // 已选择的成员列表
    };
  },
  mounted() {
    if (this.selectedList.length) {
      this.checkedList = _.cloneDeep(this.selectedList);
    }
    this.getTree();
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    /**
     * @description: 获取树形数据
     * @return {void}
     */
    async getTree() {
      this.loading = true;
      this.treeData = await Contacts.queryTreeList();
      if (this.treeData.length) {
        this.expandedKeys = [this.treeData[0].id];
      }
      this.loading = false;
    },
    /**
     * @description: 关键字搜索
     * @return {void}
     */
    handleSearch() {
      this.$refs.tree.filter(this.searchName);
    },
    /**
     * @description: 对树节点进行筛选
     * @param {String} value 筛选字段
     * @param {Object} data 节点的data
     * @return {Boolean} true 显示，false 不显示
     */
    handleFilter(value, data) {
      return data.title.indexOf(value) > -1;
    },
    /**
     * @description: 节点被点击时的回调
     * @param {Object} data 节点对应的对象
     * @param {Node} node 节点对应的 Node
     * @return {void}
     */
    handleNodeClick(data, node) {
      this.$refs.tree.setChecked(data.id, !node.checked);
      const index = this.checkedList.findIndex(item => item.id === data.id);
      if (node.checked) {
        index === -1 && this.checkedList.push(data);
      } else {
        index !== -1 && this.checkedList.splice(index, 1);
      }
    },
    /**
     * @description: 移除
     * @param {value} 移除项
     * @param {index} 索引
     * @return {void}
     */
    removeItem(value, index) {
      this.checkedList.splice(index, 1);
      this.$refs.tree.setChecked(value.id, false);
    },
    /**
     * @description: 确定
     * @return {void}
     */
    handleConfirm() {
      this.$emit("finish", this.checkedList);
      this.dialogVisible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.friends-title {
  width: fit-content;
  height: 24px;
  cursor: pointer;

  .friends-title__icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }

  .friends-title__label {
    margin-left: 5px;
    font-size: 14px;
    vertical-align: middle;
  }
}

.content {
  display: flex;

  .content-left {
    box-sizing: border-box;
    width: 322px;
    height: 370px;
    padding: 16px 22px 16px 40px;
    border-right: 1px solid #e6ebf2;
    display: flex;
    flex-direction: column;

    .search-input {
      ::v-deep(.el-input__inner) {
        border-color: #f5f7fa;
        border-radius: 10px !important;
        background-color: #f5f7fa;

        &:focus {
          border-color: var(--color-primary);
        }
      }
    }

    .tree-input {
      width: 240px;

      ::v-deep .el-input__inner {
        height: 42px;
        line-height: 42px;
        border: none;
        border-radius: 10px;
        background: #f5f7fa;
      }
    }

    .tree-name {
      padding: 20px 0 8px;
      display: flex;
      align-items: center;

      .tree-name__line {
        width: 2px;
        height: 14px;
        background: #abb4c4;
      }

      .tree-name__label {
        font-size: 14px;
        font-weight: 500;
        color: #344563;
        margin-left: 5px;
      }
    }

    .tree-container {
      flex: 1;
      overflow: auto;

      .tree {
        color: #344563 !important;

        ::v-deep .el-tree-node__content {
          height: 43px;
          border-radius: 5px;

          &:hover {
            background-color: #fdfdfe;
            color: #6A87FC !important;
          }
        }

        ::v-deep .is-current>.el-tree-node__content {
          background-color: #e6efff;
        }
      }
    }
  }

  .content-mid {
    box-sizing: border-box;
    width: 360px;
    padding: 20px 0 20px 20px;
    border-right: 1px solid #e6ebf2;

    .content-mid-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #8997b0;
      margin-left: 5px;
      margin-right: 26px;
      margin-bottom: 20px;

      .content-mid-top-check {
        display: flex;
        align-items: center;
        cursor: pointer;

        .content-mid-top-check__icon {
          width: 18px;
          height: 18px;
          background-image: url("@/assets/images/common/success.png");
          background-size: 18px 18px;
          background-repeat: no-repeat;
        }

        .content-mid-top-check__label {
          margin-left: 6px;
          user-select: none;
        }

        &.is-checked {
          color: #344563;

          .content-mid-top-check__icon {
            background-image: url("@/assets/images/common/success_colored.png");
          }
        }
      }
    }

    .content-mid-member {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .content-mid-member-item {
        width: fit-content;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        margin: 0 5px 10px 5px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #e6ebf2;
        position: relative;
        cursor: pointer;

        &:hover {
          background-color: #fcfdfe;
        }

        &.is-active {
          background-color: #d8f2f0;
          border-color: #00baad;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 11px;
            height: 8px;
            background-image: url("@/assets/images/common/check_tag.png");
            background-size: 11px 8px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .content-right {
    padding: 20px 0 20px 20px;

    .content-right-item {
      width: fit-content;
      box-sizing: border-box;
      height: 32px;
      padding: 0 8px 0 16px;
      border-radius: 5px;
      background: #f5f7fa;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        .content-right-item__icon {
          background-image: url("@/assets/images/common/close_red.png");
        }
      }

      &+.content-right-item {
        margin-top: 18px;
      }

      .content-right-item__label {
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-weight: 500;
        padding-right: 8px;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content-right-item__icon {
        width: 16px;
        height: 16px;
        background-image: url("@/assets/images/common/close_16.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
    }
  }
}

.custom-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .summary {
    font-size: 14px;
    color: #8997b0;
    font-weight: 500;
  }

  .custom-footer-btn {
    width: 112px;
  }
}
</style>