<template>
  <el-drawer :visible.sync="dialogVisible" :direction="direction" :modal="false" :size="735" class="drawer-box">
    <div slot="title">
      <div class="title">
        <span class="flabel">传输列表 </span>
      </div>
    </div>
    <el-container class="w-h-100">
      <el-aside width="150px" class="h-100">
        <el-menu :default-active="activeTab" class="el-menu-vertical-demo">
          <!-- <el-menu-item index="1">
            <i class="el-icon-upload2"></i>
            <span slot="title">正在上传</span>
          </el-menu-item> -->
          <el-menu-item index="2">
            <i class="el-icon-document"></i>
            <span slot="title">传输记录</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main class="trans-table">
        <div class="flex header-search">
          <el-input class="header-input" v-model="queryData.fileName" placeholder="请输入搜索名称" style="width: 396px"
            size="small" clearable @keyup.enter.native="handleSearch" @clear="handleSearch"></el-input>
          <el-button class="ml-16" type="primary" size="small" @click="handleSearch">搜索</el-button>
          <div></div>
          <!-- <el-link icon="el-icon-brush" :underline="false">清除记录</el-link> -->
        </div>
        <el-table header-cell-class-name="app-header-cell" row-class-name="draggable-row" row-key="id" :data="tableData"
          v-loading="loading" height="calc(100% - 200px)">
          <!-- <el-table-column type="selection" width="45"> </el-table-column> -->
          <el-table-column label="名称" min-width="90px">
            <template slot-scope="{ row }">
              <div class="filename cloud-file-name ">
                <svg-icon @click.native="handlePreview(row)" :icon="row.icon" :color="row.color"
                  class="pointer"></svg-icon>
                <div class="one-line" :title="row.fileName">{{ row.fileName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="时间" min-width="160px">

            <template slot-scope="{ row }">
              <span>{{ row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="" prop="fileSize" min-width="90px">

            <template slot-scope="{ row }">
              <div>
                <!-- （传输状态（1:上传中，2:上传成功，3：下载完成,4:上传失败，5：下载失败） -->
                <i :class="row.transferStatus == 2 ? 'el-icon-upload2 green' : 'el-icon-download'"></i>{{
    row.transferStatus == 2 ? "上传完成" : "下载完成" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="fileSize" min-width="90px">

            <template slot-scope="{ row }">
              <el-link @click="handleOpen(row)" style="font-size: 16px;margin-right: 5px;" icon="el-icon-folder-opened"
                :underline="false"></el-link>
              <el-link @click="handleClear(row)" style="font-size: 16px;" icon="el-icon-brush"
                :underline="false"></el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="my-pagination">
          <span class="my-pagination-tip">共 {{ pagingData.total }} 个内容，当前展示数量 {{ pagingData.records.length }} </span>
          <el-pagination small background class="app-pagination" layout="prev, pager, next"
            :current-page="pagingData.current" :page-size="pagingData.size" :total="pagingData.total"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </el-main>
    </el-container>
    <FileDrawer v-if="showfloder" :visible.sync="showfloder" :current="currentfile" />
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import NETSYSTEM from "@/api/NETSYSTEM";
import FileDrawer from "./FileDrawer.vue";
import { checkFileIcon } from '@/utils/file';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { FileDrawer },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      activeTab: "2",
      tableData: [],
      queryData: {
        fileName: "",
        pageNo:1,
        pageSize:10,
        sortType:'desc'
      },
      currentfile: null,
      showfloder: false,
      loading: false,
      pagingData: new this.common.Pagination()
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo"]),
  },
  watch: {},
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * @description:获取传输记录
     * @return {void}
     */
    async getList() {
      this.loading = true
      const res = await NETSYSTEM.transferFileList(this.queryData);
      this.pagingData = res;
      res.records.forEach(element => {
        element.aliasType = this.common.getFileType(null, element.fileName);
        element.color = this.common.getFileColor(element.aliasType);
        element.icon = checkFileIcon(element.fileName);
      });
      this.tableData = res.records;
      this.loading = false;
    },
    handleOpen(value) {
      this.currentfile = value;
      this.showfloder = true;
    },
    handlePreview(value) { },
    handleClear(value) {
      this.$showMessage({
        message: `确定删除该记录吗?`,
        ...this.$global.deleteMessage
      }, async (done) => {
        const res = await NETSYSTEM.deleteTransferRecord(value.id);
        done(res);
        res && this.getList();
      })
    },
    handleSearch(){
      this.queryData.pageNo = 1;
      this.getList()
    },
    handleCurrentChange(){

    },
    /**
     * @description: currentPage 改变时会触发
     * @param {Number} value 当前页
     * @return {void}
     */
    handleCurrentChange(value) {
      this.queryData.pageNo = value;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer-box {
  height: 100%;
}

.trans-table {
  padding-top: 0;
  position: relative;
  height: 100%;
  .header-search {
    padding:10px 0 20px 0;
    align-items: center;
    height: 52px;
    line-height: 32px;
  }
  .my-pagination{
    width: 550px;
    position: absolute;
    bottom: 20px;
    box-sizing: border-box;
  }
}

.filename {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 7px;
}

.green {
  color: #0ddb1b;
}

.ml-16{
  margin-left: 16px;
}
</style>
