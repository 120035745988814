<template>
  <el-dialog append-to-body class="ss-dialog" width="800px" :visible.sync="dialogVisible">
    <div slot="title">
      <div class="dialog-title" @click="dialogVisible = false">
        <img class="title__icon" src="@/assets/images/common/arrow_left_black.png" alt="" />
        <span>返回</span>
      </div>
    </div>
    <div class="dialog-body">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="从用户库选择" name="select">
          <div class="content">
            <div class="content-left">
              <el-input v-model="queryUser.keyWord" class="tree-input" placeholder="输入关键词搜索" prefix-icon="el-icon-search"
                @keyup.enter.native="getTableData"></el-input>
              <div class="tree-name">
                <div class="tree-name__line"></div>
                <div class="tree-name__label">企业组织架构</div>
              </div>
              <div class="tree-container">
                <el-tree highlight-current class="tree" node-key="id" ref="tree" :data="departTree" :props="defaultProps"
                  @node-click="handleNodeClick"></el-tree>
              </div>
            </div>
            <div class="content-mid">
              <div class="content-mid-top">
                <span>部门成员</span>
                <div class="content-mid-top-check" :class="{ 'is-checked': isChecked }" @click="selectAll">
                  <div class="content-mid-top-check__icon"></div>
                  <div class="content-mid-top-check__label">全选</div>
                </div>
              </div>
              <div class="content-mid-member">
                <div v-for="i in tableData" class="content-mid-member-item" :key="i.id"
                  :class="{ 'is-active': friendMap.has(i.id) }" @click="selectFriend(i)">{{ i.nickName }}</div>
              </div>
            </div>
            <div class="content-right">
              <div class="content-right-item" v-for="i in friendList" :key="i.id" @click="deleteFriend(i)">
                <div class="content-right-item__label">{{ i.nickName }}</div>
                <div class="content-right-item__icon"></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="手动输入" name="input">
          <el-form label-width="100px" ref="user" size="small" :model="user" :rules="rules">
            <el-form-item label="姓名" prop="realName">
              <el-input v-model="user.realName" clearable style="width: 200px;"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="user.phone" clearable style="width: 200px;"></el-input>
            </el-form-item>
            <el-form-item label="微信号" prop="weChat">
              <el-input v-model="user.weChat" clearable style="width: 200px;"></el-input>
            </el-form-item>
            <el-form-item label="QQ号码" prop="qqNumber">
              <el-input v-model="user.qqNumber" clearable style="width: 200px;"></el-input>
            </el-form-item>
            <el-form-item label="邮箱地址" prop="email">
              <el-input v-model="user.email" clearable style="width: 200px;"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="footer" slot="footer">
      <el-button type="primary" @click="handleConfirm">添&nbsp;加</el-button>
      <span v-if="activeName === 'select'" class="footer-tip">已选择 {{ friendList.length }} 个好友</span>
    </div>
  </el-dialog>
</template>

<script>
import { Contacts } from "@/api/Contacts";
import { Departmen } from "@/api/Departmen";
export default {
  name: "SelectOrCreateUser",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    authType: Number,
    subjectId: String,
  },
  data() {
    return {
      activeName: "select",
      departTree: [],
      currentDepart: null,
      queryUser: {
        departId: "",
        keyWord: "",
      },
      queryData: {
        categoryId: "",
        keyWord: "",
        sortAsc: "",
        sortDesc: "",
        pageNo: 1,
        pageSize: 999,
      },
      categoryTree: [], // 好友分类
      currentCategory: null, // 选择的分类
      defaultProps: {
        children: "children",
        label: "title",
      },
      isChecked: false, // 是否全选
      tableData: [], // 好友列表
      friendList: [], // 已选好友列表
      friendMap: new Map(),
      user: {
        realName: "",
        phone: "",
        weChat: "",
        qqNumber: "",
        email: "",
      },
      rules: {
        realName: { required: true, message: "请输入姓名", trigger: ["blur", "change"] },
      },
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  mounted() {
    this.getDepartmentTree();
    // this.getCategoryList();
  },
  methods: {
    /**
     * @description: tab 被选中时触发
     * @param {VueComponent} tab 被选中的标签 tab 实例
     * @return {void}
     */
    handleClick(tab) {
      if (tab.name === "input") {
        this.$refs.user.clearValidate();
      }
    },
    /**
     * @description: 获取组织架构
     * @return {void}
     */
    async getDepartmentTree() {
      const res = await Departmen.departTree();
      this.departTree = res.treeList || [];
      this.$nextTick(() => {
        if (this.currentDepart) {
          this.$refs.tree.setCurrentKey(this.currentDepart.id);
        } else {
          if (this.departTree.length) {
            this.currentDepart = this.departTree[0];
            this.$refs.tree.setCurrentKey(this.currentDepart.id);
            this.queryUser.departId = this.currentDepart.id;
            this.getUserList();
          }
        }
      });
    },
    /**
     * @description: 获取部门成员
     * @return {void}
     */
    async getUserList() {
      this.tableData = await Departmen.getUserListByDepartId(this.queryUser);
    },
    /**
     * @description: 获取好友分类树
     * @return {void}
     */
    async getCategoryList() {
      this.categoryTree = await Contacts.getFriendCategoryTree();
      this.$nextTick(() => {
        if (this.currentCategory) {
          this.$refs.tree.setCurrentKey(this.currentCategory.id);
        } else {
          if (this.categoryTree.length) {
            this.currentCategory = this.categoryTree[0];
            this.$refs.tree.setCurrentKey(this.currentCategory.id);
            this.queryData.categoryId = this.currentCategory.id;
            this.getTableData();
          }
        }
      });
    },
    /**
     * @description: 获取好友列表
     * @return {void}
     */
    async getTableData() {
      let res = await Contacts.getFriendList(this.queryData);
      this.tableData = res.records;
      this.isChecked = this.isSelectAll(this.tableData, this.friendMap);
    },
    /**
     * @description: 判断是否全选
     * @param {Array} array 数组
     * @param {Map} map 已选数据 MAP
     * @return {Boolean} 是否全选
     */
    isSelectAll(array, map) {
      if (array.length === 0) {
        return false
      }
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (!map.has(element.id)) {
          return false
        }
      }
      return true
    },
    /**
     * @description: 节点被点击时的回调	
     * @param {Object} data 传递给 data 属性的数组中该节点所对应的对象
     * @return {void}
     */
    handleNodeClick(data) {
      // this.queryData.categoryId = data.id;
      // this.isChecked = false;
      // this.getTableData();
      this.queryUser.departId = data.id;
      this.isChecked = false;
      this.getUserList();
    },
    /**
     * @description: 选择好友
     * @param {Object} value 好友
     * @return {void}
     */
    selectFriend(value) {
      if (!this.friendMap.has(value.id)) {
        this.friendMap.set(value.id, value);
        this.friendList.push(value);
        this.isChecked = this.isSelectAll(this.tableData, this.friendMap);
      }
    },
    /**
     * @description: 全选/取消全选
     * @return {void}
     */
    selectAll() {
      if (this.tableData.length) {
        this.tableData.forEach(element => {
          if (this.isChecked) {
            if (this.friendMap.has(element.id)) {
              this.friendMap.delete(element.id);
            }
          } else {
            if (!this.friendMap.has(element.id)) {
              this.friendMap.set(element.id, element);
            }
          }
        })
        this.isChecked = !this.isChecked;
        this.friendList = [...this.friendMap.values()];
      }
    },
    /**
     * @description: 删除好友
     * @param {Object} value 好友
     * @return {void}
     */
    deleteFriend(value) {
      this.friendMap.delete(value.id);
      this.friendList = [...this.friendMap.values()];
      this.isChecked = this.isSelectAll(this.tableData, this.friendMap);
    },
    /**
     * @description: 确定
     * @return {void}
     */
    handleConfirm() {
      if (this.activeName === "select") {
        let list = [];
        this.friendList.forEach(element => {
          list.push({
            userType: "1",
            authType: this.authType,
            avatar: "",
            phone: element.phone,
            realName: element.nickName,
            subjectId: this.subjectId,
            tenantId: "",
            userId: element.friendUserId,
          })
        })
        this.$emit("finish", list);
      } else {
        this.$refs.user.validate(valid => {
          if (!valid) {
            return
          }
          const list = [{
            userType: "2",
            authType: this.authType,
            phone: this.user.phone,
            realName: this.user.realName,
            weChat: this.user.weChat,
            qqNumber: this.user.qqNumber,
            email: this.user.email,
            subjectId: this.subjectId,
          }]
          this.$emit("finish", list);
        })
      }
      this.dialogVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-title {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;

  .title__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.dialog-body {
  padding: 12px 30px 0;

  ::v-deep(.el-tabs__nav-wrap) {
    &::after {
      background-color: #fff;
    }
  }
}

.content {
  height: 400px;
  overflow: hidden;
  display: flex;

  .content-left {
    box-sizing: border-box;
    height: 460px;
    padding: 20px 16px;
    border-right: 1px solid #e6ebf2;
    display: flex;
    flex-direction: column;

    .tree-input {
      width: 240px;

      ::v-deep .el-input__inner {
        height: 42px;
        line-height: 42px;
        border: none;
        border-radius: 10px;
        background-color: #f5f7fa;
      }
    }

    .tree-name {
      padding: 20px 0 8px;
      display: flex;
      align-items: center;

      .tree-name__line {
        width: 2px;
        height: 14px;
        background: #abb4c4;
      }

      .tree-name__label {
        font-size: 14px;
        font-weight: 500;
        color: #344563;
        margin-left: 5px;
      }
    }

    .tree-container {
      flex: 1;
      overflow: auto;

      .tree {
        color: #344563 !important;

        ::v-deep .el-tree-node__content {
          height: 43px;
          border-radius: 5px;

          &:hover {
            background-color: #fdfdfe;
            color: #1664ff !important;
          }
        }

        ::v-deep .is-current>.el-tree-node__content {
          background-color: #e6efff;
        }
      }
    }
  }

  .content-mid {
    box-sizing: border-box;
    width: 300px;
    padding: 20px 5px 20px 20px;
    border-right: 1px solid #e6ebf2;

    .content-mid-top {
      height: 20px;
      line-height: 20px;
      margin: 0 15px 20px 0;
      font-size: 14px;
      font-weight: 500;
      color: #8997b0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content-mid-top-check {
        display: flex;
        align-items: center;
        cursor: pointer;

        .content-mid-top-check__icon {
          width: 18px;
          height: 18px;
          background-image: url("@/assets/images/common/success.png");
          background-size: 18px 18px;
          background-repeat: no-repeat;
        }

        .content-mid-top-check__label {
          margin-left: 6px;
          user-select: none;
        }

        &.is-checked {
          color: #344563;

          .content-mid-top-check__icon {
            background-image: url("@/assets/images/common/success_colored.png");
          }
        }
      }
    }

    .content-mid-member {
      height: 320px;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .content-mid-member-item {
        box-sizing: border-box;
        width: fit-content;
        height: 32px;
        line-height: 32px;
        padding: 0 16px;
        border: 1px solid #e6ebf2;
        border-radius: 5px;
        margin: 0 10px 10px 0;
        background-color: #fff;
        position: relative;
        cursor: pointer;

        &:hover {
          background-color: #fcfdfe;
        }

        &.is-active {
          background-color: #d8f2f0;
          border-color: #00baad;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 11px;
            height: 8px;
            background-image: url("@/assets/images/common/check_tag.png");
            background-size: 11px 8px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .content-right {
    box-sizing: border-box;
    flex: 1;
    padding: 20px 0 20px 20px;
    overflow-y: auto;

    .content-right-item {
      box-sizing: border-box;
      width: fit-content;
      max-width: 130px;
      height: 32px;
      padding: 0 8px 0 16px;
      border-radius: 5px;
      background: #f5f7fa;
      display: flex;
      align-items: center;
      cursor: pointer;

      .content-right-item__label {
        flex: 1;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content-right-item__icon {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        background-image: url("@/assets/images/common/close_16.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }

      &:hover {
        .content-right-item__icon {
          background-image: url("@/assets/images/common/close_red.png");
        }
      }

      &+.content-right-item {
        margin-top: 16px;
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  .footer-tip {
    font-size: 14px;
    font-weight: 500;
    color: #8997b0;
  }
}
</style>