<template>
  <div class="edit-main" :class="{ 'unit-preview': currentpayload }">
    <div v-loading="loading" class="preview demo">
      <div class="w-100 app-edit-header" :class="{ 'app-preview-header': isPreview && !titlefixed }">
        <div class="header-firstfloor flex align-items-center">
          <div class="w-h-100 flex align-items-center">
            <div class="header-title flex align-items-center">
              <ProductBack @handleback="handleBack" :currentpayload="currentpayload" />
              <img @click="handleBack" :src="coverList[0] && coverList[0].coverUrl" alt="" />
              <div class="header-name one-line" v-if="subject && !isPreview">
                <ProductName :name.sync="subject.subjectName" :subject="subject" />
              </div>
              <div class="header-name one-line preview-name" v-if="subject && isPreview" :title="subject.subjectName">
                {{ subject.subjectName }}
              </div>
            </div>
          </div>
          <div class="fixed-oprate flex-between align-items-center">
            <ProductStatusButton />
            <ProductHeaderHide v-if="isPreview" :titlefixed.sync="titlefixed" />
          </div>
        </div>

        <div class="header-interlayer" v-if="!acceptType.length"></div>
        <div class="header-interlayer-tools flex align-items-center"
          v-if="!isPreview && acceptType.length && showtype != 'demo'">
          <div v-if="acceptType.length" class="header-interlayer-tools-right flex align-items-center"
            @click="showrightbox = !showrightbox" :class="{ 'tool-active': showrightbox, }">
            <svg-icon icon="edit-fun" color="#344563" style="margin-left: 5px"></svg-icon>
          </div>
        </div>
      </div>

      <div class="app-scheme-wrapper preview-main" id="fullscreen_editor">
        <SplitPaneSides v-if="Viewstatus == 3" :initial-left-width="200" :initial-right-width="showscheme ? 180 : 44"
          :min-pane-right-width="showscheme ? 180 : 44" :min-pane-width="180" :dividerPosition="2">
          <template v-slot:left>
            <!-- 左侧内容插槽 -->
            <div class="app-scheme-left app-scheme-left-100" ref="leftcolumnwrapper">
              <div class="scheme-title flex-between align-items-center">
                <div class="scheme-title-name fw-700">产品栏目</div>
                <div class="flex align-items-center">
                  <svg-icon v-if="!ispre && !showscheme" class="mr-10 pointer" icon="mind-add" color="#5E6C84"
                    :height="12" :width="12" @click.native="showDialog(null, 'showColumn')"></svg-icon>
                </div>
              </div>
              <div class="scheme-title-list tree-wrapper">
                <el-tree v-if="columnList.length" :data="columnList" :props="treeProps" node-key="id"
                  :default-expanded-keys="defaultexpandedkeys" :expand-on-click-node="false" :draggable="true"
                  :drag-drop="true" :allow-drag="allowDrag" :allow-drop="allowDrop" @node-drag-end="handleDragEnd">
                  <!-- 自定义节点模板 -->
                  <div class="scheme-title-grouplist-noline" slot-scope="{ node, data }">
                    <div v-if="node.level == 1" class="scheme-title-group-parent flex align-items-center"
                      :class="{ 'active-child': (activeColumn && activeColumn.id == data.id) || (activefixedColumn && data.id == '-1') }"
                      @click="loadChild(data, node)">
                      <div class="flex-between w-100 align-items-center">
                        <div class="one-line">{{ data.columnName }}</div>
                        <div v-if="showleftbtn && data.id != '-1' && !showscheme" class="flex">
                          <svg-icon v-if="data.level == 1 && data.columntype == 1" class="pointer" icon="mind-add"
                            :width="16" :height="16"
                            @click.native.prevent.stop="handleCreatChild(data, null)"></svg-icon>
                          <svg-icon class="pointer" icon="edit" :width="16" :height="16"
                            @click.native.prevent.stop="handleCreatOrUpdate(data)"></svg-icon>
                          <svg-icon class="pointer" icon="delete" :width="16" :height="16"
                            @click.native.prevent.stop="delColumn(data)"></svg-icon>
                        </div>
                      </div>
                      <el-popover v-if="!ispre && !showscheme && !showleftbtn"
                        popper-class="toolbar-item-popover popover" placement="right-start" width="80" trigger="click"
                        :ref="'toolbar-' + data.id" :visible-arrow="false">
                        <div class="toolbar-more" slot="reference" @click.stop></div>
                        <div v-if="data.level == 1 && data.columntype == 1" class="popover-item"
                          @click.prevent.stop="handleCreatChild(data, null)"> 添加 </div>
                        <div v-if="columnCategoryTypeDict[data.columnCategoryId] != '3'" class="popover-item"
                          @click.prevent.stop="handleColumnShare(data)">
                          分享
                        </div>
                        <template v-if="data.id != '-1'">
                          <div class="popover-item" @click.prevent.stop="handleCreatOrUpdate(data)">
                            编辑
                          </div>
                          <div class="popover-divider"></div>
                          <div class="popover-item" @click.prevent.stop="delColumn(data)">
                            删除
                          </div>
                        </template>
                      </el-popover>
                    </div>
                    <div v-else>
                      <div class="scheme-title-group-child flex-between align-items-center" :class="{
                        'active-child': clickintroduce && clickintroduce.id == data.id,
                      }" @click="handleClick(data, node.parent.data)">
                        <el-checkbox v-if="showscheme" v-model="checkedNodes" :label="data.id"
                          @change="handlecheckChange(node.parent.data)">&nbsp;</el-checkbox>
                        <div class="scheme-title-group-child-name one-line flex align-items-center">
                          <img class="img-icon scheme-items-icon" :src="data.columnIcon && data.columnIcon.startsWith('http')
                            ? data.columnIcon
                            : require('@/assets/images/sample-5.png')
                            " alt="" />
                          <div :title="data.columnName" class="one-line">
                            {{ data.columnName }}
                          </div>
                        </div>
                        <div v-if="showleftbtn && !showscheme" class="flex">
                          <svg-icon class="pointer" icon="edit" :width="16" :height="16"
                            @click.native.prevent.stop="handleCreatOrUpdate(data)"></svg-icon>
                          <svg-icon class="pointer" icon="delete" :width="16" :height="16"
                            @click.native.prevent.stop="delColumn(data)"></svg-icon>
                        </div>
                        <el-popover v-if="!ispre && !showscheme && !showleftbtn"
                          popper-class="toolbar-item-popover popover" placement="right-start" width="80" trigger="click"
                          :ref="'toolbar-' + data.id" :visible-arrow="false">
                          <div class="toolbar-more" slot="reference" @click.stop></div>
                          <div class="popover-item" @click.prevent.stop="handleCreatOrUpdate(data)">
                            编辑
                          </div>
                          <div class="popover-divider"></div>
                          <div class="popover-item" @click.prevent.stop="delColumn(data)">
                            删除
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </el-tree>
                <FileTree v-if="subject" :subject="subject" :ispre="ispre" :showscheme="showscheme"
                  :showleftbtn="showleftbtn" :ischeck="activefixedColumn && activefixedColumn.type == 1"
                  @filelabelclick="handleLabelClick" @share="handleColumnShare" />
              </div>
            </div>
          </template>
          <!-- 中间内容插槽 -->
          <template v-slot:center>
            <div class="flex w-h-100" style="overflow: hidden;">
              <div class="app-scheme-main">
                <div class="column-introduce w-h-100">
                  <ColumnintroduceView v-if="clickintroduce" :clickitem="clickintroduce" :query-ids="queryIds"
                    :ispre="showscheme" :edittype="clickintroduce.editorType ? clickintroduce.editorType : '2'" />
                  <AssociateArticle v-if="activeColumn && columnCategoryTypeDict[activeColumn.columnCategoryId] === '5'"
                    :active-column="activeColumn" :subject="subject" :isedit="showscheme" :ispre="showscheme"
                    :schemeid="activeschemeid" :updatekey="updatekey" @handleselect="handleselect" />
                  <AssociateProduct v-if="activeColumn && columnCategoryTypeDict[activeColumn.columnCategoryId] === '3'"
                    :active-column="activeColumn" :subject="subject" @handleselect="handleselect" :isedit="showscheme"
                    :ispre="showscheme" :schemeid="activeschemeid" :updatekey="updatekey" />
                  <LinkColumn v-if="activeColumn && columnCategoryTypeDict[activeColumn.columnCategoryId] === '4'"
                    :subject="subject" :active-column="activeColumn" @handleselect="handleselect" :isedit="showscheme"
                    :ispre="showscheme" :schemeid="activeschemeid" :updatekey="updatekey" />
                  <ProductFile v-if="!activeColumn && activefixedColumn && activefixedColumn.type == 1 && !showscheme"
                    :subjectdata="subject" :isunit="true" :readonly="isPreview" :crumblist="breadcrumbList" />
                  <FileSelect v-if="!activeColumn && activefixedColumn && activefixedColumn.type == 1 && showscheme"
                    :subject="subject" @handleselect="handleselect" :schemeid="activeschemeid" :updatekey="updatekey"
                    :crumblist="breadcrumbList" />
                </div>
              </div>
              <!-- 产品 -->
              <div class="association-library-wrapper" v-if="!isPreview && showrightbox && acceptType.length">
                <Toolcenter :accept="acceptType" :selectids="selectids" />
              </div>
            </div>
          </template>
          <template v-slot:right>
            <!-- 右侧内容插槽 -->
            <SchemeList :showaside.sync="showscheme" :subject="subject" @selectdetail="handleReceiving"
              @labelclick="handleLabelClick" />
          </template>
        </SplitPaneSides>
        <ShowProject v-if="Viewstatus != 3" :subject="subject" :ispre="isPreview" :schemeactiveColumn="activeColumn"
          :schemeactivefixedColumn="activefixedColumn" @updatedata="handleSchemeUpdate" />
      </div>
    </div>
    <VersionList v-if="showVersionDialog" :current-version="currentVersion" :version-list="versionList"
      :visible.sync="showVersionDialog" @finish="setVersion" />
    <ColumnVue v-if="showColumn" :current="clickColumn" :visible.sync="showColumn" :subject="subject"
      :column-list="columnList" @finish="updateColumn" />
    <ColumnChild v-if="creatDialogVisible" :visible.sync="creatDialogVisible" :active-column="activeColumn"
      :query-ids="queryIds" :clickitem="edititem" @finish="getDetail" />
    <CommonShare v-if="shareVisible" :visible.sync="shareVisible" :shareinfo="prepareshareList" :subject-id="subject.id"
      :biz-type="7" />

  </div>
</template>
<script>
import SplitPaneSides from "../../components/SplitPaneSides.vue";
import { mapGetters } from "vuex";
import API from "@/utils/apiRequest";
import { ProductLibrary } from "@/api/ProductLibrary";
import { Module } from "@/api/Module";
import { File } from "@/api/File";
import pick from "lodash/pick";
import VersionList from "./dialog/VersionList.vue";
import CoverVue from "./components/Cover.vue";
import FilesVue from "./components/Files.vue";
import AssociationVue from "./components/Association.vue";
import ResourceView from "./components/ResourceView.vue";
import ColumnVue from "./dialog/Column.vue";
import MoreSetting from "./dialog/MoreSetting.vue";
import TinyMce from "@/components/TinyMce.vue";
import TextEditor from "./components/TextEditor.vue";
import ProductName from "@/components/ProductName.vue";
import AssociateArticle from "./components/AssociateArticle.vue";
import AssociateProduct from "./components/AssociateProduct.vue";
import LinkColumn from "./components/LinkColumn.vue";
import ShowProject from "./components/ShowProject.vue";
import ProductFile from "@/views/ProductFile/index.vue";
import UnitDemo from "@/views/DemoMode/UnitDemo.vue";
import Toolcenter from "../../components/ResourceTool/Toolcenter.vue";
import ProductBack from "../../components/Product/ProductBack.vue";
import EditType from "../../components/Product/EditType.vue";
import ProductStatusButton from "@/components/Product/ProductStatusButton.vue";
import ProductHeaderHide from "@/components/Product/ProductHeaderHide.vue";
import ColumnChild from "./dialog/ColumnChild.vue";
import ColumnintroduceView from "./components/ColumnintroduceView.vue";
import SchemeList from "./components/SchemeList.vue";
import FileSelect from "./components/scheme/FileSelect.vue";
import Sortable from "sortablejs";
import CommonShare from "../../components/AppShare/CommonShare.vue";
import FileTree from "./components/FileTree.vue";

export default {
  name: "productedit",
  components: {
    VersionList,
    CoverVue,
    FilesVue,
    AssociationVue,
    ColumnVue,
    MoreSetting,
    TinyMce,
    TextEditor,
    ResourceView,
    ProductName,
    AssociateArticle,
    AssociateProduct,
    LinkColumn,
    ShowProject,
    ProductFile,
    UnitDemo,
    Toolcenter,
    ProductBack,
    EditType,
    ProductStatusButton,
    ProductHeaderHide,
    ColumnChild,
    SplitPaneSides,
    ColumnintroduceView,
    SchemeList,
    FileSelect,
    CommonShare,
    FileTree
  },
  props: {
    currentpayload: Object,
    previewtype: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current: null,
      ispre: false,
      loading: true,
      subject: null, // 主题详情
      coverList: [], // 封面列表

      activeColumn: null,
      activeColumnIndex: 0,
      columnList: [], // 栏目清单
      clickColumn: null,
      showColumn: false,

      versionList: [], // 版本列表
      currentVersion: null,
      requestPayload: null, // 接口请求提交的参数

      isPreview: false,
      showVersionDialog: false, // 版本清单弹窗
      showSave: false,

      richText: "", // 富文本内容
      coverRemark: "", // 封面说明
      startTime: 0, // 开始时间
      categoryId: null,
      showproduct: false,
      showrightbox: false,
      showinput: false,
      configcolumnList: [], //已配置的栏目列表
      querycolumnData: {
        columnIds: [],
        isCheckDemoSystem: 0, //演示系统(0.否，1.是)
        moduleId: "",
        versionId: "",
      },
      activeName: "",
      fixedColumn: [
        // {
        //   id:'fixed'+1,
        //   name:'产品文件',
        //   type:1
        // },
        {
          id: "fixed" + 2,
          name: "展示方案",
          type: 2,
        },
        {
          id: "fixed" + 3,
          name: "演示方案",
          type: 3,
        },
      ], //固定的栏目
      activefixedColumn: null,
      acceptType: [], //资源类型 5文章
      selectids: [], //选择的资源资源
      pubsubToken: [],
      showLogs: false,
      showtype: "column", //当前编辑状态 栏目/方案
      isSchemeEdit: false, //展示方案编辑状态
      titlefixed: false, //是否固定页头
      isedit: false,
      clickintroduce: null, //点击的介绍项
      creatDialogVisible: false,
      queryIds: null,
      edititem: null,
      showscheme: false,
      columnkey: 1,
      checkedNodes: [], //选择的介绍
      treeProps: {
        children: "children",
        label: "columnName",
      },
      activeschemeid: "",//当前方案id
      updatekey: 1,//用来更新选择
      resizeObserver: null,
      showleftbtn: false,//显示左边的操作按钮
      breadcrumbList: [],//文件使用的面包屑
      shareVisible: false,//分享弹窗
      prepareshareList: [],//分享列表
      defaultexpandedkeys: [],//默认展开的节点
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "userInfo",
      "hasNopowerList",
      "columnCategoryTypeDict",
      "Viewstatus",
    ]),
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {
    showtype(val) {
      if (val != "column") {
        this.handlefixedSelect(2, this.fixedColumn[0]);
        this.$nextTick(() => {
          if (this.columnList.length) {
            this.changeColumn(this.columnList[0], 0);
          } else {
            this.handlefixedSelect(1);
          }
        });
      } else {
        this.isSchemeEdit = false;
        if (this.columnList.length) {
          this.changeColumn(this.columnList[0], 0);
        } else {
          this.handlefixedSelect(1);
        }
      }
    },
    Viewstatus(val) {
      this.isPreview = val != 3 ? true : false;
    },
    isPreview(val) {
      this.handlefixedSelect(2, this.fixedColumn[0]);
      this.showtype = "demo";
      this.$nextTick(() => {
        if (this.columnList.length) {
          this.changeColumn(this.columnList[0], 0);
        } else {
          this.handlefixedSelect(1);
        }
      });
    },
  },
  mounted() {
    this.current = this.currentpayload ? this.currentpayload : this.$route.query;
    this.getVersionList();
    this.pubsubToken.push(
      // 发布一个更新资源选择id的方法
      this.$Pubsub.subscribe("update-resource-select-data", (val) => {
        this.selectids = val;
      }),
      // 发布一个资源库显示隐藏的方法
      this.$Pubsub.subscribe("update-resource-visible", (val) => {
        if (val.type) {
          this.acceptType = val.type;
        }
        this.showrightbox = val.visible;
      })
    );

    if (this.$refs.leftcolumnwrapper) {
      this.resizeObserver = new ResizeObserver(this.calculateLeftWidth);
      this.resizeObserver.observe(this.$refs.leftcolumnwrapper);
    }
  },
  beforeDestroy() {
    this.pubsubToken.forEach((token) => {
      this.$Pubsub.unsubscribe(token);
    });
    this.pubsubToken = [];
    this.resizeObserver && this.resizeObserver.disconnect();
  },
  methods: {
    /**
     * @description: 获取容器宽度
     * @return {void}
     */
    calculateLeftWidth() {
      if (!this.$refs.leftcolumnwrapper) {
        return;
      }
      const containerWidth = this.$refs.leftcolumnwrapper.offsetWidth; // 获取容器宽度
      if (containerWidth > 255) {
        this.showleftbtn = true
      } else {
        this.showleftbtn = false
      }
    },
    /**
     * @description: 获取版本列表
     * @return {void}
     */
    async getVersionList() {
      this.versionList = await ProductLibrary.getVersions({
        mirrorId: this.current.mirrorId,
        subjectId: this.current.subjectId,
      });
      const current = this.versionList.find((item) => item.viewFlag === 1);
      if (current) {
        this.currentVersion = current;
        this.getDetail();
      }
    },
    /**
     * @description: 切换版本
     * @param {Object} value 目标版本
     * @return {void}
     */
    setVersion(value) {
      this.currentVersion = value;
      this.startTime = 0;
      this.getDetail();
    },
    /**
     * @description: 获取主题详情
     * @return {void}
     */
    async getDetail() {
      this.loading = true;
      this.activeColumn = null;
      const payload = {
        mirrorId: this.current.mirrorId,
        subjectId: this.currentVersion.subjectId,
        versionId: this.currentVersion.id,
      };
      let res = await ProductLibrary.getSubjectDetail(payload);
      if (res) {
        res.tags = res.tags.map((item) =>
          pick(item, ["bizType", "id", "moduleId", "tagName", "tenantId", "type"])
        );
        this.subject = res;
        this.coverList = res.coverList;
        if (this.coverList.length) {
          this.coverList.forEach((element) => (element.type = "success"));
        } else {
          const index = this.common.getLastChar(res.id);
          const objectURL = await this.common.convertImageToBase64(
            require(`@/assets/images/sample-${index}.png`)
          );
          this.coverList.push({
            coverType: 1,
            coverUrl: objectURL,
            id: Date.now(),
            file: { raw: this.common.dataURLtoFile(objectURL, "默认封面.png") },
            type: "ready",
          });
        }
        this.coverRemark = res.coverRemark;
        this.columnList = _.sortBy(res.subjectColumnVos, "sortNo"); // 按 sortNo 排序;
        // 过滤文件栏目
        this.columnList = this.columnList.filter(
          (item) => this.columnCategoryTypeDict[item.columnCategoryId] != "2"
        );
        this.defaultexpandedkeys = [];
        this.columnList.forEach((item) => {
          item.level = 1;
          // item.showchildren = true;
          item.columntype = this.columnCategoryTypeDict[item.columnCategoryId];
          this.defaultexpandedkeys.push(item.id)
        });
        // this.columnList.push({
        //   id: '-1',
        //   columnName: '文件',
        // })
        // this.defaultexpandedkeys.push('-1')
        if (this.isPreview) {
          this.handlefixedSelect(2, this.fixedColumn[0]);
          this.showtype = "demo";
        } else {
          if (this.columnList.length) {
            this.changeColumn(this.columnList[0], 0);
          } else {
            this.handlefixedSelect(1);
          }
        }
        this.isPreview = this.previewtype ? this.previewtype : this.ispre;
      }
      this.loading = false;
      this.queryIds = {
        moduleId: this.subject.moduleId,
        subjectId: this.subject.id,
        versionId: this.subject.versionId,
      };
    },
    /**
     * @description: 切换栏目
     * @param {Object} value 点击项
     * @param {Number} index 下标
     * @return {void}
     */
    changeColumn(value, index) {
      if (value.id == '-1') {
        this.handlefixedSelect(1)
        return
      }
      this.activefixedColumn = null;
      this.activeColumnIndex = index;
      this.clickintroduce = null;
      if (this.activeColumn && this.activeColumn.id === value.id) {
        return;
      }
      this.activeColumn = value;
      if (value.columntype == 1 && value.children.length) {
        this.handleClick(value.children[0], value)
      }
    },
    /**
     * @description: 显示弹窗
     * @param {Object} value 栏目
     * @param {String} visible 控制弹窗显隐字段
     * @return {void}
     */
    showDialog(value, visible) {
      this.clickColumn = value;
      this[visible] = true;
    },
    /**
     * @description: 删除栏目
     * @param {Object} value 删除的栏目
     * @return {void}
     */
    async delColumn(value) {
      this.$showMessage(
        {
          message: `是否确认删除 <span style="font-weight: 1000;">${value.columnName}</span> ?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const payload = {
            columnCategoryId: value.columnCategoryId,
            columnIcon: value.columnIcon,
            columnId: value.id,
            columnName: value.columnName,
            moduleId: this.subject.moduleId,
            id: this.subject.id,
            sortNo: value.sortNo,
            versionId: this.subject.versionId,
          };
          const res = await ProductLibrary.deleteColumn(payload);
          done(res);
          if (res) {
            this.getDetail();
          }
        }
      );
    },
    /**
     * @description: 新增、编辑栏目
     * @param {Object} value
     * @return {void}
     */
    updateColumn(value) {
      if (value.type === "add") {
        this.getDetail()
        // let obj = value.value;
        // // obj.id = "new-" + Date.now();
        // this.columnList.push(obj);
        // this.activeColumn = obj;
        // this.activeColumnIndex = this.columnList.length - 1;
        // this.activefixedColumn = null;
        // // this.ischeckLink = false;
        // this.richText = "";
      } else {
        const index = this.columnList.findIndex((i) => i.id === this.clickColumn.id);
        this.columnList[index] = { ...this.columnList[index], ...value.value };
      }
    },
    finish() {
      this.showSave = false;
      // this.$emit("finish");
      this.getVersionList();
      this.getDetail();
    },
    /**
     * @description: 固定栏目选择
     * @param {Number} type 类型 文件/演示
     * @param {Object} value 栏目
     * @return {void}
     */
    handlefixedSelect(type, value) {
      this.activeColumn = null;
      this.showrightbox = false;
      this.selectids = [];
      if (type == 1) {
        this.activefixedColumn = {
          id: "fixed" + 1,
          name: "产品文件",
          type: 1,
        };
        this.activeColumnIndex = this.columnList.length;
        this.clickintroduce = null;
      } else {
        this.isSchemeEdit = this.Viewstatus == 3 ? true : false;
        this.activeColumnIndex = null;
        this.activefixedColumn = value;
      }
    },
    handleBack() {
      this.dialogVisible = false;
      this.$emit("close");
    },
    /**
     * @description: 方案切换时默认回到第一个栏目
     * @return {void}
     */
    handleSchemeUpdate() {
      this.handlefixedSelect(2, this.fixedColumn[0]);
      this.$nextTick(() => {
        if (this.columnList.length) {
          this.changeColumn(this.columnList[0], 0);
        } else {
          this.handlefixedSelect(1);
        }
      });
    },
    /**
     * @description: 展开/收起介绍一级栏目
     * @return {void}
     */
    async loadChild(value, node) {
      const index = this.columnList.findIndex((item) => item.id == value.id);
      this.changeColumn(value, index);
    },
    /**
     * @description:编辑/预览 介绍子栏目
     * @param {Object} value 点击项
     * @return {void}
     */
    async handleClick(value, parent) {
      if (value && value.level == 1) return;
      this.clickintroduce = value;
      this.activeColumn = parent;
      this.activefixedColumn = null;
      this.acceptType = [];
    },
    /**
     * @description:新建子栏目
     * @return {void}
     */
    handleCreatChild(value, item) {
      this.edititem = item;
      // this.columnTypeId = value.id;
      this.creatDialogVisible = true;
    },
    /**
     * @description:编辑栏目或者子栏目
     * @param {Object} value 点击项
     * @return {void}
     */
    handleCreatOrUpdate(value) {
      if (value.level != 1) {
        this.edititem = value;
        this.creatDialogVisible = true;
      } else {
        this.showDialog(value, "showColumn");
      }
    },
    /**
    * @description:接收当前方案关联数据
    * @param {Array} val 关联数据
    * @param {String} schemeid 关联数据方案id
    * @return {void}
    */
    handleReceiving(val, schemeid) {
      this.activeschemeid = schemeid;
      const list = val.filter(item => item.columntype == 1)
      let ids = [];
      list.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.children.forEach(child => {
            ids.push(child.id);
          });
        }
      });
      this.checkedNodes = ids;
      this.updatekey++
    },
    /**
    * @description:checkbox改变事件
    * @param {Object} val 当前介绍的父节点
    * @return {void}
    */
    handlecheckChange(val) {
      const existingIds = this.checkedNodes.filter(id => val.children.some(item => item.id === id));
      const data = {
        dataIds: existingIds,
        dataType: 1,//主体类型（1.介绍 2.文件 3.案例 4.链接 5.文章）
        schemeId: this.activeschemeid,
        subjectColumnId: val.id,
        subjectId: this.subject.id,
      }
      this.handleselect(data)
    },
    /**
    * @description:触发保存关联事件
    * @param {Object} val 当前选择传参
    * @return {void}
    */
    handleselect(val) {
      this.$Pubsub.publish("update-column-select", val);
    },
    /**
    * @description:方案列表子节点点击事件
    * @param {Object} type 当前点击类型（1.介绍 2.文件 3.案例 4.链接 5.文章）
    * @param {Object} value 传递的参数
    * @return {void}
    */
    handleLabelClick(type, value) {
      if (type == 2) {
        this.handlefixedSelect(1);
        this.breadcrumbList = value;
      }
    },
    /**
    * @description:栏目分享
    * @param {Object} val 当前选择项
    * @return {void}
    */
    handleColumnShare(value) {
      this.prepareshareList = [{
        fileName: value.columnName,
        subjectColumnId: value.id,//主题/演示/展示/栏目各种id
        fileType: value.id == "-1" ? "-1" : this.columnCategoryTypeDict[value.columnCategoryId]//如果是文件就传-1
      }]
      this.shareVisible = true;
    },
    /**
     * @description:拖拽排序
     * @return {void}
     */
    async handleSort(node, index) {
      const res = await ProductLibrary.moveSubjectSort({
        id: node.id,
        sortNo: index + 1,
        versionId: node.versionId
      });
    },
    /**
     * @description:指定拖拽可以进入的节点
     * @return {void}
     */
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.level == 2) {
        return false;
      } else {
        return true;
      }
    },
    /**
     * @description:指定可拖拽的节点
     * @return {void}
     */
    allowDrag(draggingNode, dropNode, type) {
      return draggingNode.level == 1
    },
    /**
     * @description:拖拽结束事件
     * @return {void}
     */
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      const index = this.columnList.findIndex(item => item.id == draggingNode.data.id)
      this.handleSort(draggingNode.data, index)
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/demo.scss";
@import "~@/scss/common.scss";

.edit-main {
  width: 100%;
  height: 100%;
  display: flex;
  // font-size: 12px;
}

.preview {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ebeef2;
  overflow-x: auto;

  .preview-main {
    flex: 1;
    // height: calc(100% - 56px);
    overflow: auto;
    background-color: #fff;
    display: flex;
  }
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-10 {
  margin-right: 10px;
}

.none {
  display: none;
}

.unit-preview {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 13;
}

.app-scheme-main {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.tree-wrapper {
  ::v-deep .el-tree-node__content {
    height: 30px;
    // padding-left: 0 !important;
  }
}

.scheme-title-grouplist-noline {
  width: calc(100% - 20px);
  box-sizing: border-box;
  // font-size: 12px;
  color: var(--color-text-primary);

  .scheme-title-group-parent {
    height: 30px;
    line-height: 30px;
    width: 100%;
    padding: 0 4px;
    position: relative;
    box-sizing: border-box;

    &:hover {
      .toolbar-more {
        display: block;
      }

      background-color: #fcfdfe;
    }

    .toolbar-more {
      display: none;
      position: absolute;
      top: 50%;
      right: 16px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      background-image: url("@/assets/images/common/more_vertical.png");
      background-size: 12px 12px;
      background-repeat: no-repeat;
    }
  }


  .scheme-title-group-child {
    // width: calc(100% - 12px);
    width: 100%;
    height: 30px;
    line-height: 30px;
    // margin-left: 16px;
    position: relative;
    // padding: 0 4px 0 10px;
    padding: 0 4px 0 0;
    box-sizing: border-box;
    left: -10px;

    &:hover {
      .toolbar-more {
        display: block;
      }

      background-color: #fcfdfe;
    }

    .scheme-title-group-child-name {
      width: calc(100% - 12px);
    }

    img {
      width: 20px;
      height: 16px;
      margin-right: 5px;
      flex-shrink: 0;
    }

    .toolbar-more {
      display: none;
      position: absolute;
      top: 50%;
      right: 0px;
      width: 12px;
      height: 12px;
      transform: translateY(-50%);
      background-image: url("@/assets/images/common/more_vertical.png");
      background-size: 12px 12px;
      background-repeat: no-repeat;
    }

    .select-icon {
      flex-shrink: 0;
    }
  }

  .scheme-title-group-child_file {
    left: 0px;
  }

  .active-child {
    // background-color: #f1ebff;
    color: var(--color-primary);
    font-weight: 600;

    &:hover {
      // background-color: #f1ebff;
      color: var(--color-primary);
    }
  }

  .svg-icon {
    margin-left: 4px;

    &:hover {
      color: var(--color-primary) !important;
    }
  }
}


.border-none {
  border: none;
}

.mr-24 {
  margin-right: 24px;
}

.mr-8 {
  margin-right: 8px;
}

.column-introduce {
  overflow: hidden;
  // max-width: 1080px;
  margin: 0 auto;
}

.association-library-wrapper {
  height: 100%;
  width: 323px;
  border-left: 1px solid #ebeef2;
  background-color: #fff;
  flex-shrink: 0;
}
</style>
